import React, { Fragment } from 'react'
import Content from 'blocks.app/content/content'
import AdvertisingCampaignsAddMethods, { IState } from './advertisingCampaigns_add.local.methods'
import AdvertisingCampaignsAddSidebar from './__sidebar/advertisingCampaigns_add__sidebar'
import AdvertisingCampaignsAddEmpty from './__empty/advertisingCampaigns_add__empty'
import AdvertisingCampaignsAddSummary from './__summary/advertisingCampaigns_add__summary'
import Catalog from 'blocks.app/catalog/catalog'
import EditText from 'blocks.simple/editText/editText'
import Button from 'blocks.simple/button/button'
import Stick from 'blocks.simple/stick/stick'
import LoaderItem from 'blocks.app/loader/__item/loader__item'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import translate from 'core/translate'
import { cn } from 'ethcss'
import { text as textStyles } from 'theme'
import { grid } from 'theme'
import styles from './advertisingCampaigns_add.jcss'
import { Typography } from 'atoms/Typography'
import { Steps } from 'atoms/Steps/Steps-view'
import { RouteLeavingGuard } from 'molecules/CustomPromt'
import { changeLocation } from 'features/routes'
import { connect, RootStateOrAny, useSelector } from 'react-redux'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import { headActions } from 'features/head/Head.state'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import helpers from '../../../core/helpers'
import { checkAvailableModule } from '../../../core/helpers/routes/routes'
import { checkRoutePathName } from 'core/helpers/routes/routes'
import { getURLSearchParamsByLocation } from '../../../features/routes/utils'
import helpersFiles from '../../../core/helpers/_files'
import { SelectTime } from 'blocks.app/scheduleSettings/scheduleSettings'
import { Radio } from 'atoms/Radio'
import ShowMore from 'blocks.simple/showMore/showMore'
import Icon from 'blocks.simple/icon/icon'

const AdvertisingCampaignsAdd = (p_: any) => {
    const {
        state,
        setState,
        onChangeSetting,
        cancel,
        onSubmit,
        getSaveText,
        onContinue,
        onSelectContentInSidebar,
        onSelectDisplays,
        onChangeDuration,
        onChangeVolume,
        onChangeCommonVolume,
        isVolumeSupport,
        getVolumeDiff,
        onChangeRepetitionCount,
        changeStep,
        onSelectContent,
        isPreventLeaveRoute,
        isShouldBlockNavigation,
    } = AdvertisingCampaignsAddMethods(p_)
    const locationQuery = getURLSearchParamsByLocation(p_.location)
    const query = helpers.getQuery(locationQuery, 'files')
    const filesState: any = useSelector<RootStateOrAny>((state) => state.files)

    const getAdBlockSettings = () => {
        const s_ = state

        if (!s_.isAdvBlock) {
            return null
        }

        return (
            <React.Fragment>
                <div className={grid.row}>
                    <div className={cn(styles.editRow)}>
                        <EditText
                            label={translate('name')}
                            value={s_.name}
                            data-field="name"
                            onChange={onChangeSetting}
                        />
                    </div>
                    <div className={cn(styles.editRow)}>
                        <Dropdown
                            label={translate('duration')}
                            type="hmsTime"
                            wrapperWidth={true}
                            isNotDropdown={false}
                            mod="withBorder"
                            options={[
                                { id: 5, name: 5 },
                                { id: 10, name: 10 },
                                { id: 30, name: 30 },
                            ]}
                            indentSize={'mini'}
                            onChange={(value: any) =>
                                setState((prevState: IState) => {
                                    return {
                                        ...prevState,
                                        duration: value.name ? value.name : value,
                                        durationError: null,
                                    }
                                })
                            }
                            onChangeTimeFormat={(value: any) =>
                                setState((prevState: IState) => {
                                    return {
                                        ...prevState,
                                        hms: { [`${value}`]: true },
                                    }
                                })
                            }
                            hmsState={s_.hms}
                            value={s_.duration}
                            searchable={true}
                            notFilterOnSearch={true}
                            placeholderAsValue={true}
                            hideNotFound={true}
                        />
                    </div>
                </div>
                <div className={cn(grid.row, grid.mt_md)}>
                    <Checkbox
                        checked={s_.applyToNewDigitalSignage}
                        label={translate('applyToNewDisplays')}
                        onClick={(applyToNewDigitalSignage: boolean) => {
                            setState((prevState: IState) => {
                                return {
                                    ...prevState,
                                    applyToNewDigitalSignage,
                                }
                            })
                        }}
                    />
                </div>
            </React.Fragment>
        )
    }
    const getControls = (enabled = true) => (
        <Stick enabled={enabled}>
            <div className={styles.controls}>
                <Button className={cn(grid.mr_mini)} onClick={cancel}>
                    {translate('cancel')}
                </Button>
                <Button mod="fill" onClick={onSubmit}>
                    {getSaveText()}
                </Button>
            </div>
        </Stick>
    )

    const s_ = state
    const isFiles = s_.step === 'files'
    const timeOptions = helpers.getTimeOptions()

    if (s_.loading) {
        return (
            <Content className={cn(grid.rowCenter)} toolbar={<div />}>
                <LoaderItem small={true} />
            </Content>
        )
    }

    if (!s_.fillersAdBlockCreated) {
        return <AdvertisingCampaignsAddEmpty onContinue={onContinue} />
    }

    return (
        <Fragment>
            <Content
                toolbar={
                    <AdvertisingCampaignsAddSidebar
                        {...s_}
                        onChange={isFiles ? onSelectContentInSidebar : onSelectDisplays}
                        onEditItem={(selectedContentId: number) => {
                            setState((prevState: IState) => {
                                return {
                                    ...prevState,
                                    selectedContentId,
                                }
                            })
                        }}
                        selectedContent={
                            isFiles
                                ? state.content.find(
                                      (item: { sourceId: number }) => item.sourceId === state.selectedContentId
                                  )
                                : null
                        }
                        selectedFile={
                            isFiles
                                ? state.files.find((item: { id: number }) => item.id === state.selectedContentId)
                                : null
                        }
                        onChangeDuration={onChangeDuration}
                        onChangeVolume={onChangeVolume}
                        onChangeCommonVolume={onChangeCommonVolume}
                        getVolumeDiff={getVolumeDiff}
                        commonVolume={s_.commonVolume}
                        isVolumeDiff={s_.isVolumeDiff}
                        isVolumeSupport={isVolumeSupport}
                        onChangeRepetitionCount={onChangeRepetitionCount}
                        isAdvBlock={s_.isAdvBlock}
                    />
                }
            >
                <Steps active={s_.step}>
                    {s_.steps.map((step: { id: string; name: string }, index: number) => (
                        <div key={`advertisingCampaignsAdd_${index}`} id={step.id} onClick={() => changeStep(step.id)}>
                            {translate(step.name)}
                        </div>
                    ))}
                </Steps>
                {getControls()}
                {s_.step !== 'summary' && (
                    <div className={cn(styles.wrapper)}>
                        <div className={styles.settings}>
                            {s_.isAdvBlock && (
                                <Typography className={styles.title} type="title">
                                    {translate('advBlock')}
                                </Typography>
                            )}
                            <div className={cn(styles.inner, { [styles.none]: s_.step !== 'files' })}>
                                <div className={styles.inputWrapper}>
                                    <div className={styles.input}>
                                        {getAdBlockSettings()}
                                        {!s_.isAdvBlock && (
                                            <div>
                                                <div className={grid.row}>
                                                    <div className={cn(styles.editRow)}>
                                                        <EditText
                                                            label={translate('name')}
                                                            value={s_.name}
                                                            data-field="name"
                                                            onChange={onChangeSetting}
                                                        />
                                                    </div>
                                                    <div className={cn(styles.editRow, styles.noMargin)}>
                                                        <EditText
                                                            type={'area'}
                                                            label={translate('description')}
                                                            error={translate(s_.descriptionError)}
                                                            value={s_.description}
                                                            data-field="description"
                                                            onChange={onChangeSetting}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={cn(grid.row, grid.mt_md)}>
                                                    <div className={cn(styles.editRow)}>
                                                        <EditText
                                                            type={'date'}
                                                            label={translate('startDate')}
                                                            dateValue={s_.localStartDate}
                                                            minDate={s_.localStartDate}
                                                            maxDate={s_.localEndDate}
                                                            onChange={(startDate: string) => {
                                                                setState((prevState: IState) => {
                                                                    return {
                                                                        ...prevState,
                                                                        localStartDate: startDate,
                                                                        conflicts: [],
                                                                    }
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className={cn(styles.editRow, styles.noMargin, styles.zIndex)}>
                                                        <EditText
                                                            type={'date'}
                                                            label={translate('endDate')}
                                                            dateValue={s_.localEndDate}
                                                            minDate={s_.localStartDate}
                                                            onChange={(endDate: string) =>
                                                                setState((prevState: IState) => {
                                                                    return {
                                                                        ...prevState,
                                                                        localEndDate: endDate,
                                                                        conflicts: [],
                                                                    }
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                {helpers.isDevMode() &&
                                                    !checkRoutePathName('/advertisingCampaigns/add') &&
                                                    !checkRoutePathName('/advertisingCampaigns/edit') && (
                                                        <div className={cn(grid.row, grid.mt_md, grid.normalCenter)}>
                                                            <Dropdown
                                                                label={translate('insertionType')}
                                                                wrapperWidth={true}
                                                                isNotDropdown={false}
                                                                mod="withBorder"
                                                                options={[
                                                                    {
                                                                        id: 'betweenBroadcast',
                                                                        name: translate('betweenBroadcast'),
                                                                    },
                                                                    { id: 'onPause', name: translate('onPause') },
                                                                ]}
                                                                value={s_.insertionType}
                                                                onChange={(value: any) =>
                                                                    setState((prevState: IState) => {
                                                                        return {
                                                                            ...prevState,
                                                                            insertionType: value.id,
                                                                        }
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                {checkRoutePathName('/advertisingCampaigns/add') ||
                                                checkRoutePathName('/advertisingCampaigns/edit') ? (
                                                    <div className={cn(grid.row, grid.mt_md)}>
                                                        <div className={cn(styles.editRow)}>
                                                            <SelectTime
                                                                time={{
                                                                    label: translate('startTime'),
                                                                    type: 'tel',
                                                                    id: 'startTimeAdvCamp',
                                                                    mask: '99:99:99',
                                                                    value: s_.localStartTime,
                                                                    noFocus: true,
                                                                }}
                                                                timeOptions={timeOptions}
                                                                onChange={(value: string) =>
                                                                    setState((prevState: IState) => {
                                                                        return {
                                                                            ...prevState,
                                                                            startTime: value,
                                                                            localStartTime: value,
                                                                        }
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                        <div className={cn(styles.editRow, styles.noMargin)}>
                                                            <SelectTime
                                                                time={{
                                                                    label: translate('endTime'),
                                                                    type: 'tel',
                                                                    id: 'endTimeAdvCamp',
                                                                    mask: '99:99:99',
                                                                    value: s_.localEndTime,
                                                                    noFocus: true,
                                                                }}
                                                                timeOptions={timeOptions}
                                                                onChange={(value: string) =>
                                                                    setState((prevState: IState) => {
                                                                        return {
                                                                            ...prevState,
                                                                            endTime: value,
                                                                            localEndTime: value,
                                                                        }
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className={cn(grid.row, grid.mt_md, grid.normalCenter)}>
                                                        <div className={cn(grid.pr_mini)}>
                                                            {translate(
                                                                s_.insertionType === 'onPause'
                                                                    ? 'playAdEvery'
                                                                    : 'playAd'
                                                            )}
                                                        </div>
                                                        {s_.insertionType === 'onPause' && (
                                                            <Dropdown
                                                                id="repetitionIntervalMS"
                                                                type="hmsTime"
                                                                wrapperWidth={true}
                                                                isNotDropdown={false}
                                                                mod="withBorder"
                                                                options={[
                                                                    { id: 5, name: 5 },
                                                                    { id: 10, name: 10 },
                                                                    { id: 30, name: 30 },
                                                                ]}
                                                                indentSize={'mini'}
                                                                onChange={(value: any) =>
                                                                    setState((prevState: IState) => {
                                                                        return {
                                                                            ...prevState,
                                                                            repetitionIntervalMS: value.name
                                                                                ? value.name
                                                                                : value,
                                                                            conflicts: [],
                                                                        }
                                                                    })
                                                                }
                                                                onChangeTimeFormat={(value: any) =>
                                                                    setState((prevState: IState) => {
                                                                        return {
                                                                            ...prevState,
                                                                            hms: { [`${value}`]: true },
                                                                        }
                                                                    })
                                                                }
                                                                hmsState={s_.hms}
                                                                value={s_.repetitionIntervalMS}
                                                                searchable={true}
                                                                notFilterOnSearch={true}
                                                                placeholderAsValue={true}
                                                                hideNotFound={true}
                                                            />
                                                        )}

                                                        <div className={cn(grid.pl_mini, grid.pr_mini)}>
                                                            {translate('playAdFrom')}
                                                        </div>
                                                        <div className={cn(styles.minTimeWidth)}>
                                                            <SelectTime
                                                                time={{
                                                                    type: 'tel',
                                                                    id: 'startTimeAdvCamp',
                                                                    mask: '99:99:99',
                                                                    value: s_.localStartTime,
                                                                    noFocus: true,
                                                                }}
                                                                timeOptions={timeOptions}
                                                                onChange={(value: string) =>
                                                                    setState((prevState: IState) => {
                                                                        return {
                                                                            ...prevState,
                                                                            startTime: value,
                                                                            localStartTime: value,
                                                                        }
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                        <div className={cn(grid.pl_mini, grid.pr_mini)}>
                                                            {translate('playAdTo')}
                                                        </div>
                                                        <div className={cn(styles.minTimeWidth)}>
                                                            <SelectTime
                                                                time={{
                                                                    type: 'tel',
                                                                    id: 'endTimeAdvCamp',
                                                                    mask: '99:99:99',
                                                                    value: s_.localEndTime,
                                                                    noFocus: true,
                                                                }}
                                                                timeOptions={timeOptions}
                                                                onChange={(value: string) =>
                                                                    setState((prevState: IState) => {
                                                                        return {
                                                                            ...prevState,
                                                                            endTime: value,
                                                                            localEndTime: value,
                                                                        }
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                <span>
                                                    {(checkRoutePathName('/advertisingAds/add') ||
                                                        checkRoutePathName('/advertisingAds/edit')) && (
                                                        <div className={cn(grid.row, grid.mt_md)}>
                                                            <div className={cn(styles.editRow)}>
                                                                {helpers.isDevMode()
                                                                    ? translate(
                                                                          s_.insertionType === 'onPause'
                                                                              ? 'adWarning'
                                                                              : 'adOnBroadcastWarning'
                                                                      )
                                                                    : translate('adWarning')}
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className={cn(grid.row, grid.mt_md, grid.mb_md)}>
                                                        <div className={cn(styles.editRow, grid.row)}>
                                                            <ShowMore
                                                                trigger={
                                                                    <div className={styles.more}>
                                                                        <Icon
                                                                            containerClass={cn(grid.mr_micro)}
                                                                            type={
                                                                                s_.isShowAdvanced
                                                                                    ? 'arrow_up'
                                                                                    : 'arrow_down'
                                                                            }
                                                                            size={16}
                                                                        />
                                                                        {s_.isShowAdvanced
                                                                            ? translate('hide')
                                                                            : translate('more')}
                                                                    </div>
                                                                }
                                                                isShow={s_.isShowAdvanced}
                                                                onToggle={(isShowAdvanced: boolean) => {
                                                                    setState((prevState: IState) => ({
                                                                        ...prevState,
                                                                        isShowAdvanced: isShowAdvanced,
                                                                    }))
                                                                }}
                                                            >
                                                                <div
                                                                    className={cn(
                                                                        grid.row,
                                                                        grid.fullWidth,
                                                                        grid.p_mini,
                                                                        grid.mb_md
                                                                    )}
                                                                >
                                                                    <Radio
                                                                        className={cn(grid.p_micro)}
                                                                        selected={
                                                                            p_.timeToUse === 'utc'
                                                                                ? p_.timeToUse === 'utc'
                                                                                : s_.timeToUse === 'utc'
                                                                        }
                                                                        onClick={function () {
                                                                            setState((prevState: IState) => {
                                                                                return {
                                                                                    ...prevState,
                                                                                    timeToUse: 'utc',
                                                                                }
                                                                            })
                                                                        }}
                                                                        label={translate('useUtcTime')}
                                                                    />
                                                                    <Radio
                                                                        className={cn(grid.p_micro)}
                                                                        selected={
                                                                            p_.timeToUse === 'local'
                                                                                ? p_.timeToUse === 'local'
                                                                                : s_.timeToUse === 'local'
                                                                        }
                                                                        onClick={function () {
                                                                            setState((prevState: IState) => {
                                                                                return {
                                                                                    ...prevState,
                                                                                    timeToUse: 'local',
                                                                                }
                                                                            })
                                                                        }}
                                                                        label={translate('useLocalTime')}
                                                                    />
                                                                </div>
                                                            </ShowMore>

                                                            {/* <Dropdown
                                                                    id="repetitionIntervalMS"
                                                                    label={translate('repetitionIntervalMS')}
                                                                    type="hmsTime"
                                                                    wrapperWidth={true}
                                                                    isNotDropdown={false}
                                                                    mod="withBorder"
                                                                    options={[
                                                                        { id: 5, name: 5 },
                                                                        { id: 10, name: 10 },
                                                                        { id: 30, name: 30 },
                                                                    ]}
                                                                    indentSize={'mini'}
                                                                    onChange={(value: any) =>
                                                                        setState((prevState: IState) => {
                                                                            return {
                                                                                ...prevState,
                                                                                repetitionIntervalMS: value.name
                                                                                    ? value.name
                                                                                    : value,
                                                                                conflicts: [],
                                                                            }
                                                                        })
                                                                    }
                                                                    onChangeTimeFormat={(value: any) =>
                                                                        setState((prevState: IState) => {
                                                                            return {
                                                                                ...prevState,
                                                                                hms: { [`${value}`]: true },
                                                                            }
                                                                        })
                                                                    }
                                                                    hmsState={s_.hms}
                                                                    value={s_.repetitionIntervalMS}
                                                                    searchable={true}
                                                                    notFilterOnSearch={true}
                                                                    placeholderAsValue={true}
                                                                    hideNotFound={true}
                                                                /> */}
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className={cn(grid.col, grid.space)}>
                                    <Catalog
                                        filterItems={
                                            checkRoutePathName('/advertisingAds/edit') ||
                                            checkRoutePathName('/advertisingAds/add')
                                                ? [
                                                      { name: translate('images'), fileType: 'image', type: 'fs' },
                                                      { name: translate('video'), fileType: 'video', type: 'fs' },
                                                  ]
                                                : null
                                        }
                                        contentAndFolders={
                                            checkRoutePathName('/advertisingAds/edit') ||
                                            checkRoutePathName('/advertisingAds/add')
                                        }
                                        type={'files'}
                                        searchbarMod={'withShadow'}
                                        showHeaderButton={true}
                                        selectedItems={s_.files}
                                        onSelect={onSelectContent}
                                        disableFolderSelection={true}
                                        toggleControls={helpersFiles.isShowEditor(filesState, query)}
                                    />
                                </div>
                            </div>
                            <div className={cn(styles.inner, { [styles.none]: s_.step !== 'displays' })}>
                                <div className={cn(grid.col, grid.space)}>
                                    <Catalog
                                        type={'displays'}
                                        searchbarMod={'withShadow'}
                                        selectedItems={s_.displays}
                                        onSelect={onSelectDisplays}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {s_.step === 'summary' && (
                    <div className={styles.wrapper}>
                        <div className={styles.settings}>
                            <AdvertisingCampaignsAddSummary
                                summary={{
                                    content: s_.content,
                                    startDate: s_.localStartDate,
                                    endDate: s_.localEndDate,
                                    displays: s_.displays,
                                    name: s_.name,
                                    description: s_.description,
                                    applyToNewDigitalSignage: s_.applyToNewDigitalSignage,
                                }}
                                conflicts={s_.conflicts}
                                isAdvBlock={s_.isAdvBlock}
                            />
                        </div>
                    </div>
                )}
                <RouteLeavingGuard
                    when={isPreventLeaveRoute()}
                    shouldBlockNavigation={isShouldBlockNavigation}
                    navigate={(path) => changeLocation(path)}
                    title={translate('notSaved')}
                    description={translate('notSavedDescriptionAdv')}
                    rejectLabel={translate('exit')}
                    acceptLabel={translate('continue')}
                />
            </Content>
        </Fragment>
    )
}

const mapStateToProps = (state: any) => {
    return {
        settings: state.user.data.settings,
        redirect: state.user.redirect,
        isDeleted: state.user.isDeleted,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        emitError: (err: string) => dispatch(emitError(err)),
        setTitle: (title: string[]) => dispatch(headActions.setTitle(title)),
    }
}

const exportAdvertisingCampaigns = checkAvailableModule('advertisingCampaigns/add')
    ? connect(mapStateToProps, mapDispatchToProps)(AdvertisingCampaignsAdd)
    : null

export default exportAdvertisingCampaigns
