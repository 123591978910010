import { addStyles } from 'ethcss'
import { grid, item, IStyles } from '../../theme'
import { defaultThemeStyles } from './tabs.theme'

const tabsStyles: IStyles = {
    fullContent: {
        ...grid.full,
        ...grid.col,
        ...item.rel,
    },
    tab: {
        ...grid.space,
        ...grid.rowCenter,
        padding: '21px 10px',
        ...item.pointer,
        textAlign: 'center',
        background: `var(--var-module-ui-component-tabs-style-background, ${defaultThemeStyles.background})`,
        color: `var(--var-module-ui-component-tabs-style-textMain, ${defaultThemeStyles.textMain})`,
        borderRadius: '7px',
        marginRight: '10px',
        '&:hover': {
            background: `var(--var-module-ui-component-tabs-style-hoverBackground, ${defaultThemeStyles.hoverBackground})!important`,
            color: `var(--var-module-ui-component-tabs-style-hover, ${defaultThemeStyles.hover})!important`,
        },
        '&:last-child': {
            marginRight: 0,
        },
        textDecoration: 'none',
    },
    tabActive: {
        borderRight: `2px solid var(--var-module-ui-component-tabs-style-activeBorder, ${defaultThemeStyles.activeBorder})!important`,
        borderBottom: `2px solid var(--var-module-ui-component-tabs-style-activeBorder, ${defaultThemeStyles.activeBorder})!important`,
        background: `var(--var-module-ui-component-tabs-style-activeBackground, ${defaultThemeStyles.activeBackground})!important`,
        color: `var(--var-module-ui-component-tabs-style-active, ${defaultThemeStyles.active})!important`,
    },
}

addStyles(tabsStyles)
export default tabsStyles
