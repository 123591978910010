import React from 'react'
import Icon from 'blocks.simple/icon/icon'
import Button from 'blocks.simple/button/button'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import DropContent from 'blocks.simple/dropContent/dropContent'
import Tag from 'blocks.simple/tag/tag'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import momentTz from 'moment-timezone'
import DisplaysInfoMethods, {
    IDisplayInfoProps,
    ISelectedInfo,
    ISetMonitorWindow,
} from './displays__info.local.methods'
import { Scrollbars } from 'react-custom-scrollbars'
import ModalAccept from 'blocks.simple/modal/_accept/modal_accept'
import { appActions } from 'blocks.app/app/app.state'
import { cn } from 'ethcss'
import translate from 'core/translate'
import { colors, text as textStyles } from 'theme'
import { api } from 'core/api/ConnectionManager'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import toolbar from '../__toolbar/displays__toolbar.methods'
import toolbarContentStyles from '../__toolbarContent/displays__toolbarContent.jcss'
import styles from './displays__info.jcss'
import helpers from 'core/helpers'
import helpersDigitalSignages from 'core/helpers/_digitalSignages'
import template from 'es6-template-strings'
import { Typography } from 'atoms/Typography'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'
import { withRouter } from 'react-router'
import { displaysActions, getClientLogs } from '../displays.state'
import { connect } from 'react-redux'
import { CustomScroll } from '../../../atoms/CustomScroll'
import fileSize from 'filesize'
import moment from 'moment/moment'
import store from 'core/store'
import Dropdown from 'blocks.simple/dropdown/dropdown'

const noBroadcast = helpersDigitalSignages.getNoBroadcastIconSrc()

const getClientVersion = ({ newerClientVersion, id, status }: ISelectedInfo) => {
    if (newerClientVersion && (status === 'online' || status === 'error')) {
        return (
            <Icon
                type="update_soft"
                containerClass={cn(grid.ml_mdPlus, styles.iconWrapperPb)}
                size={20}
                color="white"
                tooltip={{
                    title: translate('updateVersion'),
                }}
                onClick={() => api.send('updatePlayer', { digitalSignageId: id })}
            />
        )
    }
}

const getIconParameterType = (key: string) => {
    switch (key) {
        case 'availableRfid':
            return 'question'
        default:
            return key
    }
}

const renderGroupedParameters = (selectedInfo: any, copyToClipboard: any, status: string) => {
    const mainInfoGroup = []
    const networkGroup = []
    const storageGroup = []

    const mainInfoKeys = [
        'id',
        'deviceId',
        'idCashRegisterGlobal',
        'dsVersion',
        'name',
        'description',
        'manufacturer',
        'modelName',
        'serialNumber',
        'platform',
        'rootEnabled',
        'firmwareVersion',
        'applicationURL',
        'orientation',
        'resolutionWidth',
        'resolutionHeight',
        'lastScreenshotDate',
        'lastScreenshot',
        'volume',
        'groupId',
        'companyId',
        'licenseId',
        'licenseType',
        'rateType',
        'updatedAt',
        'createdAt',
    ]

    const networkKeys = [
        'ipAddress',
        'firstDns',
        'secondDns',
        'macAddress',
        'imei',
        'connectionType',
        'lastConnection',
        'networkIpMode',
        'networkWiFiSecurityMode',
        'networkWiFiEncryptionType',
        'networkWiFiSignalStrengthLevel',
        'networkGateway',
        'networkSubnetMask',
        'networkWiFiSsid',
    ]

    const storageKeys = ['storageSize', 'usedStorageSize', 'freeStorageSize']
    let prettySysNameProp = selectedInfo.prettySysName ? ' (' + selectedInfo.prettySysName + ')' : ''

    for (let key in selectedInfo) {
        if (selectedInfo.hasOwnProperty(key)) {
            if (mainInfoKeys.includes(key)) {
                mainInfoGroup.push(
                    <div key={key} className={styles.paramsItem}>
                        <Typography type="title" className={cn(grid.w45)}>
                            {translate(key)}
                        </Typography>
                        <Typography type="title" className={styles.displayInfoParameterValue}>
                            {key === 'rootEnabled' ? (
                                <div
                                    onClick={copyToClipboard(selectedInfo[key])}
                                    dangerouslySetInnerHTML={{
                                        __html: selectedInfo[key],
                                    }}
                                    className={styles.wikiLink}
                                />
                            ) : (
                                <span onClick={copyToClipboard(selectedInfo[key])}>
                                    {key === 'platform' ? selectedInfo[key] + prettySysNameProp : selectedInfo[key]}
                                </span>
                            )}
                        </Typography>
                    </div>
                )
            }
            if (networkKeys.includes(key)) {
                networkGroup.push(
                    <div key={key} className={styles.paramsItem}>
                        <Typography type="title" className={cn(grid.w45)}>
                            {translate(key)}
                        </Typography>
                        <Typography type="title" className={styles.displayInfoParameterValue}>
                            <span onClick={copyToClipboard(selectedInfo[key])}>{selectedInfo[key]}</span>
                        </Typography>
                    </div>
                )
            }
            if (storageKeys.includes(key)) {
                storageGroup.push(
                    <div key={key} className={styles.paramsItem}>
                        <Typography type="title" className={cn(grid.w45)}>
                            {translate(key)}
                        </Typography>
                        <Typography type="title" className={styles.displayInfoParameterValue}>
                            <span onClick={copyToClipboard(selectedInfo[key])}>{selectedInfo[key]}</span>
                        </Typography>
                    </div>
                )
            }
        }
    }

    return (
        <>
            {mainInfoGroup.length ? (
                <div className={styles.groupList} style={{ width: '100%' }}>
                    <div className={`${styles.groupHeader} ${styles[status]}`}>
                        <Icon type="monitor" size={22} color={'#fff'} />
                        <div className={styles.headerCaption}>{translate('device')}</div>
                    </div>
                    {mainInfoGroup.map((element) => element)}
                </div>
            ) : null}
            {networkGroup.length ? (
                <div className={styles.groupList} style={{ width: '100%' }}>
                    <div className={`${styles.groupHeader} ${styles[status]}`}>
                        <Icon type="network" size={22} color={'#fff'} />
                        <div className={styles.headerCaption}>{translate('connection')}</div>
                        {/*<div className={styles.checkConnectionBtn}>{translate('measureSpeed')}</div>*/}
                    </div>
                    {networkGroup.map((element) => element)}
                </div>
            ) : null}
            {storageGroup.length ? (
                <div className={styles.groupList} style={{ width: '100%' }}>
                    <div className={`${styles.groupHeader} ${styles[status]}`}>
                        <Icon type="internal_storage" size={18} color={'#fff'} />
                        <div className={styles.headerCaption}>{translate('internalStorage')}</div>
                    </div>
                    {storageGroup.map((element) => element)}
                </div>
            ) : null}
        </>
    )
}

const getExternalStorageInfo = (data: any, status: string, copyToClipboard: any) => {
    if (!data) return null
    if (!data.externalStorages) return null

    return data.externalStorages.map((storage: any, index: number) => {
        return (
            <div key={index} className={styles.groupList} style={{ width: '100%' }}>
                <div className={`${styles.groupHeader} ${styles[status]}`}>
                    <Icon type="storage" size={18} color={'#fff'} />
                    <div className={styles.headerCaption}>Внешнее хранилище - {storage.label}</div>
                </div>
                <div className={styles.paramsItem}>
                    <Typography type="title" className={cn(grid.w45)}>
                        {translate('storageSize')}
                    </Typography>
                    <Typography type="title" className={styles.displayInfoParameterValue}>
                        <span onClick={copyToClipboard(fileSize(storage.size.totalBytes))}>
                            {fileSize(storage.size.totalBytes)}
                        </span>
                    </Typography>
                </div>
                <div className={styles.paramsItem}>
                    <Typography type="title" className={cn(grid.w45)}>
                        {translate('usedStorageSize')}
                    </Typography>
                    <Typography type="title" className={styles.displayInfoParameterValue}>
                        <span onClick={copyToClipboard(fileSize(storage.size.usedBytes))}>
                            {fileSize(storage.size.usedBytes)}
                        </span>
                    </Typography>
                </div>
                <div className={styles.paramsItem}>
                    <Typography type="title" className={cn(grid.w45)}>
                        {translate('freeStorageSize')}
                    </Typography>
                    <Typography type="title" className={styles.displayInfoParameterValue}>
                        <span onClick={copyToClipboard(fileSize(storage.size.availableBytes))}>
                            {fileSize(storage.size.availableBytes)}
                        </span>
                    </Typography>
                </div>
                <div className={styles.paramsItem}>
                    <Typography type="title" className={cn(grid.w45)}>
                        {translate('updatedAt')}
                    </Typography>
                    <Typography type="title" className={styles.displayInfoParameterValue}>
                        <span
                            onClick={copyToClipboard(
                                moment(data.externalStoragesUpdatedAt).format('DD/MM/YYYY HH:mm:ss')
                            )}
                        >
                            {moment(data.externalStoragesUpdatedAt).format('DD/MM/YYYY HH:mm:ss')}
                        </span>
                    </Typography>
                </div>
            </div>
        )
    })
}

const getLogLevel = (p_: IDisplayInfoProps) => {
    const item = p_.selectedInfo

    if (!item) return null

    const logLevel = item.settings.logLevel

    return (
        <div className={styles.logLevelInfo}>
            {logLevel === 'Off' ? (
                <>
                    {translate('logLevelIsOff')}
                    <b className={styles.goToSettings} onClick={() => goToDeviceSettings(p_)}>
                        {translate('inDeviceSettings')}
                    </b>
                </>
            ) : (
                <>
                    {translate('currentLogLevel')}: <b>{logLevel}</b>
                </>
            )}
        </div>
    )
}

const waitForDownload = () => {
    return <div className={styles.waitForDownload}>{translate('waitForDownload')}</div>
}

const goToDeviceSettings = (p_: IDisplayInfoProps) => {
    p_.closeModal()
    store.dispatch(displaysActions.openModal({ modalState: 'displaySettings', modalOptions: {} }))
}

class DisplaysInfo extends DisplaysInfoMethods {
    contextTypes = {}

    render() {
        const s_ = this.state
        const p_ = this.props
        const availableCommands = helpersDigitalSignages.getAvailableCommands(p_.selectedInfo)

        const orientationWidth = () => {
            return p_.selectedInfo &&
                p_.selectedInfo.settings &&
                p_.selectedInfo.settings.autoScreenshot &&
                p_.selectedInfo.settings.autoScreenshot.width
                ? p_.selectedInfo.settings.autoScreenshot.width
                : p_.selectedInfo.resolutionWidth && p_.selectedInfo.resolutionWidth
        }

        const orientationHeight = () => {
            return p_.selectedInfo &&
                p_.selectedInfo.settings &&
                p_.selectedInfo.settings.autoScreenshot &&
                p_.selectedInfo.settings.autoScreenshot.height
                ? p_.selectedInfo.settings.autoScreenshot.height
                : p_.selectedInfo.resolutionHeight
        }

        return (
            <Scrollbars style={{ width: '100%', height: '100%' }}>
                <div className={cn(grid.full, grid.p_md)}>
                    <div className={`${styles.iconUpdate} ${s_.isUpdatingScreenShot ? styles.iconUpdateAnim : ''}`}>
                        {helpers.isAvailableAction(p_.selectedInfo, {
                            key: 'digitalSignage',
                            action: 'update',
                            subaction: 'updateScreenshot',
                        }) && (
                            <Icon
                                type="update_tiny"
                                size={35}
                                tooltip={{
                                    title: translate('updateScreen'),
                                }}
                                disabled={p_.selectedInfo.status === 'offline'}
                                onClick={() => {
                                    this.enableRefreshAnimation()
                                    api.send('updateScreenshotCb', {
                                        [p_.selectedInfo.type === 'group' ? 'groupId' : 'id']: p_.selectedInfo.id,
                                        width: orientationWidth(),
                                        height: orientationHeight(),
                                    })
                                }}
                            />
                        )}
                    </div>
                    <div className={styles.topInfo}>
                        <div className={cn(grid.col, grid.w50, grid.mb_mini, grid.mt_mini, grid.center)}>
                            {p_.selectedInfo.lastScreenshotDate && (
                                <Typography type="title">
                                    {translate('screenDate')}{' '}
                                    {helpers.getFormattedLocalDate(
                                        p_.selectedInfo.lastScreenshotDate,
                                        'HH:mm - DD.MM.YYYY'
                                    )}
                                </Typography>
                            )}
                        </div>
                        <div className={cn(styles.tagList, styles.params)}>
                            {p_.selectedInfo.type !== 'group' && (
                                <div className={styles.tagWrapper}>
                                    {p_.selectedInfo.tags &&
                                        p_.selectedInfo.tags
                                            .filter((item, index: number) => {
                                                if (index < this.showCount) {
                                                    return true
                                                } else {
                                                    return s_.showAll
                                                }
                                            })
                                            .map((item, index: number) => (
                                                <Tag
                                                    className={styles.tagItem}
                                                    key={`tag_info_listItem${index}`}
                                                    value={item.name}
                                                />
                                            ))}
                                    {p_.selectedInfo.tags && p_.selectedInfo.tags.length > this.showCount && (
                                        <div className={grid.row} onClick={this.showAll}>
                                            <span
                                                className={cn(item.pointer, textStyles.mini, textStyles.underline)}
                                                onClick={this.showAll}
                                            >
                                                {s_.showAll ? translate('hide') : translate('showAll')}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={styles.wrapper}>
                        <div className={styles.screenshot}>
                            <div
                                className={cn(styles.image, grid.row, grid.p_micro, item.rel, {
                                    [styles.imageWithDsInfo]: s_.dsState,
                                })}
                            >
                                {p_.selectedInfo.type !== 'group' && (
                                    <Rectangle
                                        className={styles.imageContent}
                                        width={8}
                                        height={5}
                                        contain={!!p_.selectedInfo.cover}
                                        center={!p_.selectedInfo.cover}
                                        src={p_.selectedInfo.cover ? p_.selectedInfo.cover[0] : noBroadcast}
                                    />
                                )}
                                {s_.dsState && (
                                    <div className={styles.dsStateInfo}>
                                        <div className={cn(grid.row)}>
                                            {(() => {
                                                const parameters = []
                                                const dsState = { ...s_.dsState }

                                                if (dsState) {
                                                    dsState.startTime = s_.startTime
                                                }

                                                if (dsState.currentDate) {
                                                    if (dsState.timezone) {
                                                        dsState.currentDate = momentTz(dsState.currentDate)
                                                            .tz(dsState.timezone)
                                                            .format('HH:mm:ss YYYY-MM-DD')

                                                        if (s_.startTime) {
                                                            dsState.startTime = momentTz(s_.startTime)
                                                                .tz(dsState.timezone)
                                                                .format('HH:mm:ss YYYY-MM-DD')
                                                        }
                                                    } else {
                                                        dsState.currentDate = helpers.getFormattedLocalDate(
                                                            dsState.currentDate,
                                                            'HH:mm:ss YYYY-MM-DD'
                                                        )
                                                    }
                                                }

                                                for (let key in dsState) {
                                                    if (key === 'startTime' && !dsState[key]) {
                                                        continue
                                                    }

                                                    parameters.push(
                                                        <div key={key} className={styles.dsStateInfoLine}>
                                                            <div
                                                                className={cn(
                                                                    grid.row,
                                                                    grid.pl_mini,
                                                                    grid.noWrap,
                                                                    grid.normalCenter,
                                                                    grid.w50
                                                                )}
                                                            >
                                                                <Icon
                                                                    color="white"
                                                                    size={24}
                                                                    containerClass={cn(grid.mr_mini)}
                                                                    type={getIconParameterType(key)}
                                                                />
                                                                {translate(key)}
                                                            </div>
                                                            <div
                                                                className={cn(
                                                                    grid.row,
                                                                    grid.center,
                                                                    grid.normalCenter,
                                                                    grid.w50,
                                                                    textStyles.md,
                                                                    textStyles.wordWrap
                                                                )}
                                                            >
                                                                {this.getDsStateFormat(dsState, key)}
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                return parameters
                                            })()}
                                            {s_.times && (
                                                <div className={styles.dsStateInfoLine}>
                                                    <div
                                                        className={cn(
                                                            grid.row,
                                                            grid.pl_mini,
                                                            grid.noWrap,
                                                            grid.normalCenter,
                                                            grid.w50
                                                        )}
                                                    >
                                                        <Icon
                                                            color="white"
                                                            size={24}
                                                            containerClass={cn(grid.mr_mini)}
                                                            type="power_circle"
                                                        />
                                                        {translate('uptimeDevice')}
                                                    </div>
                                                    <div
                                                        className={cn(
                                                            grid.row,
                                                            grid.center,
                                                            grid.normalCenter,
                                                            grid.w50,
                                                            textStyles.md,
                                                            textStyles.wordWrap
                                                        )}
                                                    >
                                                        {template(translate('uptimeTime'), {
                                                            time: {
                                                                days: s_.times.diffDays,
                                                                hours: s_.times.diffTimes.split(':')[0],
                                                                minutes: s_.times.diffTimes.split(':')[1],
                                                                seconds: s_.times.diffTimes.split(':')[2],
                                                            },
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {p_.selectedInfo.type === 'group' && (
                                    <Rectangle
                                        width={16}
                                        height={9}
                                        className={cn(styles.imageContent, grid.row)}
                                        center={!p_.selectedInfo.cover}
                                        src={!p_.selectedInfo.cover ? noBroadcast : ''}
                                    >
                                        {p_.selectedInfo.cover.map((cover: string, index: number) => (
                                            <div key={`group__cover_${index}`} className={cn(grid.row, grid.w50)}>
                                                <Rectangle width={16} height={9} src={cover} />
                                            </div>
                                        ))}
                                    </Rectangle>
                                )}
                            </div>
                            <div className={cn(styles.buttons, styles[p_.selectedInfo.status], grid.mt_big)}>
                                {helpers.isAvailableAction(p_.selectedInfo, {
                                    key: 'digitalSignage',
                                    action: 'update',
                                    subaction: 'downloadLogs',
                                }) && (
                                    <div>
                                        {p_.selectedInfo.type !== 'group' && p_.selectedInfo.online && (
                                            <>
                                                <DropContent
                                                    isOpen={s_.getClientLogsMenu}
                                                    position="bottom center"
                                                    iconColor="white"
                                                    indents="none"
                                                    onClickOutside={() => this.setState({ getClientLogsMenu: false })}
                                                    customContent={
                                                        <div>
                                                            <div
                                                                className={toolbarContentStyles.removeGroupButton}
                                                                onClick={() => {
                                                                    p_.getClientLogs({
                                                                        id: p_.selectedInfo.id,
                                                                        actual: true,
                                                                    })
                                                                    this.setState({ getClientLogsMenu: false })
                                                                }}
                                                            >
                                                                {translate('actual_logs')}
                                                            </div>
                                                            <div
                                                                className={toolbarContentStyles.removeAll}
                                                                onClick={() => {
                                                                    p_.getClientLogs({
                                                                        id: p_.selectedInfo.id,
                                                                        actual: false,
                                                                    })
                                                                    this.setState({ getClientLogsMenu: false })
                                                                }}
                                                            >
                                                                {translate('full_logs')}
                                                            </div>
                                                        </div>
                                                    }
                                                >
                                                    <Button
                                                        className={cn(styles.getLogsBtn)}
                                                        mod="main"
                                                        onClick={this.toggleLoadLogsMenu}
                                                        disabled={p_.isConnected}
                                                    >
                                                        {p_.isConnected
                                                            ? translate('downloadMedia')
                                                            : translate('deviceLog')}
                                                    </Button>
                                                </DropContent>
                                                {p_.isConnected ? waitForDownload() : getLogLevel(p_)}
                                            </>
                                        )}
                                    </div>
                                )}
                                {p_.selectedInfo.status === 'online_display_off' && (
                                    <div className={styles.displayOffDiv}>{translate('displayOff')}</div>
                                )}
                                <div className={styles.icons}>
                                    {helpers.isAvailableAction(p_.selectedInfo, {
                                        key: 'digitalSignage',
                                        action: 'update',
                                        subaction: 'shutdown',
                                    }) && (
                                        <DropContent
                                            isOpen={s_.showShutdownMenu}
                                            position="bottom center"
                                            iconColor="white"
                                            indents="none"
                                            onClickOutside={() => this.setState({ showShutdownMenu: false })}
                                            customContent={
                                                <div>
                                                    {availableCommands.includes('reboot') && (
                                                        <div
                                                            className={toolbarContentStyles.removeGroupButton}
                                                            onClick={() => {
                                                                api.send('rebootDisplay', { id: p_.selectedInfo.id })
                                                                this.setState({ showShutdownMenu: false })
                                                            }}
                                                        >
                                                            {translate('reboot_device')}
                                                        </div>
                                                    )}
                                                    {availableCommands.includes('restartApp') && (
                                                        <div
                                                            className={toolbarContentStyles.removeGroupButton}
                                                            onClick={() => {
                                                                let data: {
                                                                    id?: number
                                                                    groupId?: number
                                                                } = {
                                                                    id: p_.selectedInfo.id,
                                                                }

                                                                if (p_.selectedInfo.type === 'group') {
                                                                    data = {
                                                                        groupId: p_.selectedInfo.id,
                                                                    }
                                                                }
                                                                api.send('digitalSignageSendRestartApp', data)
                                                                this.setState({ showShutdownMenu: false })
                                                            }}
                                                        >
                                                            {translate('restart_application')}
                                                        </div>
                                                    )}
                                                    {availableCommands.includes('shutdown') && (
                                                        <div
                                                            className={toolbarContentStyles.removeGroupButton}
                                                            onClick={() => {
                                                                this.setState({
                                                                    showShutdownMenu: false,
                                                                    openPowerModal: true,
                                                                })
                                                            }}
                                                        >
                                                            {translate('switchOff')}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                        >
                                            <Icon
                                                type="power"
                                                size={20}
                                                containerClass={cn(styles.iconWrapperPb)}
                                                color="white"
                                                tooltip={{
                                                    title: translate('power'),
                                                }}
                                                disabled={
                                                    p_.selectedInfo.type !== 'group' &&
                                                    (p_.selectedInfo.status === 'offline' ||
                                                        (p_.selectedInfo.status === 'online' &&
                                                            availableCommands.length === 0))
                                                }
                                                onClick={(e) => this.toggleShutdownMenu(e)}
                                            />
                                        </DropContent>
                                    )}
                                    {helpers.isAvailableAction(p_.selectedInfo, {
                                        key: 'digitalSignage',
                                        action: 'update',
                                    }) && getClientVersion(p_.selectedInfo)}
                                    {helpers.isAvailableAction(p_.selectedInfo, {
                                        key: 'digitalSignage',
                                        action: 'update',
                                        subaction: 'clearDeviceStorage',
                                    }) &&
                                        p_.selectedInfo.online && (
                                            <DropContent
                                                isOpen={s_.showCleanMenu}
                                                position="bottom center"
                                                iconColor="white"
                                                indents="none"
                                                className_={styles.clearInfoClass}
                                                onClickOutside={() => this.setState({ showCleanMenu: false })}
                                                customContent={
                                                    <div className={cn(grid.p_md, textStyles.left)}>
                                                        <Typography type="title" className={cn(grid.mb_micro)}>
                                                            {translate('chooseTypeClear')}:
                                                        </Typography>
                                                        <Typography
                                                            type="text"
                                                            className={cn(textStyles.mini, grid.mb_md)}
                                                        >
                                                            {translate('deviceWillBeReboot')}
                                                        </Typography>
                                                        <Checkbox
                                                            checked={s_.clean.files}
                                                            className={cn(grid.pb_mini)}
                                                            label={translate('removeAllFilesAtDevice')}
                                                            onClick={() =>
                                                                this.setState({
                                                                    clean: this.isMultipleClearSupport(
                                                                        p_.selectedInfo.platform
                                                                    )
                                                                        ? {
                                                                              ...s_.clean,
                                                                              files: !s_.clean.files,
                                                                          }
                                                                        : { files: true },
                                                                })
                                                            }
                                                        />
                                                        <Checkbox
                                                            label={translate('clearLocalStorage')}
                                                            className={cn(grid.pb_mini)}
                                                            checked={s_.clean.database}
                                                            onClick={() =>
                                                                this.setState({
                                                                    clean: this.isMultipleClearSupport(
                                                                        p_.selectedInfo.platform
                                                                    )
                                                                        ? {
                                                                              ...s_.clean,
                                                                              database: !s_.clean.database,
                                                                          }
                                                                        : { database: true },
                                                                })
                                                            }
                                                        />
                                                        <Checkbox
                                                            label={translate('clearStatistic')}
                                                            checked={s_.clean.statistic}
                                                            onClick={() =>
                                                                this.setState({
                                                                    clean: this.isMultipleClearSupport(
                                                                        p_.selectedInfo.platform
                                                                    )
                                                                        ? {
                                                                              ...s_.clean,
                                                                              statistic: !s_.clean.statistic,
                                                                          }
                                                                        : { statistic: true },
                                                                })
                                                            }
                                                        />
                                                        <div className={cn(grid.row, grid.justify, grid.mt_md)}>
                                                            <div />
                                                            <div className={grid.row}>
                                                                <Button
                                                                    className={cn(grid.mr_mini)}
                                                                    onClick={() => {
                                                                        this.setState({ showCleanMenu: false })
                                                                    }}
                                                                >
                                                                    {translate('cancel')}
                                                                </Button>
                                                                <Button
                                                                    mod={'fill'}
                                                                    onClick={() => {
                                                                        api.send('clearDigitalSignageDiskSpace', {
                                                                            digitalSignageId: p_.selectedInfo.id,
                                                                            ...s_.clean,
                                                                        })
                                                                        this.setState({ showCleanMenu: false })
                                                                    }}
                                                                    disabled={
                                                                        !s_.clean.files &&
                                                                        !s_.clean.database &&
                                                                        !s_.clean.statistic
                                                                    }
                                                                >
                                                                    {translate('clean')}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            >
                                                <Icon
                                                    type="clean"
                                                    size={20}
                                                    color="white"
                                                    containerClass={cn(grid.ml_mdPlus, styles.iconWrapperPb)}
                                                    tooltip={{
                                                        title: translate('clean'),
                                                    }}
                                                    onClick={this.toggleCleanMenu}
                                                />
                                            </DropContent>
                                        )}
                                    {p_.selectedInfo.type !== 'group' &&
                                        p_.selectedInfo.online &&
                                        this.isMonitoringSupport(p_.selectedInfo.platform) && (
                                            <Icon
                                                type="monitoring"
                                                size={24}
                                                color="white"
                                                containerClass={cn(grid.ml_mdPlus, styles.iconWrapperPb)}
                                                tooltip={{
                                                    title: translate('monitoring'),
                                                }}
                                                onClick={() => {
                                                    p_.setMonitorWindow({
                                                        title: p_.selectedInfo.name,
                                                        type: 'digitalSignageLogMonitor',
                                                        options: {
                                                            name: p_.selectedInfo.name,
                                                            digitalSignageId: p_.selectedInfo.id,
                                                            status: p_.selectedInfo.status,
                                                        },
                                                    })
                                                }}
                                            />
                                        )}
                                    {p_.selectedInfo.type !== 'group' &&
                                        p_.selectedInfo.online &&
                                        this.isWebEngineInfoSupport(p_.selectedInfo.platform) && (
                                            <Icon
                                                type="webEngineInfo"
                                                size={24}
                                                color="white"
                                                containerClass={cn(grid.ml_mdPlus, styles.iconWrapperPb)}
                                                tooltip={{
                                                    title: translate('webEngineInfo'),
                                                }}
                                                onClick={() => {
                                                    api.send('webEngineInfo', { id: p_.selectedInfo.id })
                                                }}
                                            />
                                        )}
                                    {helpers.isAvailableAction(p_.selectedInfo, {
                                        key: 'digitalSignage',
                                        action: 'update',
                                        subaction: 'generalEditing',
                                    }) && (
                                        <Icon
                                            containerClass={cn(grid.ml_mdPlus, styles.iconWrapperPb)}
                                            type="pencil"
                                            size={20}
                                            color="white"
                                            tooltip={{
                                                title: translate('edit'),
                                            }}
                                            onClick={() => {
                                                if (helpersDigitalSignages.isFolder(p_.selectedInfo)) {
                                                    toolbar.editGroup(p_.selectedInfo)
                                                    return
                                                }

                                                toolbar.edit(p_.selectedInfo)
                                            }}
                                        />
                                    )}
                                    {helpers.isAvailableAction(p_.selectedInfo, {
                                        key: 'digitalSignage',
                                        action: 'delete',
                                    }) && (
                                        <DropContent
                                            text={`${translate('displayDeleteWarning')}?`}
                                            isOpen={s_.showDeleteMenu}
                                            position="bottom center"
                                            iconColor="white"
                                            indents={p_.selectedInfo.type === 'group' ? 'none' : 'mini'}
                                            onClickOutside={() => this.setState({ showDeleteMenu: false })}
                                            customContent={
                                                p_.selectedInfo.type === 'group' ? (
                                                    <div>
                                                        <div
                                                            onClick={() => this.removeGroup(p_.selectedInfo)}
                                                            className={toolbarContentStyles.removeGroupButton}
                                                        >
                                                            {translate('deleteGroup')}
                                                        </div>
                                                        <div className={toolbarContentStyles.removeAll}>
                                                            <Checkbox
                                                                label={translate('saveDisplays')}
                                                                onClick={() => this.toggleRemoveAll()}
                                                                checked={!s_.removeAll}
                                                            />
                                                        </div>
                                                    </div>
                                                ) : null
                                            }
                                            confirmButton={{
                                                onClick: this.deleteDisplay,
                                            }}
                                            cancelButton={{
                                                onClick: () => this.setState({ showDeleteMenu: false }),
                                            }}
                                        >
                                            <div
                                                className={`${grid.fullHeight} ${grid.colCenter} ${item.relative} ${grid.ml_mdPlus} ${styles.iconWrapperPb}`}
                                            >
                                                <Icon
                                                    type="delete"
                                                    size={20}
                                                    color="white"
                                                    tooltip={{
                                                        title: translate('delete'),
                                                    }}
                                                    onClick={(e) => this.toggleDeleteMenu(e)}
                                                />
                                            </div>
                                        </DropContent>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={styles.params}>
                            <div className={styles.copyToBufferIconWrapper}>
                                <Icon
                                    type="paste"
                                    size={24}
                                    tooltip={{ html: translate('copyToClipboard') }}
                                    onClick={this.copyParametersToClipboard}
                                    color={getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable}
                                />
                            </div>
                            <div className={styles.paramsList}>
                                <CustomScroll classNames={styles.scroll}>
                                    {renderGroupedParameters(
                                        { ...this.state.formattedParameters },
                                        this.copyParameterValueToClipboard,
                                        p_.selectedInfo.status
                                    )}
                                    {getExternalStorageInfo(
                                        s_.externalStorages,
                                        p_.selectedInfo.status,
                                        this.copyParameterValueToClipboard
                                    )}
                                </CustomScroll>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalAccept
                    modalInnerClassName={styles.modalAccept}
                    open={s_.openPowerModal}
                    onReject={this.closeAcceptModal}
                    onAccept={this.onAcceptModal}
                    onClose={this.closeAcceptModal}
                    description={translate(p_.selectedInfo.type === 'group' ? 'powerOffWarning_' : 'powerOffWarning')}
                    rejectLabel={translate('cancel')}
                    acceptLabel={translate('off')}
                />
                {s_.webEngineInfo && (
                    <div className={styles.webEngineModal}>
                        <div className={styles.webEngineModalInner}>
                            <div className={styles.webEngineModalClose} onClick={this.closeWebEngineInfo}>
                                <Icon
                                    type={'close'}
                                    color={colors.greyDark}
                                    size={16}
                                    tooltip={{ title: translate('close') }}
                                />
                            </div>
                            <Typography type={'title'}>
                                <div className={styles.webEngineInfoCaption}>{translate('webEngineInfo')}:</div>
                            </Typography>
                            <Typography>{s_.webEngineInfo}</Typography>
                        </div>
                    </div>
                )}
            </Scrollbars>
        )
    }
}

const mapStateToProps = (state: {
    displays: {
        isConnected: boolean
    }
    user: {
        data: {
            companyId: string
        }
    }
}) => {
    return {
        isConnected: state.displays.isConnected,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeModal: () => dispatch(displaysActions.closeModal()),
        getClientLogs: (logsData: { id: number; actual: boolean }) => dispatch(getClientLogs(logsData)),
        setMonitorWindow: (data: ISetMonitorWindow) => dispatch(appActions.setMonitorWindow(data)),
    }
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DisplaysInfo))
