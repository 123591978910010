import { font } from '../media'

declare var window: any

export type TThemeColorSchemeType = 'light' | 'smartplayer' | 'custom'

export interface IThemeColorSchemeVariables {
    [key: string]: string
}

export type TThemeColorSchemeVariablesMap = {
    [key in TThemeColorSchemeType]: IThemeColorSchemeVariables
}

export type TThemeColorTypes =
    | 'darkBlue'
    | 'blue'
    | 'blueDark'
    | 'blueLight'
    | 'blueWhite'
    | 'water'
    | 'darkCloud'
    | 'cloud'
    | 'success'
    | 'offline'
    | 'warning'
    | 'white'
    | 'black'
    | 'blackSemi'
    | 'blackLight'
    | 'grey'
    | 'greyLight'
    | 'greyModal'
    | 'clouds'
    | 'greyEditor'
    | 'greyEditor2'
    | 'error'
    | 'errorLight'
    | 'errorSaturated'
    | 'onlineGradient'
    | 'online'
    | 'onlineSecondary'
    | 'selected'
    | 'notSynchronized'
    | 'deepGrey'
    | 'brown'
    | 'middleGrey'
    | 'greyDark'
    | 'fadeGrey'
    | 'green'
    | 'blueAccent'
    | 'sCBlue'
    | 'violet'
    | 'displayOff'
    | 'notificationRed'

export type TThemeColors = {
    [color in TThemeColorTypes]: string
}

export const getThemeColors = (): TThemeColors => {
    return {
        darkBlue: '#003688',
        blue: '#005AE1',
        blueDark: '#677B99',
        blueLight: '#5088DC',
        blueWhite: '#E7EAF2',
        water: '#F2F4F7',
        darkCloud: '#A1B1CA',
        cloud: '#CFD4E4',
        success: '#19C061',
        warning: '#F2C94C',
        white: '#FFFFFF',
        black: '#191919',
        blackLight: '#494848',
        blackSemi: '#262626',
        grey: '#b3b3b3',
        greyLight: '#F0F0F0',
        greyModal: '#f6f9f9',
        clouds: '#eeeded',
        greyEditor: '#717575',
        greyEditor2: '#6B6B6B',
        error: '#E56E7C',
        errorLight: '#cb6e7b',
        errorSaturated: '#D61750',
        offline: '#828282',
        online: '#33C691',
        onlineSecondary: '#15E89C',
        onlineGradient: 'linear-gradient(180deg, #33C691 0%, #19C061 100%)',
        selected: '#97d1df',
        notSynchronized: '#8A5E34',
        deepGrey: '#303030',
        brown: '#373737',
        middleGrey: '#DBDBDB',
        greyDark: '#4f4f4f',
        fadeGrey: '#bdbdbd',
        green: '#2FC58A',
        blueAccent: '#2582E0',
        sCBlue: '#4E9BEA',
        violet: '#0B6EDF',
        displayOff: '#005939',
        notificationRed: '#DA2E61',
    }
}

export const colors = getThemeColors()

export const setThemeColorScheme = (currentThemeColorScheme: TThemeColorSchemeType | null, mainMenuTheme: any) => {
    const actualThemeColorScheme: TThemeColorSchemeType = currentThemeColorScheme || 'light'
    const themeCustomThemeColors = window.spconfig.themeColors || {}

    const themeColorSchemeVariables: TThemeColorSchemeVariablesMap = {
        light: {
            /** instancesCatalog */
            '--var-module-instancesCatalog-component-blockItem-style-background': colors.greyLight,
            '--var-module-instancesCatalog-component-listWrapper-style-background': colors.greyLight,
            /** settings */
            '--var-module-settings-component-settingsItem-style-background': colors.greyLight,
            /** Module: app, component: app */
            '--var-module-app-component-app-style-background': colors.white,
            '--var-module-app-component-app-style-backgroundSub': colors.grey,
            '--var-module-app-component-app-style-darkBackground': colors.blackLight,
            '--var-module-app-component-app-style-lightBackground': colors.clouds,
            '--var-module-app-component-app-style-border': colors.greyLight,
            '--var-module-app-component-app-style-borderContrast': colors.blackLight,
            '--var-module-app-component-app-style-borderActive': colors.darkCloud,
            '--var-module-app-component-app-style-textMain': colors.blackLight,
            '--var-module-app-component-app-style-textSub': colors.black,
            '--var-module-app-component-app-style-active': colors.black,
            '--var-module-app-component-app-style-disable': colors.grey,
            '--var-module-app-component-app-style-shadow': '0 4px 4px rgba(0, 0, 0, 0.25)',
            '--var-module-app-component-app-style-panel': colors.white,
            '--var-module-app-component-app-style-placeholder': colors.grey,
            '--var-module-app-component-app-style-fontFamily': font.main,
            '--var-module-app-component-app-style-error': colors.error,
            '--var-module-app-component-app-style-warning': colors.warning,
            '--var-module-app-component-app-style-online': colors.online,
            '--var-module-app-component-app-style-online-display-off': colors.displayOff,
            '--var-module-app-component-app-style-offline': colors.offline,
            /** Module: app, component: calendar */
            '--var-module-app-component-calendar-style-background': colors.white,
            '--var-module-app-component-calendar-style-header': colors.grey,
            '--var-module-app-component-calendar-style-headerText': colors.white,
            '--var-module-app-component-calendar-style-dayNumber': colors.grey,
            '--var-module-app-component-calendar-style-selectedDate': colors.greyLight,
            '--var-module-app-component-calendar-style-cellLine': colors.grey,
            '--var-module-app-component-calendar-style-lineShadow': '0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
            '--var-module-app-component-calendar-style-active-cellLine': colors.selected,
            /** Module: ui, component: icon */
            '--var-module-ui-component-icon-style-main': colors.blackLight,
            '--var-module-ui-component-icon-style-mainSub': colors.grey,
            '--var-module-ui-component-icon-style-disable': colors.grey,
            '--var-module-ui-component-icon-style-active': colors.blackSemi,
            '--var-module-ui-component-icon-style-light': colors.greyLight,
            /** Module: ui, component: switch */
            '--var-module-ui-component-switch-style-on': colors.blueLight,
            '--var-module-ui-component-switch-style-off': colors.cloud,
            '--var-module-ui-component-switch-style-onHandler': colors.blue,
            '--var-module-ui-component-switch-style-offHandler': colors.darkCloud,
            /** Module: ui, component: notifyBadge */
            '--var-module-ui-component-notifyBadge-style-textMain': colors.white,
            '--var-module-ui-component-notifyBadge-style-active': colors.blackLight,
            '--var-module-ui-component-notifyBadge-style-disable': colors.grey,
            '--var-module-ui-component-notifyBadge-style-success': colors.success,
            '--var-module-ui-component-notifyBadge-style-warning': colors.warning,
            '--var-module-ui-component-notifyBadge-style-error': colors.error,
            '--var-module-ui-component-notifyBadge-style-normal': colors.selected,
            '--var-module-ui-component-notifyBadge-style-online': colors.online,
            /** Module: ui, component: button */
            '--var-module-ui-component-button-style-default-background': colors.white,
            '--var-module-ui-component-button-style-default-shadow': 'none',
            '--var-module-ui-component-button-style-default-border': 'transparent',
            '--var-module-ui-component-button-style-default-textMain': colors.grey,
            '--var-module-ui-component-button-style-default-icon': colors.grey,
            '--var-module-ui-component-button-style-default-active': colors.blackLight,
            '--var-module-ui-component-button-style-fill-background': colors.greyDark,
            '--var-module-ui-component-button-style-fill-shadow': 'none',
            '--var-module-ui-component-button-style-fill-border': 'transparent',
            '--var-module-ui-component-button-style-fill-textMain': colors.white,
            '--var-module-ui-component-button-style-fill-icon': colors.grey,
            '--var-module-ui-component-button-style-fill-active': colors.blackLight,
            '--var-module-ui-component-button-style-error-background': colors.error,
            '--var-module-ui-component-button-style-error-shadow': 'none',
            '--var-module-ui-component-button-style-error-border': 'transparent',
            '--var-module-ui-component-button-style-error-textMain': colors.white,
            '--var-module-ui-component-button-style-error-icon': colors.white,
            '--var-module-ui-component-button-style-error-active': colors.error,
            '--var-module-ui-component-button-style-secondary-background': colors.selected,
            '--var-module-ui-component-button-style-secondary-textMain': colors.white,
            '--var-module-ui-component-button-style-secondary-border': 'transparent',
            '--var-module-ui-component-button-style-secondary-shadow': 'none',
            '--var-module-ui-component-button-style-secondary-icon': colors.white,
            '--var-module-ui-component-button-style-secondary-active': colors.blackLight,
            '--var-module-ui-component-button-style-withBorder-background': colors.white,
            '--var-module-ui-component-button-style-withBorder-shadow': 'none',
            '--var-module-ui-component-button-style-withBorder-border': colors.blackLight,
            '--var-module-ui-component-button-style-withBorder-activeBorder': colors.black,
            '--var-module-ui-component-button-style-withBorder-textMain': colors.blackLight,
            '--var-module-ui-component-button-style-withBorder-icon': colors.black,
            '--var-module-ui-component-button-style-withBorder-active': colors.blackLight,
            '--var-module-ui-component-button-style-withShadow-background': colors.white,
            '--var-module-ui-component-button-style-withShadow-border': 'transparent',
            '--var-module-ui-component-button-style-withShadow-shadow': '0px 4px 4px rgba(133, 133, 133, 0.25)',
            '--var-module-ui-component-button-style-withShadow-textMain': colors.black,
            '--var-module-ui-component-button-style-withShadow-icon': colors.black,
            '--var-module-ui-component-button-style-withShadow-active': colors.blackLight,
            '--var-module-ui-component-button-style-list-background': colors.white,
            '--var-module-ui-component-button-style-list-border': 'transparent',
            '--var-module-ui-component-button-style-list-shadow': '0px 4px 4px rgba(133, 133, 133, 0.25)',
            '--var-module-ui-component-button-style-list-textMain': colors.grey,
            '--var-module-ui-component-button-style-list-icon': colors.black,
            '--var-module-ui-component-button-style-list-active': colors.blackLight,
            /** Module: ui, component: range */
            '--var-module-ui-component-range-style-contrast-track': colors.blackLight,
            '--var-module-ui-component-range-style-contrast-rail': colors.white,
            '--var-module-ui-component-range-style-colored-track': colors.blackLight,
            '--var-module-ui-component-range-style-colored-rail': colors.white,
            '--var-module-ui-component-range-style-monochrome-track': colors.greyLight,
            '--var-module-ui-component-range-style-monochrome-rail': colors.grey,
            '--var-module-ui-component-range-style-handler': colors.blackLight,
            '--var-module-ui-component-range-style-handlerInactive': colors.blackLight,
            '--var-module-ui-component-range-style-counter': colors.blackLight,
            '--var-module-ui-component-range-style-icon': colors.blackLight,
            /** Module: ui, component: datepicker */
            '--var-module-ui-component-range-style-background': colors.white,
            '--var-module-ui-component-range-style-navigation': colors.greyLight,
            '--var-module-ui-component-range-style-selected': colors.selected,
            '--var-module-ui-component-range-style-border': colors.greyLight,
            '--var-module-ui-component-range-style-currentMonthText': colors.black,
            /** Module: ui, component: sideMenu */
            '--var-module-ui-component-sideMenu-style-icon': colors.white,
            '--var-module-ui-component-sideMenu-style-iconHover': colors.blueLight,
            '--var-module-ui-component-sideMenu-style-offline': colors.offline,

            /** Module: dashboard, component: memory */
            '--var-module-dashboard-component-memory-style-chart-color-1': colors.white,
            '--var-module-dashboard-component-memory-style-chart-color-2': colors.blueAccent,
            '--var-module-dashboard-component-memory-style-chart-color-3': colors.selected,
            '--var-module-dashboard-component-memory-style-chart-color-4': colors.online,
            '--var-module-dashboard-component-memory-style-chart-color-5': colors.grey,
            /** Module: dashboard, component: dashboard */
            '--var-module-dashboard-component-dashboard-style-online': colors.green,
            '--var-module-dashboard-component-dashboard-style-offline': colors.blackLight,
            '--var-module-dashboard-component-dashboard-style-error': colors.errorSaturated,
            '--var-module-dashboard-component-dashboard-style-updating': colors.warning,
            '--var-module-dashboard-component-dashboard-style-notSynchronized': colors.notSynchronized,
            /** Module: dashboard, component: network */
            '--var-module-dashboard-component-network-style-incoming': colors.blackLight,
            '--var-module-dashboard-component-network-style-outgoing': colors.darkCloud,
            /** Module: ui, component: typography */
            '--var-module-ui-component-typography-style-textPromo': colors.blackLight,
            '--var-module-ui-component-typography-style-textMain': colors.blackLight,
            '--var-module-ui-component-typography-style-textSub': colors.grey,
            '--var-module-ui-component-typography-style-textError': colors.errorLight,
            /** Module: ui, component: radio */
            '--var-module-ui-component-radio-style-textMain': colors.blackLight,
            '--var-module-ui-component-radio-style-background': colors.brown,
            '--var-module-ui-component-radio-style-border': colors.fadeGrey,
            '--var-module-ui-component-radio-style-active-border': colors.brown,
            /** Module: ui, component: card */
            '--var-module-ui-component-card-style-background': colors.white,
            '--var-module-ui-component-card-style-border': colors.white,

            /** Module: ui, component: actionBar */
            '--var-module-ui-component-actionBar-style-separator': colors.greyLight,
            '--var-module-ui-component-actionBar-style-border': colors.greyLight,
            '--var-module-ui-component-actionBar-style-iconBackground': colors.white,

            /** Module: ui, component: switchButton */
            '--var-module-ui-component-switchButton-style-fill-border': colors.grey,
            '--var-module-ui-component-switchButton-style-fill-handler': colors.white,
            '--var-module-ui-component-switchButton-style-fill-background': colors.grey,
            '--var-module-ui-component-switchButton-style-fill-activeBorder': colors.brown,
            '--var-module-ui-component-switchButton-style-fill-activeHandler': colors.white,
            '--var-module-ui-component-switchButton-style-fill-activeBackground': colors.brown,

            /** Module: ui, component: loader */
            '--var-module-ui-component-loader-style-selected': colors.selected,
            '--var-module-ui-component-loader-style-borderMain': colors.white,
            '--var-module-ui-component-loader-style-borders': colors.selected,
            '--var-module-ui-component-loader-style-inv-borderMain': colors.white,
            '--var-module-ui-component-loader-style-inv-borders': colors.selected,
            '--var-module-ui-component-loader-style-activeBackground': colors.darkBlue,
            '--var-module-ui-component-loader-style-background': colors.white,

            /** Module: ui, component: status */
            '--var-module-ui-component-marker-style-offline': colors.offline,
            '--var-module-ui-component-marker-style-online': colors.onlineSecondary,
            '--var-module-ui-component-marker-style-error': colors.errorSaturated,
            '--var-module-ui-component-marker-style-border': 'rgba(25, 25, 25, .1)',

            /** Module: app, component: user */
            '--var-module-app-component-user-style-textSub': colors.grey,
            /** Module: app, component: content */
            '--var-module-app-component-content-style-background': colors.white,
            '--var-module-app-component-contentBorder-style-background': colors.greyLight,
            /** Module: app, component: downloads */
            '--var-module-app-component-downloads-style-wrapperBorder': colors.black,
            '--var-module-app-component-downloads-style-border': colors.greyLight,
            '--var-module-app-component-download-progress-style-border': colors.greyLight,
            '--var-module-app-component-downloads-style-header': colors.blackLight,
            '--var-module-app-component-downloads-style-background': colors.white,
            '--var-module-app-component-downloads-style-textMain': colors.white,
            '--var-module-app-component-downloads-style-title': colors.grey,
            '--var-module-app-component-downloads-style-titleComplete': colors.black,
            '--var-module-app-component-downloads-style-progressComplete': colors.online,
            '--var-module-app-component-downloads-style-progressOver': colors.grey,
            '--var-module-app-component-downloads-style-active': colors.black,
            '--var-module-app-component-downloads-style-disable': colors.grey,
            /** Module: ui, component: progressBar */
            '--var-module-ui-component-progressBar-style-progressPath': colors.blackLight,
            '--var-module-ui-component-progressBar-style-progressTrail': colors.grey,
            /** Module: app, component: panel */
            '--var-module-app-component-panel-style-background': colors.white,

            /** Module: device, component: info */
            '--var-module-device-component-info-style-deviceState': 'rgba(0, 0, 0, 0.7)',
            '--var-module-device-component-info-style-deviceStateText': colors.white,
            '--var-module-device-component-info-style-button': colors.white,
            '--var-module-device-component-info-style-imageContent': colors.blackLight,
            '--var-module-device-component-info-style-modalAccept': colors.darkCloud,
            /** Module: device, component: audio */
            '--var-module-device-component-audio-style-background': 'rgba(0, 0, 0, 0.9)',
            '--var-module-device-component-audio-style-text': colors.white,
            '--var-module-device-component-audio-style-error': colors.error,

            /** Module: device, component: group */
            '--var-module-device-component-group-style-deleteScheduleOdd': colors.greyLight,
            '--var-module-device-component-group-style-listItem': colors.blackLight,

            /** Module: ui, component: collapse */
            '--var-module-ui-component-collapse-style-textMain': colors.blackLight,
            /** Module: app, component: editor */
            '--var-module-app-component-editor-style-backgroundMain': colors.greyEditor,
            '--var-module-app-component-editor-style-backgroundSub': colors.grey,
            '--var-module-app-component-editor-style-border': colors.white,
            '--var-module-app-component-editor-style-textMain': colors.black,
            '--var-module-app-component-editor-style-textSub': colors.white,
            '--var-module-app-component-editor-style-backgroundContent': colors.blackLight,
            '--var-module-app-component-editor-style-selectLine': colors.selected,
            '--var-module-app-component-editor-style-backgroundLight': colors.greyLight,
            /** Module: editor, component: animation */
            '--var-module-editor-component-animation-style-text': colors.white,
            '--var-module-editor-component-animation-style-col1': colors.blackLight,
            '--var-module-editor-component-animation-style-col2': colors.greyEditor2,
            '--var-module-editor-component-animation-style-col3': colors.greyEditor,
            '--var-module-editor-component-animation-style-setting': colors.grey,
            '--var-module-editor-component-animation-style-settingActive': colors.white,
            '--var-module-editor-component-animation-style-sampleBackground': colors.grey,
            /** Module: editor, component: templates */
            '--var-module-editor-component-templates-style-template': colors.grey,
            '--var-module-editor-component-templates-style-template-title': colors.grey,
            '--var-module-editor-component-templates-style-template-border': colors.white,
            /** Module: editor, component: item */
            '--var-module-editor-component-item-style-text': colors.white,
            '--var-module-editor-component-item-style-textSecondary': colors.darkCloud,
            '--var-module-editor-component-item-style-link': colors.cloud,
            '--var-module-editor-component-item-style-linkText': colors.darkCloud,
            '--var-module-editor-component-item-style-line': colors.white,
            '--var-module-editor-component-item-style-lineSelected': colors.selected,
            '--var-module-editor-component-item-style-inputFile': colors.cloud,
            /** Module: editor, component: preview */
            '--var-module-editor-component-preview-style-background': colors.darkCloud,
            '--var-module-editor-component-preview-style-timelineBackground': 'rgba(0, 0, 0, 0.25)',
            '--var-module-editor-component-preview-style-timeline': colors.white,
            /** Module: editor, component: timeline */
            '--var-module-editor-component-timeline-style-text': colors.white,
            '--var-module-editor-component-timeline-style-lineBorder': colors.white,
            '--var-module-editor-component-timeline-style-border': colors.grey,
            '--var-module-editor-component-timeline-style-list-border': colors.darkCloud,
            '--var-module-editor-component-timeline-style-list-background': colors.white,
            '--var-module-editor-component-timeline-style-preview-background': colors.greyLight,
            '--var-module-editor-component-timeline-style-timeBlock': colors.grey,
            '--var-module-editor-component-timeline-style-selected': colors.grey,
            '--var-module-editor-component-timeline-style-textViewText': colors.grey,
            '--var-module-editor-component-timeline-style-backLayer': 'rgba(0, 0, 0, 0.45)',
            /** Module: editor, component: footer */
            '--var-module-editor-component-footer-style-background': colors.white,
            '--var-module-editor-component-footer-style-emptySoundtrack': colors.white,
            '--var-module-editor-component-footer-style-timeNumber': colors.blackLight,
            '--var-module-editor-component-footer-style-timeNumberText': colors.white,
            '--var-module-editor-component-footer-style-border': colors.white,
            '--var-module-editor-component-footer-style-loopLine': colors.selected,
            /** Module: editor, component: soundtrack */
            '--var-module-editor-component-soundtrack-style-panel': colors.white,
            '--var-module-editor-component-soundtrack-style-border': colors.cloud,
            '--var-module-editor-component-soundtrack-style-text': colors.grey,
            '--var-module-editor-component-soundtrack-style-textSecondary': colors.black,
            /** Module: editor, component: header */
            '--var-module-editor-component-header-style-icon': colors.white,
            '--var-module-editor-component-header-style-iconActive': colors.grey,

            /** Module: videoEditor, component: content */
            '--var-module-videoEditor-component-videoEditor-style-colsRight': colors.white,
            '--var-module-videoEditor-component-videoEditor-style-toggleActive': colors.blackLight,
            '--var-module-videoEditor-component-videoEditor-style-toggleDisable': colors.darkCloud,
            '--var-module-videoEditor-component-videoEditor-style-dropzone': colors.grey,
            '--var-module-videoEditor-component-videoEditor-style-timeLine': colors.white,
            '--var-module-videoEditor-component-videoEditor-style-controlsBackground': 'rgba(0, 0, 0, 0.45)',
            '--var-module-videoEditor-component-videoEditor-style-mediaUpload': colors.white,
            '--var-module-videoEditor-component-content-style-background': colors.blackLight,

            /** Module: app, component: tableEditor */
            '--var-module-app-component-tableEditor-style-background': colors.white,
            '--var-module-app-component-tableEditor-style-previewBorder': colors.greyLight,
            '--var-module-app-component-tableEditor-style-description': colors.darkCloud,
            '--var-module-app-component-tableEditor-style-title': colors.darkBlue,

            /** Module: ui, component: steps */
            '--var-module-ui-component-steps-style-textMain': colors.black,
            '--var-module-ui-component-steps-style-active': colors.black,
            '--var-module-ui-component-steps-style-hover': colors.white,
            '--var-module-ui-component-steps-style-background': colors.white,
            '--var-module-ui-component-steps-style-activeBackground': colors.middleGrey,
            '--var-module-ui-component-steps-style-hoverBackground': colors.middleGrey,
            '--var-module-ui-component-steps-style-border': colors.greyLight,
            '--var-module-ui-component-steps-style-activeBorder': colors.grey,
            '--var-module-ui-component-steps-style-hoverBorder': colors.grey,
            '--var-module-ui-component-steps-style-inverseTabActive': colors.grey,
            '--var-module-ui-component-steps-style-inverseTabDisable': 'rgba(0, 0, 0, 0.9)',
            '--var-module-ui-component-steps-style-inverseBorder': colors.greyLight,
            /** Module: ui, component: tabs */
            '--var-module-ui-component-tabs-style-textMain': colors.grey,
            '--var-module-ui-component-tabs-style-active': colors.white,
            '--var-module-ui-component-tabs-style-hover': colors.white,
            '--var-module-ui-component-tabs-style-background': colors.white,
            '--var-module-ui-component-tabs-style-activeBackground': colors.grey,
            '--var-module-ui-component-tabs-style-hoverBackground': colors.grey,
            '--var-module-ui-component-tabs-style-border': colors.greyLight,
            '--var-module-ui-component-tabs-style-activeBorder': colors.grey,
            '--var-module-ui-component-tabs-style-hoverBorder': colors.grey,
            /** Module: ui, component: line */
            '--var-module-ui-component-line-style-background': colors.greyEditor,
            '--var-module-ui-component-line-style-border': colors.white,
            '--var-module-ui-component-line-style-outerBorder': colors.blackLight,
            '--var-module-ui-component-line-style-textMain': colors.white,
            /** Module: ui, component: searchbar */
            '--var-module-ui-component-searchbar-style-withShadow-border': 'transparent',
            '--var-module-ui-component-searchbar-style-withShadow-background': colors.white,
            '--var-module-ui-component-searchbar-style-withShadow-textMain': colors.blackLight,
            '--var-module-ui-component-searchbar-style-withShadow-icon': colors.grey,
            '--var-module-ui-component-searchbar-style-withShadow-activeIcon': colors.blackLight,
            '--var-module-ui-component-searchbar-style-withShadow-shadow': `2px 3px 4px 0px rgba(0, 0, 0, 0.25)`,
            '--var-module-ui-component-searchbar-style-none-border': 'transparent',
            '--var-module-ui-component-searchbar-style-none-background': colors.white,
            '--var-module-ui-component-searchbar-style-none-textMain': colors.blackLight,
            '--var-module-ui-component-searchbar-style-none-icon': colors.grey,
            '--var-module-ui-component-searchbar-style-none-activeIcon': colors.blackLight,
            '--var-module-ui-component-searchbar-style-none-shadow': `none`,
            /** Module: ui, component: dropdown */
            '--var-module-ui-component-dropdown-style-default-background': colors.white,
            '--var-module-ui-component-dropdown-style-default-activeBackground': colors.white,
            '--var-module-ui-component-dropdown-style-default-border': colors.greyLight,
            '--var-module-ui-component-dropdown-style-default-activeBorder': colors.blackLight,
            '--var-module-ui-component-dropdown-style-default-textMain': colors.blackLight,
            '--var-module-ui-component-dropdown-style-default-activeTextMain': colors.blackLight,
            '--var-module-ui-component-dropdown-style-default-textSub': colors.blackLight,
            '--var-module-ui-component-dropdown-style-default-activeTextSub': colors.blackLight,
            '--var-module-ui-component-dropdown-style-default-icon': colors.blackLight,
            '--var-module-ui-component-dropdown-style-default-activeIcon': colors.blackLight,
            '--var-module-ui-component-dropdown-style-default-shadow': 'none',
            '--var-module-ui-component-dropdown-style-withShadow-background': colors.white,
            '--var-module-ui-component-dropdown-style-withShadow-activeBackground': colors.white,
            '--var-module-ui-component-dropdown-style-withShadow-border': 'transparent',
            '--var-module-ui-component-dropdown-style-withShadow-activeBorder': 'transparent',
            '--var-module-ui-component-dropdown-style-withShadow-textMain': colors.blackLight,
            '--var-module-ui-component-dropdown-style-withShadow-activeTextMain': colors.blackLight,
            '--var-module-ui-component-dropdown-style-withShadow-textSub': colors.grey,
            '--var-module-ui-component-dropdown-style-withShadow-activeTextSub': colors.blackLight,
            '--var-module-ui-component-dropdown-style-withShadow-icon': colors.blackLight,
            '--var-module-ui-component-dropdown-style-withShadow-activeIcon': colors.blackLight,
            '--var-module-ui-component-dropdown-style-withShadow-shadow': `2px 3px 4px 0px rgba(0, 0, 0, 0.25)`,
            '--var-module-ui-component-dropdown-style-none-background': colors.white,
            '--var-module-ui-component-dropdown-style-none-activeBackground': colors.white,
            '--var-module-ui-component-dropdown-style-none-border': 'transparent',
            '--var-module-ui-component-dropdown-style-none-activeBorder': 'transparent',
            '--var-module-ui-component-dropdown-style-none-textMain': colors.blackLight,
            '--var-module-ui-component-dropdown-style-none-activeTextMain': colors.blackLight,
            '--var-module-ui-component-dropdown-style-none-textSub': colors.blackLight,
            '--var-module-ui-component-dropdown-style-none-activeTextSub': colors.blackLight,
            '--var-module-ui-component-dropdown-style-none-icon': colors.blackLight,
            '--var-module-ui-component-dropdown-style-none-activeIcon': colors.blackLight,
            '--var-module-ui-component-dropdown-style-none-shadow': 'none',
            '--var-module-ui-component-dropdown-style-fill-background': colors.grey,
            '--var-module-ui-component-dropdown-style-fill-activeBackground': colors.grey,
            '--var-module-ui-component-dropdown-style-fill-border': colors.grey,
            '--var-module-ui-component-dropdown-style-fill-activeBorder': colors.grey,
            '--var-module-ui-component-dropdown-style-fill-textMain': colors.white,
            '--var-module-ui-component-dropdown-style-fill-activeTextMain': colors.white,
            '--var-module-ui-component-dropdown-style-fill-textSub': colors.white,
            '--var-module-ui-component-dropdown-style-fill-activeTextSub': colors.white,
            '--var-module-ui-component-dropdown-style-fill-icon': colors.white,
            '--var-module-ui-component-dropdown-style-fill-activeIcon': colors.white,
            '--var-module-ui-component-dropdown-style-fill-shadow': 'none',
            /** Module: ui, component: dropMenu */
            '--var-module-ui-component-dropMenu-style-blockShadow': '0px 4px 4px rgba(0, 0, 0, 0.25)',

            /** Module: ui, component: newDropdown */
            '--var-module-ui-component-newDropdown-style-control': colors.white,
            '--var-module-ui-component-newDropdown-style-controlText': colors.greyDark,
            '--var-module-ui-component-newDropdown-style-controlBorder': colors.grey,
            '--var-module-ui-component-newDropdown-style-menuBorder': colors.blue,
            '--var-module-ui-component-newDropdown-style-menuBackground': colors.white,
            /** Module: ui, component: checkbox */
            '--var-module-ui-component-checkbox-style-circle-textMain': colors.grey,
            '--var-module-ui-component-checkbox-style-circle-border': colors.grey,
            '--var-module-ui-component-checkbox-style-circle-background': colors.brown,
            '--var-module-ui-component-checkbox-style-circle-icon': colors.white,
            '--var-module-ui-component-checkbox-style-circleInv-textMain': colors.grey,
            '--var-module-ui-component-checkbox-style-circleInv-border': colors.white,
            '--var-module-ui-component-checkbox-style-circleInv-background': colors.brown,
            '--var-module-ui-component-checkbox-style-circleInv-icon': colors.white,
            '--var-module-ui-component-checkbox-style-circleDis-background': colors.brown,
            '--var-module-ui-component-checkbox-style-circleDis-textMain': colors.grey,
            '--var-module-ui-component-checkbox-style-circleDis-border': colors.white,
            '--var-module-ui-component-checkbox-style-circleDis-icon': colors.white,
            '--var-module-ui-component-checkbox-style-white-textMain': colors.white,
            '--var-module-ui-component-checkbox-style-white-border': colors.white,
            '--var-module-ui-component-checkbox-style-white-background': colors.white,
            '--var-module-ui-component-checkbox-style-white-icon': colors.black,
            /** Module: ui, component: editText */
            '--var-module-ui-component-editText-style-default-textMain': colors.blackLight,
            '--var-module-ui-component-editText-style-default-error': colors.errorLight,
            '--var-module-ui-component-editText-style-default-shadow': '2px 3px 4px 0px rgba(0, 0, 0, 0.25)',
            '--var-module-ui-component-editText-style-default-border': colors.greyLight,
            '--var-module-ui-component-editText-style-default-icon': colors.grey,
            '--var-module-ui-component-editText-style-default-placeholder': colors.grey,
            '--var-module-ui-component-editText-style-default-background': colors.white,
            '--var-module-ui-component-editText-style-text-textMain': colors.blackLight,
            '--var-module-ui-component-editText-style-text-error': colors.errorLight,
            '--var-module-ui-component-editText-style-text-shadow': 'none',
            '--var-module-ui-component-editText-style-text-border': 'transparent',
            '--var-module-ui-component-editText-style-text-icon': colors.grey,
            '--var-module-ui-component-editText-style-text-placeholder': colors.grey,
            '--var-module-ui-component-editText-style-text-background': 'transparent',
            '--var-module-ui-component-editText-style-white-textMain': colors.white,
            '--var-module-ui-component-editText-style-white-error': colors.errorLight,
            '--var-module-ui-component-editText-style-white-shadow': 'none',
            '--var-module-ui-component-editText-style-white-border': 'transparent',
            '--var-module-ui-component-editText-style-white-icon': colors.white,
            '--var-module-ui-component-editText-style-white-placeholder': colors.white,
            '--var-module-ui-component-editText-style-white-background': 'transparent',
            '--var-module-ui-component-editText-style-withBorder-textMain': colors.blackLight,
            '--var-module-ui-component-editText-style-withBorder-error': colors.errorLight,
            '--var-module-ui-component-editText-style-withBorder-shadow': 'none',
            '--var-module-ui-component-editText-style-withBorder-border': colors.greyLight,
            '--var-module-ui-component-editText-style-withBorder-icon': colors.blackLight,
            '--var-module-ui-component-editText-style-withBorder-placeholder': colors.grey,
            '--var-module-ui-component-editText-style-withBorder-background': colors.white,
            '--var-module-ui-component-editText-style-withLightBorder-textMain': colors.blackLight,
            '--var-module-ui-component-editText-style-withLightBorder-error': colors.errorLight,
            '--var-module-ui-component-editText-style-withLightBorder-shadow': 'none',
            '--var-module-ui-component-editText-style-withLightBorder-border': colors.greyLight,
            '--var-module-ui-component-editText-style-withLightBorder-icon': colors.blackLight,
            '--var-module-ui-component-editText-style-withLightBorder-placeholder': colors.grey,
            '--var-module-ui-component-editText-style-withLightBorder-background': colors.white,
            '--var-module-ui-component-editText-style-withBottomLine-textMain': colors.blackLight,
            '--var-module-ui-component-editText-style-withBottomLine-error': colors.errorLight,
            '--var-module-ui-component-editText-style-withBottomLine-shadow': 'none',
            '--var-module-ui-component-editText-style-withBottomLine-border': colors.blackLight,
            '--var-module-ui-component-editText-style-withBottomLine-icon': colors.grey,
            '--var-module-ui-component-editText-style-withBottomLine-placeholder': colors.grey,
            '--var-module-ui-component-editText-style-withBottomLine-background': colors.white,
            '--var-module-ui-component-editText-style-inverseBorder-textMain': colors.white,
            '--var-module-ui-component-editText-style-inverseBorder-border': colors.white,
            '--var-module-ui-component-editText-style-inverseBorder-background': 'transparent',
            /** Module: catalog, component: breadcrumbs */
            '--var-module-catalog-component-breadcrumbs-style-textMain': colors.grey,
            '--var-module-catalog-component-breadcrumbs-style-hover': colors.black,
            '--var-module-catalog-component-breadcrumbs-style-active': colors.blackLight,
            '--var-module-catalog-component-breadcrumbs-style-disable': colors.grey,
            '--var-module-catalog-component-breadcrumbs-style-background': colors.white,
            '--var-module-catalog-component-breadcrumbs-style-drop': colors.white,
            '--var-module-catalog-component-breadcrumbs-style-dropBackground': 'rgba(0, 0, 0, 0.45)',
            /** Module: catalog, component: catalog */
            '--var-module-catalog-component-catalog-icon-folder': `images/helpers/_files/folder.svg`,
            '--var-module-catalog-component-catalog-icon-smartFolder': `images/helpers/_files/smartFolder.svg`,
            '--var-module-catalog-component-catalog-icon-audio': `images/helpers/_files/audio.png`,
            /** Module: catalog, component: item */
            '--var-module-catalog-component-item-style-idle': colors.darkCloud,
            '--var-module-catalog-component-item-style-online': colors.online,
            '--var-module-catalog-component-item-style-offline': colors.grey,
            '--var-module-catalog-component-item-style-offlineGroup': colors.grey,
            '--var-module-catalog-component-item-style-progress': colors.darkCloud,
            '--var-module-catalog-component-item-style-textMain': colors.white,
            '--var-module-catalog-component-item-style-panel': colors.white,
            '--var-module-catalog-component-item-style-titleBackground': 'rgba(0, 0, 0, 0.45)',
            '--var-module-catalog-component-item-style-backgroundMain': colors.grey,
            '--var-module-catalog-component-item-style-backgroundSub': colors.greyLight,
            '--var-module-catalog-component-item-style-error': colors.errorLight,
            '--var-module-catalog-component-item-style-active': 'rgba(0, 0, 0, 0.45)',
            '--var-module-catalog-component-item-style-shadow': '-1px 3px 4px 0px rgba(0, 0, 0, 0.25)',
            '--var-module-catalog-component-item-style-noBroadcastText': colors.white,
            '--var-module-catalog-component-item-style-iconWrapper': colors.white,
            /** Module: catalog, component: referenceBook */
            '--var-module-catalog-component-referenceBook-style-backgroundMain': colors.white,
            /** Module: catalog, component: actionBar */
            '--var-module-catalog-component-actionBar-style-modalFormText': colors.darkCloud,
            /** Module: catalog, component: filter */
            '--var-module-catalog-component-filter-style-background': colors.white,
            /** Module: catalog, component: select */
            '--var-module-catalog-component-select-style-background': colors.white,
            '--var-module-catalog-component-select-style-button': colors.white,
            /** Module: catalognew, component: select */
            '--var-module-catalognew-component-catalognew-style-header': colors.greyLight,

            /** Module: step, component noDevices */
            '--var-module-step-component-noDevices-icon-step-1': `images/no-devices/step-1.svg`,
            '--var-module-step-component-noDevices-icon-step-2': `images/no-devices/step-2.svg`,
            '--var-module-step-component-noDevices-icon-step-3': `images/no-devices/step-3.svg`,
            '--var-module-step-component-noDevices-style-dark': colors.deepGrey,
            '--var-module-step-component-noDevices-style-light': colors.grey,

            /** Module: ui, component: table */
            '--var-module-ui-component-table-style-background': colors.white,
            '--var-module-ui-component-table-style-evenLine': colors.greyLight,
            '--var-module-ui-component-table-style-thead': colors.greyLight,
            '--var-module-ui-component-table-style-text': colors.blackLight,
            '--var-module-ui-component-table-style-border': colors.white,
            '--var-module-ui-component-table-style-borderTop': colors.greyLight,
            '--var-module-ui-component-table-style-selected': colors.grey,
            '--var-module-ui-component-table-style-selected-text': colors.white,
            '--var-module-ui-component-table-style-cellBorder': colors.greyLight,
            /** Module: ui, component: tag */
            '--var-module-ui-component-tag-style-background': colors.blueLight,
            '--var-module-ui-component-tag-style-editing-border': colors.white,
            '--var-module-ui-component-tag-style-label': colors.white,
            '--var-module-ui-component-tag-style-editing': colors.blue,
            /** Module: ui, component: tagPage */
            '--var-module-ui-component-tag-style-searchbar': colors.water,
            '--var-module-ui-component-tag-style-searchbarTag': colors.darkBlue,

            /** Module: schedule, component: add */
            '--var-module-schedule-component-add-style-step': colors.darkCloud,
            '--var-module-schedule-component-add-style-active-step': colors.darkBlue,
            '--var-module-schedule-component-add-style-stepBorder': colors.cloud,

            /** Module: toolbar, component: content */
            '--var-module-toolbar-component-content-style-info': colors.white,
            '--var-module-toolbar-component-content-style-errorBackground': colors.error,
            '--var-module-toolbar-component-toolbar-style-tag': colors.white,

            /** Module: sidebar, component: sidebar */
            '--var-module-sidebar-component-sidebar-style-background': colors.white,
            '--var-module-sidebar-component-sidebar-style-buttonUploads': colors.blackLight,
            '--var-module-sidebar-component-sidebar-style-toggleButton': colors.white,
            '--var-module-sidebar-component-sidebar-style-description': colors.darkCloud,
            '--var-module-sidebar-component-sidebar-style-text': colors.black,
            '--var-module-sidebar-component-sidebar-style-title': colors.black,
            '--var-module-sidebar-component-sidebar-style-textHover': colors.black,
            '--var-module-sidebar-component-sidebar-style-wrapperBackground': colors.blackLight,
            '--var-module-sidebar-component-sidebar-style-wrapperText': colors.greyLight,
            '--var-module-sidebar-component-sidebar-style-contentBackground': colors.white,
            '--var-module-sidebar-component-sidebar-style-border': colors.cloud,
            '--var-module-sidebar-component-sidebar-style-borderElement': colors.black,
            '--var-module-sidebar-component-screenshot-style-border': colors.white,
            '--var-module-sidebar-component-screenshot-style-infoBorder': colors.blackLight,
            '--var-module-sidebar-component-screenshot-style-infoText': colors.grey,
            '--var-module-sidebar-component-screenshot-style-text': colors.white,

            '--var-module-sidebar-component-sidebar-style-menuTextHover': colors.black,
            '--var-module-sidebar-component-sidebar-style-menuText': colors.grey,
            '--var-module-sidebar-component-sidebar-style-toggleText': colors.grey,
            '--var-module-sidebar-component-sidebar-style-activeToggleText': colors.blackLight,
            '--var-module-sidebar-component-sidebar-style-disableToggleText': colors.grey,
            '--var-module-sidebar-component-sidebar-style-itemSelected': colors.greyLight,
            /** Module: sidebar, component: toolbarUpload */
            '--var-module-sidebar-component-toolbarUpload-style-background': colors.grey,
            '--var-module-sidebar-component-toolbarUpload-style-text': colors.white,
            /** Module: sidebar, component: schedule */
            '--var-module-sidebar-component-schedule-style-title': colors.blackLight,
            '--var-module-sidebar-component-schedule-style-addIcon': colors.grey,
            '--var-module-sidebar-component-schedule-style-icon': 'rgba(0, 0, 0, 0.45)',
            '--var-module-sidebar-component-schedule-style-iconColor': colors.grey,
            /** Module: sidebar, component: filePreview */
            '--var-module-sidebar-component-filePreview-style-text': colors.grey,
            '--var-module-sidebar-component-filePreview-style-textHover': colors.blackLight,
            '--var-module-sidebar-component-filePreview-style-background': colors.grey,
            '--var-module-sidebar-component-filePreview-style-timeline': colors.white,
            /** Module: sidebar, component: broadcast */
            '--var-module-sidebar-component-broadcast-style-title': colors.grey,
            /** Module: sidebar, component: display */
            '--var-module-sidebar-component-display-style-offline': colors.grey,

            /** Module: broadcast, component: videowall */
            '--var-module-broadcast-component-videowall-style-preview': colors.blackLight,
            '--var-module-broadcast-component-videowall-style-wallLine': colors.greyLight,
            '--var-module-broadcast-component-videowall-style-tooltip': colors.cloud,

            /** Module: app, component: modal */
            '--var-module-app-component-modal-style-background': colors.white,

            /** Module: files, component: add */
            '--var-module-files-component-add-style-toolbar': colors.white,
            '--var-module-files-component-add-style-activeItem': colors.clouds,

            /** Module: editor, component: flowDiagram */
            '--var-module-editor-component-flowDiagram-style-path': colors.darkCloud,

            /** Module: roles, component: roles */
            '--var-module-roles-component-roles-style-headerText': colors.grey,
            '--var-module-roles-component-roles-style-tableBorder': colors.grey,
            '--var-module-roles-component-roles-style-leftColumnBorder': colors.grey,

            /** Module: audio, component: trackList */
            '--var-module-audio-component-trackList-style-border': colors.white,
            '--var-module-audio-component-trackList-style-text': colors.white,
            /** Module: audio, component: playground */
            '--var-module-audio-component-playground-style-text': colors.white,
            '--var-module-audio-component-playground-style-levelContent': colors.blackLight,
            '--var-module-audio-component-playground-style-levelActive': colors.darkCloud,
            '--var-module-audio-component-playground-style-levelPointer': colors.white,
            /** Module: audio, component: placeholder */
            '--var-module-audio-component-playground-style-background': colors.white,
            /** Module: ui, component: circleButton */
            '--var-module-ui-component-circleButton-style-background': colors.greyLight,
            '--var-module-ui-component-circleButton-style-text': colors.black,
            '--var-module-ui-component-circleButton-style-background-active': colors.white,
            /** Module: videoChat, component: videoChat */
            '--var-module-videoChat-component-videoChat-style-localVideo': colors.grey,
            '--var-module-videoChat-component-videoChat-style-video': colors.blackLight,
            /** Module: ui, component: scroll */
            '--var-module-ui-component-scroll-style-background': colors.greyLight,

            /** Module: map, component: selectMapDialog */
            '--var-module-map-component-selectMapDialog-style-background': colors.white,

            /** Module: map, component: mapLocationList */
            '--var-module-map-component-mapLocationList-style-background': colors.white,
            '--var-module-map-component-mapLocationList-style-hoverBackground': colors.greyLight,
            '--var-module-map-component-mapLocationList-style-text': colors.black,
            '--var-module-map-component-mapLocationList-style-hoverText': colors.black,
            '--var-module-map-component-mapLocationList-style-shadow': '0 4px 4px rgba(0, 0, 0, 0.25)',

            /** Module: displays, component: platform */
            '--var-module-displays-component-platform-background': colors.greyLight,
            '--var-module-displays-component-platform-border': colors.white,

            /** Module: breadcrumbs, component: catalog */
            '--var-module-breadcrumbs-component-icon-style-active': colors.blackLight,
            '--var-module-breadcrumbs-component-icon-style-disable': colors.grey,
        },
        smartplayer: {
            /** settings */
            '--var-module-settings-component-settingsItem-style-background': colors.white,
            /** Module: app, component: app */
            '--var-module-app-component-app-style-background': colors.blueWhite,
            '--var-module-app-component-app-style-backgroundSub': colors.darkCloud,
            '--var-module-app-component-app-style-darkBackground': colors.blackLight,
            '--var-module-app-component-app-style-lightBackground': colors.water,
            '--var-module-app-component-app-style-border': colors.cloud,
            '--var-module-app-component-app-style-textMain': colors.darkBlue,
            '--var-module-app-component-app-style-textSub': colors.darkCloud,
            '--var-module-app-component-app-style-active': colors.blue,
            '--var-module-app-component-app-style-disable': colors.darkCloud,
            '--var-module-app-component-app-style-shadow': '0 4px 4px rgba(0, 54, 136, 0.25)',
            '--var-module-app-component-app-style-placeholder': colors.darkCloud,
            '--var-module-app-component-app-style-fontFamily': font.regular,
            /** Module: app, component: calendar */
            '--var-module-app-component-calendar-style-background': colors.white,
            '--var-module-app-component-calendar-style-header': colors.darkCloud,
            '--var-module-app-component-calendar-style-headerText': colors.white,
            '--var-module-app-component-calendar-style-dayNumber': colors.darkCloud,
            '--var-module-app-component-calendar-style-selectedDate': colors.cloud,
            '--var-module-app-component-calendar-style-cellLine': colors.darkCloud,
            '--var-module-app-component-calendar-style-lineShadow': '0px 4px 4px rgba(0, 54, 136, 0.25)',
            '--var-module-app-component-calendar-style-active-cellLine': colors.selected,
            /** Icons */
            '--var-module-app-component-menu-icon-dashboard': 'dashboard_smartplayer',
            '--var-module-app-component-menu-icon-devices': 'display_smartplayer',
            '--var-module-app-component-menu-icon-addContentToDevice': 'multimedia_smartplayer',
            '--var-module-app-component-menu-icon-broadcasts': 'megaphone_smartplayer',
            '--var-module-app-component-menu-icon-schedule': 'date_smartplayer',
            '--var-module-app-component-menu-icon-files': 'folder_smartplayer',
            '--var-module-app-component-menu-icon-reports': 'statistics_smartplayer',
            '--var-module-app-component-menu-icon-users': 'users_smartplayer',
            '--var-module-app-component-menu-icon-usersActionsHistory': 'notifications_empty_smartplayer',
            '--var-module-app-component-menu-icon-settings': 'gear_smartplayer',
            '--var-module-app-component-menu-icon-globalProgress': 'download_smartplayer',
            '--var-module-app-component-menu-icon-logout': 'logout_smartplayer',

            /** Module: ui, component: table */
            '--var-module-ui-component-table-style-background': colors.white,
            '--var-module-ui-component-table-style-evenLine': colors.water,
            '--var-module-ui-component-table-style-thead': colors.blueWhite,
            '--var-module-ui-component-table-style-text': colors.blackLight,
            '--var-module-ui-component-table-style-border': colors.white,
            '--var-module-ui-component-table-style-borderTop': colors.cloud,
            '--var-module-ui-component-table-style-selected': colors.blackLight,
            '--var-module-ui-component-table-style-selected-text': colors.white,
            '--var-module-ui-component-table-style-cellBorder': colors.cloud,

            /** Module: ui, component: icon */
            '--var-module-ui-component-icon-style-main': colors.darkBlue,
            '--var-module-ui-component-icon-style-mainSub': colors.darkCloud,
            '--var-module-ui-component-icon-style-disable': colors.darkCloud,
            '--var-module-ui-component-icon-style-active': colors.blue,
            '--var-module-ui-component-icon-style-light': colors.cloud,
            /** Module: ui, component: notifyBadge */
            '--var-module-ui-component-notifyBadge-style-textMain': colors.white,
            '--var-module-ui-component-notifyBadge-style-active': colors.darkBlue,
            '--var-module-ui-component-notifyBadge-style-disable': colors.darkCloud,
            '--var-module-ui-component-notifyBadge-style-success': colors.success,
            '--var-module-ui-component-notifyBadge-style-error': colors.errorSaturated,
            '--var-module-ui-component-notifyBadge-style-normal': colors.selected,
            '--var-module-ui-component-notifyBadge-style-online': colors.online,
            /** Module: ui, component: button */
            '--var-module-ui-component-button-style-default-background': colors.white,
            '--var-module-ui-component-button-style-default-shadow': 'none',
            '--var-module-ui-component-button-style-default-border': 'transparent',
            '--var-module-ui-component-button-style-default-textMain': colors.darkBlue,
            '--var-module-ui-component-button-style-default-icon': colors.darkBlue,
            '--var-module-ui-component-button-style-default-active': colors.blue,
            '--var-module-ui-component-button-style-fill-background': colors.darkCloud,
            '--var-module-ui-component-button-style-fill-shadow': 'none',
            '--var-module-ui-component-button-style-fill-border': 'transparent',
            '--var-module-ui-component-button-style-fill-textMain': colors.white,
            '--var-module-ui-component-button-style-fill-icon': colors.white,
            '--var-module-ui-component-button-style-fill-active': colors.blue,
            '--var-module-ui-component-button-style-error-background': colors.error,
            '--var-module-ui-component-button-style-error-shadow': 'none',
            '--var-module-ui-component-button-style-error-border': 'transparent',
            '--var-module-ui-component-button-style-error-textMain': colors.white,
            '--var-module-ui-component-button-style-error-icon': colors.white,
            '--var-module-ui-component-button-style-error-active': colors.error,
            '--var-module-ui-component-button-style-secondary-background': colors.blueLight,
            '--var-module-ui-component-button-style-secondary-textMain': colors.white,
            '--var-module-ui-component-button-style-secondary-border': 'transparent',
            '--var-module-ui-component-button-style-secondary-shadow': 'none',
            '--var-module-ui-component-button-style-secondary-icon': colors.white,
            '--var-module-ui-component-button-style-secondary-active': colors.blackLight,
            '--var-module-ui-component-button-style-withBorder-textMain': colors.darkBlue,
            '--var-module-ui-component-button-style-withBorder-background': colors.white,
            '--var-module-ui-component-button-style-withBorder-shadow': 'none',
            '--var-module-ui-component-button-style-withBorder-border': colors.greyLight,
            '--var-module-ui-component-button-style-withBorder-activeBorder': colors.darkBlue,
            '--var-module-ui-component-button-style-withBorder-icon': colors.darkBlue,
            '--var-module-ui-component-button-style-withBorder-active': colors.blue,
            '--var-module-ui-component-button-style-withShadow-textMain': colors.darkBlue,
            '--var-module-ui-component-button-style-withShadow-background': colors.white,
            '--var-module-ui-component-button-style-withShadow-border': 'transparent',
            '--var-module-ui-component-button-style-withShadow-shadow': '0px 4px 4px rgba(133, 133, 133, 0.25)',
            '--var-module-ui-component-button-style-withShadow-icon': colors.darkBlue,
            '--var-module-ui-component-button-style-withShadow-active': colors.blue,
            '--var-module-ui-component-button-style-list-textMain': colors.darkCloud,
            '--var-module-ui-component-button-style-list-background': colors.white,
            '--var-module-ui-component-button-style-list-border': 'transparent',
            '--var-module-ui-component-button-style-list-shadow': '0px 4px 4px rgba(133, 133, 133, 0.25)',
            '--var-module-ui-component-button-style-list-icon': colors.darkBlue,
            '--var-module-ui-component-button-style-list-active': colors.blue,
            /** Module: ui, component: range */
            '--var-module-ui-component-range-style-contrast-track': colors.blackLight,
            '--var-module-ui-component-range-style-contrast-rail': colors.white,
            '--var-module-ui-component-range-style-colored-track': colors.darkBlue,
            '--var-module-ui-component-range-style-colored-rail': colors.white,
            '--var-module-ui-component-range-style-monochrome-track': colors.greyLight,
            '--var-module-ui-component-range-style-monochrome-rail': colors.grey,
            '--var-module-ui-component-range-style-handler': colors.darkBlue,
            '--var-module-ui-component-range-style-handlerInactive': colors.darkCloud,
            '--var-module-ui-component-range-style-counter': colors.darkBlue,
            /** Module: ui, component: datepicker */
            '--var-module-ui-component-range-style-background': colors.white,
            '--var-module-ui-component-range-style-navigation': colors.greyLight,
            '--var-module-ui-component-range-style-selected': colors.selected,
            '--var-module-ui-component-range-style-border': colors.greyLight,
            '--var-module-ui-component-range-style-currentMonthText': colors.black,
            /** Module: ui, component: sideMenu */
            '--var-module-ui-component-sideMenu-style-icon': colors.white,
            '--var-module-ui-component-sideMenu-style-iconHover': colors.blueLight,
            '--var-module-ui-component-sideMenu-style-offline': colors.offline,
            /** Module: ui, component: circleButton */
            '--var-module-ui-component-circleButton-style-background': colors.cloud,
            '--var-module-ui-component-circleButton-style-text': colors.darkBlue,
            '--var-module-ui-component-circleButton-style-background-active': colors.white,
            /** Module: step, component noDevices */
            '--var-module-step-component-noDevices-icon-step-1': `images/no-devices/step-1_smartplayer.svg`,
            '--var-module-step-component-noDevices-icon-step-2': `images/no-devices/step-2_smartplayer.svg`,
            '--var-module-step-component-noDevices-icon-step-3': `images/no-devices/step-3_smartplayer.svg`,
            '--var-module-step-component-noDevices-style-dark': colors.blueLight,
            '--var-module-step-component-noDevices-style-light': colors.darkCloud,

            /** Module: ui, component: status */
            '--var-module-ui-component-marker-style-border': 'rgba(0, 54, 136, .1)',

            /** Module: dashboard, component: memory */
            '--var-module-dashboard-component-dashboard-style-offline': colors.blackLight,
            '--var-module-dashboard-component-dashboard-style-error': colors.errorSaturated,
            '--var-module-dashboard-component-dashboard-style-online': colors.green,
            '--var-module-dashboard-component-memory-style-chart-color-1': colors.water,
            '--var-module-dashboard-component-memory-style-chart-color-2': colors.blueAccent,
            '--var-module-dashboard-component-memory-style-chart-color-3': colors.darkBlue,
            '--var-module-dashboard-component-memory-style-chart-color-4': colors.online,
            '--var-module-dashboard-component-memory-style-chart-color-5': colors.darkCloud,
            /** Module: ui, component: typography */
            '--var-module-ui-component-typography-style-textPromo': colors.blue,
            '--var-module-ui-component-typography-style-textMain': colors.darkBlue,
            '--var-module-ui-component-typography-style-textSub': colors.darkCloud,
            '--var-module-ui-component-typography-style-textError': colors.errorLight,
            /** Module: ui, component: radio */
            '--var-module-ui-component-radio-style-textMain': colors.darkBlue,
            '--var-module-ui-component-radio-style-background': colors.brown,
            '--var-module-ui-component-radio-style-border': colors.brown,
            '--var-module-ui-component-radio-style-active-border': colors.brown,
            /** Module: ui, component: card */
            '--var-module-ui-component-card-style-background': colors.white,
            '--var-module-ui-component-card-style-border': colors.cloud,

            /** Module: ui, component: actionBar */
            '--var-module-ui-component-actionBar-style-separator': colors.cloud,
            '--var-module-ui-component-actionBar-style-border': colors.cloud,
            '--var-module-ui-component-actionBar-style-iconBackground': colors.white,

            /** Module: ui, component: switchButton */
            '--var-module-ui-component-switchButton-style-fill-border': colors.grey,
            '--var-module-ui-component-switchButton-style-fill-handler': colors.white,
            '--var-module-ui-component-switchButton-style-fill-background': colors.grey,
            '--var-module-ui-component-switchButton-style-fill-activeBorder': colors.brown,
            '--var-module-ui-component-switchButton-style-fill-activeHandler': colors.white,
            '--var-module-ui-component-switchButton-style-fill-activeBackground': colors.brown,

            /** Module: app, component: user */
            '--var-module-app-component-user-style-textSub': colors.darkCloud,
            /** Module: app, component: content */
            '--var-module-app-component-content-style-background': colors.blueWhite,
            '--var-module-app-component-contentBorder-style-background': colors.darkCloud,
            /** Module: app, component: downloads */
            '--var-module-app-component-downloads-style-wrapperBorder': colors.darkCloud,
            '--var-module-app-component-downloads-style-border': colors.cloud,
            '--var-module-app-component-downloads-style-header': colors.darkCloud,
            '--var-module-app-component-downloads-style-background': colors.white,
            '--var-module-app-component-downloads-style-textMain': colors.white,
            '--var-module-app-component-downloads-style-title': colors.darkCloud,
            '--var-module-app-component-downloads-style-titleComplete': colors.darkBlue,
            '--var-module-app-component-downloads-style-progressComplete': colors.online,
            '--var-module-app-component-downloads-style-progressOver': colors.darkCloud,
            '--var-module-app-component-downloads-style-active': colors.darkBlue,
            '--var-module-app-component-downloads-style-disable': colors.darkCloud,
            /** Module: ui, component: progressBar */
            '--var-module-ui-component-progressBar-style-progressPath': colors.darkBlue,
            '--var-module-ui-component-progressBar-style-progressTrail': colors.darkCloud,
            /** Module: catalog, component: breadcrumbs */
            '--var-module-catalog-component-breadcrumbs-style-textMain': colors.darkCloud,
            '--var-module-catalog-component-breadcrumbs-style-hover': colors.blue,
            '--var-module-catalog-component-breadcrumbs-style-active': colors.blue,
            '--var-module-catalog-component-breadcrumbs-style-disable': colors.darkCloud,
            '--var-module-catalog-component-breadcrumbs-style-background': colors.white,
            /** Module: catalog, component: header */
            '--var-module-catalog-component-header-style-background': colors.white,
            /** Module: catalog, component: catalog */
            '--var-module-catalog-component-catalog-icon-folder': `images/helpers/_files/folder_smartplayer.svg`,
            '--var-module-catalog-component-catalog-icon-smartFolder': `images/helpers/_files/smartFolder_smartplayer.svg`,
            '--var-module-catalog-component-catalog-icon-audio': `images/helpers/_files/audio_smartplayer.png`,
            '--var-module-catalog-component-catalog-icon-table': `images/helpers/_files/table_smartplayer.png`,
            '--var-module-catalog-component-catalog-icon-link': `images/helpers/_files/link_smartplayer.png`,
            '--var-module-catalog-component-catalog-icon-webApp': `images/helpers/_files/web_app_smartplayer.png`,
            '--var-module-catalog-component-catalog-icon-office': `images/helpers/_files/office_smartplayer.png`,
            '--var-module-catalog-component-catalog-icon-corruptFile': `images/helpers/corrupt_file_smartplayer.png`,
            '--var-module-catalog-component-catalog-icon-empty': `images/helpers/_files/empty_smartplayer.png`,
            '--var-module-catalog-component-catalog-icon-transparent': `images/helpers/_files/transparent_smartplayer.png`,
            /** Module: broadcast, component: broadcast */
            '--var-module-broadcast-component-broadcast-icon-previewAudio': `images/helpers/audio_smartplayer.png`,
            '--var-module-broadcast-component-broadcast-icon-previewEmpty': `images/helpers/empty_smartplayer.png`,
            '--var-module-broadcast-component-broadcast-icon-previewOffice': `images/helpers/office_smartplayer.png`,
            /** Module: displays, component: broadcast */
            '--var-module-displays-component-broadcast-icon-noBroadcast': `images/displays/no-broadcast_smartplayer.png`,
            /** Module: displays, component: platform */
            '--var-module-displays-component-platform-icon-windows': `images/displays/platforms/windows_smartplayer.png`,
            '--var-module-displays-component-platform-icon-windows_active': `images/displays/platforms/windows_active.png`,
            '--var-module-displays-component-platform-icon-webos': `images/displays/platforms/webos_smartplayer.png`,
            '--var-module-displays-component-platform-icon-webos_active': `images/displays/platforms/webos_active.png`,
            '--var-module-displays-component-platform-icon-tizen': `images/displays/platforms/tizen_smartplayer.png`,
            '--var-module-displays-component-platform-icon-tizen_active': `images/displays/platforms/tizen_active.png`,
            '--var-module-displays-component-platform-icon-linux': `images/displays/platforms/linux_smartplayer.png`,
            '--var-module-displays-component-platform-icon-linux_active': `images/displays/platforms/linux_active.png`,
            '--var-module-displays-component-platform-icon-android': `images/displays/platforms/android_smartplayer.png`,
            '--var-module-displays-component-platform-icon-android_active': `images/displays/platforms/android_active.png`,
            '--var-module-displays-component-platform-icon-sssp': `images/displays/platforms/sssp_smartplayer.png`,
            '--var-module-displays-component-platform-icon-sssp_active': `images/displays/platforms/sssp_active.png`,
            '--var-module-displays-component-platform-icon-sssp_new': `images/displays/platforms/sssp_new_smartplayer.png`,
            '--var-module-displays-component-platform-icon-sssp_new_active': `images/displays/platforms/sssp_new_active.png`,
            '--var-module-displays-component-platform-icon-sssp_old': `images/displays/platforms/sssp_old_smartplayer.png`,
            '--var-module-displays-component-platform-icon-sssp_old_active': `images/displays/platforms/sssp_old_active.png`,
            '--var-module-displays-component-platform-icon-brightsign': `images/displays/platforms/brightsign_smartplayer.png`,
            '--var-module-displays-component-platform-icon-brightsign_active': `images/displays/platforms/brightsign_active.png`,
            '--var-module-displays-component-platform-background': colors.darkCloud,
            '--var-module-displays-component-platform-border': colors.white,

            /** Module: ui, component: collapse */
            '--var-module-ui-component-collapse-style-textMain': colors.darkBlue,
            /** Module: app, component: editor */
            '--var-module-app-component-editor-style-backgroundMain': colors.darkCloud,
            '--var-module-app-component-editor-style-backgroundSub': colors.cloud,
            '--var-module-app-component-editor-style-border': colors.white,
            '--var-module-app-component-editor-style-textMain': colors.darkBlue,
            '--var-module-app-component-editor-style-textSub': colors.white,
            '--var-module-app-component-editor-style-backgroundContent': colors.blackSemi,
            '--var-module-app-component-editor-style-selectLine': colors.selected,
            '--var-module-app-component-editor-style-backgroundLight': colors.blueWhite,
            /** Module: app, component: videoEditor */
            '--var-module-videoEditor-component-videoEditor-style-colsRight': colors.white,
            '--var-module-videoEditor-component-videoEditor-style-toggleActive': colors.darkBlue,
            '--var-module-videoEditor-component-videoEditor-style-toggleDisable': colors.darkCloud,
            '--var-module-videoEditor-component-videoEditor-style-dropzone': colors.darkCloud,
            '--var-module-videoEditor-component-videoEditor-style-timeLine': colors.white,
            '--var-module-videoEditor-component-videoEditor-style-controlsBackground': 'rgba(0, 0, 0, 0.45)',
            '--var-module-videoEditor-component-videoEditor-style-mediaUpload': colors.white,
            '--var-module-videoEditor-component-content-style-background': colors.blackLight,

            /** Module: editor, component: templates */
            '--var-module-editor-component-templates-style-template': colors.darkCloud,
            '--var-module-editor-component-templates-style-template-title': colors.darkCloud,
            '--var-module-editor-component-templates-style-template-border': colors.white,
            /** Module: editor, component: timeline */
            '--var-module-editor-component-timeline-style-text': colors.white,
            '--var-module-editor-component-timeline-style-lineBorder': colors.white,
            '--var-module-editor-component-timeline-style-border': colors.darkCloud,
            '--var-module-editor-component-timeline-style-list-border': colors.darkCloud,
            '--var-module-editor-component-timeline-style-list-background': colors.white,
            '--var-module-editor-component-timeline-style-preview-background': colors.cloud,
            '--var-module-editor-component-timeline-style-timeBlock': colors.cloud,
            '--var-module-editor-component-timeline-style-selected': colors.blackLight,
            '--var-module-editor-component-timeline-style-textViewText': colors.darkCloud,
            '--var-module-editor-component-timeline-style-backLayer': 'rgba(0, 0, 0, 0.45)',
            /** Module: editor, component: animation */
            '--var-module-editor-component-animation-style-text': colors.white,
            '--var-module-editor-component-animation-style-col1': colors.darkCloud,
            '--var-module-editor-component-animation-style-col2': colors.cloud,
            '--var-module-editor-component-animation-style-col3': colors.blueWhite,
            '--var-module-editor-component-animation-style-setting': colors.darkBlue,
            '--var-module-editor-component-animation-style-settingActive': colors.blue,
            '--var-module-editor-component-animation-style-sampleBackground': colors.darkBlue,
            /** Module: ui, component: steps */
            '--var-module-ui-component-steps-style-textMain': colors.darkBlue,
            '--var-module-ui-component-steps-style-active': colors.white,
            '--var-module-ui-component-steps-style-hover': colors.white,
            '--var-module-ui-component-steps-style-background': colors.white,
            '--var-module-ui-component-steps-style-activeBackground': colors.middleGrey,
            '--var-module-ui-component-steps-style-hoverBackground': colors.middleGrey,
            '--var-module-ui-component-steps-style-border': colors.cloud,
            '--var-module-ui-component-steps-style-activeBorder': colors.darkCloud,
            '--var-module-ui-component-steps-style-hoverBorder': colors.darkCloud,
            /** Module: ui, component: tabs */
            '--var-module-ui-component-tabs-style-textMain': colors.darkCloud,
            '--var-module-ui-component-tabs-style-active': colors.white,
            '--var-module-ui-component-tabs-style-hover': colors.white,
            '--var-module-ui-component-tabs-style-background': colors.white,
            '--var-module-ui-component-tabs-style-activeBackground': colors.darkCloud,
            '--var-module-ui-component-tabs-style-hoverBackground': colors.darkCloud,
            '--var-module-ui-component-tabs-style-border': colors.cloud,
            '--var-module-ui-component-tabs-style-activeBorder': colors.darkCloud,
            '--var-module-ui-component-tabs-style-hoverBorder': colors.darkCloud,
            '--var-module-ui-component-tabs-style-vertical-background': colors.darkCloud,
            '--var-module-ui-component-tabs-style-vertical-backgroundActive': colors.blue,
            /** Module: ui, component: line */
            '--var-module-ui-component-line-style-background': colors.darkCloud,
            '--var-module-ui-component-line-style-border': colors.white,
            '--var-module-ui-component-line-style-outerBorder': colors.blackSemi,
            '--var-module-ui-component-line-style-textMain': colors.white,
            /** Module: ui, component: searchbar */
            '--var-module-ui-component-searchbar-style-withShadow-border': `transparent`,
            '--var-module-ui-component-searchbar-style-withShadow-background': colors.white,
            '--var-module-ui-component-searchbar-style-withShadow-textMain': colors.darkBlue,
            '--var-module-ui-component-searchbar-style-withShadow-icon': colors.darkBlue,
            '--var-module-ui-component-searchbar-style-withShadow-activeIcon': colors.blue,
            '--var-module-ui-component-searchbar-style-withShadow-shadow': `2px 3px 4px 0px rgba(0, 54, 136, 0.25)`,
            '--var-module-ui-component-searchbar-style-none-border': `transparent`,
            '--var-module-ui-component-searchbar-style-none-background': colors.white,
            '--var-module-ui-component-searchbar-style-none-textMain': colors.darkBlue,
            '--var-module-ui-component-searchbar-style-none-icon': colors.darkBlue,
            '--var-module-ui-component-searchbar-style-none-activeIcon': colors.blue,
            '--var-module-ui-component-searchbar-style-none-shadow': `none`,
            /** Module: ui, component: dropdown */
            '--var-module-ui-component-dropdown-style-default-background': colors.white,
            '--var-module-ui-component-dropdown-style-default-activeBackground': colors.white,
            '--var-module-ui-component-dropdown-style-default-border': colors.darkBlue,
            '--var-module-ui-component-dropdown-style-default-activeBorder': colors.darkBlue,
            '--var-module-ui-component-dropdown-style-default-textMain': colors.darkBlue,
            '--var-module-ui-component-dropdown-style-default-activeTextMain': colors.darkBlue,
            '--var-module-ui-component-dropdown-style-default-textSub': colors.darkCloud,
            '--var-module-ui-component-dropdown-style-default-activeTextSub': colors.blue,
            '--var-module-ui-component-dropdown-style-default-icon': colors.darkBlue,
            '--var-module-ui-component-dropdown-style-default-activeIcon': colors.blue,
            '--var-module-ui-component-dropdown-style-default-shadow': 'none',
            '--var-module-ui-component-dropdown-style-withShadow-background': colors.white,
            '--var-module-ui-component-dropdown-style-withShadow-activeBackground': colors.white,
            '--var-module-ui-component-dropdown-style-withShadow-border': 'transparent',
            '--var-module-ui-component-dropdown-style-withShadow-activeBorder': 'transparent',
            '--var-module-ui-component-dropdown-style-withShadow-textMain': colors.darkBlue,
            '--var-module-ui-component-dropdown-style-withShadow-activeTextMain': colors.darkBlue,
            '--var-module-ui-component-dropdown-style-withShadow-textSub': colors.darkCloud,
            '--var-module-ui-component-dropdown-style-withShadow-activeTextSub': colors.blue,
            '--var-module-ui-component-dropdown-style-withShadow-icon': colors.darkBlue,
            '--var-module-ui-component-dropdown-style-withShadow-activeIcon': colors.blue,
            '--var-module-ui-component-dropdown-style-withShadow-shadow': `2px 3px 4px 0px rgba(0, 54, 136, 0.25)`,
            '--var-module-ui-component-dropdown-style-none-background': colors.white,
            '--var-module-ui-component-dropdown-style-none-activeBackground': colors.white,
            '--var-module-ui-component-dropdown-style-none-border': 'transparent',
            '--var-module-ui-component-dropdown-style-none-activeBorder': 'transparent',
            '--var-module-ui-component-dropdown-style-none-textMain': colors.darkBlue,
            '--var-module-ui-component-dropdown-style-none-activeTextMain': colors.darkBlue,
            '--var-module-ui-component-dropdown-style-none-textSub': colors.darkCloud,
            '--var-module-ui-component-dropdown-style-none-activeTextSub': colors.blue,
            '--var-module-ui-component-dropdown-style-none-icon': colors.darkBlue,
            '--var-module-ui-component-dropdown-style-none-activeIcon': colors.blue,
            '--var-module-ui-component-dropdown-style-none-shadow': 'none',
            '--var-module-ui-component-dropdown-style-fill-background': colors.cloud,
            '--var-module-ui-component-dropdown-style-fill-activeBackground': colors.cloud,
            '--var-module-ui-component-dropdown-style-fill-border': colors.cloud,
            '--var-module-ui-component-dropdown-style-fill-activeBorder': colors.cloud,
            '--var-module-ui-component-dropdown-style-fill-textMain': colors.white,
            '--var-module-ui-component-dropdown-style-fill-activeTextMain': colors.white,
            '--var-module-ui-component-dropdown-style-fill-textSub': colors.white,
            '--var-module-ui-component-dropdown-style-fill-activeTextSub': colors.white,
            '--var-module-ui-component-dropdown-style-fill-icon': colors.white,
            '--var-module-ui-component-dropdown-style-fill-activeIcon': colors.white,
            '--var-module-ui-component-dropdown-style-fill-shadow': 'none',
            /** Module: ui, component: checkbox */
            '--var-module-ui-component-checkbox-style-circle-textMain': colors.darkBlue,
            '--var-module-ui-component-checkbox-style-circle-border': colors.darkCloud,
            '--var-module-ui-component-checkbox-style-circle-background': colors.darkCloud,
            '--var-module-ui-component-checkbox-style-circle-icon': colors.white,
            '--var-module-ui-component-checkbox-style-circleInv-textMain': colors.darkBlue,
            '--var-module-ui-component-checkbox-style-circleInv-border': colors.white,
            '--var-module-ui-component-checkbox-style-circleInv-background': colors.brown,
            '--var-module-ui-component-checkbox-style-circleInv-icon': colors.white,
            '--var-module-ui-component-checkbox-style-circleDis-background': colors.brown,
            '--var-module-ui-component-checkbox-style-circleDis-textMain': colors.grey,
            '--var-module-ui-component-checkbox-style-circleDis-border': colors.white,
            '--var-module-ui-component-checkbox-style-circleDis-icon': colors.white,
            '--var-module-ui-component-checkbox-style-white-textMain': colors.white,
            '--var-module-ui-component-checkbox-style-white-border': colors.white,
            '--var-module-ui-component-checkbox-style-white-background': colors.white,
            '--var-module-ui-component-checkbox-style-white-icon': colors.black,
            /** Module: sidebar, component: sidebar */
            '--var-module-sidebar-component-sidebar-style-background': colors.white,
            '--var-module-sidebar-component-sidebar-style-buttonUploads': colors.blueDark,
            '--var-module-sidebar-component-sidebar-style-toggleButton': colors.white,
            '--var-module-sidebar-component-sidebar-style-description': colors.darkCloud,
            '--var-module-sidebar-component-sidebar-style-text': colors.black,
            '--var-module-sidebar-component-sidebar-style-title': colors.black,
            '--var-module-sidebar-component-sidebar-style-textHover': colors.blue,
            '--var-module-sidebar-component-sidebar-style-wrapperBackground': colors.blueDark,
            '--var-module-sidebar-component-sidebar-style-wrapperText': colors.greyLight,
            '--var-module-sidebar-component-sidebar-style-contentBackground': colors.white,
            '--var-module-sidebar-component-sidebar-style-border': colors.cloud,
            '--var-module-sidebar-component-sidebar-style-borderElement': colors.black,
            '--var-module-sidebar-component-screenshot-style-infoBorder': colors.blackLight,
            '--var-module-sidebar-component-screenshot-style-text': colors.white,
            '--var-module-sidebar-component-sidebar-style-menuTextHover': colors.blue,
            '--var-module-sidebar-component-sidebar-style-menuText': colors.darkCloud,
            '--var-module-sidebar-component-sidebar-style-toggleText': colors.darkCloud,
            '--var-module-sidebar-component-sidebar-style-activeToggleText': colors.blue,
            '--var-module-sidebar-component-sidebar-style-disableToggleText': colors.darkCloud,
            '--var-module-sidebar-component-sidebar-style-itemSelected': colors.blueWhite,
            /** Module: sidebar, component: toolbarUpload */
            '--var-module-sidebar-component-toolbarUpload-style-background': colors.darkCloud,
            '--var-module-sidebar-component-toolbarUpload-style-text': colors.white,
            /** Module: sidebar, component: schedule */
            '--var-module-sidebar-component-schedule-style-title': colors.darkBlue,
            '--var-module-sidebar-component-schedule-style-addIcon': colors.darkCloud,
            '--var-module-sidebar-component-schedule-style-icon': 'rgba(0, 0, 0, 0.45)',
            '--var-module-sidebar-component-schedule-style-iconColor': colors.darkCloud,
            /** Module: sidebar, component: filePreview */
            '--var-module-sidebar-component-filePreview-style-text': colors.darkCloud,
            '--var-module-sidebar-component-filePreview-style-textHover': colors.blue,
            '--var-module-sidebar-component-filePreview-style-background': colors.darkCloud,
            '--var-module-sidebar-component-filePreview-style-timeline': colors.white,
            /** Module: sidebar, component: broadcast */
            '--var-module-sidebar-component-broadcast-style-title': colors.darkCloud,
            /** Module: ui, component: editText */
            '--var-module-ui-component-editText-style-default-textMain': colors.darkBlue,
            '--var-module-ui-component-editText-style-default-error': colors.errorLight,
            '--var-module-ui-component-editText-style-default-shadow': '2px 3px 4px 0px rgba(0, 54, 136, 0.25)',
            '--var-module-ui-component-editText-style-default-border': colors.cloud,
            '--var-module-ui-component-editText-style-default-icon': colors.darkCloud,
            '--var-module-ui-component-editText-style-default-placeholder': colors.darkCloud,
            '--var-module-ui-component-editText-style-default-background': colors.white,
            '--var-module-ui-component-editText-style-text-textMain': colors.darkBlue,
            '--var-module-ui-component-editText-style-text-error': colors.errorLight,
            '--var-module-ui-component-editText-style-text-shadow': 'none',
            '--var-module-ui-component-editText-style-text-border': 'transparent',
            '--var-module-ui-component-editText-style-text-icon': colors.darkCloud,
            '--var-module-ui-component-editText-style-text-placeholder': colors.darkCloud,
            '--var-module-ui-component-editText-style-text-background': 'transparent',
            '--var-module-ui-component-editText-style-white-textMain': colors.white,
            '--var-module-ui-component-editText-style-white-error': colors.errorLight,
            '--var-module-ui-component-editText-style-white-shadow': 'none',
            '--var-module-ui-component-editText-style-white-border': 'transparent',
            '--var-module-ui-component-editText-style-white-icon': colors.white,
            '--var-module-ui-component-editText-style-white-placeholder': colors.white,
            '--var-module-ui-component-editText-style-white-background': colors.white,
            '--var-module-ui-component-editText-style-withBorder-textMain': colors.darkBlue,
            '--var-module-ui-component-editText-style-withBorder-error': colors.errorLight,
            '--var-module-ui-component-editText-style-withBorder-shadow': 'none',
            '--var-module-ui-component-editText-style-withBorder-border': colors.darkBlue,
            '--var-module-ui-component-editText-style-withBorder-icon': colors.darkBlue,
            '--var-module-ui-component-editText-style-withBorder-placeholder': colors.darkCloud,
            '--var-module-ui-component-editText-style-withBorder-background': colors.white,
            '--var-module-ui-component-editText-style-withLightBorder-textMain': colors.darkBlue,
            '--var-module-ui-component-editText-style-withLightBorder-error': colors.errorLight,
            '--var-module-ui-component-editText-style-withLightBorder-shadow': '2px 3px 4px 0px rgba(0, 54, 136, 0.25)',
            '--var-module-ui-component-editText-style-withLightBorder-border': colors.cloud,
            '--var-module-ui-component-editText-style-withLightBorder-icon': colors.darkBlue,
            '--var-module-ui-component-editText-style-withLightBorder-placeholder': colors.darkCloud,
            '--var-module-ui-component-editText-style-withLightBorder-background': colors.white,
            '--var-module-ui-component-editText-style-withBottomLine-textMain': colors.darkBlue,
            '--var-module-ui-component-editText-style-withBottomLine-error': colors.errorLight,
            '--var-module-ui-component-editText-style-withBottomLine-shadow': 'transparent',
            '--var-module-ui-component-editText-style-withBottomLine-border': colors.darkBlue,
            '--var-module-ui-component-editText-style-withBottomLine-icon': colors.grey,
            '--var-module-ui-component-editText-style-withBottomLine-placeholder': colors.darkCloud,
            '--var-module-ui-component-editText-style-withBottomLine-background': colors.white,
            /** Module: catalog, component: item_ */
            '--var-module-catalog-component-item-style-online': colors.online,
            '--var-module-catalog-component-item-style-offline': colors.offline,
            '--var-module-catalog-component-item-style-offlineGroup': colors.darkCloud,
            '--var-module-catalog-component-item-style-textMain': colors.white,
            '--var-module-catalog-component-item-style-backgroundMain': colors.darkCloud,
            '--var-module-catalog-component-item-style-backgroundSub': colors.cloud,
            '--var-module-catalog-component-item-style-error': colors.errorLight,
            '--var-module-catalog-component-item-style-active': 'rgba(161,177,202, 0.45)',
            '--var-module-catalog-component-item-style-shadow': '-1px 3px 4px 0px rgba(0, 54, 136, 0.25)',
            /** Module: catalog, component: referenceBook */
            '--var-module-catalog-component-referenceBook-style-backgroundMain': colors.white,
            /** Module: videoChat, component: videoChat */
            '--var-module-videoChat-component-videoChat-style-localVideo': colors.darkCloud,
            '--var-module-videoChat-component-videoChat-style-video': colors.blackLight,
            /** Module: ui, component: scroll */
            '--var-module-ui-component-scroll-style-background': colors.cloud,

            /** Module: map, component: selectMapDialog */
            '--var-module-map-component-selectMapDialog-style-background': colors.white,

            /** Module: map, component: mapLocationList */
            '--var-module-map-component-mapLocationList-style-background': colors.white,
            '--var-module-map-component-mapLocationList-style-hoverBackground': colors.blue,
            '--var-module-map-component-mapLocationList-style-text': colors.black,
            '--var-module-map-component-mapLocationList-style-hoverText': colors.white,
            '--var-module-map-component-mapLocationList-style-shadow': '0 4px 4px rgba(0, 54, 136, 0.25)',
            ...themeCustomThemeColors,
        },
        custom: {
            ...themeCustomThemeColors,
        },
    }

    _clearAllThemeStyles(themeColorSchemeVariables)
    const themeColorSchemeObject = themeColorSchemeVariables[actualThemeColorScheme]

    if (!themeColorSchemeObject) return

    Object.entries(themeColorSchemeObject).forEach(([key, value]) => {
        document.body.style.setProperty(key, value)
    })

    setMainMenuThemeColorScheme(mainMenuTheme)
}

const _clearAllThemeStyles = (themes: any) => {
    for (let theme in themes) {
        for (let themeVariableKey in themes[theme]) {
            document.body.style.setProperty(themeVariableKey, '')
        }
    }
}

export const setMainMenuThemeColorScheme = (currentThemeColorScheme: any | null) => {
    const actualThemeColorScheme: any = currentThemeColorScheme || 'dark'

    const themeColorSchemeVariables: any = {
        dark: {
            '--var-module-app-component-menu-style-background': colors.blackSemi,
            '--var-module-app-component-menu-style-textMain': colors.grey,
            '--var-module-app-component-menu-style-textSub': colors.grey,
            '--var-module-app-component-menu-style-hover': colors.white,
            '--var-module-app-component-menu-style-disable': colors.grey,
            '--var-module-app-component-menu-style-active': colors.white,
            '--var-module-app-component-menu-style-shadow': '0px 4px 4px rgba(129, 129, 129, 0.5)',
            '--var-module-app-component-menu-style-icon-color': colors.white,
            '--var-module-app-component-user-style-textMain': colors.white,
            '--var-module-ui-component-icon-style-menuIconActive': colors.blackSemi,
            '--var-module-app-component-menu-style-dividerBackground': colors.grey,
            '--var-module-app-component-menu-style-logoColor': colors.white,
        },
        light: {
            '--var-module-app-component-menu-style-background': colors.greyLight,
            '--var-module-app-component-menu-style-textMain': colors.grey,
            '--var-module-app-component-menu-style-textSub': colors.grey,
            '--var-module-app-component-menu-style-hover': colors.black,
            '--var-module-app-component-menu-style-disable': colors.grey,
            '--var-module-app-component-menu-style-active': colors.blackLight,
            '--var-module-app-component-menu-style-shadow': '0px 4px 4px rgba(129, 129, 129, 0.5)',
            '--var-module-app-component-menu-style-icon-color': colors.blackLight,
            '--var-module-app-component-user-style-textMain': colors.blackLight,
            '--var-module-app-component-menu-style-dividerBackground': colors.grey,
            '--var-module-app-component-menu-style-logoColor': colors.blackLight,
        },
    }

    const themeColorSchemeObject = themeColorSchemeVariables[actualThemeColorScheme]

    if (!themeColorSchemeObject) return

    Object.entries(themeColorSchemeObject).forEach(([key, value]: any) => {
        document.body.style.setProperty(key, value)
    })
}

export const getThemeStyleValue = (module: string, component: string, style: string): string => {
    return document.body.style.getPropertyValue(`--var-module-${module}-component-${component}-style-${style}`)
}

export const getThemeIconValue = (module: string, component: string, icon: string): string => {
    return document.body.style.getPropertyValue(`--var-module-${module}-component-${component}-icon-${icon}`)
}
