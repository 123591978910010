import React, { memo } from 'react'
import Button from 'blocks.simple/button/button'
import Catalog from 'blocks.app/catalog/catalog'
import VideoEditorSidebarMethods, { IVideoEditorSidebarMethodsProps } from './videoEditor__sidebar.local.methods'
import Icon from 'blocks.simple/icon/icon'
import EditText from 'blocks.simple/editText/editText'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import Dropdown from 'blocks.simple/dropdown/dropdown'

import deepEqual from 'fast-deep-equal'
import moment from 'moment'
import helpers from 'core/helpers'
import helpersFiles from 'core/helpers/_files/index'
import translate from 'core/translate'
import { mediaJS } from 'blocks.app/config'

import { cn } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import styles from './videoEditor__sidebar.jcss'
import item from 'blocks.simple/item/item.jcss'
import { Typography } from 'atoms/Typography'
import { defaultThemeStyles as defaultThemeSidebarStyles } from 'blocks.app/sidebar/Sidebar-theme'

const degrees = [
    { id: 0, name: 0 },
    { id: 90, name: 90 },
    { id: 180, name: 180 },
    { id: 270, name: 270 },
]
const bitRate = [
    { id: 1000, name: '360p' },
    { id: 2500, name: '480p' },
    { id: 5000, name: '720p' },
    { id: 8000, name: '1080p' },
    { id: 16000, name: '1440p' },
    { id: 40000, name: '2160p' },
]
const bitRateValues = bitRate.map((item) => item.id)

const getShowSettingsHeaderIcon = (type: string) => {
    switch (type) {
        case 'edit':
            return 'pencil'
        default:
            return 'gear'
    }
}
const VideoEditorSidebar = (props: IVideoEditorSidebarMethodsProps) => {
    const {
        state,
        calculateProportions,
        isButtonCheckedAndDisabled,
        onChangeCrop,
        onChangeNumberField,
        setDisabledMultiSelect,
        setState,
        setActiveToggle,
    } = VideoEditorSidebarMethods(props)

    let dragTimer: any = null
    const p_ = props
    const s_ = { ...state }
    const disableProcessButton = deepEqual(p_.commands, p_.defaultCommands)
    const activeToolbarColor = `var(--var-module-sidebar-component-sidebar-style-activeToggleText, ${defaultThemeSidebarStyles.activeToggleText})`
    const notActiveToolbarColor = `var(--var-module-sidebar-component-sidebar-style-toggleText, ${defaultThemeSidebarStyles.toggleText})`

    return (
        <div
            ref={props.videoEditorSidebarRef}
            style={p_.minimized ? { width: '58px', overflow: 'hidden' } : { width: '33.3333333%' }}
            className={cn(styles.colsRight, { [styles.colsRightOpen]: !p_.minimized })}
        >
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <div
                        onClick={function () {
                            p_.toggleMinimized()
                        }}
                        className={styles.minimize}
                    >
                        <Icon size={16} type={p_.minimized ? 'double_arrow_left' : 'double_arrow_right'} />
                    </div>
                    {!p_.minimized && (
                        <div className={grid.spaceWidth}>
                            {!p_.showSettings && (
                                <div className={styles.toggle}>
                                    <div
                                        className={cn(grid.col2, styles.toggleItem, {
                                            [styles.toggleItemActive]: s_.activeToggle === 'tasks',
                                            [styles.toggleItemDisable]: s_.activeToggle !== 'tasks',
                                        })}
                                        onClick={() => {
                                            setState((prevState) => {
                                                return {
                                                    ...prevState,
                                                    activeToggle: 'tasks',
                                                }
                                            })
                                        }}
                                    >
                                        <div className={grid.rowCenter}>
                                            <Icon
                                                type={'list'}
                                                color={
                                                    s_.activeToggle === 'tasks'
                                                        ? activeToolbarColor
                                                        : notActiveToolbarColor
                                                }
                                            />
                                            <div className={grid.ml_micro}>{translate('tasks')}</div>
                                        </div>
                                    </div>
                                    <div
                                        className={cn(grid.col2, styles.toggleItem, {
                                            [styles.toggleItemActive]: s_.activeToggle === 'files',
                                        })}
                                        onClick={() => {
                                            setState((prevState) => {
                                                return {
                                                    ...prevState,
                                                    activeToggle: 'files',
                                                    activeProcess: 0,
                                                }
                                            })
                                        }}
                                    >
                                        <div className={grid.rowCenter}>
                                            <Icon
                                                type={'folder'}
                                                color={
                                                    s_.activeToggle === 'files'
                                                        ? activeToolbarColor
                                                        : notActiveToolbarColor
                                                }
                                            />
                                            <div className={grid.ml_micro}>{translate('files')}</div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {p_.showSettings && (
                                <div className={styles.toggle}>
                                    <div className={cn(grid.fullWidth, styles.toggleItem, styles.toggleItemActive)}>
                                        <div className={grid.rowCenter}>
                                            <Icon type={getShowSettingsHeaderIcon(p_.showSettings)} />
                                            <div className={grid.ml_micro}>
                                                {p_.showSettings === 'edit'
                                                    ? translate(`${p_.media.type}Editing`)
                                                    : translate('settings')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className={styles.contentWrapper}>
                    <div
                        className={styles.content}
                        style={s_.activeToggle === 'tasks' && !p_.showSettings ? {} : { display: 'none' }}
                    >
                        <div className={cn(styles.catalogWrapper, grid.mt_md)}>
                            <Catalog
                                searchbarMod={'withShadow'}
                                key={'catalog_videoEditorTasks'}
                                type={'videoEditorTasks'}
                                selectedInfo={p_.media}
                                onSelectInfo={p_.onSelectTask}
                                selectedItems={p_.selectedTasks}
                                onSelect={p_.onSelectTasks}
                                disableMultiSelect={setDisabledMultiSelect}
                                onChange={function (item: any, type: string) {
                                    p_.onChangeTask(item, type)
                                }}
                                treeView={false}
                                useWindow={false}
                            />
                        </div>
                    </div>
                    <div
                        className={styles.content}
                        style={s_.activeToggle === 'files' && !p_.showSettings ? {} : { display: 'none' }}
                    >
                        <div
                            className={styles.catalogWrapper}
                            onClick={function () {
                                p_.openMinimized()
                            }}
                        >
                            <Catalog
                                type={'files'}
                                searchbarMod={'withShadow'}
                                small={true}
                                useWindow={false}
                                cols={4}
                                hideHeader={p_.minimized}
                                classNameContent={cn({ [item.none]: p_.minimized })}
                                showHeaderButton={false}
                                disposition={'tile'}
                                selectedInfo={p_.media}
                                filterItems={[
                                    { name: translate('all') },
                                    { name: translate('video'), fileType: 'video', type: 'fs' },
                                    { name: translate('image'), fileType: 'image', type: 'fs' },
                                ]}
                                onDragStart={() => {
                                    if (mediaJS.tabMini.matches) {
                                        clearTimeout(dragTimer)
                                        dragTimer = setTimeout(() => {
                                            p_.toggleMinimized()
                                        }, 300)
                                    }
                                }}
                                onDragEnd={() => {
                                    if (mediaJS.tabMini.matches) {
                                        clearTimeout(dragTimer)
                                    }
                                }}
                                isMobileDragEnabled
                            />
                        </div>
                    </div>
                    {p_.showSettings === 'edit' && p_.media.type === 'video' && (
                        <div className={styles.content}>
                            <div>
                                <Typography className={styles.title} type="title">
                                    {translate('duration')}
                                </Typography>
                                <div className={styles.settingsContent}>
                                    <EditText
                                        value={p_.commands.cut.startTime}
                                        label={translate('startTime')}
                                        containerClass={cn(grid.w45, grid.mr_md)}
                                        mask={'99:99:99'}
                                        onChange={function (value: number | string) {
                                            const data = helpers.deepCopy(p_.commands)

                                            data.cut.startTime = value
                                            p_.onChangeCommands(data)
                                        }}
                                        onBlur={function (value: number | string) {
                                            if (!moment(value, 'HH:mm:ss', true).isValid()) {
                                                const data = helpers.deepCopy(p_.commands)

                                                data.cut.startTime = ''
                                                p_.onChangeCommands(data)
                                            }
                                        }}
                                    />
                                    <EditText
                                        value={p_.commands.cut.duration}
                                        label={translate('endTime')}
                                        containerClass={cn(grid.w45)}
                                        mask={'99:99:99'}
                                        onChange={function (value: number | string) {
                                            const data = helpers.deepCopy(p_.commands)

                                            data.cut.duration = value
                                            p_.onChangeCommands(data)
                                        }}
                                        onBlur={function (value: number | string) {
                                            const data = helpers.deepCopy(p_.commands)

                                            if (!moment(value, 'HH:mm:ss', true).isValid()) {
                                                data.cut.duration = ''
                                                p_.onChangeCommands(data)
                                            }
                                        }}
                                    />
                                </div>
                                <Typography className={styles.contentTitle} type="title">
                                    {translate('size')}
                                </Typography>
                                <div className={styles.settingsContent}>
                                    <EditText
                                        mod={'withBorder'}
                                        value={p_.commands.crop.width}
                                        label={translate('width')}
                                        containerClass={cn(grid.w45, grid.mr_md)}
                                        type="number"
                                        onChange={(value: number) => {
                                            onChangeCrop('width', value)
                                        }}
                                    />
                                    <EditText
                                        mod={'withBorder'}
                                        value={p_.commands.crop.height}
                                        label={translate('height')}
                                        containerClass={cn(grid.w45)}
                                        type="number"
                                        onChange={(value: number) => {
                                            onChangeCrop('height', value)
                                        }}
                                    />
                                </div>
                                {!p_.media.multiple && (
                                    <div className={styles.settingsContent}>
                                        <Checkbox
                                            label={translate('keepProportions')}
                                            checked={s_.keepProportions}
                                            onClick={(checked: boolean) => {
                                                if (checked) {
                                                    calculateProportions()
                                                }
                                                setState((prevState) => {
                                                    return {
                                                        ...prevState,
                                                        keepProportions: checked,
                                                    }
                                                })
                                            }}
                                        />
                                    </div>
                                )}
                                <Typography className={styles.contentTitle} type="title">
                                    {translate('conversion')}
                                </Typography>
                                {p_.media.mimetype !== 'video/mp4' && (
                                    <div style={{ paddingBottom: 0 }} className={styles.settingsContent}>
                                        <Checkbox
                                            disabled={!isButtonCheckedAndDisabled(p_.media.mimetype)}
                                            label={translate('covertVideoToMp4')}
                                            checked={
                                                !isButtonCheckedAndDisabled(p_.media.mimetype)
                                                    ? true
                                                    : p_.commands.convertToMp4.active
                                            }
                                            onClick={function (checked: boolean) {
                                                if (helpersFiles.availableVideoMimeTypes(p_.media.mimetype)) {
                                                    const data = helpers.deepCopy(p_.commands)

                                                    data.convertToMp4.active = checked
                                                    p_.onChangeCommands(data)
                                                }
                                            }}
                                        />
                                    </div>
                                )}
                                <Typography className={styles.subTitle} type="title">
                                    {translate('bitRate')}
                                </Typography>
                                <div className={styles.settingsContent}>
                                    <EditText
                                        value={p_.commands.setVideoBitrate.bitrate}
                                        label={translate('customBitRate')}
                                        containerClass={cn(grid.w45)}
                                        onChange={(value: number) => {
                                            onChangeNumberField(value, 'setVideoBitrate', 'bitrate')
                                        }}
                                    />
                                    <Dropdown
                                        containerClassName={cn(grid.w45)}
                                        label={translate('availableBitRate')}
                                        value={
                                            bitRateValues.includes(p_.commands.setVideoBitrate.bitrate)
                                                ? p_.commands.setVideoBitrate.bitrate
                                                : null
                                        }
                                        options={bitRate}
                                        onChange={function (bitrate) {
                                            const data = helpers.deepCopy(p_.commands)

                                            data.setVideoBitrate.bitrate = bitrate.id
                                            p_.onChangeCommands(data)
                                        }}
                                    />
                                </div>
                                <Typography className={styles.subTitle} type="title">
                                    {translate('fps')}
                                </Typography>
                                <div className={styles.settingsContent}>
                                    <EditText
                                        value={p_.commands.setFps.fps}
                                        containerClass={cn(grid.w45)}
                                        onChange={(value: number) => {
                                            onChangeNumberField(value, 'setFps', 'fps')
                                        }}
                                    />
                                </div>
                                <Typography className={styles.contentTitle} type="title">
                                    {translate('rotate')}
                                </Typography>
                                <div className={styles.settingsContent}>
                                    <Dropdown
                                        containerClassName={cn(grid.w45)}
                                        value={p_.commands.rotate.degrees}
                                        options={degrees}
                                        onChange={function (degree) {
                                            const data = helpers.deepCopy(p_.commands)

                                            data.rotate.degrees = degree.id
                                            p_.onChangeCommands(data)
                                        }}
                                    />
                                </div>
                                <div className={styles.description}>{translate('rotateClockwise')}</div>
                                <Typography className={styles.contentTitle} type="title">
                                    {translate('sound')}
                                </Typography>
                                <div className={styles.settingsContent}>
                                    <Checkbox
                                        label={translate('deleteSoundFromVideo')}
                                        checked={p_.commands.removeAudio.active}
                                        onClick={function (checked: boolean) {
                                            const data = helpers.deepCopy(p_.commands)

                                            data.removeAudio.active = checked
                                            p_.onChangeCommands(data)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={styles.buttonWrapper}>
                                <Button
                                    className={cn(grid.mr_md)}
                                    rounded="full_md"
                                    mod="fill"
                                    onClick={setActiveToggle}
                                    disabled={
                                        disableProcessButton ||
                                        p_.media.status === 'in_queue' ||
                                        p_.media.status === 'in_progress'
                                    }
                                >
                                    {translate('process')}
                                </Button>
                                <Button
                                    rounded="full_md"
                                    animation={false}
                                    onClick={function () {
                                        p_.onChangeShowSettings(null)
                                    }}
                                >
                                    {translate('cancel')}
                                </Button>
                            </div>
                        </div>
                    )}
                    {p_.showSettings === 'edit' && p_.media.type === 'image' && (
                        <div className={styles.content}>
                            <div>
                                <Typography className={styles.contentTitle} type="title">
                                    {translate('duration')}
                                </Typography>
                                <div className={styles.settingsContent}>
                                    <EditText
                                        mod={'withBorder'}
                                        value={p_.commands.imageToVideo.duration}
                                        containerClass={cn(grid.w45)}
                                        label={translate('durationSec')}
                                        onChange={(value: number) => {
                                            onChangeNumberField(value, 'imageToVideo', 'duration')
                                        }}
                                    />
                                </div>
                                <Typography className={styles.contentTitle} type="title">
                                    {translate('size')}
                                </Typography>
                                <div className={styles.settingsContent}>
                                    <EditText
                                        mod={'withBorder'}
                                        value={p_.commands.crop.width}
                                        label={translate('width')}
                                        containerClass={cn(grid.w45, grid.mr_md)}
                                        type="number"
                                        onChange={(value: number) => {
                                            onChangeCrop('width', value)
                                        }}
                                    />
                                    <EditText
                                        mod={'withBorder'}
                                        value={p_.commands.crop.height}
                                        label={translate('height')}
                                        containerClass={cn(grid.w45)}
                                        type="number"
                                        onChange={(value: number) => {
                                            onChangeCrop('height', value)
                                        }}
                                    />
                                </div>
                                {!p_.media.multiple && (
                                    <div className={styles.settingsContent}>
                                        <Checkbox
                                            label={translate('keepProportions')}
                                            checked={s_.keepProportions}
                                            onClick={(checked: boolean) => {
                                                if (checked) {
                                                    calculateProportions()
                                                }
                                                setState((prevState) => {
                                                    return {
                                                        ...prevState,
                                                        keepProportions: checked,
                                                    }
                                                })
                                            }}
                                        />
                                    </div>
                                )}
                                <Typography className={styles.contentTitle} type="title">
                                    {translate('bitRate')}
                                </Typography>
                                <div className={styles.settingsContent}>
                                    <EditText
                                        value={p_.commands.setVideoBitrate.bitrate}
                                        label={translate('customBitRate')}
                                        containerClass={cn(grid.w45)}
                                        onChange={(value: number) => {
                                            onChangeNumberField(value, 'setVideoBitrate', 'bitrate')
                                        }}
                                    />
                                    <Dropdown
                                        containerClassName={cn(grid.w45)}
                                        label={translate('availableBitRate')}
                                        value={
                                            bitRateValues.includes(p_.commands.setVideoBitrate.bitrate)
                                                ? p_.commands.setVideoBitrate.bitrate
                                                : null
                                        }
                                        options={bitRate}
                                        onChange={function (bitrate) {
                                            const data = helpers.deepCopy(p_.commands)

                                            data.setVideoBitrate.bitrate = bitrate.id
                                            p_.onChangeCommands(data)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={styles.buttonWrapper}>
                                <Button
                                    className={cn(grid.mr_md)}
                                    rounded="full_md"
                                    mod="fill"
                                    onClick={setActiveToggle}
                                    disabled={
                                        disableProcessButton ||
                                        p_.media.status === 'in_queue' ||
                                        p_.media.status === 'in_progress'
                                    }
                                >
                                    {translate('process')}
                                </Button>
                                <Button
                                    rounded="full_md"
                                    animation={false}
                                    onClick={function () {
                                        p_.onChangeShowSettings(null)
                                    }}
                                >
                                    {translate('cancel')}
                                </Button>
                            </div>
                        </div>
                    )}
                    {p_.showSettings === 'settings' && (
                        <div className={styles.content}>
                            <div className={grid.p_md}>
                                {p_.selectedTasks.length <= 0 && (
                                    <EditText
                                        value={p_.settings.fileName}
                                        label={translate('name')}
                                        onChange={function (fileName: string) {
                                            p_.onChangeSettings(fileName, 'fileName')
                                        }}
                                    />
                                )}
                                {p_.selectedTasks.length > 0 && (
                                    <EditText
                                        value={p_.settings.fileNamePrefix}
                                        label={translate('fileNamePrefix')}
                                        onChange={function (fileNamePrefix: string) {
                                            p_.onChangeSettings(fileNamePrefix, 'fileNamePrefix')
                                        }}
                                    />
                                )}
                                <Dropdown
                                    containerClassName={cn(grid.w100, grid.mt_md)}
                                    label={translate('parentGroup')}
                                    value={p_.settings.folderId}
                                    options={s_.folders}
                                    placeholder={translate('chooseFolder')}
                                    onChange={function (folder: { [index: string]: any }) {
                                        p_.onChangeSettings(folder.id, 'folderId')
                                    }}
                                    searchable
                                    centered
                                    type="tree"
                                />
                            </div>
                            <div className={styles.buttonWrapper}>
                                <Button
                                    rounded="full_md"
                                    animation={false}
                                    onClick={function () {
                                        p_.onChangeShowSettings(null)
                                    }}
                                >
                                    {translate('close')}
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default VideoEditorSidebar
