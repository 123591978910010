import React from 'react'
import ItemRoles from './Tile'
import { Icon } from 'molecules/Icon'
import { api } from 'core/api/ConnectionManager'
import helpers from 'core/helpers'
import { routes } from 'features/routes'
import translate from 'core/translate'
import moment from 'moment'
import styles from './styles'
import { grid } from 'theme'
import { changeLocation } from 'features/routes'

const openRoleCreate = () => {
    changeLocation(`/${routes.createRole.path}`)
}

export default {
    getMethod: 'getRoles',
    additionParams: {
        editableOnlyQ: true,
    },
    getCustomFilter: () => ({
        editableOnlyQ: true,
    }),
    emptySearchText: () => translate('notFound'),
    emptyFolderText: () => translate('noRoles'),
    settingsPrefixes: 'roles',
    multiSelect: true,
    headerCustomContent: {
        left: () => {
            return (
                <React.Fragment>
                    {helpers.isAvailable({ key: 'role', action: 'create' }) && (
                        <div className={styles.catalogTemplateRoleIcon} onClick={openRoleCreate}>
                            <Icon className={grid.mr_mini} type="create_item" size={28} />
                            <span className={styles.roleAddText}>{translate('addMoreRole')}</span>
                        </div>
                    )}
                </React.Fragment>
            )
        },
    },
    tile: ItemRoles,
    dateFilterOptions: () => [
        { id: 'completed', name: translate('completed') },
        { id: 'current', name: translate('current') },
        { id: 'upcoming', name: translate('upcoming') },
    ],
    getItemModel: (dataItem, getSelected) => {
        const item = { ...dataItem }

        if (!item.__view) {
            item.__view = {
                selected: getSelected(item),
            }
        }

        return item
    },
    getBackendModel: (item) => api.send('getRoleById', { roleId: item.id }, { hideLoader: true }),
    isEqual: (firstItem, secondItem) => firstItem.id === secondItem.id,
    onCreateListeners: [],
    onUpdateListeners: [(cb, clearList) => api.addObserver('roleUpdated', cb, clearList)],
    onDeleteListeners: [(cb, clearList) => api.addObserver('roleDeleted', cb, clearList)],
    fields: () => [
        { id: 'label', name: translate('labelName') },
        { id: 'createdAt', name: translate('dateOfCreation') },
    ],
    init(callback) {
        this.setState({ isInit: true, fields: this.template.fields() }, callback)
    },
    sortOptions: () => [
        { value: '-createdAt', label: translate('sortCreatedAt') },
        { value: 'label', label: translate('byNameAsc') },
    ],
    viewOptions: () => [
        { value: 'tile', label: translate('tile') },
        { value: 'list', label: translate('list') },
    ],
    list: {
        label({ label }) {
            const firstChar = label.charAt(0)

            return (
                <div className={styles.catalogTemplateRoleLabelWrapper}>
                    {firstChar && <div className={styles.catalogTemplateRoleLabelChar}>{firstChar}</div>}
                    <div>{label}</div>
                </div>
            )
        },
        createdAt({ createdAt }) {
            return moment(createdAt).format('DD/MM/YYYY HH:mm')
        },
    },
}
