import React, { useEffect } from 'react'
import EditText, { EditTextPassword } from 'blocks.simple/editText/editText'
import Link from 'blocks.simple/link/link'
import Button from 'blocks.simple/button/button'
import { routes } from 'features/routes'
import { cn } from 'ethcss'
import AuthorizationForgetMethods from './authorization__forget.local.methods'
import authorizationStyles from '../authorization.jcss'
import { grid, item } from 'theme'
import translate from 'core/translate'
import { text as textStyles } from 'theme'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import { changeLocation } from 'features/routes'
import { useDispatch } from 'react-redux'
import { headActions } from 'features/head/Head.state'

interface IProps {
    location: {
        pathname: string
        search: string
        hash: string
        state: any
    }
    success?: string
    setTitle?: (title: string[]) => void
    onLoading: (isLoading: boolean) => void
}

const Forget = (p_: IProps) => {
    const { setNewPassword, recovery, state, setState } = AuthorizationForgetMethods(p_)
    const s_: any = state
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(headActions.setTitle([translate('forgetPasswordTitle')]))
    }, [])

    const query = getURLSearchParamsByLocation(p_.location)

    if (query.email && query.hash) {
        return (
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                    setNewPassword({
                        hash: query.hash,
                        email: query.email,
                    })
                }}
                className={authorizationStyles.formWrapper}
            >
                {s_.error === 'recoveryError' && (
                    <div>
                        {translate(s_.error)}
                        <Link
                            href={`mailto:${window.spconfig.emailSupport}`}
                            target="_blank"
                            textKey="passwordRecoveryMail"
                            mods={['hoverUnderline']}
                        />
                    </div>
                )}
                {s_.error !== 'recoveryError' && s_.recoverySuccess && <div>{translate('soonYouRedirected')}</div>}
                {s_.error !== 'recoveryError' && !s_.recoverySuccess && (
                    <div>
                        <div className={authorizationStyles.item}>
                            <EditTextPassword
                                id={'password_forget'}
                                error={translate(s_.errorPassword)}
                                placeholder={translate('password')}
                                mod="withBottomLine"
                                rounded="none"
                                value={s_.password}
                                onChange={(value: string) =>
                                    setState((prevState) => {
                                        return { ...prevState, password: value, error: null, errorPassword: null }
                                    })
                                }
                            />
                        </div>
                        <div className={authorizationStyles.item}>
                            <EditTextPassword
                                id={'passwordRepeat_forget'}
                                error={translate(s_.errorRepeatPassword)}
                                placeholder={translate('passwordRepeat')}
                                mod="withBottomLine"
                                rounded="none"
                                value={s_.passwordRepeat}
                                onChange={(value: string) =>
                                    setState((prevState) => {
                                        return {
                                            ...prevState,
                                            passwordRepeat: value,
                                            error: null,
                                            errorRepeatPassword: null,
                                        }
                                    })
                                }
                            />
                        </div>
                        <div className={`${s_.error ? '' : item.hidden} ${authorizationStyles.error}`}>
                            {translate(s_.error)}
                        </div>
                        <div className={cn(grid.row, grid.center, grid.mt_mini)}>
                            <Button type="submit" mod="fill" className={cn(grid.row, grid.center, grid.w70)}>
                                {translate('restore')}
                            </Button>
                        </div>
                    </div>
                )}
            </form>
        )
    } else {
        return (
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                    recovery()
                }}
                className={authorizationStyles.formWrapper}
            >
                <div className={authorizationStyles.item}>
                    {s_.error !== 'recoveryError' && s_.success && <div>{translate('recoverySuccessSend')}</div>}
                    {s_.error === 'recoveryError' && (
                        <div>
                            {translate(s_.error)}
                            <Link
                                href={`mailto:${window.spconfig.emailSupport}`}
                                target="_blank"
                                textKey="passwordRecoveryMail"
                                mods={['hoverUnderline']}
                            />
                        </div>
                    )}
                    {s_.success && <div>{translate(p_.success ? p_.success : '')}</div>}
                    {s_.error !== 'recoveryError' && !s_.success && (
                        <div>
                            <EditText
                                id={'email_forget'}
                                error={translate(s_.errorEmail)}
                                placeholder={translate('email')}
                                className={cn(textStyles.center)}
                                type="text"
                                mod="withBottomLine"
                                rounded="none"
                                value={s_.email}
                                onChange={(value: string) =>
                                    setState((prevState) => {
                                        return { ...prevState, email: value, error: null, errorEmail: null }
                                    })
                                }
                            />
                        </div>
                    )}
                </div>
                {s_.error !== 'recoveryError' && !s_.success && (
                    <div className={`${s_.error ? '' : item.hidden} ${authorizationStyles.error}`}>
                        {translate(s_.error)}
                    </div>
                )}
                {s_.error !== 'recoveryError' && !s_.success && (
                    <div className={cn(grid.row, grid.center, grid.mt_mini)}>
                        <Button type="submit" mod="fill" className={cn(grid.row, grid.center, grid.w70)}>
                            {translate('restore')}
                        </Button>
                    </div>
                )}
                {s_.error !== 'recoveryError' && s_.success && (
                    <div>
                        <div className={cn(grid.row, grid.center, grid.mt_mini)}>
                            <Button
                                onClick={function () {
                                    changeLocation(`/${routes.login.path}`)
                                }}
                                mod="fill"
                                className={cn(grid.row, grid.center, grid.w70)}
                            >
                                {translate('back')}
                            </Button>
                        </div>
                    </div>
                )}
            </form>
        )
    }
}

export default Forget
