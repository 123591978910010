import React, { useEffect, useState } from 'react'
import translate from '../../core/translate'
import Content from '../../blocks.app/content/content'
import styles from './licenses.jcss'
import { cn } from 'ethcss'
import { api } from '../../core/api/ConnectionManager'
import moment, { Moment } from 'moment'
import { colors } from '../../theme'
import store from '../../core/store'
import Icon from '../../blocks.simple/icon/icon'
import Button from '../../blocks.simple/button/button'
import Dropdown from '../../blocks.simple/dropdown/dropdown'
import EditText from '../../blocks.simple/editText/editText'
import LoaderItem from '../../blocks.app/loader/__item/loader__item'
import { License } from './License'
import { LicenseDemo } from './LicenseDemo'
import { RootStateOrAny, useSelector } from 'react-redux'

const getBackgroundColor = (license: any) => {
    if (!license.activeQ) return colors.grey
    if (license.licenseType === 'purchase') return colors.blueAccent
    return colors.success
}
const getLicenseType = (type: string) => {
    if (!type) return ''

    return translate(type)
}
const getInactiveStatus = (license: any) => {
    return translate(license.deletedQ ? 'licenseRevoked' : 'licenseCompleted')
}

const licenseTypes = ['purchase', 'subscription']
const tariffs = [
    'noTariff',
    'licenseTypeSmartSound',
    'licenseTypeSmartScreen',
    'licenseTypeSmartTouch',
    'licenseTypeVideoWall',
    'licenseTypeVideoWall4k',
    'licenseTypeVideoAnalytics',
]

export const Licenses = () => {
    const [licenses, setLicenses] = useState<any>([])
    const [companyStats, setCompanyStats] = useState<any>(null)
    const [showGiftModal, setShowGiftModal] = useState<boolean>(false)
    const [addLicense, setAddLicense] = useState<boolean>(false)
    const [deviceQuantity, setDeviceQuantity] = useState<string>('1')
    const [selectedLicense, setSelectedLicence] = useState<{ id: number; name: string }>({
        id: 0,
        name: translate('purchase'),
    })
    const [selectedTariff, setSelectedTariff] = useState({ id: 0, name: translate('noTariff') })
    const [selectedPeriod, setSelectedPeriod] = useState<{ id: number; name: string }>({
        id: 0,
        name: translate('free'),
    })
    const [startDate, setStartDate] = useState<Moment>(moment())
    const [endDate, setEndDate] = useState<Moment | null>(null)
    const [comment, setComment] = useState<string>('')
    const [uploadLicenseStatus, setUploadLicenseStatus] = useState<string>('')
    const [validationOfQuantity, setValidationOfQuantity] = useState(true)
    const user = store.getState().user.data
    const company = user && user.company
    const showDemoLicense = window.spconfig.showDemoLicense
    const closeGiftModal = () => {
        setShowGiftModal(false)
    }
    const openGiftModal = () => {
        setShowGiftModal(true)
    }
    const activateGiftLicences = () => {
        api.send('activateGiftLicense', { expiredD: 14, devicesCount: 20 }).then(() => openGiftModal())
    }
    const endDateOptions = [
        { id: 0, name: translate('freeDate') },
        { id: 1, name: translate('month') },
        { id: 6, name: translate('sixMonth') },
        { id: 12, name: translate('year') },
    ]
    const licenseTypeOptions = [
        { id: 0, name: translate('purchase') },
        { id: 1, name: translate('subscription') },
    ]
    const licenseTariffOptions = [
        { id: 0, name: translate('noTariff') },
        { id: 1, name: translate('licenseTypeSmartSound') },
        { id: 2, name: translate('licenseTypeSmartScreen') },
        { id: 3, name: translate('licenseTypeSmartTouch') },
        { id: 4, name: translate('licenseTypeVideoWall') },
        { id: 5, name: translate('licenseTypeVideoWall4k') },
        { id: 6, name: translate('licenseTypeVideoAnalytics') },
    ]
    const onCloseAddLicense = () => {
        setAddLicense(false)
        setDeviceQuantity('1')
        setSelectedLicence({ id: 1, name: translate('subscription') })
        setSelectedPeriod({ id: 0, name: translate('free') })
        setStartDate(moment())
        setEndDate(null)
        setComment('')
    }
    const createLicenseFile = async () => {
        try {
            api.post('requestLicense', {
                deviceQuantity,
                licenseType: licenseTypes[selectedLicense.id],
                tariff: tariffs[selectedTariff.id] === 'noTariff' ? null : tariffs[selectedTariff.id],
                startDate,
                endDate,
                comment,
                companyId: company.id,
            }).then((res: any) => {
                downloadAsFile(res.text)
            })
        } catch (err) {
            console.log(err)
        }

        onCloseAddLicense()
    }
    const uploadLicense = async (e: any) => {
        setUploadLicenseStatus('uploadLicenseProcessing')

        try {
            const formData = new FormData()
            formData.append('file', e.target.files[0])
            await api.post('uploadLicenseFile', formData).then((res) => {
                if (!res.error) {
                    setUploadLicenseStatus('uploadLicenseComplete')
                }
            })
        } catch (err) {
            setUploadLicenseStatus('uploadLicenseError')
        }
    }
    function downloadAsFile(data: any) {
        let a = document.createElement('a')
        let file = new Blob([data], { type: 'text' })
        a.href = URL.createObjectURL(file)
        a.download = 'license_request'
        a.click()
    }
    const setTimePeriod = (value: number) => {
        setEndDate(moment(startDate).add(value, 'months'))
    }

    useEffect(() => {
        api.send('getLicenseByFilters', {
            sort: [['licenseId', 'ASC']],
            limit: 1000,
        }).then((res: any) => {
            if (res.data) {
                setLicenses(res.data)
            }
        })

        api.send('getLicenseDSStats', {}, { showEmitError: false }).then((res: any) => {
            setCompanyStats(res)
        })
    }, [])
    useEffect(() => {
        if (!selectedLicense.id) {
            setSelectedPeriod({ id: 0, name: translate('freeDate') })
            setEndDate(null)
        }
    }, [selectedLicense])

    useEffect(() => {
        const listenersId: string[] = []

        api.addObserver(
            'licenseCreated',
            (data: any) => {
                setLicenses([...licenses, data])
            },
            listenersId
        )

        return () => {
            listenersId.forEach((id) => api.removeObserver(id))
        }
    }, [licenses])

    useEffect(() => {
        if (
            Number(deviceQuantity) < 1 ||
            !Number.isInteger(Number(deviceQuantity)) ||
            deviceQuantity.includes('e') ||
            !deviceQuantity
        ) {
            setValidationOfQuantity(false)
        } else {
            setValidationOfQuantity(true)
        }
    }, [deviceQuantity])

    return (
        <Content title={translate('licenses')}>
            <div className={styles.licensesWrapper}>
                {companyStats && (
                    <div className={styles.companyWrapper}>
                        {company && (
                            <div className={styles.companyLeft}>
                                <div className={styles.companyName}>{company.name}</div>
                                <div className={styles.companyRow}>
                                    {translate('companyIdShort')}: {company.id}
                                </div>
                                <div className={styles.companyRow}>
                                    {translate('projectManager')}: {company.manager ? company.manager : '-'}
                                </div>
                                <div className={styles.companyRow}>
                                    {translate('companyIncorporationDate')}:
                                    {moment(company.createdAt).format('DD.MM.YYYY')}
                                </div>
                                <div className={styles.companyRow}>
                                    {translate('lastAccountActivity')}:
                                    {user && user.lastLoginAt && moment(user.lastLoginAt).format('DD.MM.YYYY')}
                                </div>
                            </div>
                        )}
                        <div className={styles.companyRight}>
                            <div className={cn(styles.companyBlock, styles.companyBlockWithBorder)}>
                                <div className={styles.title}>{translate('devicesInCompany')}</div>
                                <div className={styles.count}>{companyStats.DStotal}</div>
                                <div className={styles.deviceIcon}>
                                    <Icon type="monitor" color={colors.grey} size={28} />
                                </div>
                            </div>
                            <div className={styles.companyBlock}>
                                <div className={styles.title}>{translate('trialLicensesCount')}</div>
                                <div className={styles.count}>{companyStats.activeTrialLicensesDevicesCount}</div>
                            </div>
                            <div className={styles.companyBlock}>
                                <div className={styles.title}>{translate('subscribeLicensesCount')}</div>
                                <div className={styles.count}>
                                    {companyStats.activeSubscriptionLicensesDevicesCount}
                                </div>
                            </div>
                            <div className={styles.companyBlock}>
                                <div className={styles.title}>{translate('purchaseLicensesCount')}</div>
                                <div className={styles.count}>{companyStats.activeRedeemedLicensesDevicesCount}</div>
                            </div>
                        </div>
                    </div>
                )}
                <div className={styles.licenceActionsButtons}>
                    <Button
                        mod="fill"
                        className={styles.licenceActionButton}
                        onClick={() => {
                            setAddLicense(true)
                        }}
                    >
                        {translate('requestLicenses')}
                    </Button>
                    <Button mod="fill" className={styles.licenceActionButton}>
                        <input className={styles.licenceInputFile} type={'file'} onChange={uploadLicense} />
                        {translate('uploadLicenses')}
                    </Button>
                </div>
                <div>
                    <div className={styles.licensesBlock}>
                        {licenses.length ? (
                            licenses.map((license: any) => {
                                return <License license={license} />
                            })
                        ) : showDemoLicense ? (
                            <LicenseDemo activate={activateGiftLicences} />
                        ) : null}
                    </div>
                </div>
            </div>
            {showGiftModal && (
                <div className={styles.modal}>
                    <div className={styles.modalInner}>
                        <div className={styles.modalClose} onClick={closeGiftModal}>
                            <Icon type={'close'} size={20} />
                        </div>
                        <div className={styles.giftCaption}>{translate('isGift')}</div>
                        <div className={styles.giftText}>{translate('youGetLicenses')}</div>
                    </div>
                </div>
            )}
            {addLicense && (
                <div className={styles.requestLicensesModal}>
                    <div className={styles.requestLicensesModalInner}>
                        <div className={styles.requestLicensesFieldWrapper}>
                            <EditText
                                label={translate('deviceQuantity')}
                                value={deviceQuantity}
                                validationBorder={!validationOfQuantity}
                                type="number"
                                onChange={setDeviceQuantity}
                            />
                            {!validationOfQuantity && (
                                <div className={styles.redError}>{translate('mustBeGreaterThanZero')}</div>
                            )}
                        </div>
                        <div className={styles.requestLicensesFieldWrapper}>
                            <Dropdown
                                options={licenseTypeOptions}
                                label={translate('licenseType')}
                                value={selectedLicense && selectedLicense.id}
                                onChange={setSelectedLicence}
                                mod={'scheduleButtonBorder'}
                            />
                        </div>
                        <div className={styles.requestLicensesFieldWrapper}>
                            <Dropdown
                                options={licenseTariffOptions}
                                label={translate('tariff')}
                                value={selectedTariff && selectedTariff.id}
                                onChange={setSelectedTariff}
                                mod={'scheduleButtonBorder'}
                            />
                        </div>
                        <div className={styles.requestLicensesFieldWrapper}>
                            <EditText
                                label={translate('startDate')}
                                dateValue={startDate}
                                type="date"
                                onChange={setStartDate}
                            />
                        </div>
                        <div className={cn(styles.requestLicensesFieldWrapper, styles.requestLicensesFields)}>
                            <Dropdown
                                options={endDateOptions}
                                label={translate('endDate')}
                                value={selectedPeriod && selectedPeriod.id}
                                onChange={(option) => {
                                    if (option.id) {
                                        setTimePeriod(option.id)
                                    }
                                    setSelectedPeriod(option)
                                }}
                                mod={'scheduleButtonBorder'}
                                containerClassName={styles.halfField}
                                disabled={!selectedLicense.id}
                            />
                            <EditText
                                dateValue={endDate}
                                type="date"
                                onChange={setEndDate}
                                containerClass={styles.halfFieldForDate}
                                disabled={selectedPeriod.id || !selectedLicense.id}
                            />
                        </div>
                        <div className={styles.requestLicensesFieldWrapper}>
                            <EditText label={translate('comment')} value={comment} type="area" onChange={setComment} />
                        </div>
                        <div className={styles.createFileButtons}>
                            <Button mod="withShadow" className={styles.createFileButton} onClick={onCloseAddLicense}>
                                {translate('cancel')}
                            </Button>
                            <Button
                                mod="withShadow"
                                className={styles.createFileButton}
                                onClick={createLicenseFile}
                                disabled={!validationOfQuantity}
                            >
                                {translate('createForm')}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            {uploadLicenseStatus && (
                <div className={styles.uploadLicenseStatus}>
                    <div className={styles.uploadLicenseStatusInner}>
                        <div className={styles.uploadLicenseStatusClose} onClick={() => setUploadLicenseStatus('')}>
                            <Icon type={'close'} size={18} />
                        </div>
                        <div className={styles.uploadText}>{translate(uploadLicenseStatus)}</div>
                        {uploadLicenseStatus === 'uploadLicenseProcessing' && <LoaderItem inv={true} />}
                        {uploadLicenseStatus === 'uploadLicenseComplete' && (
                            <Icon type={'complete'} size={106} color={colors.green} />
                        )}
                        {uploadLicenseStatus === 'uploadLicenseError' && (
                            <Icon type={'checkError'} size={106} color={colors.black} />
                        )}
                    </div>
                </div>
            )}
        </Content>
    )
}
