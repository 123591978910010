import { useEffect, useState } from 'react'
import helpers from 'core/helpers'

export type Device = {
    id: number
    name: string
}

export type SelectedBlock = {
    duration: number
    devices: Device[]
    content: ContentItem[]
}

export type ContentItem = {
    id: number
    duration: number
    durationPx: number
    order: number
    source: {
        name: string
        id: number
        __view: object
    }
}

export interface IAdvBlockState {
    over: {
        [index: string]: boolean
    }
    deleteData: { blockIndex: number; deviceIndex?: number } | null
    selectedBlockIndex: number | null
    selectedDeviceIndex: number | null
    selectedBlock: SelectedBlock | null
    selectedDevice: Device | null
    isGroupSelected: boolean
    scaleHeight: number
    duration: number
}

export type Block = {
    content: ContentItem[]
    devices: Device[]
    duration: number
    dynamic: boolean
}

export interface IAdvBlockProps {
    blocks: Block[]
    devices?: Device[]
    date: string
    onChange: (selected: any) => void
    onChangeDate: (newDate: string, cb: any) => void
    onUpdateBlocks: (blocks: Block[], digitalSignageId: number[]) => void
    selectedBlockData: {
        selectedBlockIndex: number | null
        selectedDeviceIndex: number | null
        selectedBlock: SelectedBlock | null
        selectedDevice: Device | null
        isGroupSelected: boolean
        scaleHeight: number
        duration: number
    } | null
}

const AdvertisingCampaignsAdvBlockMethods = (p_: IAdvBlockProps) => {
    const minItemHeight = 200
    const [state, setState] = useState<IAdvBlockState>({
        over: {},
        deleteData: null,
        selectedBlockIndex: null,
        selectedDeviceIndex: null,
        selectedBlock: null,
        selectedDevice: null,
        isGroupSelected: false,
        scaleHeight: 0,
        duration: 0,
    })

    useEffect(() => {
        if (p_.selectedBlockData) {
            setState((prevState: IAdvBlockState) => {
                return {
                    ...prevState,
                    ...p_.selectedBlockData,
                }
            })
        }

        return () => p_.onChange(null)
    }, [])

    const selectBlock = (selectedBlockIndex: number, selectedDeviceIndex: number | null) => {
        let minDuration = 1000
        const selectedBlock = helpers.deepCopy(p_.blocks[selectedBlockIndex])
        let duration = selectedBlock.duration
        const isGroupSelected = selectedBlock && selectedBlock.devices.length > 1 && selectedDeviceIndex === null
        const selectedDevice =
            selectedBlock && !isGroupSelected
                ? selectedBlock.devices[selectedDeviceIndex ? selectedDeviceIndex : 0]
                : null
        if (selectedBlock.content[0]) {
            minDuration = selectedBlock.content[0].duration
        }

        selectedBlock.content.forEach((contentItem: ContentItem) => {
            if (minDuration > contentItem.duration) {
                minDuration = contentItem.duration
            }
        })

        const scaleHeight = (duration / minDuration) * minItemHeight
        selectedBlock.content = selectedBlock.content.map((contentItem: ContentItem) => {
            return constructContentItem(contentItem, duration, scaleHeight)
        })

        const selectedBlockData = {
            selectedBlock,
            isGroupSelected,
            selectedDevice,
            selectedBlockIndex,
            selectedDeviceIndex,
            scaleHeight,
            duration,
        }

        setState((prevState: IAdvBlockState) => {
            return { ...prevState, ...selectedBlockData }
        })

        p_.onChange(selectedBlockData)
    }
    const constructContentItem = (contentItem: ContentItem, duration: number, scaleHeight: number) => {
        contentItem.durationPx = msToPixels(contentItem.duration, duration, scaleHeight)
        contentItem.source.__view = {}

        return contentItem
    }
    const msToPixels = (ms: number, duration: number, scaleHeight: number) => {
        return (ms / duration) * scaleHeight
    }
    const onChangeDate = (date: string) => {
        p_.onChangeDate(date, () => {
            const s_ = state

            if (typeof s_.selectedBlockIndex === 'number') {
                selectBlock(s_.selectedBlockIndex, s_.selectedDeviceIndex)
            }
        })
    }
    const onRemoveBlock = () => {
        if (!state.deleteData) return

        const { blockIndex, deviceIndex } = state.deleteData
        const blocks = [...p_.blocks]
        const digitalSignageId = []

        if (typeof deviceIndex !== 'undefined') {
            digitalSignageId.push(blocks[blockIndex].devices[deviceIndex].id)
            blocks[blockIndex].devices.splice(deviceIndex, 1)
        } else {
            blocks[blockIndex].devices.forEach((device: { id: number }) => {
                digitalSignageId.push(device.id)
            })
            blocks.splice(blockIndex, 1)
        }

        p_.onChange(null)
        setState((prevState: IAdvBlockState) => {
            return {
                ...prevState,
                selectedBlock: null,
                deleteData: null,
            }
        })
        p_.onUpdateBlocks(blocks, digitalSignageId)
    }

    return {
        s_: state,
        setState,
        selectBlock,
        onChangeDate,
        onRemoveBlock,
    }
}

export default AdvertisingCampaignsAdvBlockMethods
