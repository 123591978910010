import React, { Fragment, FunctionComponent, memo } from 'react'
import Collapse from 'blocks.simple/collapse/collapse'
import translate from 'core/translate'
import { cn } from 'ethcss'
import { styles } from './DisplayOfWarningsSettings-styles'
import { SwitchButton } from 'atoms/SwitchButton'
import { RootStateOrAny, useDispatch } from 'react-redux'
import { updateUserSettings } from 'blocks.app/user/user.state'
import styles_ from '../settings.jcss'
import { useSelector } from 'react-redux'

const DisplayOfWarningsComponent: FunctionComponent = () => {
    const dispatch = useDispatch()
    const title: string = translate('displayOfWarnings')
    const displayedTooltips = useSelector((store: RootStateOrAny) => store.user.data.settings.displayedTooltips)

    const deletePromptCheck = (): boolean => displayedTooltips && displayedTooltips.deleteFilePrompt === true
    const savePromptCheck = (): boolean => displayedTooltips && displayedTooltips.saveBroadcastPrompt === true

    const isCheckDeleteWarning = (): boolean => {
        if (deletePromptCheck()) {
            return false
        } else {
            return true
        }
    }

    const isCheckSaveWarning = (): boolean => {
        if (savePromptCheck()) {
            return false
        } else {
            return true
        }
    }

    const onChangeDeleteWarning = (): void => {
        deletePromptCheck()
            ? dispatch(
                  updateUserSettings({ data: { displayedTooltips: { deleteFilePrompt: false } }, isSaveSettings: true })
              )
            : dispatch(
                  updateUserSettings({ data: { displayedTooltips: { deleteFilePrompt: true } }, isSaveSettings: true })
              )
    }

    const onChangeSaveWarning = (): void => {
        savePromptCheck()
            ? dispatch(
                  updateUserSettings({
                      data: { displayedTooltips: { saveBroadcastPrompt: false } },
                      isSaveSettings: true,
                  })
              )
            : dispatch(
                  updateUserSettings({
                      data: { displayedTooltips: { saveBroadcastPrompt: true } },
                      isSaveSettings: true,
                  })
              )
    }

    return (
        <Fragment>
            <Collapse title={() => title} className={cn(styles_.background, styles.DisplayOfWarningsSettings)}>
                <div className={styles.DisplayOfWarningsSettings__item}>
                    <div className={styles.DisplayOfWarningsSettings__text}>
                        {translate('showContentDeleteWarning')}
                    </div>
                    <div className={styles.DisplayOfWarningsSettings__button}>
                        <SwitchButton onChange={onChangeDeleteWarning} checked={isCheckDeleteWarning()} />
                    </div>
                </div>

                <div className={styles.DisplayOfWarningsSettings__item}>
                    <div className={styles.DisplayOfWarningsSettings__text}>
                        {translate('showBroadcastsSaveWarning')}
                    </div>
                    <div className={styles.DisplayOfWarningsSettings__button}>
                        <SwitchButton onChange={onChangeSaveWarning} checked={isCheckSaveWarning()} />
                    </div>
                </div>
            </Collapse>
        </Fragment>
    )
}

export const DisplayOfWarnings = memo(DisplayOfWarningsComponent)
