import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AppError } from './AppNotifications-types'

export interface customError {
    error: string
    message: string | number
}

export interface IAppNotificationsState {
    error: AppError | string | number | null | customError
    errorTimeout: null | NodeJS.Timeout
}

const initialState: IAppNotificationsState = {
    error: null,
    errorTimeout: null,
}

const TIMEOUT_MS = 5000

const resetTimer = (timer: NodeJS.Timer | null) => {
    if (!timer) return null

    clearTimeout(timer)
}

export const emitError = createAsyncThunk(
    'appNotifications/emitError',
    async (errorCode: string | number | customError, { getState, dispatch }) => {
        const state = getState() as { appNotifications: IAppNotificationsState }

        resetTimer(state.appNotifications.errorTimeout)

        return {
            error: errorCode,
            errorTimeout: setTimeout(() => {
                dispatch(appNotificationsActions.clearError())
            }, TIMEOUT_MS),
        }
    }
)

export const emitAppError = createAsyncThunk(
    'appNotifications/emitAppError',
    async ({ errorCode, message }: AppError, { getState, dispatch, rejectWithValue }) => {
        const state = getState() as { appNotifications: IAppNotificationsState }

        resetTimer(state.appNotifications.errorTimeout)

        return {
            error: {
                errorCode,
                message,
            },
            errorTimeout: setTimeout(() => {
                dispatch(appNotificationsActions.clearError())
            }, TIMEOUT_MS),
        }
    }
)

const appNotificationsSlice = createSlice({
    name: 'appNotifications',
    initialState,
    reducers: {
        clearError(state) {
            state.error = null
        },
    },
    extraReducers: (builder) =>
        builder
            .addCase(emitError.fulfilled, (state, { payload }) => {
                const { error, errorTimeout } = payload

                state.error = error
                state.errorTimeout = errorTimeout
            })
            .addCase(emitError.rejected, (state, { payload }) => {
                state.error = null
            })
            .addCase(emitAppError.fulfilled, (state, { payload }) => {
                const { error, errorTimeout } = payload

                state.error = error
                state.errorTimeout = errorTimeout
            })
            .addCase(emitAppError.rejected, (state, { payload }) => {
                state.error = null
            }),
})

const { reducer: appNotificationsReducer, actions: appNotificationsActions } = appNotificationsSlice
export { appNotificationsReducer, appNotificationsActions }
