import React, { useEffect } from 'react'
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc'
import { DisplaysSynchronizationHooks } from './displays__synchronization.hooks'
import { SwitchButton } from 'atoms/SwitchButton'
import styles from './displays__synchronization.jcss'
import Icon from '../../../blocks.simple/icon/icon'
import translate from '../../../core/translate'
import { cn } from 'ethcss'

const getColorByStatus = (status: string) => {
    switch (status) {
        case 'online':
            return '#33C691'
        case 'offline':
            return '#828282'
        case 'error':
            return '#D61750'
        case 'update':
            return '#F2C94C'
    }
}

const getSyncIcon = (status: boolean | null) => {
    if (status === true) {
        return (
            <div className={styles.syncIcon}>
                <Icon type={'synchronized'} color={'white'} size={20} tooltip={{ title: translate('synchronized') }} />
            </div>
        )
    }
    if (status === false) {
        return (
            <div className={styles.syncIcon}>
                <Icon
                    type={'unsynchronized'}
                    color={'white'}
                    size={20}
                    tooltip={{ title: translate('unsynchronized') }}
                />
            </div>
        )
    }
    return null
}

const isSynchronized = (syncRole: any) => {
    return syncRole === 'master' || syncRole === 'slave' || syncRole === 'unknown'
}

const SortableItem = SortableElement(({ cell, devices, order, collapse, screenshots, getSyncRole }: any) => {
    const device = devices.find((device: any, index: number) => device.id === cell.id)
    const screenshot = device ? screenshots.find((screenshot: any) => screenshot.digitalSignageId === device.id) : null
    const syncRole = getSyncRole(cell.id)

    return (
        <div
            style={{
                width: '288px',
                height: '162px',
                marginRight: '20px',
                marginBottom: '20px',
                pointerEvents: collapse ? 'none' : 'auto',
            }}
            className={`${styles.cell} ${collapse ? styles.collapse : ''} device-item`}
        >
            {device ? (
                <div
                    style={{
                        background: screenshot ? `url(${screenshot.src})` : getColorByStatus(device.status),
                        backgroundSize: 'cover',
                    }}
                    className={styles.display}
                >
                    <div className={cn(styles.displayInfo, collapse ? styles.hideDeviceInfo : {})}>
                        {syncRole && (
                            <div className={styles.syncStatus}>
                                <img src={`images/displays/synchronization/${syncRole}.svg`} />
                            </div>
                        )}
                        <div className={styles.name}>{device.name}</div>
                        <div className={styles.option}>{device.platform}</div>
                        <div className={styles.option}>{device.ip}</div>
                        <div className={styles.option}>{device.connectionType}</div>
                        {getSyncIcon(isSynchronized(syncRole))}
                    </div>
                </div>
            ) : null}
        </div>
    )
})

const SortableList = SortableContainer(({ data, collapse, screenshots, getSyncRole }: any) => {
    return (
        <div className={styles.syncListInner}>
            {data.grid.map((cell: any, index: number) => (
                <SortableItem
                    key={index}
                    index={index}
                    order={index}
                    devices={data.devices}
                    cell={cell}
                    collapse={collapse}
                    screenshots={screenshots}
                    getSyncRole={getSyncRole}
                />
            ))}
        </div>
    )
})

const SortableComponent = ({ data, onSortEnd, collapse, screenshots, getSyncRole }: any) => {
    return (
        <SortableList
            data={data}
            onSortEnd={onSortEnd}
            collapse={collapse}
            screenshots={screenshots}
            getSyncRole={getSyncRole}
            axis="xy"
        />
    )
}

export const DisplaysSynchronization = ({ selectedInfo }: any) => {
    const {
        collapse,
        setCollapse,
        grid,
        setGrid,
        devices,
        setDevices,
        getRowOptions,
        onOptionsChange,
        collapseMargins,
        autoScreenshot,
        onSwitchChange,
        autoScreenshotInterval,
        onInputChange,
        tableView,
        onChangeView,
        sortArrayOfObjectsByKey,
        screenshots,
        takeScreenshots,
        changeOrderDevices,
        statuses,
        getSyncRole,
        getDevices,
    } = DisplaysSynchronizationHooks({ selectedInfo })

    const getStatusCount = (status: any) => {
        return statuses[status]
    }

    useEffect(() => {
        collapseMargins(collapse)
    }, [collapse])

    const orderList = ({ oldIndex, newIndex }: any) => {
        const movedGrid = arrayMove(grid, oldIndex, newIndex)
        const newDevicesList = devices.map((device: any) => {
            let syncListOrder = 0

            movedGrid.forEach((cell: any, index: number) => {
                if (cell.id === device.id) {
                    syncListOrder = index
                }
            })
            return {
                ...device,
                syncListOrder,
            }
        })
        const deviceOrdersToSave = newDevicesList.map((device: any) => {
            return {
                digitalSignageId: device.id,
                syncListOrder: device.syncListOrder,
            }
        })
        setDevices(newDevicesList)
        setGrid(arrayMove(grid, oldIndex, newIndex))

        if (deviceOrdersToSave.length) {
            changeOrderDevices(deviceOrdersToSave)
        }
    }

    const renderTable = () => {
        return (
            <table className={styles.table}>
                <tbody>
                    <tr>
                        <td className={styles.col}></td>
                        <td className={styles.col}>{translate('name')}</td>
                        <td className={styles.col}>{translate('role')}</td>
                        <td className={styles.col}>{translate('synchronization')}</td>
                        <td className={styles.col}>{translate('platform')}</td>
                        <td className={styles.col}>{translate('ipAddress')}</td>
                        <td className={styles.col}>{translate('connection')}</td>
                        <td className={styles.col}>{translate('status')}</td>
                    </tr>
                    {sortArrayOfObjectsByKey(devices, 'syncListOrder').map((device: any) => {
                        return (
                            <tr className={device.syncStatus === 'master' ? styles.rowMaster : styles.row}>
                                <td className={styles.col}>
                                    <div
                                        className={styles.statusCircle}
                                        style={{ backgroundColor: getColorByStatus(device.status) }}
                                    />
                                </td>
                                <td className={styles.col}>{device.name}</td>
                                <td className={styles.col}>{getSyncRole(device.id)}</td>
                                <td className={styles.col}>
                                    {translate(
                                        isSynchronized(getSyncRole(device.id)) ? 'synchronized' : 'unsynchronized'
                                    )}
                                </td>
                                <td className={styles.col}>{device.platform}</td>
                                <td className={styles.col}>{device.ip}</td>
                                <td className={styles.col}>{device.connectionType}</td>
                                <td className={styles.col}>{translate(device.status)}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    }

    return (
        <div>
            <div className={styles.toggleView} onClick={onChangeView}>
                {tableView ? (
                    <Icon
                        type={'syncViewToggleTile'}
                        color={'black'}
                        size={23}
                        tooltip={{ title: translate('tileView') }}
                    />
                ) : (
                    <Icon
                        type={'syncViewToggleList'}
                        color={'black'}
                        size={22}
                        tooltip={{ title: translate('listView') }}
                    />
                )}
            </div>
            <div className={styles.header}>
                <div className={styles.caption}>
                    {translate('synchronizationScheme')} <b>{selectedInfo.name}</b>
                </div>
                <div className={styles.statuses}>
                    <div className={styles.status} style={{ color: getColorByStatus('online') }}>
                        {translate('online')}: {getStatusCount('online')}
                    </div>
                    <div className={styles.status} style={{ color: getColorByStatus('offline') }}>
                        {translate('offline')}: {getStatusCount('offline')}
                    </div>
                    <div className={styles.status} style={{ color: getColorByStatus('error') }}>
                        {translate('error')}: {getStatusCount('error')}
                    </div>
                    <div className={styles.status} style={{ color: getColorByStatus('update') }}>
                        {translate('updating')}: {getStatusCount('updating')}
                    </div>
                    {/*<Icon type={'update'} color={'grey'} size={14} tooltip={{title: translate('update')}}/>*/}
                </div>
            </div>
            {tableView ? (
                <div className={styles.tableView}>{renderTable()}</div>
            ) : (
                <div className={styles.tileView}>
                    <div className={styles.actionsPanel}>
                        <div className={styles.actionButton} onClick={() => setCollapse(!collapse)}>
                            {translate(collapse ? 'showDescription' : 'hideDescription')}
                        </div>
                        <div className={styles.actionButton} onClick={getDevices}>
                            {translate('update')}
                        </div>
                        <div className={styles.actionButton} onClick={takeScreenshots}>
                            {translate('makeScreenshot')}
                        </div>
                        <div className={styles.autoScreenshot}>
                            <div className={styles.autoScreenshotLabel}>{translate('autoScreenShot')}</div>
                            <SwitchButton onChange={onSwitchChange} checked={autoScreenshot} />
                        </div>
                        <div className={styles.autoScreenshotInterval}>
                            <div className={styles.autoScreenshotIntervalLabel}>{translate('intervalSeconds')}</div>
                            <input
                                className={styles.autoScreenshotIntervalInput}
                                value={autoScreenshotInterval}
                                onChange={onInputChange}
                                min="10"
                                type="number"
                            />
                        </div>
                        <div className={styles.selectRowsCount}>
                            <div className={styles.selectRowsCountLabel}>{translate('rowsCount')}</div>
                            <select className={styles.selectRowsCountSelect} onChange={onOptionsChange}>
                                {getRowOptions()}
                            </select>
                        </div>
                    </div>
                    <div className={styles.syncList}>
                        <SortableComponent
                            data={{ grid, devices }}
                            onSortEnd={orderList}
                            collapse={collapse}
                            screenshots={screenshots}
                            getSyncRole={getSyncRole}
                        />
                    </div>
                    <div className={styles.howToUse}>{translate('howToUseSync')}</div>
                </div>
            )}
        </div>
    )
}
