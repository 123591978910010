import React from 'react'
import deepEqual from 'fast-deep-equal'

const directions = {
    up: 'bottom',
    left: 'right',
    down: 'top',
    right: 'left',
}

const getMarqueeTranslate = (position) => `translate(${position.x}px, ${position.y}px)`
const getPosition = (p_) => ({
    width: p_.width,
    height: p_.height,
    left: p_.left,
    top: p_.top,
})

class EditorItemLocalMethods extends React.Component {
    state = {
        name: this.props.name,
        active: [],
        played: 0,
        volume: this.props.volume,
        ready: false,
        error: false,
        position: getPosition(this.props),
        isShowVideoBar: false,
    }
    textFileTypes = ['text', 'xml', 'csv', 'rss']
    feedInterval = null
    marquee = {}

    componentDidUpdate(prevProps) {
        const p_ = this.props

        this.setItemPosition(prevProps)
        this.startFeedAnimation(prevProps)

        if (p_.name !== prevProps.name) {
            this.setState({ name: p_.name })
        }
        if (p_.volume !== prevProps.volume) {
            this.setState({ volume: p_.volume })
        }
    }

    componentWillUnmount() {
        this.clearFeedInterval()
    }

    startFeedAnimation = () => {
        const p_ = this.props

        if (this.canStartFeedAnimation(p_)) {
            this.clearFeedInterval()
            this.startFeedInterval()
        }
    }

    setItemPosition = (prevProps) => {
        const nextProps = this.props
        const prevPosition = getPosition(prevProps)
        const nextPosition = getPosition(nextProps)

        if (!deepEqual(prevPosition, nextPosition)) {
            this.setState({ position: nextPosition })
        }
    }

    onResize = (position, direction) => {
        const p_ = this.props

        if (p_.fileType === 'web_app') {
            this.setState({ position })
        }

        p_.onResize(position, direction)
    }

    canStartFeedAnimation = (p_) => {
        if (!this.textFileTypes.includes(p_.fileType)) {
            return false
        }

        if (!(p_.data && p_.data.settings && p_.data.settings.marquee)) {
            return false
        }

        const marquee = p_.data.settings.marquee

        const feedData = {
            areaWidth: this.wrapperRef.offsetWidth,
            areaHeight: this.wrapperRef.offsetHeight,
            speed: marquee.speed,
            direction: marquee.direction,
        }

        if (!deepEqual(this.marquee, feedData)) {
            this.marquee = feedData
            return true
        }

        return false
    }
    startFeedInterval = () => {
        if (this.marquee.direction === 'disabled') {
            return
        }

        this.textContentRef.style.width = 'auto'
        this.textContentRef.style.whiteSpace = 'nowrap'

        const marqueeWidth = this.textContentRef.offsetWidth
        const marqueeHeight = this.textContentRef.offsetHeight

        const options = {
            iterationCount: 1000,
            width: this.marquee.areaWidth,
            height: this.marquee.areaHeight,
            marqueeHeight,
            marqueeWidth,
        }

        const speed = this.marquee.speed * 0.5
        options.deltaX = (options.width * speed) / options.iterationCount
        options.deltaY = (options.height * speed) / options.iterationCount

        this.moveMarquee(options)
    }
    moveMarquee = (options) => {
        const position = {
            x: options.marqueeWidth,
            y: 0,
        }

        if (this.marquee.direction === 'right') {
            position.x = -options.marqueeWidth
        }

        if (this.marquee.direction === 'up') {
            position.x = 0
            position.y = options.marqueeHeight
        }

        if (this.marquee.direction === 'down') {
            position.x = 0
            position.y = -options.marqueeHeight
        }

        this.initMarqueeStyles(position)

        this.feedInterval = setInterval(() => {
            if (this.marquee.direction === 'left') {
                if (position.x < options.width * -1) {
                    position.x = options.marqueeWidth
                }
                position.x -= options.deltaX
            }
            if (this.marquee.direction === 'up') {
                if (position.y < options.height * -1) {
                    position.y = options.marqueeHeight
                }
                position.y -= options.deltaY
            }

            if (this.marquee.direction === 'right') {
                if (position.x > options.width) {
                    position.x = -options.marqueeWidth
                }
                position.x += options.deltaX
            }
            if (this.marquee.direction === 'down') {
                if (position.y > options.height) {
                    position.y = -options.marqueeHeight
                }
                position.y += options.deltaY
            }
            if (this.textContentRef) {
                this.textContentRef.style.transform = getMarqueeTranslate(position)
            }
        }, 10)
    }
    initMarqueeStyles = (position) => {
        const direction = directions[this.marquee.direction]
        const styles = {
            position: 'absolute',
            top: 'initial',
            left: 'initial',
            right: 'initial',
            bottom: 'initial',
            [direction]: 0,
            transform: getMarqueeTranslate(position),
        }

        Object.assign(this.textContentRef.style, styles)
    }
    clearFeedInterval = () => {
        if (this.textContentRef) {
            Object.assign(this.textContentRef.style, this.getTextContentStyles())
        }

        clearInterval(this.feedInterval)
    }
    textOrientationCheck = () => {
        const p_ = this.props
        return (
            p_.data &&
            p_.data.settings &&
            p_.data.settings.textOrientation &&
            p_.data.settings.textOrientation === 'vertical'
        )
    }
    getTextContentStyles = () => {
        const p_ = this.props

        return {
            fontFamily: 'inherit',
            // fontSize: `${100 * p_.scale}%`,
            fontSize: p_.data && p_.data.style ? p_.data.style.size + 'px' : `${100 * p_.scale}%`,
            position: 'relative',
            transform: 'none',
            whiteSpace: 'normal',
            textDecoration:
                p_.data && p_.data.settings.marquee.direction !== 'disabled' && p_.data.style.underline
                    ? 'underline'
                    : 'none',
            writingMode: this.textOrientationCheck() && 'vertical-lr',
            textOrientation: this.textOrientationCheck() && 'upright',
        }
    }

    // video/audio players
    onReactPlayerEnded = () => {
        this.setState({ active: [], played: 0 })
    }
    onReactPlayerProgress = (e) => {
        if (e.played < 1) {
            this.setState({ played: e.played })
        }
    }
    onReactPlayerReady = () => {
        this.setState({ ready: true, error: false })
    }
    onReactPlayerError = () => {
        this.setState({ error: true, ready: false })
    }
    showVideoBar = () => {
        this.setState({ isShowVideoBar: true })
    }
    hideVideoBar = () => {
        this.setState({ isShowVideoBar: false })
    }
}

export default EditorItemLocalMethods
