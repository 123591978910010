import React from 'react'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import CustomDropdown from 'atoms/Dropdown'
import EditableDropdown from 'blocks.simple/editableDropdown/editableDropdown'
import Icon from 'blocks.simple/icon/icon'
import Colorpicker from 'blocks.simple/colorpicker/colorpicker'
import translate from 'core/translate'
import template from 'es6-template-strings'
import { cn } from 'ethcss'
import styles from './textEditor__toolbar.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'

const textAlignOptions = [
    {
        id: 'left',
        iconSize: 18,
        icon: 'leftText',
        iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
        iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
    },
    {
        id: 'right',
        iconSize: 18,
        icon: 'rightText',
        iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
        iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
    },
    {
        id: 'center',
        iconSize: 18,
        icon: 'centerText',
        iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
        iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
    },
    {
        id: 'justify',
        iconSize: 18,
        icon: 'justifyText',
        iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
        iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
    },
]

const verticalAlignOptions = [
    {
        id: 'top',
        iconSize: 18,
        icon: 'topAlign',
        iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
        iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
    },
    {
        id: 'middle',
        iconSize: 18,
        icon: 'middleAlign',
        iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
        iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
    },
    {
        id: 'bottom',
        iconSize: 18,
        icon: 'bottomAlign',
        iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
        iconActiveColor: getThemeStyleValue('ui', 'icon', 'active') || defaultThemeStyles.active,
    },
]

const fontSizeOptions = () => {
    const sizes = [
        '8px',
        '9px',
        '10px',
        '11px',
        '12px',
        '14px',
        '16px',
        '18px',
        '20px',
        '22px',
        '24px',
        '26px',
        '28px',
        '36px',
        '48px',
        '72px',
    ]
    const options: { id: string; name: string }[] = []

    sizes.forEach((size) => {
        options.push({ id: size.slice(0, -2), name: size })
    })

    return options
}

const MAX_FONT_SIZE = 1500

const TextEditorToolbar = (p_: {
    onlyText: boolean
    small: boolean
    type: string
    onChange: any
    refreshInterval: number
    fontList: { id: number; name: string; companyId: number | null }[]
    style: {
        size: string
        fontId: string
        color: string
        align: string
        verticalAlign: string
        bold: string
        italic: string
        underline: string
        backgroundColor: string
    }
    onChangeStyles: any
    entryType: string
}) => {
    const getColorByStatus = (status: string) => {
        if (status) {
            return getThemeStyleValue('app', 'app', 'active') || defaultThemeAppStyles.active
        }
        return getThemeStyleValue('app', 'app', 'disable') || defaultThemeAppStyles.disable
    }

    const timerOptions = [
        { name: template(translate('timer'), { time: 5 }), id: 5 },
        { name: template(translate('timer'), { time: 15 }), id: 15 },
        { name: template(translate('timer'), { time: 30 }), id: 30 },
    ]

    const sourceOptions = [
        { name: translate('textSource'), id: 'text' },
        { name: translate('urlSource'), id: 'rss' },
        { name: translate('xmlSource'), id: 'xml' },
        { name: translate('csvSource'), id: 'csv' },
    ]

    return (
        <div className={styles.textEditorToolbarWrapper}>
            {!p_.onlyText && (
                <div className={cn(styles.toolbarItem, { [grid.w25]: p_.small })}>
                    <CustomDropdown
                        mod={'none'}
                        options={sourceOptions}
                        value={p_.type}
                        icon={'arrow_down'}
                        activeIcon={'arrow_up'}
                        iconSide={'right'}
                        valueKey={'id'}
                        labelKey={'name'}
                        onChange={function (selected) {
                            const data = [{ key: 'type', value: selected.id }]

                            if (selected.id === 'xml') {
                                data.push({ key: 'content', value: '' })
                            }

                            p_.onChange(data)
                        }}
                    />
                </div>
            )}
            {!p_.onlyText && (
                <div className={cn(styles.toolbarItem, { [grid.w25]: p_.small })}>
                    <CustomDropdown
                        mod={'none'}
                        options={timerOptions}
                        value={p_.refreshInterval / 1000}
                        disabled={p_.type !== 'rss'}
                        icon={'arrow_down'}
                        activeIcon={'arrow_up'}
                        iconSide={'right'}
                        valueKey={'id'}
                        labelKey={'name'}
                        onChange={function (selected) {
                            const interval = selected.id * 1000
                            p_.onChange({ key: 'refreshInterval', value: interval })
                        }}
                    />
                </div>
            )}
            <div className={cn(styles.toolbarItem, { [grid.w25]: p_.small })}>
                <CustomDropdown
                    mod={'none'}
                    options={p_.fontList}
                    disabled={!p_.fontList.length}
                    value={p_.style.fontId}
                    icon={'arrow_down'}
                    activeIcon={'arrow_up'}
                    iconSide={'right'}
                    valueKey={'id'}
                    labelKey={'name'}
                    onChange={function (font) {
                        p_.onChangeStyles([
                            { key: 'fontId', value: font.id },
                            { key: 'fontFamily', value: font.name },
                        ])
                    }}
                />
            </div>
            <div
                className={cn(styles.toolbarItem, { [grid.w25]: p_.small })}
                style={p_.small ? { borderRight: 'none' } : {}}
            >
                <EditableDropdown
                    placeholder={p_.style.size}
                    showIconOnHover={false}
                    options={fontSizeOptions()}
                    config={{
                        pattern: /[0-9]/,
                    }}
                    onBlur={function (e: any) {
                        let value = Number(e.value)

                        if (value) {
                            if (value > MAX_FONT_SIZE) {
                                value = MAX_FONT_SIZE
                            }

                            p_.onChangeStyles({ key: 'size', value: `${value}px` })
                        }
                    }}
                    onChange={function (e: any) {
                        p_.onChangeStyles({ key: 'size', value: e.id })
                    }}
                    activeMod="none"
                    indentSize="mini"
                    searchable
                    onlyPlaceholder
                />
            </div>
            {p_.small && <div className={styles.sep} />}
            <Colorpicker
                className={styles.iconWrapper}
                onChange={function (hex) {
                    p_.onChangeStyles({ key: 'color', value: hex })
                }}
                color={p_.style.color}
                title={translate('fontColor')}
                side={p_.small ? 'left' : false}
                //disableAlpha
            >
                <div className={cn(grid.rowCenter, grid.space, grid.noWrap)}>
                    <Icon
                        type={'letters'}
                        className={cn(grid.ml_mini, grid.mr_nano)}
                        color={getColorByStatus(p_.style.color)}
                        size={20}
                    />
                    <div className={styles.textColor} style={{ backgroundColor: p_.style.color }} />
                </div>
            </Colorpicker>
            <div className={cn(styles.toolbarItem, grid.rowCenter)}>
                <Dropdown
                    options={textAlignOptions}
                    value={p_.style.align}
                    rounded={'none'}
                    customHeader={
                        <div className={item.indent_normal}>
                            <Icon type={`${p_.style.align}Text`} size={20} />
                        </div>
                    }
                    mod={'none'}
                    onChange={function (selected) {
                        p_.onChangeStyles({ key: 'align', value: selected.id })
                    }}
                    wrapperWidth
                    centered
                />
            </div>
            {p_.entryType === 'cell' && (
                <div className={cn(styles.toolbarItem, grid.rowCenter)}>
                    <Dropdown
                        options={verticalAlignOptions}
                        value={p_.style.verticalAlign}
                        rounded={'none'}
                        customHeader={
                            <div className={item.indent_normal}>
                                <Icon type={`${p_.style.verticalAlign}Align`} size={20} />
                            </div>
                        }
                        mod={'none'}
                        onChange={function (selected) {
                            p_.onChangeStyles({ key: 'verticalAlign', value: selected.id })
                        }}
                        wrapperWidth
                        centered
                    />
                </div>
            )}
            <div className={styles.iconWrapper}>
                <Icon
                    type={'boldLetter'}
                    color={getColorByStatus(p_.style.bold)}
                    className={cn(styles.icons)}
                    onClick={function () {
                        p_.onChangeStyles({ key: 'bold', value: !p_.style.bold })
                    }}
                    size={20}
                />
            </div>
            <div className={styles.iconWrapper}>
                <Icon
                    type={'italicLetter'}
                    color={getColorByStatus(p_.style.italic)}
                    className={cn(styles.icons)}
                    onClick={function () {
                        p_.onChangeStyles({ key: 'italic', value: !p_.style.italic })
                    }}
                    size={20}
                />
            </div>
            <div className={styles.iconWrapper}>
                <Icon
                    type={'underlineLetter'}
                    className={cn(styles.icons)}
                    color={getColorByStatus(p_.style.underline)}
                    onClick={function () {
                        p_.onChangeStyles({ key: 'underline', value: !p_.style.underline })
                    }}
                    size={20}
                />
            </div>
            {p_.entryType !== 'cell' && (
                <Colorpicker
                    className={cn(styles.iconWrapper)}
                    style={{ borderRight: 'none' }}
                    onChange={function (hex) {
                        p_.onChangeStyles({ key: 'backgroundColor', value: hex })
                    }}
                    side={p_.small ? 'right' : false}
                    color={p_.style.backgroundColor}
                    title={translate('generalBackground')}
                    //disableAlpha
                />
            )}
        </div>
    )
}

export default TextEditorToolbar
