import PropTypes from 'prop-types'
import React from 'react'
import EditorAutoPositionAudio from '../__autoPositionAudio/editor__autoPositionAudio'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import EditText from 'blocks.simple/editText/editText'
import Checkbox from 'blocks.simple/checkbox/checkbox'

import translate from 'core/translate'

import { cn } from 'ethcss'
import styles from './editor__areaSettings.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { OrderMode } from 'core/models/Editor'
import Collapse from 'blocks.simple/collapse/collapse'

const contentModeOptionsList = [OrderMode.userProvided, OrderMode.shuffle]

class EditorAreaSettings extends React.Component {
    constructor(p_) {
        super(p_)

        this.state = {
            options: this.getOptions(p_),
            position: this.getPosition(p_),
            isWidthNegative: false,
            isHeightNegative: false,
        }

        this.contentModeOptions = this.getContentModeOptions(contentModeOptionsList)
        this.getOptions = this.getOptions.bind(this)
        this.onChange = this.onChange.bind(this)
        this.getRelPosition = this.getRelPosition.bind(this)
        this.onEndTyping = this.onEndTyping.bind(this)

        p_.onSetContentOrderMode(p_.contentOrderMode)
    }
    // TODO: Убрать deprecated метод
    componentWillReceiveProps(p_) {
        const s_ = this.state
        if (p_.position.width < 0) {
            return this.setState({ ...s_, isWidthNegative: true })
        } else if (p_.position.height < 0) {
            return this.setState({ ...s_, isHeightNegative: true })
        } else {
            this.setState({
                options: this.getOptions(p_),
                position: this.getPosition(p_),
                isHeightNegative: false,
                isWidthNegative: false,
            })
        }
    }

    // Опции для выбора редактироуемой зоны
    getOptions(p_) {
        return [
            { id: 'allAreas', name: translate('allAreas') },
            ...p_.options.map((option, index) => ({ id: index, name: `${translate('area')} ${index + 1}` })),
        ]
    }

    getContentModeOptions = (options) => {
        return [...options.map((option) => ({ id: option, name: translate(option) }))]
    }

    // Обновление позиции по таймеру окончания набора текста
    onEndTyping() {
        const p_ = this.props
        const isUpdated = p_.onEndTyping()

        if (!isUpdated) {
            this.setState({
                position: this.getPosition(p_),
            })
        }
    }

    // Получить позицию в абсолютных координатах в пикселях
    getPosition(p_) {
        const position = {
            top: Math.round(p_.position.top * p_.resolutionHeight),
            height: Math.round(p_.position.height * p_.resolutionHeight),
            left: Math.round(p_.position.left * p_.resolutionWidth),
            width: Math.round(p_.position.width * p_.resolutionWidth),
        }

        return position
    }

    // Получить позицию в относительных координатах
    getRelPosition() {
        const p_ = this.props
        const s_ = this.state

        return {
            top: s_.position.top / p_.resolutionHeight,
            height: s_.position.height / p_.resolutionHeight,
            left: s_.position.left / p_.resolutionWidth,
            width: s_.position.width / p_.resolutionWidth,
        }
    }

    // Изменение позиции зоны
    onChange(value, field) {
        const s_ = this.state
        const position = s_.position
        position[field] = value

        this.setState({ position })
    }

    onChangeWidthOrHeight(value, field) {
        const s_ = this.state
        if (value < 0) {
            field === 'width'
                ? this.setState({ ...s_, isWidthNegative: true })
                : this.setState({ ...s_, isHeightNegative: true })
        } else {
            this.setState({ ...s_, isHeightNegative: false, isWidthNegative: false })
            this.onChange(value, field)
        }
    }

    changeOrderMode = (e) => {
        const p_ = this.props
        p_.onSetContentOrderMode(e.id)
    }

    getAreaTitle = () => {
        return (
            <div className={styles.collapseText}>
                <div className={item.ellipsis}>{translate('areaSettings')}</div>
            </div>
        )
    }

    render() {
        const s_ = this.state
        const p_ = this.props
        const isShowAutoPositionAudio = !p_.areaContent.some((content) => content.source.fileType !== 'audio')

        return (
            <div>
                <Collapse
                    title={() => this.getAreaTitle()}
                    titleClassName={styles.collapseTitle}
                    activeClassName={styles.collapseActive}
                    className={styles.collapse}
                    withoutOverflow={true}
                    initialOpen={true}
                >
                    <div>
                        {!p_.isSimple && (
                            <div className={grid.colCenter}>
                                <div className={styles.label}>{translate('area')}</div>
                                <div className={grid.w45}>
                                    <Dropdown
                                        value={p_.showAllAreas ? 'allAreas' : p_.area}
                                        options={s_.options}
                                        centered={true}
                                        onChange={p_.onSelect}
                                        isRelativeList={true}
                                    />
                                </div>
                            </div>
                        )}
                        {!p_.showAllAreas && (
                            <div>
                                {!p_.isSimple && (
                                    <div>
                                        <div className={styles.row}>
                                            <EditText
                                                type={'number'}
                                                containerClass={cn(styles.inputWrapper)}
                                                value={s_.position.left}
                                                label={translate('leftIndent')}
                                                onChange={(value) => this.onChange(value, 'left')}
                                                indentSize={'mini'}
                                                onEndTyping={this.onEndTyping}
                                            />
                                            <EditText
                                                type={'number'}
                                                containerClass={cn(styles.inputWrapper)}
                                                value={s_.position.top}
                                                label={translate('topIndent')}
                                                onChange={(value) => this.onChange(value, 'top')}
                                                indentSize={'mini'}
                                                onEndTyping={this.onEndTyping}
                                            />
                                        </div>
                                        <div className={styles.heightAndWidthRow}>
                                            <span className={cn(grid.col, grid.w45)}>
                                                <EditText
                                                    type={'number'}
                                                    value={s_.position.width}
                                                    label={translate('width')}
                                                    onChange={(value) => this.onChangeWidthOrHeight(value, 'width')}
                                                    indentSize={'mini'}
                                                    onEndTyping={this.onEndTyping}
                                                    validationBorder={s_.isWidthNegative}
                                                />
                                                {s_.isWidthNegative && (
                                                    <p className={cn(grid.mt_micro, item.errorInputText)}>
                                                        {translate('valueCannotBeLessThanZero')}
                                                    </p>
                                                )}
                                            </span>
                                            <span className={cn(grid.col, grid.w45)}>
                                                <EditText
                                                    type={'number'}
                                                    value={s_.position.height}
                                                    label={translate('height')}
                                                    onChange={(value) => this.onChangeWidthOrHeight(value, 'height')}
                                                    indentSize={'mini'}
                                                    onEndTyping={this.onEndTyping}
                                                    validationBorder={s_.isHeightNegative}
                                                />
                                                {s_.isHeightNegative && (
                                                    <p className={cn(grid.mt_micro, item.errorInputText)}>
                                                        {translate('valueCannotBeLessThanZero')}
                                                    </p>
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                )}
                                <div className={styles.checkBox}>
                                    <Checkbox
                                        label={translate('contentWithoutSpaces')}
                                        checked={p_.autoPos === 'withoutSpaces'}
                                        onClick={() => p_.onAutoPosContent('withoutSpaces')}
                                    />
                                </div>
                                <div className={styles.checkBox}>
                                    <Checkbox
                                        label={translate('contentLooping')}
                                        checked={p_.loop}
                                        onClick={p_.onToggleLoop}
                                    />
                                </div>
                                {this.contentModeOptions && (
                                    <div className={styles.row}>
                                        <Dropdown
                                            className={cn(grid.w100)}
                                            value={p_.contentOrderMode}
                                            options={this.contentModeOptions}
                                            label={translate('playOrder')}
                                            onChange={this.changeOrderMode}
                                            isRelativeList={true}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                        {isShowAutoPositionAudio && p_.areaContent.length > 0 && (
                            <div>
                                <div className={cn(styles.title, styles.borderTop, grid.mt_big)}>
                                    <div className={item.ellipsis}>{translate('autoPositionAudio')}</div>
                                </div>
                                <EditorAutoPositionAudio
                                    content={p_.areaContent}
                                    soundOrderMode={p_.contentOrderMode}
                                />
                            </div>
                        )}
                    </div>
                </Collapse>
            </div>
        )
    }
}

EditorAreaSettings.propTypes = {
    options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    position: PropTypes.object,
    area: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onSelect: PropTypes.func,
    onEndTyping: PropTypes.func,
    resolutionWidth: PropTypes.number,
    resolutionHeight: PropTypes.number,
    onAutoPosContent: PropTypes.func,
    onToggleLoop: PropTypes.func,
    autoPos: PropTypes.string,
    loop: PropTypes.bool,
    isSimple: PropTypes.bool,
    showAllAreas: PropTypes.bool,
    onSetContentOrderMode: PropTypes.func,
}

EditorAreaSettings.defaultProps = {
    area: 0,
    options: [],
    position: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    },
    onSelect: () => {},
}

export default EditorAreaSettings
