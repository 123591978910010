import React from 'react'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import TruncateString from 'molecules/TruncateString'

import helpers from 'core/helpers'

import { cn } from 'ethcss'
import styles from './styles'

const getRoleChar = (str) => str.charAt(0)

const ItemRoles = (p_) => {
    const isActive = p_.item.__view.selected || p_.active

    return (
        <React.Fragment>
            <div className={cn(styles.catalogItemRolesWrapper, { [styles.catalogItemRolesContentActive]: isActive })}>
                <div
                    className={styles.catalogItemRolesContent}
                    onClick={(e) => {
                        if (helpers.isDblTouchTap(e)) {
                            p_.onDoubleClickObject(p_.item)
                            return
                        }

                        if (p_.multiSelect) {
                            p_.onSelect({ selected: !p_.item.__view.selected, item: p_.item })
                            return
                        }

                        p_.onSelectInfo(e)
                    }}
                >
                    <div
                        className={cn(styles.catalogItemRolesCell, {
                            [styles.catalogItemRoleCellMultiSelect]: p_.multiSelect && p_.item.__view.selected,
                        })}
                    >
                        <div className={styles.catalogItemRolesCellContent}>
                            <div className={styles.catalogItemRolesCharLayer} />
                            <span className={styles.catalogItemRolesCellText}>{getRoleChar(p_.item.label)}</span>
                        </div>
                    </div>
                    <div className={styles.catalogItemRolesFooter}>
                        <div className={styles.catalogItemRolesInfo}>
                            <div className={styles.catalogItemRolesName}>
                                <TruncateString text={p_.item.label} />
                            </div>
                        </div>
                    </div>
                    {p_.multiSelect && (
                        <div className={styles.catalogItemRoleCheckbox}>
                            <Checkbox
                                checked={p_.item.__view.selected}
                                onChange={(selected, e) => {
                                    e.stopPropagation()
                                    p_.onSelect({ selected, item: p_.item })
                                }}
                                size={24}
                            />
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    )
}
export default ItemRoles
