import React, { FC, useEffect, useState } from 'react'
import styles from './files__smartFolderModal.jcss'
import Modal from 'blocks.simple/modal/modal'
import Icon from 'blocks.simple/icon/icon'
import Button from 'blocks.simple/button/button'
import translate from '../../../core/translate'
import EditText from '../../../blocks.simple/editText/editText'
import { SwitchButton } from 'atoms/SwitchButton'
import { cn } from 'ethcss'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import { Radio } from '../../../atoms/Radio'
import { api } from '../../../core/api/ConnectionManager'
import helpers from 'core/helpers'

const initRule = {
    name: '',
    limit: 20,
    offset: 0,
    animation: {
        in: null,
        out: null,
        state: null,
    },
    recursiveQ: true,
    contentTypes: ['image', 'video'],
    presortOrder: [['id', 'DESC']],
    hideNotAcceptedQ: false,
    showNewFilesQ: true,
    contentOrderMode: 'user_provided',
    defaultDurationS: 10,
    onUpdateNewestFirstQ: true,
}

const RulesList: FC<any> = ({ setStatus, onClose, setRuleForEdit, onSelectRule, rules, setRules }) => {
    const [selectedRuleId, setSelectedRuleId] = useState(null)

    const onAdd = () => {
        setStatus('add-rule')
    }
    const onEdit = (e: any, rule: any) => {
        e.stopPropagation()
        setRuleForEdit(rule)
        setStatus('add-rule')
    }
    const onDelete = (e: any, id: number) => {
        e.stopPropagation()
        api.send('deleteFile', { id }).then((res: any) => {
            if (res) {
                setRules(rules.filter((rule: any) => rule.id !== res.id[0]))
            }
        })
    }

    return (
        <div className={styles.rulesContainer}>
            <div className={styles.rulesList}>
                {rules.map((rule: any) => {
                    return (
                        <div
                            className={cn(
                                styles.rule,
                                rule.id === selectedRuleId ? styles.selectedRule : '',
                                onSelectRule ? styles.ruleLeftPadding : ''
                            )}
                        >
                            {onSelectRule && (
                                <Radio
                                    onClick={() => {
                                        if (onSelectRule) {
                                            setSelectedRuleId(rule.id)
                                        }
                                    }}
                                    className={styles.radio}
                                    selected={rule.id === selectedRuleId}
                                />
                            )}
                            <div className={styles.ruleTitle}>{rule.name}</div>
                            <div className={styles.editRule} onClick={(e) => onEdit(e, rule)}>
                                <Icon type={'pencil'} size={14} color={'black'} />
                            </div>
                            <div className={styles.deleteRule} onClick={(e) => onDelete(e, rule.id)}>
                                <Icon type={'delete'} size={14} color={'black'} />
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className={styles.addBtn} onClick={onAdd}>
                <Icon type={'plus'} size={18} color={'black'} />
            </div>
            {!rules.length && <div className={styles.emptyInfo}>{translate('rulesEmpty')}</div>}
            {onSelectRule && <div className={styles.ruleSelectInfo}>{translate('ruleChoice')}</div>}
            <div className={styles.rulesListBtnWrapper}>
                <Button type="submit" mod="withBorder" className={styles.cancelBtn} onClick={onClose}>
                    {translate('close')}
                </Button>
                {onSelectRule && (
                    <Button
                        type="submit"
                        mod={'fill'}
                        className={styles.accept}
                        onClick={() => onSelectRule(selectedRuleId)}
                    >
                        {translate(selectedRuleId && rules.length ? 'apply' : 'skip')}
                    </Button>
                )}
            </div>
        </div>
    )
}

const Rule: FC<any> = ({ setStatus, ruleForEdit, setRuleForEdit, rules, setRules, folderId }) => {
    const [rule, setRule] = useState(ruleForEdit ? ruleForEdit.data : initRule)

    const backToRulesList = () => {
        setStatus('rules-list')
    }

    const onChangeRule = (value: string | number, fieldName: string) => {
        setRule({
            ...rule,
            [fieldName]: value,
        })
    }

    const typeIsExist = (type: string) => {
        return rule.contentTypes.includes(type)
    }

    const changeType = (type: string) => {
        setRule({
            ...rule,
            contentTypes: typeIsExist(type)
                ? rule.contentTypes.filter((element: any) => element !== type)
                : rule.contentTypes.concat(type),
        })
    }

    const changeContentOrderMode = () => {
        setRule({
            ...rule,
            contentOrderMode: rule.contentOrderMode === 'shuffle' ? 'user_provided' : 'shuffle',
        })
    }

    const changeUpdateNewestFirstQ = () => {
        setRule({
            ...rule,
            onUpdateNewestFirstQ: !rule.onUpdateNewestFirstQ,
        })
    }

    const changeHideNotAcceptedQ = () => {
        setRule({
            ...rule,
            hideNotAcceptedQ: !rule.hideNotAcceptedQ,
        })
    }

    const changeUseNetworkFolder = () => {
        setRule({
            ...rule,
            useNetworkFolder: !rule.useNetworkFolder,
        })
    }

    const changeShowNewFilesQ = () => {
        setRule({
            ...rule,
            showNewFilesQ: !rule.showNewFilesQ,
        })
    }

    const onSave = (rule: any) => {
        let _rule = { ...rule }

        if (rule.useNetworkFolder && rule.networkFolderUrl && !rule.scanFolderInterval) {
            _rule.scanFolderInterval = '10'
        }

        if (ruleForEdit) {
            const data = {
                id: ruleForEdit.id,
                data: _rule,
            }
            api.send('updateFile', data).then((res: any) => {
                if (res) {
                    setRules(
                        rules.map((rule: any) => {
                            if (rule.id === res.id) {
                                return res
                            }

                            return rule
                        })
                    )
                }
                setStatus('rules-list')
                setRuleForEdit(null)
            })

            return
        }

        const data = {
            type: 'smartFolder',
            folderId,
            data: _rule,
        }

        api.send('createSource', data).then((res) => {
            if (res) {
                setRules([...rules, res])
                setStatus('rules-list')
                setRuleForEdit(null)
            }
        })
    }

    useEffect(() => {
        if (rule.scanFolderInterval) {
            const interval = +rule.scanFolderInterval

            if (interval <= 0) {
                setRule({
                    ...rule,
                    scanFolderInterval: 1,
                })
            }
            if (interval > 1140) {
                setRule({
                    ...rule,
                    scanFolderInterval: 1140,
                })
            }
        }
    }, [rule])

    return (
        <div className={styles.ruleContainer}>
            {rule && (
                <>
                    <div className={styles.caption}>{translate('ruleSettings')}</div>
                    <div className={styles.settingsBlock}>
                        <div className={styles.subcaption}>{translate('ruleName')}</div>
                        <EditText
                            mod={'withBorder'}
                            placeholder={translate('enterRuleName')}
                            value={rule.name}
                            containerClass={styles.input}
                            onChange={(value: string) => onChangeRule(value, 'name')}
                        />
                    </div>
                    <div className={styles.settingsBlock}>
                        <div className={styles.switchWrapper}>
                            <div className={cn(styles.switchCaption, styles.subcaption)}>{translate('moderation')}</div>
                            <SwitchButton onChange={changeHideNotAcceptedQ} checked={rule.hideNotAcceptedQ} />
                        </div>
                        <Checkbox
                            onClick={changeShowNewFilesQ}
                            className={cn(
                                styles.additionContent__checkbox,
                                !rule.hideNotAcceptedQ ? styles.disabled : ''
                            )}
                            labelClassName={cn(styles.additionContent__label)}
                            checked={rule.showNewFilesQ}
                            label={translate('showFilesWithoutModeration')}
                            disabled={!rule.hideNotAcceptedQ}
                        />
                    </div>
                    <div className={styles.settingsBlock}>
                        <div className={styles.subcaption}>{translate('contentFilter')}</div>
                        <div className={styles.settingsRow}>
                            <Checkbox
                                onClick={() => changeType('image')}
                                className={cn(styles.additionContent__checkbox)}
                                labelClassName={cn(styles.additionContent__label)}
                                checked={typeIsExist('image')}
                                label={translate('specifyDuration')}
                            />
                            <EditText
                                mod={'withBorder'}
                                type="number"
                                placeholder={translate('enterRuleName')}
                                value={rule.defaultDurationS}
                                containerClass={styles.input}
                                onChange={(value: string) => onChangeRule(+value, 'defaultDurationS')}
                            />
                        </div>
                        <div className={styles.settingsRow}>
                            <Checkbox
                                onClick={() => changeType('video')}
                                className={cn(styles.additionContent__checkbox)}
                                labelClassName={cn(styles.additionContent__label)}
                                checked={typeIsExist('video')}
                                label={translate('videos')}
                            />
                        </div>
                    </div>
                    <div className={styles.settingsBlock}>
                        <div className={styles.subcaption}>{translate('maxContentForPlayback')}</div>
                        <EditText
                            mod={'withBorder'}
                            value={rule.limit}
                            containerClass={styles.input}
                            type={'number'}
                            onChange={(value: string) => onChangeRule(value, 'limit')}
                        />
                    </div>
                    <div className={styles.settingsBlock}>
                        <div className={styles.subcaption}>{translate('viewOrder')}</div>
                        <div className={styles.settingsRow}>
                            <Checkbox
                                onClick={changeContentOrderMode}
                                className={cn(styles.additionContent__checkbox)}
                                labelClassName={cn(styles.additionContent__label)}
                                checked={rule.contentOrderMode === 'shuffle'}
                                label={translate('shuffle')}
                            />
                        </div>
                        <div className={styles.settingsRow}>
                            <Checkbox
                                onClick={changeUpdateNewestFirstQ}
                                className={cn(styles.additionContent__checkbox)}
                                labelClassName={cn(styles.additionContent__label)}
                                checked={rule.onUpdateNewestFirstQ}
                                label={translate('onUpdateNewestFirstQ')}
                            />
                        </div>
                    </div>
                    {helpers.isDevMode() && (
                        <div className={styles.settingsBlock}>
                            <div className={styles.subcaption}>{translate('networkFolder')}</div>
                            <div className={styles.switchWrapper}>
                                <div className={cn(styles.switchCaption, styles.subcaption)}>
                                    {translate('useNetworkFolder')}
                                </div>
                                <SwitchButton onChange={changeUseNetworkFolder} checked={rule.useNetworkFolder} />
                            </div>
                            <div className={styles.settingsRow}>
                                <EditText
                                    mod={'withBorder'}
                                    value={rule.networkFolderUrl}
                                    containerClass={cn(styles.input, !rule.useNetworkFolder ? styles.disabled : '')}
                                    label={translate('networkFolderUrl')}
                                    onChange={(value: string) => onChangeRule(value, 'networkFolderUrl')}
                                    disabled={!rule.useNetworkFolder}
                                />
                            </div>
                            <div className={styles.settingsRow}>
                                <EditText
                                    mod={'withBorder'}
                                    value={rule.scanFolderInterval}
                                    containerClass={cn(styles.input, !rule.useNetworkFolder ? styles.disabled : '')}
                                    label={translate('scanFolderInterval')}
                                    onChange={(value: string) => onChangeRule(value, 'scanFolderInterval')}
                                    type={'number'}
                                    disabled={!rule.useNetworkFolder}
                                />
                            </div>
                        </div>
                    )}
                </>
            )}
            <div className={styles.buttons}>
                <Button type="submit" mod="withBorder" className={styles.backBtn} onClick={backToRulesList}>
                    {translate('back')}
                </Button>
                <Button
                    disabled={!rule.name}
                    type="submit"
                    mod={'fill'}
                    className={styles.saveBtn}
                    onClick={() => onSave(rule)}
                >
                    {translate('save')}
                </Button>
            </div>
        </div>
    )
}

export const SmartFolderSettings: FC<any> = ({ onClose, smartFolderRules, onSelectRule, folderId }) => {
    const [rules, setRules] = useState(smartFolderRules)
    const [ruleForEdit, setRuleForEdit] = useState(null)
    const [status, setStatus] = useState('rules-list')

    const renderContent = () => {
        switch (status) {
            case 'rules-list':
                return (
                    <RulesList
                        setStatus={setStatus}
                        onClose={onClose}
                        setRuleForEdit={setRuleForEdit}
                        onSelectRule={onSelectRule}
                        rules={rules}
                        setRules={setRules}
                    />
                )
            case 'add-rule':
                return (
                    <Rule
                        setStatus={setStatus}
                        ruleForEdit={ruleForEdit}
                        setRuleForEdit={setRuleForEdit}
                        rules={rules}
                        setRules={setRules}
                        folderId={folderId}
                    />
                )
        }
    }

    return (
        <Modal open={true} showCloseIcon={false} mod={'clear'} transitionName={'fade'}>
            {renderContent()}
        </Modal>
    )
}
