import config from 'core/config'
import validator from 'validator'
import helpers from '../index'
import moment from 'moment'
import parsePath from 'parse-filepath'
import { hexToRgba } from 'core/utils'
import translate from 'core/translate'

import { TEXT_DISPLAY_AS, TFileType } from 'core/models/Files'
import { defaultFilesIconsTheme } from '../FilesIcons-theme'
import { getThemeIconValue } from '../../../theme/colors'
import { IStyles } from 'theme'
import { isExist } from 'core/utils/coreUtil'

interface InputIcons {
    [key: string]: string
}

const inputIcons: InputIcons = {
    pc: 'pc',
    dp: 'input',
    hdmi1: 'hdmi',
    hdmi2: 'hdmi',
}

export interface CellModelStyle {
    size: string
    fontFamily: string
    color: string
    bold: boolean
    italic: boolean
    align: string
    underline: boolean
    verticalAlign: string
}

export interface CellModelBorderPosition {
    size: number
    type: string
    color: string
}

export interface CellModelBorders {
    top: CellModelBorderPosition
    right: CellModelBorderPosition
    bottom: CellModelBorderPosition
    left: CellModelBorderPosition
}

export interface CellModelMargins {
    top: number
    right: number
    bottom: number
    left: number
}

export interface CellModelBackground {
    color: string
    transparency: number
}

export interface CellModelOptions {
    blink: boolean
    dataConnected: boolean
    wordBreak: boolean
    breakOnCharacters: boolean
    textOverflow: string
    blinkSpeed: number
    blinkSymmetry: number
    pageFlipSpeed: number
    scrollSpeed: number
}

export interface CellModel {
    text: string
    style: CellModelStyle
    borders: CellModelBorders
    margins: CellModelMargins
    background: CellModelBackground
    options: CellModelOptions
}

export interface ConvertStylesOptions {
    withoutStyles?: string[]
    cutWithoutPoints?: true
}

export interface FilesAddPayload {
    isAddText: boolean
    isAddLink: boolean
    isAddTable: boolean
}

export const cellModel: CellModel = {
    text: '',
    style: {
        size: '16',
        fontFamily: 'Arial',
        color: '#000000',
        bold: false,
        italic: false,
        align: 'left',
        underline: false,
        verticalAlign: 'top',
    },
    borders: {
        top: {
            size: 0,
            type: 'solid',
            color: '#000000',
        },
        right: {
            size: 0,
            type: 'solid',
            color: '#000000',
        },
        bottom: {
            size: 0,
            type: 'solid',
            color: '#000000',
        },
        left: {
            size: 0,
            type: 'solid',
            color: '#000000',
        },
    },
    margins: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
    background: {
        color: '#ffffff',
        transparency: 1,
    },
    options: {
        blink: false,
        dataConnected: false,
        wordBreak: false,
        breakOnCharacters: false,
        textOverflow: 'cut',
        blinkSpeed: 1,
        blinkSymmetry: 50,
        pageFlipSpeed: 1,
        scrollSpeed: -1,
    },
}

export const commandsModel = {
    cut: {
        startTime: '',
        duration: '',
    },
    crop: {
        width: 0,
        height: 0,
    },
    convertToMp4: {
        active: false,
    },
    rotate: {
        degrees: null,
    },
    removeAudio: {
        active: false,
    },
    setVideoBitrate: {
        bitrate: '',
        constant: false,
    },
    imageToVideo: {
        duration: '',
    },
    setFps: {
        fps: '',
    },
}

export default {
    getSrc(fileItem: any) {
        const textFileType = ['text', 'xml', 'csv', 'rss']

        if (fileItem.fileType === 'audio') {
            return getThemeIconValue('catalog', 'catalog', 'audio') || `${defaultFilesIconsTheme.audio}`
        }

        if (fileItem.fileType === 'office') {
            return getThemeIconValue('catalog', 'catalog', 'office') || `${defaultFilesIconsTheme.office}`
        }

        if (fileItem.fileType === 'empty') {
            return getThemeIconValue('catalog', 'catalog', 'empty') || `${defaultFilesIconsTheme.empty}`
        }

        if (textFileType.includes(fileItem.fileType)) {
            if (fileItem.displayAs === TEXT_DISPLAY_AS.QR_CODE) {
                return fileItem.thumbnail
            }

            return getThemeIconValue('catalog', 'catalog', 'transparent') || `${defaultFilesIconsTheme.transparent}`
        }

        if (fileItem.type === 'table') {
            return getThemeIconValue('catalog', 'catalog', 'table') || `${defaultFilesIconsTheme.table}`
        }

        if (fileItem.fileType === 'web_app' || fileItem.fileType === 'rigla_local_xml') {
            if (fileItem.thumbnail) {
                return fileItem.thumbnail.replace(/\(/g, '%28').replace(/\)/g, '%29')
            }
            return getThemeIconValue('catalog', 'catalog', 'webApp') || `${defaultFilesIconsTheme.webApp}`
        }

        if (fileItem.type === 'link') {
            return getThemeIconValue('catalog', 'catalog', 'link') || `${defaultFilesIconsTheme.link}`
        }

        if (fileItem.isDirectory) {
            if (fileItem.hasSmartFolderRulesQ) {
                return getThemeIconValue('catalog', 'catalog', 'smartFolder') || `${defaultFilesIconsTheme.smartFolder}`
            }
            return getThemeIconValue('catalog', 'catalog', 'folder') || `${defaultFilesIconsTheme.folder}`
        }

        if (fileItem.extension === '.gif') {
            return fileItem.src
        }

        return fileItem.thumbnail
    },
    isIcon(fileItem: any) {
        const types = ['audio', 'office', 'link', 'web_app', 'rigla_local_xml', 'table']
        return !!fileItem.isDirectory || types.includes(fileItem.fileType)
    },
    isMedia(fileType: TFileType) {
        return fileType === 'audio' || fileType === 'video'
    },
    convertSeconds(secs: number) {
        let minutes = Math.floor(secs / 60)
        secs = secs % 60
        minutes = minutes % 60
        return `${pad(minutes)}:${pad(secs)}`

        function pad(num: number) {
            return ('0' + num).slice(-2)
        }
    },
    getDownloadLink(selectedList: any[], params: string) {
        let downloadLink = null
        const list = selectedList
        if (list.length > 0) {
            downloadLink = `${config.api.root}v2/download/files?${params}`

            const sourceId = ['']
            const folderId = ['']
            list.forEach((contentItem) => {
                if (contentItem.isDirectory) {
                    folderId.push(contentItem.id)
                } else {
                    sourceId.push(contentItem.id)
                }
            })

            if (sourceId.length > 1) {
                downloadLink += sourceId.join('&sourceId[]=')
            }
            if (folderId.length > 1) {
                downloadLink += folderId.join('&folderId[]=')
            }
        }

        return downloadLink
    },
    isEqual(state: any) {
        if (!state.item || !state.prevItem) {
            return false
        }

        return state.item.id === state.prevItem.id && state.item.isDirectory === state.prevItem.isDirectory
    },
    getFiles(list: any[]) {
        if (list.length) {
            list = list.map((item: any) => item.source)
        }
        return list
    },
    getFileIconName(item: any) {
        if (item.type === 'input') {
            return inputIcons[item.fileType]
        }
        if (item.fileType === 'xml' || item.fileType === 'rigla_local_xml') {
            return 'web_app'
        }

        return item.fileType
    },
    removeFontSize(string: string) {
        return string.replace(/(font-size:)\s*\d+\s*(px;)/g, '')
    },
    isShowEditor({ isAddText, isAddLink, isAddTable }: FilesAddPayload, query: any) {
        if (!isExist(query) && !isExist(query)) {
            return false
        }

        return (
            (isAddText && query.type === 'text') ||
            (isAddTable && query.type === 'table') ||
            (isAddLink && query.type === 'link')
        )
    },
    getFileItem(source: any) {
        const fileItem = {
            id: source.id,
            isDirectory: source.isDirectory,
            name: source.name,
            fileType: source.fileType,
            type: source.type,
            thumbnail: source.thumbnail,
            src: source.src,
        }

        return fileItem
    },
    convertStyles(styles: IStyles, options: ConvertStylesOptions = {}) {
        const convertedStyles: IStyles = {}
        const style = { ...styles }

        for (let key in style) {
            if (style.hasOwnProperty(key)) {
                if (key === 'size') {
                    convertedStyles['fontSize'] = style[key]
                } else if (key === 'bold') {
                    convertedStyles['fontWeight'] = style[key] ? 'bold' : 'normal'
                } else if (key === 'underline') {
                    convertedStyles['textDecoration'] = style[key] ? key : 'none'
                } else if (key === 'italic') {
                    convertedStyles['fontStyle'] = style[key] ? key : 'normal'
                } else if (key === 'align') {
                    convertedStyles['textAlign'] = style[key]
                } else if (key === 'wordBreak') {
                    convertedStyles['whiteSpace'] = style[key] ? 'normal' : ''
                } else if (key === 'breakOnCharacters') {
                    convertedStyles['wordBreak'] = style[key] ? 'break-all' : ''
                } else if (key === 'blink') {
                    convertedStyles['animation'] =
                        style.blinkSpeed && style[key] ? `blinkCell ${style.blinkSpeed}s infinite` : ''
                } else if (key === 'textOverflow') {
                    if (style[key] === 'cut') {
                        if (options.cutWithoutPoints) {
                            convertedStyles['overflow'] = 'hidden'
                        } else {
                            convertedStyles['textOverflow'] = 'ellipsis'
                        }
                    }
                } else {
                    convertedStyles[key] = style[key]
                }
            }
        }

        if (options.withoutStyles) {
            options.withoutStyles.forEach((style: string) => {
                delete convertedStyles[style]
            })
        }

        return convertedStyles
    },
    getRgbaBackgroundColor({ color = '#ffffff', transparency = 1 }) {
        return hexToRgba(color, transparency)
    },
    isVolumeExist(fileType: TFileType) {
        const types = ['video', 'audio', 'web_app', 'web_page']

        return types.includes(fileType)
    },
    getDefaultTableOptions() {
        const cells: CellModel[][] = [[], [], []]

        for (let i = 0; i < 3; i++) {
            for (let j = 0; j < 3; j++) {
                const cell = helpers.deepCopy(cellModel) as CellModel
                cells[i].push(cell)
            }
        }

        return {
            tableFormat: {
                colNumber: 3,
                rowNumber: 3,
                rowHeight: 'auto',
                colWidth: 'auto',
                heightByRow: [],
                widthByCol: [],
            },
            cells,
            connectedData: {
                pathToFile: '',
                valueLimits: {
                    enabled: false,
                    min: 0,
                    max: 100,
                },
                maxCharactersNumber: {
                    enabled: false,
                    value: 1000,
                },
                fileAgeLimit: {
                    enabled: false,
                    hours: 24,
                    minutes: 0,
                },
                trimRows: true,
                removeEmptyRows: {
                    enabled: false,
                    onlyInBeginningAndEnd: false,
                },
                rowProcessing: {
                    fromRow: {
                        enabled: false,
                        value: 1,
                    },
                    toRow: {
                        enabled: false,
                        value: 10,
                    },
                    lastRow: false,
                },
                columnProcessing: {
                    fromColumn: {
                        enabled: false,
                        value: 1,
                    },
                    toColumn: {
                        enabled: false,
                        value: 1,
                    },
                    fieldDelimiter: ',',
                },
                extraData: {
                    useDefaultData: false,
                    fileNotExistDefaultData: '',
                    fileTooOldDefaultData: '',
                    textBeforeData: '',
                    textAfterData: '',
                },
                adjustTableToFitData: false,
                keepFormatOfLastRowOnAdjust: false,
            },
        }
    },
    validateLink(link: string) {
        if (!link) {
            return {
                isValid: false,
                error: 'emptyLinkError',
            }
        }

        const parsedUrl = parsePath(link)
        let type = 'web_page'

        if (!validator.isURL(link, { protocols: ['http', 'https', 'rtp', 'rtsp', 'udp'], require_protocol: true })) {
            return {
                isValid: false,
                error: 'linkFormatError',
            }
        }

        // UDP
        if (parsedUrl.dir === 'udp:/') {
            type = 'stream'
        }

        // HLS
        if (parsedUrl.extname === '.m3u8') {
            type = 'stream'
        }

        // SmoothStream
        if (parsedUrl.dir.includes('.ism') && parsedUrl.basename === 'Manifest') {
            type = 'stream'
        }

        // DASH
        if (parsedUrl.extname === '.mpd') {
            type = 'stream'
        }

        // RTP & RTSP
        if (parsedUrl.dir === 'rtp:/' || parsedUrl.path.includes('rtsp://')) {
            type = 'stream'
        }

        return {
            isValid: true,
            type,
        }
    },
    autoGenerateTableName() {
        const dateString = moment().format('HH:mm:ss DD/MM/YYYY')

        return `${translate('newTable')} ${dateString}`
    },
    calculateAspectRatioFit({
        sourceWidth,
        sourceHeight,
        width,
        height,
    }: {
        sourceWidth: number
        sourceHeight: number
        width: number
        height: number
    }) {
        const ratio = Math.min(width / sourceWidth, height / sourceHeight)

        return { width: sourceWidth * ratio, height: sourceHeight * ratio }
    },
    availableVideoMimeTypes(type: string) {
        const mimeTypes = [
            'video/mp4',
            'video/ogg',
            'audio/ogg',
            'video/ogv',
            'application/x-troff-msvideo',
            'video/avi',
            'video/msvideo',
            'video/x-msvideo',
            'video/x-matroska',
            'video/quicktime',
        ]

        return mimeTypes.includes(type)
    },
    getTagsList(tags: any) {
        return tags.map((tag: any) => ({
            id: tag.id,
            name: tag.name,
            label: tag.name,
        }))
    },
    getTagsNamesList(tags: any) {
        return tags.map((tag: any) => tag.label)
    },
    getFileNameWithoutExt(filename: string, extensions: string[] = []) {
        const regexpString = _buildExtensionsString(extensions)
        return filename.replace(regexpString, '')

        function _buildExtensionsString(extensions: string[]) {
            let str = ''

            extensions.forEach((item, index) => {
                if (index === extensions.length - 1) {
                    str += `\\${item}`
                } else {
                    str += `\\${item}|`
                }
            })

            return new RegExp(str)
        }
    },
    isSvgThumbnail(src: string) {
        if (!src) return false

        return src.split('.').pop() === 'svg'
    },
}
