import { addStyles } from 'ethcss'
import { grid, item, IStyles } from 'theme'
import { indents } from 'blocks.app/config'
import { mediaCSS } from 'theme'
import { defaultThemeStyles } from 'blocks.app/sidebar/Sidebar-theme'

const filesStyles: IStyles = {
    filterWrapper: {
        marginRight: -grid.mr_md['margin-right'],
    },
    filter: {
        ...item.white,
    },
    menuItem: {
        ...grid.p_mini,
        cursor: 'pointer',
        ...item.transitionErase,
        textAlign: 'center',
        color: `var(--var-module-sidebar-component-sidebar-style-menuTextHover, ${defaultThemeStyles.menuTextHover})`,
        display: 'block',
        textDecoration: 'none',
        '&:hover': {
            color: `var(--var-module-sidebar-component-sidebar-style-menuText, ${defaultThemeStyles.menuText})`,
        },
    },
    breadcrumbs: {
        ...grid.pl_mdPlus,
        marginLeft: -grid.m_mdPlus['margin'],
        marginTop: '10px',
        width: `calc(100% + ${2 * grid.m_mdPlus['margin']}px)`,
        [mediaCSS.tabMini]: {
            marginTop: -grid.m_mini['margin'],
        },
    },
    breadcrumbs_x: {
        paddingLeft: '0px',
    },
    button: {
        position: 'absolute',
        bottom: indents.md,
        left: 'calc(50% - 55px)',
    },
    modalWrapper: {
        ...grid.full,
        ...grid.rowCenter,
    },
    modalInner: {
        ...grid.w30,
        [mediaCSS.tab]: {
            ...grid.w60,
        },
        [mediaCSS.tabMini]: {
            ...grid.w90,
        },
        backgroundColor: `var(--var-module-sidebar-component-sidebar-style-background, ${defaultThemeStyles.background})`,
        boxShadow: '0 19px 38px rgb(0 0 0 / 30%), 0 15px 12px rgb(0 0 0 / 22%)',
        ...item.rounded_full_mini,
        overflow: 'hidden',
        position: 'relative',
        minHeight: 300,
    },
}

addStyles(filesStyles)
export default filesStyles
