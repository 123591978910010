import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import EditText from 'blocks.simple/editText/editText'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import Button from 'blocks.simple/button/button'
import Templates from '../../__templates/editor__templates'
import AreaSettings from '../../__areaSettings/editor__areaSettings'
import ConditionSettings from '../../__conditionSettings/editor__conditionSettings'
import BroadcastSettings from '../../__broadcastSettings/editor__broadcastSettings'
import DurationSettings from '../../__durationSettings/editor__durationSettings'
import EditorActions from '../../__actions/editor__actions'
import EditorFiles from '../../__files/editor__files'
import Icon from 'blocks.simple/icon/icon'
import RangeVolume from 'blocks.simple/range/_volume/range_volume'
import Range from 'blocks.simple/range/range'
import Tippy from 'blocks.simple/tippy/tippy'
import helpers from 'core/helpers'
import filesHelpers from 'core/helpers/_files'
import { editor } from '../../editor.local.methods'
import translate from 'core/translate'
import deepEqual from 'fast-deep-equal'
import { cn } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { text as textStyles } from 'theme'
import item from 'blocks.simple/item/item.jcss'
import styles from './editor__toolbar_right.jcss'
import settingsStyles from '../../__areaSettings/editor__areaSettings.jcss'
import { indents } from 'blocks.app/config'
import { defaultThemeStyles as defaultThemeSidebarStyles } from 'blocks.app/sidebar/Sidebar-theme'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import { withRouter } from 'react-router'
import Collapse from 'blocks.simple/collapse/collapse'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import Modal from 'blocks.simple/modal/modal'
import './style.css'

const iconSize = 16
const indentName = 'md'

const isCanCreateAction = (source) => {
    const types = ['video', 'image', 'text']

    if (!source) {
        return false
    }

    return types.includes(source.fileType)
}

const defaultStyleState = {
    opacity: 1,
}

const allowChangeStyleContentTypes = ['web_app', 'video', 'audio', 'image', 'web_page', 'office']

class EditorToolbarRight extends React.Component {
    constructor(p_) {
        super(p_)

        this.state = {
            selectContent: this.getContentOptions(p_.content, p_.sources),
            activeToggle: 'files',
            contentItemPosition: {},
            style: {
                ...defaultStyleState,
            },
            isOpened: !p_.isMobile,
        }
        this.changeContentStart = this.changeContentStart.bind(this)
        this.changeContentDuration = this.changeContentDuration.bind(this)
        this.setContentTime = this.setContentTime.bind(this)
        this.onEndTyping = this.onEndTyping.bind(this)
        this.openPdfPagesChoice = this.openPdfPagesChoice.bind(this)
        this.closePdfPagesChoice = this.closePdfPagesChoice.bind(this)
    }

    componentDidUpdate(prevProps) {
        const p_ = this.props

        if (p_.selectedContentItem && !deepEqual(prevProps.selectedContentItem, p_.selectedContentItem)) {
            let fileItem = p_.sources[p_.content[p_.selectedContent].sourceId]

            this.setState({
                layerOptions: this.getLayerOptions(p_.content),
                selectContent: this.getContentOptions(p_.content, p_.sources),
                contentName: fileItem.name,
                isMedia: filesHelpers.isMedia(fileItem.fileType),
                contentStart: editor.msDurationToS(p_.selectedContentItem.startTime),
                contentDuration: editor.msDurationToS(p_.selectedContentItem.duration),
                isChangedStart: false,
                isChangedDuration: false,
                contentItemPosition: this.getContentItemPosition(p_),
                style: p_.selectedContentItem.style || { ...defaultStyleState },
                subItemDurationS: p_.selectedContentItem.subItemDurationS,
                subItems: null,
                modalPdfPagesChoice: false,
            })
        }

        if (!prevProps.minimized && p_.minimized) {
            this.setState({ activeToggle: 'files' })
        }
    }

    onEndTyping() {
        const p_ = this.props

        const isUpdated = editor.updateAreaPosition(p_.selectedArea, this.areaSettings.getRelPosition())
        return isUpdated
    }

    getContentOptions(content = [], sources) {
        const contentOptions = []

        content.forEach((contentItem, index) => {
            contentOptions.push({ id: index, name: helpers.strip_html_tags(sources[contentItem.sourceId].name) })
        })

        return contentOptions
    }

    getLayerOptions(content = []) {
        const layerOptions = []

        content.forEach((contentItem, index) => {
            layerOptions.push({ id: index, name: `${index + 1}` })
        })

        return layerOptions
    }

    changeContentStart(contentStart) {
        this.setState({ contentStart, isChangedStart: true })
    }

    changeContentDuration(contentDuration) {
        this.setState({ contentDuration, isChangedDuration: true })
    }

    changeSubItemDurationS(subItemDurationS) {
        const p_ = this.props
        const subItems = p_.selectedContentItem.subItems
        const pageCount = subItems ? subItems.length : p_.selectedContentItem.source.data.pageCount

        this.changeContentDuration(pageCount * subItemDurationS)
        this.setContentTime(pageCount * subItemDurationS, 'duration')
        this.setState({ subItemDurationS })
    }

    saveSubItems = (subItems) => {
        const p_ = this.props
        const duration = p_.selectedContentItem.subItemDurationS * subItems.length

        this.changeContentDuration(duration)
        this.setContentTime(duration, 'duration')
        editor.updateSubItems(p_.selectedArea, p_.selectedContent, subItems)
    }
    saveSubItemDurationS = () => {
        const s_ = this.state
        const p_ = this.props

        editor.updateSubItemDurationS(p_.selectedArea, p_.selectedContent, s_.subItemDurationS)
    }

    openPdfPagesChoice() {
        this.setState({ modalPdfPagesChoice: true })
    }

    closePdfPagesChoice() {
        this.setState({ modalPdfPagesChoice: false })
    }

    change

    setContentTime(value, type) {
        let start, duration
        const p_ = this.props
        const { selectedContentItem, page } = this.props

        if (type === 'start' && !this.state.isChangedStart && type === 'duration' && !this.state.isChangedDuration) {
            return
        }

        if (type === 'start' && value === '') {
            p_.emitError('startTimeError')
            return
        }
        if (type === 'duration' && value === '') {
            p_.emitError('enterDuration')
            return
        }

        if (type === 'start') {
            start = value * 1000
            duration = selectedContentItem.duration
        }
        if (type === 'duration') {
            start = selectedContentItem.startTime
            duration = value * 1000
        }

        if (!p_.autoPos && start + duration > page.duration) {
            if (type === 'start') {
                p_.emitError('advContentError')
            }
            if (type === 'duration') {
                p_.emitError('advContentError')
            }
            return
        }

        editor.updateTime(start, duration, p_.selectedArea, p_.selectedContent)
    }

    getContentItemPosition = (p_) => {
        const selectedAreaItem = p_.areas[p_.selectedArea]
        const contentPosition = p_.selectedContentItem.position
        const absArea = {
            height: Math.round(selectedAreaItem.height * p_.resolutionHeight),
            width: Math.round(selectedAreaItem.width * p_.resolutionWidth),
        }

        const position = {
            top: Math.round(contentPosition.top * absArea.height),
            height: Math.round(contentPosition.height * absArea.height),
            left: Math.round(contentPosition.left * absArea.width),
            width: Math.round(contentPosition.width * absArea.width),
        }

        return position
    }
    changeContentItemPosition = (value, fieldName) => {
        const s_ = this.state

        s_.contentItemPosition[fieldName] = value
        this.setState(s_)
    }
    saveContentItemPosition = () => {
        const s_ = this.state
        const p_ = this.props
        const selectedAreaItem = p_.areas[p_.selectedArea]
        const prevContentPosition = p_.selectedContentItem.position
        const absArea = {
            height: Math.round(selectedAreaItem.height * p_.resolutionHeight),
            width: Math.round(selectedAreaItem.width * p_.resolutionWidth),
        }
        let moveType = 'drag'
        const relPosition = {
            top: s_.contentItemPosition.top / absArea.height,
            height: s_.contentItemPosition.height / absArea.height,
            left: s_.contentItemPosition.left / absArea.width,
            width: s_.contentItemPosition.width / absArea.width,
        }
        if (prevContentPosition.width !== relPosition.width || prevContentPosition.height !== relPosition.height) {
            moveType = 'resize'
        }

        editor.updatePosition(p_.selectedArea, p_.selectedContent, relPosition, moveType)
    }
    getContentDirectory = (p_) => {
        if (!p_.content && !p_.content.length) return null
        const folderId = p_.content[p_.selectedContent].source.folderId
        if (!folderId) return null

        return (
            <div className={item.targetFolder}>
                <a
                    className={item.targetFolderLink}
                    href={`/#/files?folderId=${folderId}`}
                    target={'_blank'}
                    rel="noopener noreferrer"
                >
                    {translate('location')}
                </a>
            </div>
        )
    }

    render() {
        const s_ = this.state
        const p_ = this.props
        const selectedAreaItem = p_.areas[p_.selectedArea]
        const query = helpers.getQuery(getURLSearchParamsByLocation(p_.location), 'files')

        const actionsOnContent =
            p_.selectedContentItem &&
            !p_.isSimple &&
            isCanCreateAction(p_.selectedContentItem.source) &&
            p_.pages.length > 1
        const isSoundtrack = p_.selectedArea === -1
        const isDynamicDurationContent =
            p_.selectedContentItem &&
            p_.selectedContentItem.source &&
            p_.selectedContentItem.source.fileType === 'web_app' &&
            p_.selectedContentItem.source.data &&
            p_.selectedContentItem.source.data.widgetInfo &&
            p_.selectedContentItem.source.data.widgetInfo.dynamicDurationQ
        const isSmartFolder =
            p_.selectedContentItem &&
            p_.selectedContentItem.source &&
            p_.selectedContentItem.source.fileType === 'smartFolder'
        const isPdf = p_.selectedContentItem && p_.selectedContentItem.source.extension === '.pdf'

        const activeToolbarColor = `var(--var-module-sidebar-component-sidebar-style-activeToggleText, ${defaultThemeSidebarStyles.activeToggleText})`
        const notActiveToolbarColor = `var(--var-module-sidebar-component-sidebar-style-toggleText, ${defaultThemeSidebarStyles.toggleText})`
        const isShowContentSettings = p_.showSettings === 'content' && p_.selectedContentItem

        return p_.toolbarIsOpened ? (
            <div
                style={
                    p_.minimized
                        ? { width: `${iconSize + 2 * indents[indentName]}px`, overflow: 'hidden' }
                        : p_.isMobile
                        ? { width: '100%' }
                        : { width: '33.3333333%' }
                }
                className={cn(styles.colsRight, { [styles.colsRightDevice]: !p_.minimized })}
            >
                <div className={cn(grid.fullHeight, grid.col)}>
                    <div className={styles.header}>
                        {!p_.isMobile && (
                            <>
                                <div
                                    style={{ padding: indents[indentName] }}
                                    onClick={() => {
                                        editor.toggleMinimized()
                                    }}
                                    className={cn(styles.minimize)}
                                >
                                    <Icon
                                        size={iconSize}
                                        type={p_.minimized ? 'double_arrow_left' : 'double_arrow_right'}
                                    />
                                </div>
                                <div className={grid.spaceWidth}>
                                    {!p_.showSettings && !p_.minimized && (
                                        <div className={styles.toggle}>
                                            <div
                                                className={cn(styles.toggleItem, {
                                                    [styles.toggleItemActive]: s_.activeToggle === 'files',
                                                    [styles.toggleItemDisable]: s_.activeToggle !== 'files',
                                                })}
                                                onClick={() => {
                                                    this.setState({ activeToggle: 'files' })
                                                }}
                                            >
                                                <div className={grid.rowCenter}>
                                                    <Icon
                                                        type={'folder'}
                                                        color={
                                                            s_.activeToggle === 'files'
                                                                ? activeToolbarColor
                                                                : notActiveToolbarColor
                                                        }
                                                    />
                                                    <div className={grid.ml_micro}>{translate('files')}</div>
                                                </div>
                                            </div>
                                            {!p_.isSimple && (
                                                <div
                                                    className={cn(styles.toggleItem, {
                                                        [styles.toggleItemActive]: s_.activeToggle === 'templates',
                                                        [styles.toggleItemDisable]: s_.activeToggle !== 'templates',
                                                    })}
                                                    onClick={() => {
                                                        this.setState({ activeToggle: 'templates' })
                                                    }}
                                                >
                                                    <div className={grid.rowCenter}>
                                                        <Icon
                                                            type={'list'}
                                                            color={
                                                                s_.activeToggle === 'templates'
                                                                    ? activeToolbarColor
                                                                    : notActiveToolbarColor
                                                            }
                                                        />
                                                        <div className={grid.ml_micro}>{translate('templates')}</div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {(p_.showSettings === 'broadcast' || p_.showSettings === 'duration') &&
                                        !p_.minimized && (
                                            <div className={styles.toggle}>
                                                <div
                                                    className={cn(
                                                        styles.toggleItem,
                                                        styles.toggleItemActive,
                                                        grid.spaceWidth
                                                    )}
                                                >
                                                    <div className={grid.rowCenter}>
                                                        <Icon type={'gear'} />
                                                        <div className={grid.ml_micro}>{translate('settings')}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    {(p_.showSettings === 'area' || p_.showSettings === 'content') && !p_.minimized && (
                                        <div className={styles.toggle}>
                                            <div
                                                className={cn(
                                                    styles.toggleItem,
                                                    styles.toggleItemActive,
                                                    grid.spaceWidth
                                                )}
                                            >
                                                <div className={grid.rowCenter}>
                                                    <div className={grid.ml_micro}>
                                                        {translate('areaAndContentSettings')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                    <div
                        className={styles.catalogWrapper}
                        style={
                            p_.showSettings || s_.activeToggle !== 'files'
                                ? { display: 'none' }
                                : p_.minimized
                                ? { height: 'auto' }
                                : {}
                        }
                        onClick={function () {
                            editor.openMinimized()
                        }}
                    >
                        <EditorFiles
                            minimized={p_.minimized}
                            query={query}
                            isAddTable={p_.isAddTable}
                            isAddText={p_.isAddText}
                            isAddLink={p_.isAddLink}
                            location={p_.location}
                            isMobile={p_.isMobile}
                            onMultipleFilesSelection={(selectedItems) =>
                                editor.setSourcesOnMultipleSelect(selectedItems)
                            }
                            onCancelMultipleFilesSelection={() => console.log('cancel selection')}
                        />
                    </div>
                    <div
                        className={styles.templatesWrapper}
                        style={
                            p_.showSettings || s_.activeToggle !== 'templates'
                                ? { display: 'none' }
                                : p_.minimized
                                ? { height: 'auto' }
                                : {}
                        }
                    >
                        <Templates acceptedSelectedTemplate={p_.selectedTemplateItem} />
                    </div>

                    {(p_.showSettings === 'area' || p_.showSettings === 'content') && selectedAreaItem && (
                        <div className={cn(grid.col, grid.spaceHeight, item.overY)}>
                            {p_.selectedArea >= 0 && (
                                <AreaSettings
                                    isSimple={p_.isSimple}
                                    options={p_.areas}
                                    area={p_.selectedArea}
                                    position={{
                                        top: selectedAreaItem.top,
                                        height: selectedAreaItem.height,
                                        left: selectedAreaItem.left,
                                        width: selectedAreaItem.width,
                                    }}
                                    onSelect={(selected) => {
                                        p_.onSelectArea(selected.id, true)
                                    }}
                                    ref={(areaSettings) => {
                                        this.areaSettings = areaSettings
                                    }}
                                    onEndTyping={this.onEndTyping}
                                    resolutionWidth={p_.resolutionWidth}
                                    resolutionHeight={p_.resolutionHeight}
                                    onAutoPosContent={function (posType) {
                                        editor.autoPosContent(posType, p_.selectedArea)
                                    }}
                                    onToggleLoop={function () {
                                        editor.toggleLoop(p_.selectedArea)
                                    }}
                                    onSetContentOrderMode={(mode) => {
                                        editor.setContentOrderMode(p_.selectedArea, mode)
                                    }}
                                    autoPos={p_.autoPos}
                                    loop={p_.loop}
                                    contentOrderMode={p_.contentOrderMode}
                                    showAllAreas={p_.showAllAreas}
                                    areaContent={selectedAreaItem.content}
                                />
                            )}
                            {isShowContentSettings && (
                                <div>
                                    <Collapse
                                        title={() => (
                                            <div className={cn(settingsStyles.collapseText)}>
                                                <div className={item.ellipsis}>{translate('contentSettings')}</div>
                                            </div>
                                        )}
                                        titleClassName={settingsStyles.collapseTitle}
                                        activeClassName={settingsStyles.collapseActive}
                                        className={settingsStyles.collapse}
                                        withoutOverflow={true}
                                        initialOpen={true}
                                    >
                                        {this.getContentDirectory(p_)}
                                        <div>
                                            <div className={grid.colCenter}>
                                                <div className={(styles.label, textStyles.center)}>
                                                    {translate('advContent')}
                                                </div>
                                                <div className={grid.w45}>
                                                    <Dropdown
                                                        value={p_.selectedContent}
                                                        options={s_.selectContent}
                                                        onChange={function (selected) {
                                                            p_.onSelectContent(selected.id)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            {!isSoundtrack && (
                                                <div className={settingsStyles.row}>
                                                    <EditText
                                                        label={translate('startTime')}
                                                        type="number"
                                                        containerClass={cn(settingsStyles.inputWrapper)}
                                                        value={s_.contentStart}
                                                        onChange={this.changeContentStart}
                                                        onEndTyping={(value) => this.setContentTime(value, 'start')}
                                                        indentSize={'mini'}
                                                    />
                                                    {isDynamicDurationContent || isSmartFolder ? (
                                                        <EditText
                                                            label={translate('duration')}
                                                            disabled={true}
                                                            type="text"
                                                            containerClass={cn(settingsStyles.inputWrapper)}
                                                            value={translate('duration_dynamic')}
                                                            onChange={this.changeContentDuration}
                                                            onEndTyping={(value) =>
                                                                this.setContentTime(value, 'duration')
                                                            }
                                                            indentSize={'mini'}
                                                        />
                                                    ) : (
                                                        <EditText
                                                            label={translate('duration')}
                                                            disabled={s_.isMedia}
                                                            type="number"
                                                            containerClass={cn(settingsStyles.inputWrapper)}
                                                            value={s_.contentDuration}
                                                            onChange={this.changeContentDuration}
                                                            onEndTyping={(value) =>
                                                                this.setContentTime(value, 'duration')
                                                            }
                                                            indentSize={'mini'}
                                                        />
                                                    )}
                                                </div>
                                            )}
                                            {!isSoundtrack && (
                                                <div className={settingsStyles.row}>
                                                    <div className={settingsStyles.inputWrapper}>
                                                        <Dropdown
                                                            value={p_.selectedContentItem.position.zIndex}
                                                            label={translate('layerLevel')}
                                                            options={s_.layerOptions}
                                                            onChange={function (selected) {
                                                                editor.sortContent(p_.selectedContent, selected.id)
                                                            }}
                                                            indentSize={'mini'}
                                                        />
                                                    </div>
                                                    <div className={cn(settingsStyles.inputWrapper)}>
                                                        <div className={styles.label}>
                                                            {translate('outputFrequency')}
                                                        </div>
                                                        <Button
                                                            fullWidth={true}
                                                            indentSize="mini"
                                                            mod="fill"
                                                            onClick={function () {
                                                                editor.cloneContent(p_.selectedContentItem)
                                                            }}
                                                            rounded={'full_md'}
                                                        >
                                                            {translate('add')}
                                                        </Button>
                                                    </div>
                                                </div>
                                            )}
                                            {isSoundtrack && (
                                                <div className={cn(grid.rowCenter, grid.p_md)}>
                                                    <div className={cn(settingsStyles.inputWrapper)}>
                                                        <div className={cn(styles.label)}>
                                                            {translate('outputFrequency')}
                                                        </div>
                                                        <Button
                                                            fullWidth={true}
                                                            indentSize="mini"
                                                            mod="fill"
                                                            onClick={function () {
                                                                editor.cloneContent(p_.selectedContentItem)
                                                            }}
                                                            rounded={'full_md'}
                                                        >
                                                            {translate('add')}
                                                        </Button>
                                                    </div>
                                                </div>
                                            )}
                                            {p_.selectedArea >= 0 && (
                                                <div className={settingsStyles.row}>
                                                    <EditText
                                                        label={translate('leftIndent')}
                                                        type="number"
                                                        containerClass={cn(settingsStyles.inputWrapper)}
                                                        value={s_.contentItemPosition.left}
                                                        onChange={(value) =>
                                                            this.changeContentItemPosition(value, 'left')
                                                        }
                                                        onEndTyping={this.saveContentItemPosition}
                                                        indentSize={'mini'}
                                                    />
                                                    <EditText
                                                        label={translate('topIndent')}
                                                        disabled={s_.isMedia}
                                                        type="number"
                                                        containerClass={cn(settingsStyles.inputWrapper)}
                                                        value={s_.contentItemPosition.top}
                                                        onChange={(value) =>
                                                            this.changeContentItemPosition(value, 'top')
                                                        }
                                                        onEndTyping={this.saveContentItemPosition}
                                                        indentSize={'mini'}
                                                    />
                                                </div>
                                            )}
                                            {p_.selectedArea >= 0 && (
                                                <div className={settingsStyles.row}>
                                                    <EditText
                                                        label={translate('width')}
                                                        type="number"
                                                        containerClass={cn(settingsStyles.inputWrapper)}
                                                        value={s_.contentItemPosition.width}
                                                        onChange={(value) =>
                                                            this.changeContentItemPosition(value, 'width')
                                                        }
                                                        onEndTyping={this.saveContentItemPosition}
                                                        indentSize={'mini'}
                                                    />
                                                    <EditText
                                                        label={translate('height')}
                                                        type="number"
                                                        containerClass={cn(settingsStyles.inputWrapper)}
                                                        value={s_.contentItemPosition.height}
                                                        onChange={(value) =>
                                                            this.changeContentItemPosition(value, 'height')
                                                        }
                                                        onEndTyping={this.saveContentItemPosition}
                                                        indentSize={'mini'}
                                                    />
                                                </div>
                                            )}
                                            {isPdf && (
                                                <div className={settingsStyles.row}>
                                                    <EditText
                                                        label={translate('subItemDurationS')}
                                                        type="number"
                                                        containerClass={cn(settingsStyles.inputWrapper)}
                                                        value={s_.subItemDurationS}
                                                        onChange={(value) => {
                                                            this.changeSubItemDurationS(value)
                                                        }}
                                                        onEndTyping={this.saveSubItemDurationS}
                                                        indentSize={'mini'}
                                                    />
                                                    {pageCountCheck(p_.selectedContentItem) && (
                                                        <div className={cn(settingsStyles.inputWrapper)}>
                                                            <div className={styles.label}>
                                                                {translate('subItemsPageChoice')}
                                                            </div>
                                                            <Button
                                                                fullWidth={true}
                                                                indentSize="mini"
                                                                mod="fill"
                                                                onClick={() => this.openPdfPagesChoice()}
                                                                rounded={'full_md'}
                                                            >
                                                                {translate('choice')}
                                                            </Button>
                                                        </div>
                                                    )}
                                                    {s_.modalPdfPagesChoice && (
                                                        <ModalPdfChoice
                                                            onClose={this.closePdfPagesChoice}
                                                            content={p_.selectedContentItem}
                                                            apply={this.saveSubItems}
                                                        />
                                                    )}
                                                </div>
                                            )}
                                            {p_.selectedContentItem.volume !== null && (
                                                <VolumeSettings volume={p_.selectedContentItem.volume} />
                                            )}
                                        </div>
                                        {allowChangeStyleContentTypes.includes(
                                            p_.selectedContentItem.source.fileType
                                        ) && (
                                            <div>
                                                <div
                                                    className={cn(
                                                        settingsStyles.title,
                                                        settingsStyles.borderTop,
                                                        grid.mt_mdPlus
                                                    )}
                                                >
                                                    {translate('styles')}
                                                </div>
                                                <div className={cn(grid.pl_md, grid.pr_md, grid.mb_md)}>
                                                    <div className={cn(styles.label, textStyles.center)}>
                                                        {translate('opacity')}
                                                    </div>
                                                    <OpacitySettings opacity={s_.style.opacity} style={s_.style} />
                                                </div>
                                            </div>
                                        )}
                                        {actionsOnContent &&
                                            helpers.isAvailable({
                                                key: 'broadcastEventsAndActions',
                                                action: 'create',
                                            }) && (
                                                <EditorActions
                                                    content={p_.selectedContentItem}
                                                    pages={p_.pages}
                                                    currentPage={p_.page}
                                                />
                                            )}
                                    </Collapse>
                                </div>
                            )}
                            {isShowContentSettings && !p_.isSimple && !isSoundtrack && (
                                <ConditionSettings
                                    playingCondition={p_.selectedContentItem.playingCondition}
                                    onChange={(playingCondition) => {
                                        editor.setPlayingCondition(
                                            p_.selectedArea,
                                            p_.selectedContent,
                                            playingCondition
                                        )
                                    }}
                                />
                            )}
                            <div className={grid.space} />
                            {!p_.showAllAreas && (
                                <div>
                                    <div className={cn(grid.rowCenter, grid.w100, grid.p_md)}>
                                        <Button
                                            indentSize="mini"
                                            className={cn(grid.mr_mini)}
                                            mod="fill"
                                            onClick={function () {
                                                editor.selectContent(
                                                    p_.selectedArea,
                                                    p_.selectedContent,
                                                    false,
                                                    {},
                                                    p_.isMobile
                                                )
                                                if (p_.isMobile) {
                                                    editor.showToolbar(false)
                                                }
                                            }}
                                        >
                                            {translate('cancel')}
                                        </Button>
                                        <Button
                                            mod={'withBorder'}
                                            indentSize="mini"
                                            onClick={() => {
                                                if (isSoundtrack) {
                                                    editor.selectContent(
                                                        p_.selectedArea,
                                                        p_.selectedContent,
                                                        false,
                                                        {},
                                                        p_.isMobile
                                                    )
                                                } else {
                                                    const isUpdated = editor.updateAreaPosition(
                                                        p_.selectedArea,
                                                        this.areaSettings.getRelPosition()
                                                    )
                                                    editor.selectContent(
                                                        p_.selectedArea,
                                                        p_.selectedContent,
                                                        !isUpdated,
                                                        {},
                                                        p_.isMobile
                                                    )
                                                }

                                                if (p_.isMobile) {
                                                    editor.showToolbar(false)
                                                }
                                            }}
                                        >
                                            {translate('save')}
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    {p_.showSettings === 'broadcast' && (
                        <div className={cn(grid.col, grid.normalCenter, grid.spaceHeight, item.overY)}>
                            <BroadcastSettings {...p_.broadcastSettings} location={p_.location} />
                        </div>
                    )}
                    {p_.showSettings === 'duration' && (
                        <div className={cn(grid.col, grid.normalCenter, grid.spaceHeight, item.overY)}>
                            <DurationSettings
                                {...p_.durationSettings}
                                pages={p_.broadcastPages}
                                page={p_.page}
                                isSimple={p_.isSimple}
                            />
                        </div>
                    )}
                </div>
            </div>
        ) : null
    }
}

EditorToolbarRight.propTypes = {
    content: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    areas: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    selectedContent: PropTypes.number,
    selectedArea: PropTypes.number,
    resolutionWidth: PropTypes.number,
    resolutionHeight: PropTypes.number,
    showSettings: PropTypes.string,
    selectedContentItem: PropTypes.object,
    selectedTemplateItem: PropTypes.object,
    durationSettings: PropTypes.object,
    broadcastSettings: PropTypes.object,
    sources: PropTypes.object,
    page: PropTypes.object,
    pages: PropTypes.array,
    broadcastPages: PropTypes.array,
    onSelectContent: PropTypes.func,
    onSelectArea: PropTypes.func,
    minimized: PropTypes.bool,
    isSimple: PropTypes.bool,
    showAllAreas: PropTypes.bool,
    areaViewType: PropTypes.string,
}

export default connect((state) => {
    return {
        isAddText: state.files.isAddText,
        isAddTable: state.files.isAddTable,
        isAddLink: state.files.isAddLink,
    }
})(withRouter(EditorToolbarRight))

function OpacitySettings(p_) {
    const [opacity, setOpacity] = useState(p_.opacity)

    useEffect(() => {
        setOpacity(p_.opacity)
    }, [p_.opacity])

    function onChange(value) {
        setOpacity(value)
    }

    function onAfterChange(value) {
        const styles = {
            ...p_.style,
            opacity: value,
        }

        editor.updateContentStyle(styles)
    }

    return (
        <Tippy title={`${opacity}`}>
            <Range min={0} max={1} step={0.1} value={opacity} onChange={onChange} onAfterChange={onAfterChange} />
        </Tippy>
    )
}

function VolumeSettings(p_) {
    const [volume, setVolume] = useState(p_.volume)

    useEffect(() => {
        setVolume(p_.volume)
    }, [p_.volume])

    function onChange(value) {
        setVolume(value)
    }

    function onAfterChange(value) {
        if (p_.type === 'soundtrack') {
            editor.updateSoundtrackVolume(value)
            return
        }

        editor.updateVolume(value)
    }

    return (
        <div className={styles.volumeWrapper}>
            <div className={styles.volume}>
                <RangeVolume volume={volume} onChange={onChange} onAfterChange={onAfterChange} />
            </div>
        </div>
    )
}

function ModalPdfChoice({ onClose, content, apply }) {
    const [pagesList, setPagesList] = useState(getPageItems(content))

    const closeAndApply = () => {
        const filtered = pagesList.filter((element) => element.selected).map((element) => element.id)

        apply(filtered)
        onClose()
    }

    return (
        <Modal open={true} mod={'white'} showCloseIcon={false}>
            <div className={styles.modalContent}>
                <div className={styles.modalInner}>
                    <div className={styles.inner}>
                        <div className={styles.pagesList}>
                            {pagesList.map((page) => {
                                return (
                                    <div className={`${styles.pageItem} itemMargin`} key={page.id}>
                                        <div
                                            className={`${styles.itemSelector} ${page.selected ? styles.selected : ''}`}
                                            onClick={() => {
                                                setPagesList(
                                                    pagesList.map((item) => {
                                                        if (item.id === page.id) {
                                                            item.selected = !item.selected
                                                        }
                                                        return item
                                                    })
                                                )
                                            }}
                                        >
                                            <Icon type="checked" color={'white'} className={styles.checkedIcon} />
                                        </div>
                                        <div className={styles.pageName}>
                                            {page.id + 1} {translate('page')}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className={styles.footer}>
                    <div className={cn(grid.row, grid.center)}>
                        <Button rounded="full_md" onClick={() => onClose()}>
                            {translate('cancel')}
                        </Button>
                        <Button
                            rounded="full_md"
                            className={cn(grid)}
                            mod="fill"
                            onClick={closeAndApply}
                            disabled={!pagesList.filter((page) => page.selected).length > 0}
                        >
                            {translate('apply')}
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

const getPageItems = (content) => {
    const subItems = content.subItems
    const pageCount = content.source.data.pageCount
    let items = []

    for (let i = 0; i < pageCount; i++) {
        items.push({
            id: i,
            selected: subItems ? subItems.includes(i) : true,
        })
    }

    return items
}

const pageCountCheck = (content) => {
    if (content) {
        if (content.source) {
            if (content.source.data) {
                if (content.source.data.pageCount) {
                    return true
                }
            }
        }
    }

    return false
}

VolumeSettings.propTypes = {
    type: PropTypes.string,
    volume: PropTypes.number,
}

VolumeSettings.defaultProps = {
    volume: 0,
}

const mapDispatchToProps = (dispatch) => {
    return {
        emitError: (err) => dispatch(emitError(err)),
    }
}

export const EditorVolumeSettings = connect(null, mapDispatchToProps)(VolumeSettings)
