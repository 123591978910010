import React, { useEffect, useState } from 'react'
import { api } from 'core/api/ConnectionManager'
import moment from 'moment'
import helpersSchedule from 'core/helpers/_schedule'
import helpers from 'core/helpers'
import { routes } from 'features/routes'
// @ts-ignore
import omit from 'omit-empty'
import { changeLocation } from 'features/routes'
import { Device } from '../../pages/schedule/_add/schedule_add.local.methods'

export interface IAddScheduleProps {
    onChangeDevices: any
    onChangeBroadcast?: any
    onGetConflicts?: any
    emitError?: (err: string) => void
    devices: Device[]
    schedule: any
    broadcast: any
    deferredDownload: { date: string | null; time: string | null }
    onSave?: (value: boolean) => void
    onChangeSchedule: any
    changeLocationOnSave: () => void
    onChangeDeferredDownload: (value: string, field: string) => void
    advDuration: {} | null
    advContentLength: number | null
    hideConflicts?: boolean
    ref?: any
    addScheduleRef?: any
}

const AddScheduleMethods = (p_: IAddScheduleProps) => {
    const [state, setState] = useState<any>({
        tabs: getTabs(),
        selectedTab: 'devices',
        groups: [],
        showConflictsModal: false,
        schedules: [],
        solutions: {},
        conflicts: [],
        applyConflictsSolution: false,
        selectedSolution: 'replace',
        dataToSave: null,
        showWarningWindow: false,
        saveOptions: null,
    })

    function getTabs() {
        const tabs = []

        if (p_.onChangeDevices) {
            tabs.push('devices')
        }
        if (p_.onChangeBroadcast) {
            tabs.push('broadcasts')
        }

        return tabs
    }

    const closeWarningWindow = () => {
        setState((prevState: any) => {
            return {
                ...prevState,
                dataToSave: null,
                showWarningWindow: false,
            }
        })
    }

    const saveDataAndClose = () => {
        const { saveMethod, dataToSave, onSave, cb } = state.dataToSave

        api.send(saveMethod, dataToSave).then((res) => {
            if (res) {
                onSave()
                cb(true)
                closeWarningWindow()
            }
        })
    }

    const validateDevices = (cb: any, tryToSolveConflicts: boolean) => {
        const s_ = state

        const devices = removeDeviceFromConflicts(p_.devices)

        if (devices.length === 0) {
            const tabs = s_.tabs.filter((tab: string) => tab !== 'restrictions')

            if (p_.onGetConflicts) {
                p_.onGetConflicts([])
            }
            setState((prevState: any) => {
                return {
                    ...prevState,
                    conflicts: [],
                    tabs,
                    selectedTab: 'devices',
                }
            })

            if (tryToSolveConflicts) {
                if (p_.emitError) {
                    p_.emitError('onSolveConflictsAllDevicesRemoved')
                }
            } else {
                if (p_.emitError) {
                    p_.emitError('devicesEmpty')
                }
            }
            cb(false)
            return
        }
        const groupId: number[] = []
        const digitalSignageId: number[] = []
        const groupModel: number[] = []

        p_.devices.forEach((device: any) => {
            if (device.type === 'group') {
                groupId.push(device.id)
                groupModel.push(device)
            } else {
                digitalSignageId.push(device.id)
            }
        })

        setState((prevState: any) => {
            return {
                ...prevState,
                groups: groupId,
            }
        })

        api.send('getDisplaysFromGroups', { groupId, digitalSignageId }).then((devices: any) => {
            const devicesList = devices.map((device: { type: string }) => {
                device.type = 'digitalSignage'

                return device
            })

            removeDeviceFromConflicts(devicesList, groupModel)

            cb(true)
        })
    }
    const removeDeviceFromConflicts = (devices: Device[], groups: any = []) => {
        const s_ = state

        if (s_.conflicts.length && s_.applyConflictsSolution) {
            const devicesIdToDelete = s_.conflicts.map((conflict: any) => conflict.obj.id)
            const filteredDevices = devices.filter((device) => !devicesIdToDelete.includes(device.id))

            if (p_.onChangeDevices) {
                p_.onChangeDevices([...filteredDevices, ...groups])
            }

            return filteredDevices
        }

        if (p_.onChangeDevices) {
            p_.onChangeDevices([...devices, ...groups])
        }

        return devices
    }
    const validateSchedule = (cb: any) => {
        let isValid = true

        const startTime = moment(p_.schedule.startTime, 'HH:mm:ss').unix()
        const endTime = moment(p_.schedule.endTime, 'HH:mm:ss').unix()

        if (endTime < startTime) {
            isValid = false
            if (p_.emitError) {
                p_.emitError('endTimeLessStartTime')
            }
        }

        if (!p_.schedule.repeatDays.length) {
            if (p_.schedule.repeatMode === 'weekly') {
                isValid = false
                if (p_.emitError) {
                    p_.emitError('repeatDaysEmpty')
                }
            }
            if (p_.schedule.repeatMode === 'exact_dates') {
                isValid = false
                if (p_.emitError) {
                    p_.emitError('selectAtLeastOneDate')
                }
            }
        }

        cb(isValid)
    }
    const validateBroadcast = (cb: any) => {
        const isValid = p_.broadcast
        if (!isValid) {
            if (p_.emitError) {
                p_.emitError(p_.onChangeBroadcast ? 'selectBroadcastsEmpty' : 'createBroadcastError')
            }
        }

        cb(isValid)
    }
    const validateDeferredDownload = (cb: any) => {
        let isValid = true

        if (p_.deferredDownload) {
            if (!p_.deferredDownload.date && p_.deferredDownload.time) {
                if (p_.emitError) {
                    p_.emitError('fillDeferredDate')
                }
                isValid = false
            }

            if (p_.deferredDownload.date && !p_.deferredDownload.time) {
                if (p_.emitError) {
                    p_.emitError('fillDeferredTime')
                }
                isValid = false
            }

            if (p_.deferredDownload.date && p_.deferredDownload.time && isValid) {
                const date = moment(p_.deferredDownload.date).format('YYYY-MM-DD')
                const contentDownloadDateTime = moment(`${date} ${p_.deferredDownload.time}`)
                const startDateTime = moment(
                    `${moment(p_.schedule.startDate).format('YYYY-MM-DD')} ${p_.schedule.startTime}`
                )

                if (startDateTime.isBefore(contentDownloadDateTime)) {
                    if (p_.emitError) {
                        p_.emitError('contentDownloadCantBeLongestStartDateTime')
                    }
                    isValid = false
                }
            }
        }

        cb(isValid)
    }

    useEffect(() => {
        if (state.saveOptions) {
            saveMethod(state.saveOptions.cb, state.saveOptions.options)
            setState((prevState: any) => {
                return {
                    ...prevState,
                    saveOptions: null,
                }
            })
        }
    }, [state.saveOptions])
    const save = (cb: any, options: any = {}) => {
        validateBroadcast((isBroadcastValid: boolean) => {
            if (isBroadcastValid) {
                validateDevices((isValidDevices: boolean) => {
                    if (isValidDevices) {
                        validateSchedule((isValidSchedule: boolean) => {
                            if (isValidSchedule) {
                                validateDeferredDownload((isValidDeferred: boolean) => {
                                    if (isValidDeferred) {
                                        setState((prevState: any) => {
                                            return {
                                                ...prevState,
                                                saveOptions: {
                                                    cb,
                                                    options,
                                                },
                                            }
                                        })
                                    } else {
                                        cb(false)
                                    }
                                })
                            } else {
                                cb(false)
                            }
                        })
                    } else {
                        cb(false, 'devices')
                    }
                }, options.tryToSolveConflicts)
            } else {
                cb(false)
            }
        })
    }
    const saveMethod = (cb: any, options: any = {}) => {
        const s_ = state

        const onSave = options.onSave ? options.onSave : () => {}
        const devices: Device[] = p_.devices.filter((device: Device) => device.type !== 'group')
        const schedule = helpersSchedule.getSchedule(p_.schedule, devices)
        const dataToSave: any = {
            ...schedule,
            digitalSignageId: devices.map((device) => device.id),
            groupId: s_.groups,
        }

        if (!s_.groups.length) {
            delete dataToSave.groupId
        }
        if (p_.schedule.id) {
            dataToSave.id = p_.schedule.id
        }
        if (p_.broadcast.id) {
            dataToSave.broadcastId = p_.broadcast.id
        } else {
            dataToSave.broadcast = p_.broadcast
        }
        if (dataToSave.repeatMode === 'weekly') {
            if (dataToSave.timeToUse !== 'local') {
                const startDate = moment
                    .utc(`${dataToSave.startDate} ${dataToSave.startTime}`, 'YYYY-MM-DD HH:mm:ss')
                    .format()
                dataToSave.repeatDays = helpersSchedule.formatRepeatDays(dataToSave.repeatDays, startDate)
            } else {
                dataToSave.repeatDays = dataToSave.localRepeatDays
            }
        }
        if (dataToSave.repeatMode === 'exact_dates') {
            dataToSave.startDate = dataToSave.repeatDays[0].startDate
            dataToSave.localStartDate = dataToSave.localRepeatDays[0].startDate

            if (dataToSave.repeatDays.length === 1) {
                dataToSave.endDate = dataToSave.startDate
                dataToSave.localEndDate = dataToSave.localStartDate
            } else {
                dataToSave.endDate = dataToSave.repeatDays[dataToSave.repeatDays.length - 1].endDate
                dataToSave.localEndDate = dataToSave.localRepeatDays[dataToSave.repeatDays.length - 1].endDate
            }
        }

        if (p_.deferredDownload) {
            const dateTime = omit(p_.deferredDownload)
            const date: any = p_.deferredDownload.date

            if (Object.keys(dateTime).length) {
                dataToSave.localContentDownloadDateTime = moment(
                    `${moment(date).format('YYYY-MM-DD')} ${p_.deferredDownload.time}`
                ).format('YYYY-MM-DD HH:mm:ss')
                dataToSave.contentDownloadDateTime = moment(
                    `${moment(date).format('YYYY-MM-DD')} ${p_.deferredDownload.time}`
                )
                    .utc()
                    .toISOString()
            }
        }

        if (!options.withoutValidate) {
            api.send('getBroadcastRestrictionsForSchedule', dataToSave).then((res: any) => {
                if (!res.isValid) {
                    let tabs = s_.tabs
                    const restrictionsTab = 'restrictions'

                    if (!tabs.includes(restrictionsTab)) {
                        tabs.push(restrictionsTab)
                    }
                    p_.onGetConflicts(res.conflicts)

                    setState((prevState: any) => {
                        return {
                            ...prevState,
                            tabs,
                            selectedTab: restrictionsTab,
                            conflicts: res.conflicts,
                        }
                    })

                    return
                }

                api.send('getOverlappingSchedules', { ...dataToSave })
                    .then((res: any) => {
                        if (!res.schedules.length) {
                            const saveMethod = dataToSave.id ? 'updateSchedule' : 'createSchedule'

                            if (saveMethod === 'createSchedule') {
                                return api.send(saveMethod, dataToSave)
                            }

                            setState((prevState: any) => {
                                return {
                                    ...prevState,
                                    dataToSave: {
                                        saveMethod,
                                        dataToSave,
                                        onSave,
                                        cb,
                                    },
                                    showWarningWindow: true,
                                }
                            })

                            return
                        }

                        setState((prevState: any) => {
                            return {
                                ...prevState,
                                showConflictsModal: true,
                                schedules: res.schedules,
                                solutions: res.solutions,
                            }
                        })
                        cb(false)
                    })
                    .then((res) => {
                        if (res) {
                            onSave()
                            cb(true)
                        }
                    })
            })
        }
        if (options.withoutValidate) {
            const saveMethod = dataToSave.id ? 'updateSchedule' : 'createSchedule'
            api.send(saveMethod, dataToSave).then((res) => {
                if (res) {
                    onSave()
                    cb(true)
                }
            })
        }
    }
    const closeConflictsModal = () => {
        setState((prevState: any) => {
            return {
                ...prevState,
                showConflictsModal: false,
                schedules: [],
                solutions: {},
            }
        })
    }
    const solveConflicts = () => {
        setState((prevState: any) => {
            return {
                ...prevState,
                showConflictsModal: false,
                schedules: [],
                solutions: {},
                applyConflictsSolution: true,
            }
        })
        setTimeout(() => {
            save(
                (isValid: boolean) => {
                    if (isValid) {
                        changeLocation(`/${routes.schedule.path}`)
                    }
                },
                { onSave: () => (p_.onSave ? p_.onSave(true) : () => {}), tryToSolveConflicts: true }
            )
        }, 100)
    }
    const saveWithSolution = () => {
        const s_ = state
        let data: any = {
            ...p_.schedule,
        }

        if (s_.selectedSolution === 'placeAfter') {
            const scheduleItem = s_.solutions.placeAfter
            const startDate = helpers.getLocalDate({ date: scheduleItem.startDate, time: scheduleItem.startTime })
            const endDate = helpers.getLocalDate({ date: scheduleItem.endDate, time: scheduleItem.endTime })

            data = {
                ...data,
                startDate,
                endDate,
                startTime: startDate!.format('HH:mm:ss'),
                endTime: endDate!.format('HH:mm:ss'),
            }
        }

        if (s_.selectedSolution === 'replace') {
            data.removeOverlappingSchedules = true
        }

        p_.onChangeSchedule(data)
        setState((prevState: any) => {
            return {
                ...prevState,
                showConflictsModal: false,
            }
        })
        setTimeout(() => {
            save(
                () => {
                    if (p_.changeLocationOnSave) {
                        p_.changeLocationOnSave()
                        return
                    }

                    changeLocation(`/${routes.schedule.path}`)
                },
                { withoutValidate: true, onSave: () => (p_.onSave ? p_.onSave(true) : () => {}) }
            )
        }, 100)
    }

    useEffect(() => {
        if (p_.addScheduleRef) {
            p_.addScheduleRef.current = { validateSchedule, validateDevices, save }
        }
    }, [p_])

    return {
        state,
        setState,
        solveConflicts,
        closeWarningWindow,
        saveDataAndClose,
        closeConflictsModal,
        saveWithSolution,
    }
}

export default AddScheduleMethods
