import { addStyles } from 'ethcss'
import { grid, IStyles, item, text, mediaCSS } from 'theme'
import { defaultThemeStyles } from '../../../blocks.app/content/content.theme'
import { cols, layers } from 'blocks.app/config'
import { colors } from '../../../theme'

const rolesStyles: IStyles = {
    createRoleContentWrapper: {
        ...grid.col,
        ...grid.space,
        ...item.overNone,
    },
    rolesAcceptDialogText: {
        ...grid.m_mdPlus,
        ...text.up,
        ...text.center,
        letterSpacing: '0.1em',
    },
    toolbar: {
        ...grid.fullHeight,
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        borderLeft: `1px solid var(--var-module-app-component-contentBorder-style-background, ${defaultThemeStyles.background})`,
        background: colors.white,
        ...grid.col,
        width: `${cols.normal}%`,
        overflowY: 'auto',
        zIndex: layers.sticky + 1,
        [mediaCSS.tab]: {
            ...item.transitionErase,
            zIndex: 4999,
            ...grid.w50,
            maxWidth: 0,
        },
    },
    toolbarInner: {
        textAlign: 'center',
    },
    toolbarUserName: {
        fontSize: '18px',
        fontWeight: 'bold',
        marginTop: '24px',
        color: colors.blackLight,
    },
    toolbarRoleName: {
        fontSize: '18px',
        fontWeight: 'bold',
        marginTop: '24px',
        color: colors.blackLight,
    },
    toolbarRoleIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.blackLight,
        color: colors.white,
        fontSize: '144px',
        width: '196px',
        height: '196px',
        margin: '45px auto 36px auto',
        borderRadius: '5px',
    },
    toolbarUserAvatar: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.blackLight,
        width: '196px',
        height: '196px',
        margin: '45px auto 36px auto',
        borderRadius: '5px',
    },
    toolbarPhotoWrapper: {
        position: 'relative',
        margin: '0 auto',
        width: '72%',
        height: '72%',
        borderRadius: '50%',
    },
    noUserPhoto: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    menuItem: {
        ...grid.p_mini,
        cursor: 'pointer',
        ...item.transitionErase,
        textAlign: 'center',
        color: `var(--var-module-sidebar-component-sidebar-style-menuTextHover)`,
        display: 'block',
        textDecoration: 'none',
        marginTop: '4px',
        '&:hover': {
            color: `var(--var-module-sidebar-component-sidebar-style-menuText)`,
        },
    },
    sidebarTopListItem: {
        ...grid.space,
        width: 0,
        overflow: 'hidden',
        cursor: 'pointer',
        lineHeight: 1.2,
    },
    sidebarTopListItemWrapper: {
        padding: '6px 0',
    },
    listItemBlock: {
        marginTop: '30px',
    },
    divider: {
        margin: '30px 0',
        borderBottom: `1px solid var(--var-module-app-component-contentBorder-style-background, ${defaultThemeStyles.background})`,
    },
    contentStyles: {
        [mediaCSS.tab]: {
            paddingTop: 50,
        },
    },
    catalogStyles: {
        padding: '0 30px',

        [mediaCSS.mob]: {
            padding: '0 10px',
        },
    },
    showToolbar: {
        [mediaCSS.tab]: {
            maxWidth: '50%!important',
            ...item.transitionErase,
        },
    },
    toolbarMargin: {
        marginTop: '66px',
        [mediaCSS.tab]: {
            marginTop: 0,
        },
    },
    closeBtn: {
        position: 'absolute',
        bottom: '10px',
        left: '50%',
        transform: 'translateX(-50%)',

        [mediaCSS.tab]: {
            bottom: 40,
        },
    },
    closeBtn_x: {
        position: 'absolute',
        bottom: '70px',
        left: '50%',
        transform: 'translateX(-50%)',
    },
}

addStyles(rolesStyles)
export default rolesStyles
