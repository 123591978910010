import React from 'react'
import Content from 'blocks.app/content/content'
import Icon from 'blocks.simple/icon/icon'
import SettingsDeviceTileView from './__deviceTileView/settings__deviceTileView'
import SettingsMenuView from './__menuView/settings__menuView'
import { SettingsEmails } from './__emails/settings__emails'
import { AccountsSettings } from './__accounts'
import Lang from './__lang/settings__lang'
import SettingsFonts from './__fonts/settings__fonts'
import FilterList from './__filterList/settings__filterList'
import SettingsContentDuration from './__contentDuration/settings__contentDuration'
import SettingsPlaceholder from './__placeholder/settings__placeholder'
import { InactivitySettings } from './__inactivity'
import { RootStateOrAny, useSelector } from 'react-redux'
import translate from 'core/translate'
import styles from './settings.jcss'
import SettingsTheme from './__theme/settings__theme'
import { Typography } from 'atoms/Typography'
import { AuthenticationSettings } from './__authentication'
import { useWindowResize } from 'core/hooks'
import SettingsMainMenuTheme from './__mainMenuTheme/settings__mainMenuTheme'
import { DisplayOfWarnings } from './__display_warnings/DisplayOfWarningsSettings-view'
import { changeLocation } from '../../features/routes'
import { Link } from 'react-router-dom'
import { RemoteSupport } from './__remoteSupport/RemoteSupport'
import DownloadVideoOptions from './__downloadVideoOptions/settings___downloadVideoOptions'

declare var window: any

const Settings = () => {
    const backEndVersion = useSelector<RootStateOrAny>((state) => state.app.backEndVersion)
    const user: any = useSelector<RootStateOrAny>((state) => state.user.data)
    const company: any = useSelector<RootStateOrAny>((state) => state.user.company)
    const isSettingsEnable = () => user && user.settings && company
    const { width } = useWindowResize({ width: window.innerWidth, height: window.innerHeight })

    const mouseDownHandler = (event: React.MouseEvent<HTMLAnchorElement>) => {
        if (event.button === 0) {
            changeLocation('about')
        }
    }

    const renderFooter = () => {
        const isDocumentationShow =
            window.spconfig && window.spconfig.documentation_url && !window.spconfig.hideDocumentationRef

        return (
            <React.Fragment>
                {isDocumentationShow && (
                    <Typography type="title" className={styles.version}>
                        <a
                            href={window.spconfig.documentation_url}
                            className={styles.documentationLink}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {translate('documentation')}
                            <Icon type={'external_link'} containerClass={styles.documentationIcon} />
                        </a>
                    </Typography>
                )}
                <Typography type="title" className={styles.version}>
                    <Link
                        onMouseDown={mouseDownHandler}
                        className={styles.softwareLink}
                        rel="noopener noreferrer"
                        target="_blank"
                        to={'/about'}
                    >
                        {translate('aboutSoftware')}
                    </Link>
                </Typography>
            </React.Fragment>
        )
    }

    const renderSettings = () => {
        if (!isSettingsEnable()) return null

        const { settings, emailConfirmedQ } = user
        const { locale, inactivityTimeoutS, theme, twoFactorAuth, mainMenuTheme, notificationsEmail } = settings
        const { placeholderSource, applicationUrls, remoteSupport } = company

        return (
            <>
                <SettingsTheme theme={theme} />
                <SettingsMainMenuTheme mainMenuTheme={mainMenuTheme} />
                <InactivitySettings inactivityTimeoutS={inactivityTimeoutS} lang={locale} />
                <SettingsDeviceTileView />
                {width > 960 && <SettingsMenuView />}
                {/*<SettingsUrls urls={applicationUrls} />*/}
                <AuthenticationSettings
                    twoFactorAuth={twoFactorAuth}
                    isEmailConfirmed={emailConfirmedQ}
                    lang={locale}
                />
                <SettingsEmails notificationsEmail={notificationsEmail} />
                <AccountsSettings />
                <SettingsPlaceholder source={placeholderSource} />
                <Lang lang={locale} />
                <SettingsFonts />
                <FilterList />
                <RemoteSupport remoteSupport={remoteSupport} />
                <DisplayOfWarnings />
                <DownloadVideoOptions />
                <SettingsContentDuration />
            </>
        )
    }

    return (
        <Content title={translate('settings')} wrapperClassName={styles.settingsWrapper}>
            {renderSettings()}
            <div className={styles.space} />
            {renderFooter()}
        </Content>
    )
}

export default Settings
