import React from 'react'
import DisplaysMethods from './displays.local.methods'
import Content from 'blocks.app/content/content'
import Modal from 'blocks.simple/modal/modal'
import Catalog from 'blocks.app/catalog/catalog'
import DisplaysToolbar from './__toolbar/displays__toolbar'
import { connect } from 'react-redux'
import translate from 'core/translate'
import helpers from 'core/helpers'
import helpersDisplays from 'core/helpers/_digitalSignages'
import { cn } from 'ethcss'
import styles from './displays.jcss'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import { displaysActions, downloadLogsLink, getDisplaysWithoutAddress, openApprove } from './displays.state'
import { getParamsForLink } from 'core/helpers/user'
import { isHorizontalMenu } from '../../core/helpers/menu'
import { withWindowResize } from 'core/hocs'
import { checkAvailableModule } from '../../core/helpers/routes/routes'
import { TagShortModel } from '../../core/models/Tags'
import { IDevice } from '../../core/models/DigitalSignage'

class Displays extends DisplaysMethods {
    render() {
        const p_ = this.props
        const s_ = this.state
        const params = getParamsForLink(p_.tokens, p_.mainUser.id, p_.user.id)
        const downloadLink =
            helpersDisplays.getDownloadLink(s_.selectedInfo ? [s_.selectedInfo] : s_.selectedItems, params) || ''
        const query = getURLSearchParamsByLocation(p_.location)
        const parsedQuery = helpers.getQuery(query, 'displays')
        const disposition = this.getDisposition()
        const groupId = parsedQuery.groupId ? parseInt(parsedQuery.groupId, 10) : null

        return (
            <Content
                title={translate('devices')}
                className={p_.className}
                toolbar={
                    <DisplaysToolbar
                        query={query}
                        selectedInfo={s_.selectedInfo}
                        selectedItems={s_.selectedItems}
                        groups={p_.groups}
                        groupId={groupId}
                        onSelectInfo={this.onSelectInfo}
                        onCloseInfo={() => this.setState({ selectedInfo: null })}
                        onChange={this.onSelectItems}
                        onGetFoldersOnMultiple={this.onGetFoldersOnMultiple}
                        downloadLink={downloadLink}
                    />
                }
                toolbarClassName={styles.toolbar}
                indentsMod={disposition === 'map' ? 'none' : null}
            >
                <Catalog
                    key={'displays__catalog'}
                    type={'displays'}
                    disposition={disposition}
                    selectedInfo={s_.selectedInfo}
                    onSelectInfo={this.onSelectInfo}
                    selectedItems={s_.selectedItems}
                    onSelect={this.onSelect}
                    onDoubleClickObject={this.onDoubleClickObject}
                    onGetTags={this.onGetTags}
                    breadcrumbsClassName={cn(styles.breadcrumbs, {
                        [styles.breadcrumbs_x]: isHorizontalMenu() && p_.windowWidth > 960,
                    })}
                    mapSelect={this.mapSelect}
                    onChange={this.onCatalogChange}
                    mapUnselect={this.onMapUnselect}
                    fixedHeader
                    wrapperClassName={styles.displaysWrapper}
                />
                <Modal
                    open={p_.modalState}
                    onClose={function () {
                        p_.closeModal()
                    }}
                    {...p_.modalOptions}
                    mod={this.modalOptions.style}
                    closeIconStyles={p_.modalState === 'approve' ? this.modalOptions.closeIconStyles : null}
                    showCloseIcon={this.modalOptions.showCloseIcon}
                >
                    {this.renderModalContent()}
                </Modal>
            </Content>
        )
    }
}

const mapStateToProps = (state: any) => ({
    modalState: state.displays.modalState,
    modalOptions: state.displays.modalOptions,
    tags: state.displays.tags,
    groups: state.displays.groups,
    minimize: state.app.minimizeMenu,
    tokens: state.user.tokens,
    user: state.user.data,
    mainUser: state.user.mainUser,
    isConnected: state.displays.isConnected,
})

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeModal: () => dispatch(displaysActions.closeModal()),
        getDisplaysWithoutAddress: () => dispatch(getDisplaysWithoutAddress()),
        setTags: (tags: TagShortModel[]) => dispatch(displaysActions.setTags(tags)),
        setGroups: (tags: IDevice[]) => dispatch(displaysActions.setGroups(tags)),
        clearMap: () => dispatch(displaysActions.clearMap()),
        clearState: () => dispatch(displaysActions.clearState()),
        downloadLogsLink: (res: any) => dispatch(downloadLogsLink(res)),
        openApprove: (code: string) => dispatch(openApprove(code)),
    }
}

const exportDisplays = checkAvailableModule('displays')
    ? connect(mapStateToProps, mapDispatchToProps)(withWindowResize(Displays))
    : null

export default exportDisplays
