import React, { SyntheticEvent } from 'react'
import ScheduleMethods from './schedule.local.methods'
import Content from 'blocks.app/content/content'
import ScheduleToolbar from './__toolbar/schedule__toolbar'
import Search from './__search/schedule__search'
import { Calendar } from 'blocks.app/calendar/calendar'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import Modal from 'blocks.simple/modal/modal'
import SearchbarFilterList from 'blocks.app/searchbar/_filterList/searchbar_filterList'
import ScheduleSelect from 'pages/schedule/__select/schedule__select'
import { connect } from 'react-redux'
import moment from 'moment'
import styles from './schedule.jcss'
import { grid } from '../../theme'
import { cn } from 'ethcss'
import translate from 'core/translate'
import { transitions } from 'blocks.app/config'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import { changeFilter } from 'features/routes'
import { UpdateUserSettings, updateUserSettings } from 'blocks.app/user/user.state'
import { getSchedules, scheduleActions, ScheduleEvent } from './schedule.state'
import { headActions } from 'features/head/Head.state'
import { checkAvailableModule } from '../../core/helpers/routes/routes'

type BroadcastItem = {
    id: number
    title: string
    broadcastTitle: string
    start: number
    end: number
    endDate: number
    timeTitle: string
    type: string
}

const EventWrapper = connect(
    (state: any) => ({
        selectedEventId: state.schedule.selectedEventId,
    }),
    (dispatch, ownProps) => {
        return {
            navigate: (date: number) => dispatch(scheduleActions.navigate(date)),
            setSelectedEventId: (id: string) => dispatch(scheduleActions.setSelectedEventId(id)),
        }
    }
)((p_: any) => {
    const onSelect = () => {
        p_.setSelectedEventId(p_.event.id)
        p_.navigate(moment(p_.event.start).toDate().getTime())
    }

    return (
        <div
            className={cn(
                'rbc-event',
                {
                    'rbc-selected': p_.event.id === p_.selectedEventId,
                },
                'rbc-event-content-overflow'
            )}
            style={{ ...p_.children.props.style }}
            onClick={onSelect}
        >
            <div className="rbc-event-content" title={p_.event.title}>
                {p_.event.title}
            </div>
        </div>
    )
})

const DateCellWrapper = connect(
    (state: any) => ({
        selectedDate: state.schedule.selectedDate,
        eventsByDate: state.schedule.eventsByDate,
        selectedDates: state.schedule.selectedDates,
        view: state.user.data.settings.view.schedule,
    }),
    (dispatch, ownProps) => {
        return {
            navigate: (date: number) => dispatch(scheduleActions.navigate(date)),
            setSelectedEventId: (id: string) => dispatch(scheduleActions.setSelectedEventId(id)),
            selectDate: (selectDate: { checked: boolean; events: ScheduleEvent[] }) =>
                dispatch(scheduleActions.selectDate(selectDate)),
        }
    }
)((p_: any) => {
    const selectedDateStr = moment(p_.selectedDate).format('YYYY-MM-DD')
    const valueDateStr = moment(p_.value).format('YYYY-MM-DD')
    const allowSelect = p_.eventsByDate[valueDateStr]
    const selectedDate = allowSelect
        ? p_.selectedDates.filter((date: { id: number }) =>
              allowSelect.map((item: { id: number }) => item.id).includes(date.id)
          ).length > 0
        : false

    const onClick = (checked: boolean) => {
        p_.selectDate({
            checked,
            events: p_.eventsByDate[valueDateStr],
        })
    }

    const onSelect = () => {
        p_.setSelectedEventId(null)
        p_.navigate(moment(p_.value).toDate().getTime())
    }

    return (
        <div
            className={cn('rbc-day-bg', {
                'rbc-selectedDate': selectedDateStr === valueDateStr,
            })}
            style={{ flexBasis: '14.2857%', maxWidth: '14.2857%' }}
            onClick={onSelect}
        >
            {allowSelect && (
                <div className={cn(p_.view === 'month' ? styles.checkboxWrapper : styles.fullBlockCheckboxWrapper)}>
                    <div />
                    <Checkbox
                        onClick={onClick}
                        animate={false}
                        checked={selectedDate}
                        className={styles.checkbox}
                        style={styles.wrapperClassName}
                    />
                </div>
            )}
        </div>
    )
})

const Schedule = (p_: any) => {
    const {
        openModal,
        selectedItems,
        setOpenModal,
        getDisposition,
        onViewChange,
        removeFilterItem,
        getCalendarDate,
        getCalendarEvents,
        onToday,
        onNavigate,
        setScheduleData,
    } = ScheduleMethods(p_)

    const query = getURLSearchParamsByLocation(p_.location)
    const disposition = getDisposition()

    return (
        <Content
            className={styles.wrapper}
            wrapperClassName={styles.scheduleWrapper}
            toolbar={
                <ScheduleToolbar
                    onChange={(list: BroadcastItem[]) => {
                        p_.setSelectedDates(list)
                    }}
                    cancelAll={() => p_.setSelectedDates([])}
                />
            }
        >
            <div
                className={cn(grid.w100)}
                onClick={() => {
                    setOpenModal(true)
                }}
            >
                <Search
                    {...p_}
                    query={query}
                    placeholder={translate('chooseOrFindDisplayOrBroadcast')}
                    onChange={function (name: string) {
                        changeFilter({ name, id: '', type: '' })
                    }}
                    onClickView={(e: SyntheticEvent) => e.stopPropagation()}
                    viewValue={disposition}
                    onViewChange={onViewChange}
                />
            </div>
            {p_.filterList.length > 0 && (
                <div className={grid.pt_mini}>
                    <SearchbarFilterList
                        onRemoveFilterItem={removeFilterItem}
                        topIndent={'none'}
                        filterList={p_.filterList}
                    />
                </div>
            )}
            <div className={cn(grid.row, grid.normalCenter, grid.justify, grid.mt_mini, grid.mb_mini)}>
                <div />
                <Checkbox
                    checked={p_.showPastSchedule}
                    onClick={(showPastSchedule: boolean) => {
                        p_.setShowPastSchedule(showPastSchedule)
                        p_.filterSchedules()
                    }}
                    label={translate('showPastSchedule')}
                />
            </div>
            <div className={cn(grid.space, grid.col)}>
                <Calendar
                    components={{
                        eventWrapper: EventWrapper,
                        dateCellWrapper: DateCellWrapper,
                    }}
                    //@ts-ignore
                    date={getCalendarDate()}
                    events={getCalendarEvents()}
                    view={disposition}
                    onToday={onToday}
                    navigate={onNavigate}
                />
            </div>

            <Modal
                open={openModal}
                onClose={() => {
                    setOpenModal(false)
                }}
            >
                <ScheduleSelect
                    selectedItems={selectedItems}
                    onShowSchedule={(scheduleData: string) => {
                        setOpenModal(false)
                        setTimeout(() => {
                            setScheduleData(scheduleData)
                        }, transitions.normal)
                    }}
                />
            </Modal>
        </Content>
    )
}

const mapStateToProps = (state: any) => {
    return {
        showPastSchedule: state.schedule.showPastSchedule,
        filterList: state.schedule.filterList,
        selectedDate: state.schedule.selectedDate,
        events: state.schedule.events,
        eventsByDate: state.schedule.eventsByDate,
        user: state.user.data,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setTitle: (title: string[]) => dispatch(headActions.setTitle(title)),
        updateUserSettings: (userSettings: UpdateUserSettings) => dispatch(updateUserSettings(userSettings)),
        onDelete: (id: number) => dispatch(scheduleActions.onDeleteSchedule(id)),
        onUpdate: (schedule: any) => dispatch(scheduleActions.onUpdateSchedule(schedule)),
        clearState: () => dispatch(scheduleActions.clearState()),
        setSelectedDate: (date: number) => dispatch(scheduleActions.setSelectedDate(date)),
        setSelectedDates: (selectDate: ScheduleEvent[]) => dispatch(scheduleActions.setSelectedDates(selectDate)),
        setFilterList: (filterList: any) => dispatch(scheduleActions.setFilterList(filterList)),
        clearSchedules: () => dispatch(scheduleActions.clearSchedules()),
        getSchedules: (schedulesData: string) => dispatch(getSchedules(schedulesData)),
        setShowPastSchedule: (isShow: boolean) => dispatch(scheduleActions.setShowPastSchedule(isShow)),
        filterSchedules: () => dispatch(scheduleActions.filterSchedules()),
        navigate: (date: number) => dispatch(scheduleActions.navigate(date)),
    }
}

const exportSchedule = checkAvailableModule('schedule') ? connect(mapStateToProps, mapDispatchToProps)(Schedule) : null

export default exportSchedule
