import React from 'react'
import 'rc-slider/assets/index.css'
// @ts-ignore
import ReactRange from 'rc-slider'
import styles from './range.jcss'
import { cn } from 'ethcss'

interface IRangeProps {
    handle: boolean
    mod: string
    getHandleOffset: (value: number) => void
    className?: string | {}
    offset?: number
    range?: boolean
    min?: number
    max?: number
    value?: number
    onChange?: any
    onAfterChange?: any
    step?: number
    disabled?: boolean
    tooltip?: any
}

const RangeHandle = (offset: number, p_: IRangeProps) => {
    if (p_.handle) {
        p_.getHandleOffset(offset)

        return (
            <div
                style={{ left: `${offset}%` }}
                className={cn(styles.handle, { [styles.handleInactive]: offset === 0 })}
            />
        )
    }
}

const Range = (p_: IRangeProps) => {
    return (
        <div className={styles.rangeWrapper}>
            <ReactRange
                {...p_}
                className={cn(styles.rangeSlider, styles[p_.mod], p_.className)}
                handle={({ offset }: { offset: number }) => RangeHandle(offset, p_)}
            />
        </div>
    )
}
Range.defaultProps = {
    handle: true,
    mod: 'darkBlue',
    getHandleOffset: () => {},
}

export default Range
