import moment from 'moment'
import { useEffect, useState } from 'react'
import { api } from '../../core/api/ConnectionManager'
import { colors } from 'theme'
import translate from 'core/translate'
import { RootStateOrAny, useSelector } from 'react-redux'
import helpers from 'core/helpers'

export interface INotification {
    category: string
    companyId: number
    context: { [index: string]: any }
    createdAt: string
    defaultMessage: null | { [index: string]: any }
    id: number
    localeMessage: null | { [index: string]: any }
    status: string
    type: string
    updatedAt: string
}
export interface IState {
    tabs: ['notificationCenter', 'usersActionsHistory'],
    selectedTab: string,
    notifications: INotification[],
    isModalActive: boolean,
    activeModalId: null | number,
    notificationInModal: null | INotification,
    startDate: string,
    endDate: string,
    apply: boolean
}
export const NotificationСenterHooks = () => {

    const [state, setState] = useState<IState>({
        tabs: ['notificationCenter', 'usersActionsHistory'],
        selectedTab: 'notificationCenter',
        notifications: [],
        isModalActive: false,
        activeModalId: null,
        notificationInModal: null,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(1, 'day').format('YYYY-MM-DD'),
        apply: false
    })

    const [filterFields, setFilterFields] = useState<string>('')
    const userPermissions = useSelector((state: RootStateOrAny) => state.user.data.permissions)
    let notificationsPermissions = userPermissions.find((element: { [index: string]: any }) => element.name === 'notificationCenter')
    let isDeletePermission = notificationsPermissions ? notificationsPermissions.actions.find((element: { [index: string]: any }) => element.name === 'delete') : null

    const getCardBg = (type: string) => {
        switch (type) {
            case 'information':
                return colors.greyLight
            case 'warning':
                return colors.blueAccent
            case 'error':
                return colors.notificationRed
            default:
                return colors.greyLight
        }
    }

    const isInformationType = (type: string) => {
        switch (type) {
            case 'information':
                return colors.blackLight
            default:
                return colors.white
        }
    }

    const modelText = (notification: any, field: string) => {
        if (notification && notification.localeMessage && notification.localeMessage[field]) { return notification.localeMessage[field] }
        else if (notification && notification.defaultMessage && notification.defaultMessage[field]) { return notification.defaultMessage[field] }

        else if (notification && notification.context && notification.notificationCode) {
            if (
                notification.notificationCode === 1
            ) {
                if (field === 'title') {
                    return translate('videoParamsWarning')
                } else if (field === 'name') {
                    return notification.context.instance.name
                } else if (field === 'text') {
                    return translate('videoParamsNotificationText')
                } else {
                    return ' '
                }
            }
            else if (notification.notificationCode === 2) {
                if (field === 'title') {
                    return translate('licenseEndingParamsWarning') + notification.context.expiredDays + ' ' +
                    helpers.getLabelByNumber(notification.context.expiredDays, [
                        translate('dayN1'),
                        translate('dayN2'),
                        translate('dayN3'),
                    ])
                } else if (field === 'name') {
                    return '-'
                } else if (field === 'text') {
                    return translate('licenseEndingParamsNotificationText')
                } else {
                    return ' '
                }
            }
            else if (notification.notificationCode === 3) {
                if (field === 'title') {
                    return translate('licensePasswordSecurity')
                } else if (field === 'name') {
                    return '-'
                } else if (field === 'text') {
                    return translate('licensePasswordSecurityText')
                } else {
                    return ' '
                }
            }
            else { return }
        }

        else if (notification && notification.context && notification.context.instance && notification.context.instance[field]) { return notification.context.instance[field] }
        else { return '-' }
    }

    const getCardIcon = (category: string, type: string) => {
        if (type === 'error') {
            return 'notificationsError'
        }
        switch (category) {
            case 'license':
                return 'licenses'
            case 'digitalSignage':
                return 'display'
            case 'engineeringWorks':
                return 'notificationsBuild'
            case 'broadcast':
                return 'camera'
            case 'content':
                return 'files'
            default:
                return 'notificationsWarning'
        }
    }
    const getFilterParam = (filterParam?: string) => {
        switch (filterParam) {
            case 'default':
            case 'license':
            case 'digitalSignage':
            case 'engineeringWorks':
            case 'broadcast':
            case 'content':
                return `[
                    {
                        "type": "select",
                        "name": "category",
                        "options": [
                            {
                                "value": "${filterParam}",
                                "selected": true
                            }
                         ]
                    }
                ]`
            case 'information':
            case 'warning':
            case 'error':
                return `[
                    {
                        "type": "select",
                        "name": "type",
                        "options": [
                            {
                                "value": "${filterParam}",
                                "selected": true
                            }
                         ]
                    }
                ]`
            case 'read':
            case 'unread':
                return `[
                    {
                        "type": "select",
                        "name": "status",
                        "options": [
                            {
                                "value": "${filterParam}",
                                "selected": true
                            }
                         ]
                    }
                ]`
            case 'byStartDateChecked':
                return `[
                    {
                        "type": "range",
                        "name": "createdAt",
                        "minValue": "${moment(state.startDate).format('YYYY-MM-DD')}",
                        "maxValue": "${moment(state.endDate).format('YYYY-MM-DD')}"
                    }
                ]`
            default:
                return ''
        }
    }
    const getNotifications = (filterParam?: string) => {
        let appliedFilters: string = getFilterParam(filterParam)
        api.send('getNotifications',
            appliedFilters === '' ? {
                'sort': ['createdAt', 'DESC'],
                'limit': 1000,
            } :
                {
                    'sort': ['createdAt', 'DESC'],
                    'limit': 1000,
                    appliedFilters
                }).then((res: any) => {
                    if (res && res.data) {
                        setState((prevState) => {
                            return {
                                ...prevState,
                                notifications: res.data,
                            }
                        })
                    }
                })
    }

    const deleteNotification = (id: number) => api.send('deleteNotifications', { 'notificationId': id })

    const listenersId: string[] = []

    useEffect(() => {
        let items: INotification[] = state.notifications != null ? [...state.notifications] : []
        const index = items.findIndex((val: INotification) => val.id === state.activeModalId)

        api.addObserver('notificationsDeleted', (res: any) => {
            let notificationsFiltered = items.filter((element: { [index: string]: any }) => element.id !== res.id)
            return (
                setState((prevState: any) => {
                    return {
                        ...prevState,
                        notifications: notificationsFiltered,
                    }
                }))
        }, listenersId)

        api.addObserver('notificationsCreated', (res: any) => {
            return (
                setState((prevState: any) => {
                    return {
                        ...prevState,
                        notifications: [res, ...state.notifications],
                    }
                }))
        }, listenersId)

        api.addObserver('notificationsUpdated', (res: any) => {
            let updatedNotificationIndex: number = items.findIndex((element: { [index: string]: any }) => element.id === res.id)
            items[updatedNotificationIndex] = res
            return (
                setState((prevState) => {
                    return {
                        ...prevState,
                        notifications: items,
                    }
                }))
        }, listenersId)

        state.activeModalId && state.notifications && state.notifications.map((notification: INotification) =>
            notification.id === state.activeModalId && setState((prevState) => {
                return {
                    ...prevState,
                    notificationInModal: notification,
                }
            }))

        if (state.notifications && state.notifications[index] && state.notifications[index].status === 'unread') {
            state.activeModalId && state.activeModalId && api.send('readNotifications', { 'notificationId': state.activeModalId }).then(() => {
                items[index].status = 'read'
                setState((prevState) => {
                    return {
                        ...prevState,
                        notifications: items,
                    }
                })
            })
        }

        return () => {
            listenersId.forEach((id) => api.removeObserver(id))
        }

    }, [state.activeModalId, state.notifications])

    useEffect(() => {
        getNotifications(filterFields)
    }, [filterFields, state.apply])

    return {
        getCardBg,
        isInformationType,
        getCardIcon,
        getNotifications,
        deleteNotification,
        state,
        setState,
        filterFields,
        setFilterFields,
        modelText,
        isDeletePermission
    }
}
