import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { layers } from 'blocks.app/config'
import { defaultThemeStyles } from 'blocks.app/sidebar/Sidebar-theme'
import { defaultThemeStyles as defaultThemeEditorStyles } from '../editor.theme'
import { colors, mediaCSS } from '../../../theme'

const editorToolbarStyles = {
    toolbarBlock: {
        position: 'relative',
    },
    editorToolbarWrapper: {
        ...grid.pt_micro,
        ...grid.pb_micro,
        ...grid.pl_mini,
        ...grid.pr_mini,
        ...grid.row,
        position: 'relative',
        ...grid.normalCenter,
        ...grid.justify,
    },
    editorToolbarWrapperMobile: {
        display: 'none',
        position: 'absolute',
        top: 74,
        left: 0,
        padding: '7px 0 !important',
        backgroundColor: colors.white,
        boxShadow: '0px 4px 4px rgba(0, 54, 136, 0.25)',
        borderRadius: 10,
        zIndex: 1000,
    },
    actionsBarOpened: {
        display: 'block !important',
    },
    editorRowMobile: {
        display: 'block !important',
    },
    editorToolbarHeader: {
        ...grid.row,
        ...grid.normalCenter,
        width: '100%',
        position: 'relative',
        padding: '8px 0',
        borderBottom: `1px solid var(--var-module-app-component-editor-style-border, ${defaultThemeEditorStyles.border})`,
    },
    editorToolbarHeaderMobile: {
        display: 'block !important',
        padding: '2px 0',
    },
    broadcastName: {
        ...grid.space,
        textAlign: 'center',
        minHeight: 15,

        [mediaCSS.tab]: {
            position: 'relative',
            textAlign: 'left',
            borderBottom: `1px solid ${colors.white}`,
        },
    },
    titleEditIcon: {
        position: 'absolute',
        bottom: 10,
        right: 10,
    },
    titleEditText: {
        color: `var(--var-module-app-component-editor-style-textSub, ${defaultThemeEditorStyles.textSub})`,
        textAlign: 'center',
        margin: '0 auto',

        [mediaCSS.tab]: {
            textAlign: 'left',
        },
    },
    headerIcons: {
        ...grid.row,
        position: 'absolute',
        right: 0,
        top: 8,
    },
    headerIconsMobile: {
        position: 'static !important',
        justifyContent: 'flex-end',
    },
    mobileIconWrapper: {
        marginRight: '0 !important',
        padding: '8px 10px !important',
    },
    iconWrapper: {
        ...grid.p_microPlus,
        ...grid.mr_micro,
        position: 'relative',
        '&:last-child': {
            ...grid.mr_none,
        },
        '&:hover': {
            zIndex: layers.sticky + 3,
        },
    },
    iconWrapperMobile: {
        marginRight: '0 !important',
    },
    selected: {
        backgroundColor: `var(--var-module-sidebar-component-sidebar-style-contentBackground, ${defaultThemeStyles.contentBackground})`,
        borderRadius: '50%',
    },
    buttons: {
        ...grid.row,
        ...grid.center,
    },
    dNoneModalSave: {
        display: 'none !important',
    },
    modalSaveApprove: {
        transform: 'translate(-50%)',
        backgroundColor: `var(--var-module-sidebar-component-sidebar-style-contentBackground, ${defaultThemeStyles.contentBackground})`,
        boxShadow: `var(--var-module-ui-component-dropMenu-style-blockShadow, 0px 4px 4px rgba(0, 0, 0, 0.25))`,
        position: 'fixed',
        zIndex: 9999,
        borderRadius: '8px',
        width: '250px',
        marginTop: '5px',

        [mediaCSS.tab]: {
            left: '50%',
        },
    },
    actionBarToggle: {
        position: 'absolute',
        top: 48,
        left: 10,
    },
}

addStyles(editorToolbarStyles)
export default editorToolbarStyles
