import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { radiusSizes } from 'blocks.app/config'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'
import { IStyles } from '../../../theme'

const textEditorToolbarStyles: IStyles = {
    textEditorToolbarWrapper: {
        ...grid.row,
        ...grid.justify,
        borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
    },
    toolbarItem: {
        ...grid.row,
        ...grid.space,
        ...grid.normalCenter,
        cursor: 'pointer',
        borderRight: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
    },
    icons: {
        ...grid.mr_mini,
        ...grid.ml_mini,
    },
    textColor: {
        ...grid.mr_mini,
        ...grid.ml_micro,
        width: 25,
        height: 25,
        border: `2px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
        borderRadius: radiusSizes.micro,
    },
    iconWrapper: {
        ...grid.rowCenter,
        ...grid.space,
        cursor: 'pointer',
        ...grid.pt_micro,
        ...grid.pb_micro,
        borderRight: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
    },
    sep: {
        ...grid.w100,
        borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
    },
}

addStyles(textEditorToolbarStyles)
export default textEditorToolbarStyles
