import PropTypes from 'prop-types'
import React from 'react'
import Icon from 'blocks.simple/icon/icon'
import DropMenuMethods from './dropMenu.local.methods'
import Animation from 'blocks.simple/animation/animation'
import enhanceWithClickOutside from 'react-click-outside'
import { cn } from 'ethcss'
import styles from './dropMenu.jcss'
import { grid } from '../../theme'
import itemStyles from 'blocks.simple/item/item.jcss'

class DropMenu extends DropMenuMethods {
    static propTypes = {
        onClick: PropTypes.func,
        indent: PropTypes.string,
    }
    static defaultProps = {
        onClick: () => {},
        indent: 'mini',
    }

    render() {
        const p_ = this.props
        let activeItems: {
            [index: string]: { id: string; children: any }
        } = {}

        const activeItemsList = p_.items.map((item: any, index: number) => {
            const isActive = this.isActive(item.id)
            if (isActive) {
                activeItems[item.id] = item
            }

            if (item.children) {
                return (
                    <Animation key={`dropMenu__content_${index}`}>
                        {isActive && (
                            <div
                                onClick={(e) => e.stopPropagation()}
                                className={cn(styles.content, {
                                    [styles.contentAuto]: p_.contentHeight,
                                    [styles.contentGray]: p_.gray,
                                })}
                            >
                                {item.children}
                            </div>
                        )}
                    </Animation>
                )
            } else {
                return null
            }
        })

        return (
            <div
                onClick={this.closeItems}
                className={cn(styles.wrapper)}
                style={p_.contentHeight ? { height: 'auto' } : {}}
            >
                <div className={cn(styles.icons, p_.className, p_.mod === 'centered' ? grid.center : grid.justify)}>
                    {p_.items.map((item: any, index: number) => {
                        if (item.show === false) {
                            return null
                        }

                        return (
                            <div
                                key={`dropMenu__item_${index}`}
                                className={cn(
                                    //@ts-ignore
                                    { [itemStyles.disabled]: item.disabled },
                                    p_.buttonWrapperClassName,
                                    styles.listTriggerItem
                                )}
                                onClick={
                                    !item.disabled
                                        ? (e: any) => {
                                              this.toggleSubMenu(e, item.id)
                                              if (p_.onClick) {
                                                  p_.onClick(item.id)
                                              }
                                          }
                                        : () => {}
                                }
                            >
                                {item.icon && (
                                    <Icon
                                        disabled={item.disabled}
                                        type={this.isActive(item.id) ? item.activeIcon || item.icon : item.icon}
                                        color={item.color || 'white'}
                                        size={item.size ? item.size : 18}
                                        tooltip={{ ...item.tooltip }}
                                    />
                                )}
                                {item.button}
                                <Animation>
                                    {activeItems[item.id] && activeItems[item.id].children && (
                                        <div className={cn(styles.arrow, { [styles.arrowAuto]: p_.contentHeight })}>
                                            <Icon
                                                type={'arrowhead_up'}
                                                size={12}
                                                color={p_.gray ? 'greyLight' : 'white'}
                                            />
                                        </div>
                                    )}
                                </Animation>
                            </div>
                        )
                    })}
                </div>
                {activeItemsList}
            </div>
        )
    }
}

export default enhanceWithClickOutside(DropMenu)
