import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { addStyles } from 'ethcss'
import { defaultThemeStyles } from '../index.theme'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'
import { IStyles, mediaCSS } from '../../../../theme'

const ItemDisplaysStyles: IStyles = {
    displaysWrapper() {
        return {
            ...grid.col,
            boxShadow: `var(--var-module-catalog-component-item-style-shadow, ${defaultThemeStyles.shadow})`,
            cursor: 'pointer',
            overflow: 'hidden',
            [`&:hover .${this.checkWrapper}`]: {
                opacity: 1,
            },
            [`&:hover .${this.infoLine}`]: {
                height: 64,
            },
        }
    },
    groupWrapper() {
        return {
            ...item.hover,
            cursor: 'pointer',
            [`&:hover .${this.checkWrapper}`]: {
                opacity: 1,
            },
        }
    },
    group: {
        ...grid.col,
        ...grid.full,
        boxShadow: `var(--var-module-catalog-component-item-style-shadow, ${defaultThemeStyles.shadow})`,
        ...item.cover,
        ...item.rel,
        ...item.overNone,
        zIndex: 10,
        height: '90%',
        top: '10%',
    },
    groupBackWrapper: {
        ...grid.full,
        ...grid.colCenter,
        ...item.abs,
        top: 0,
        left: 0,
        zIndex: 1,
    },
    groupBack: {
        ...grid.fullHeight,
        boxShadow: `var(--var-module-catalog-component-item-style-shadow, ${defaultThemeStyles.shadow})`,
        position: 'relative',
    },
    groupBack_first: {
        width: '94%',
        height: '90%',
        backgroundColor: `var(--var-module-catalog-component-item-style-backgroundSub, ${defaultThemeStyles.backgroundSub})`,
    },
    groupBack_second: {
        width: '88%',
    },
    errorGroup: {
        backgroundColor: `var(--var-module-catalog-component-item-style-error, ${defaultThemeStyles.error})`,
    },
    onlineGroup: {
        backgroundColor: `var(--var-module-catalog-component-item-style-online, ${defaultThemeStyles.online})`,
    },
    offlineGroup: {
        backgroundColor: `var(--var-module-catalog-component-item-style-offlineGroup, ${defaultThemeStyles.offline})`,
    },
    offline: {
        background: `var(--var-module-catalog-component-item-style-offline, ${defaultThemeStyles.offline})`,
        backgroundSize: 'cover',
    },
    online: {
        background: `var(--var-module-catalog-component-item-style-online, ${defaultThemeStyles.online})`,
        backgroundSize: 'cover',
    },
    online_display_off: {
        background: '#005939',
        backgroundSize: 'cover',
    },
    error: { backgroundColor: `var(--var-module-catalog-component-item-style-error, ${defaultThemeStyles.error})` },
    blackLayer: {
        backgroundColor: `var(--var-module-catalog-component-item-style-active, ${defaultThemeStyles.active})`,
    },
    noBroadcast: {
        border: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
        backgroundColor: `var(--var-module-catalog-component-item-style-backgroundMain, ${defaultThemeStyles.backgroundMain})`,
    },
    offlineText: { color: `var(--var-module-catalog-component-item-style-offline, ${defaultThemeStyles.offline})` },
    onlineText: { color: `var(--var-module-catalog-component-item-style-online, ${defaultThemeStyles.online})` },
    errorText: { color: `var(--var-module-catalog-component-item-style-error, ${defaultThemeStyles.error})` },
    noBroadcastText: {
        color: `var(--var-module-catalog-component-item-style-noBroadcastText, ${defaultThemeStyles.noBroadcastText})`,
    },
    content: {
        ...grid.space,
        ...grid.colCenter,
        position: 'relative',
    },
    iconWrapper: {
        position: 'absolute',
        fontSize: 24,
        ...grid.rowCenter,
        width: 24,
        height: 24,
        backgroundColor: `var(--var-module-catalog-component-item-style-iconWrapper, ${defaultThemeStyles.iconWrapper})`,
        borderRadius: '50%',
        top: grid.p_microPlus.padding,
        right: grid.p_mini.padding,
    },
    registrationCode: {
        ...grid.full,
        position: 'absolute',
        ...grid.colCenter,
        ...grid.center,
        fontWeight: 'bold',
        color: `var(--var-module-catalog-component-item-style-textMain, ${defaultThemeStyles.textMain})`,
    },
    registrationCodeSimple: {
        ...grid.mt_micro,
        color: `var(--var-module-catalog-component-item-style-textMain, ${defaultThemeStyles.textMain})`,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16,
    },
    simpleWrapper: {
        ...grid.rowCenter,
        ...grid.full,
    },
    checkWrapper: {
        position: 'absolute',
        ...item.transitionErase,
        top: grid.p_micro.padding,
        right: grid.p_micro.padding,
        zIndex: 2,
        opacity: 0,

        [mediaCSS.tab]: {
            opacity: 1,
            right: 0,
        },
    },
    iconWrapper_broadcast: {
        backgroundColor: `var(--var-module-catalog-component-item-style-backgroundSub, ${defaultThemeStyles.backgroundSub})`,
    },
    loading: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.noWrap,
        color: `var(--var-module-catalog-component-item-style-panel, ${defaultThemeStyles.panel})`,
        fontSize: 12,
    },
    progress: {
        ...grid.mr_micro,
        ...grid.normalCenter,
        ...grid.row,
        width: 20,
        height: 20,
    },
    infoLine: {
        position: 'absolute',
        ...grid.fullWidth,
        ...grid.p_microPlus,
        ...grid.justify,
        ...grid.row,
        ...grid.noWrap,
        color: `var(--var-module-catalog-component-item-style-panel, ${defaultThemeStyles.panel})`,
        fontSize: 14,
        ...item.transitionEraseHeight,
        bottom: 0,
        left: 0,
        height: 36,
    },
    infoLineProgress: {
        visibility: 'visible',
    },
    infoText: {},
    pseudoIcon: {
        width: 25,
    },
    infoIcon: {
        position: 'absolute',
        right: 10,
        bottom: 10,
    },
    orientationIcon: {
        ...grid.mr_mini,
    },
    statusPanel: {
        ...grid.flex,
        ...grid.around,
        width: '100%',
        padding: '20px 20px 0',
        color: `var(--var-module-catalog-component-item-style-textMain, ${defaultThemeStyles.textMain})`,
        fontSize: 12,
        textAlign: 'center',

        [mediaCSS.tab]: {
            padding: '4px 4px 0 4px',
        },
    },
    displayText: {
        [mediaCSS.tab]: {
            fontSize: '12px !important',
        },
    },
    blocked: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
    },
    blockedImg: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
}

addStyles(ItemDisplaysStyles)
export default ItemDisplaysStyles
