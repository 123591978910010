import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Sidebar from 'blocks.app/sidebar/sidebar'
import ToolbarFile from '../__toolbarFile/files__toolbarFile'
import ToolbarUploads from '../__toolbarUploads/files__toolbarUploads'
import FilesEditTagsModal from '../__editTagsModal/files__editTagsModal'
import TagsList from 'blocks.app/tagsList/tagsList'
import translate from 'core/translate'
import helpers from 'core/helpers'
import { routes } from 'features/routes'
import { api } from 'core/api/ConnectionManager'
// @ts-ignore
import omitEmpty from 'omit-empty'
import filesStyles from '../files.jcss'
import { grid, item, text } from '../../../theme'
import { getURLSearchParamsByLocation, getURLSearchParamsString } from 'features/routes/utils'
import { withRouter } from 'react-router'
import { changeLocation } from 'features/routes'
import { filesActions, rotateVideo } from '../files.state'
import { cancelUpload } from 'core/services/DownloadService/DownloadService.state'
import { SmartFolderSettings } from '../__smartFolder/files__smartFolderModal'
import Modal from 'blocks.simple/modal/modal'
import { cn } from 'ethcss'
import { Typography } from 'atoms/Typography'
import Button from 'blocks.simple/button/button'

interface IBottomContent {
    folderId?: string
    id?: number
    query?: any
    selectedInfo?: any
    openTagsEditModalOpen: () => void
    onChange?: ([]) => void
    landscapeStatus?: string
    downloadLink: string
    rotateVideo?: (sourceId: { sourceId: number }) => void
    openSmartFolderSettings?: () => void
    onEdit: () => void
    showPreview: boolean
    onClickPreview?: () => void
    selectedItems?: {}[]
    onCloseSmartFolderSettings?: () => void
}

const getMediaFiles = (type: string) => {
    return type === 'video' || type === 'audio'
}
const getDownloadFiles = (type: string) => {
    const types = ['rss', 'web_page', 'text', 'table', 'link', 'xml', 'csv']

    return !types.includes(type)
}

const BottomContent = (p_: IBottomContent) => {
    const [isOpenedWhereUse, setOpenedWhereUse] = useState<boolean>(false)
    const [broadcastsCount, setBroadcastsCount] = useState<number>(0)

    const isShowVideoEditorMenuItem =
        p_.selectedInfo &&
        p_.selectedInfo.fileType === 'video' &&
        helpers.isAvailableAction(p_.selectedInfo, {
            key: 'files',
            action: 'update',
        })
    const queryParameters: any = helpers.getQuery(p_.query, 'files')

    if (!queryParameters.folderId) {
        queryParameters.folderId = null
    } else {
        queryParameters.folderId = parseInt(queryParameters.folderId, 10)
    }
    useEffect(() => {
        isOpenedWhereUse &&
            api.send('getBroadcastStats', { sourceId: p_.selectedInfo.id }).then((data: any) => {
                setBroadcastsCount(data.broadcasts)
            })
    }, [isOpenedWhereUse])

    return (
        <div>
            {!p_.selectedInfo && (
                <div
                    className={filesStyles.menuItem}
                    onClick={function () {
                        if (p_.openTagsEditModalOpen) {
                            p_.openTagsEditModalOpen()
                        }
                    }}
                >
                    {translate('tags')}
                </div>
            )}
            {p_.selectedInfo && queryParameters.query && queryParameters.folderId !== p_.selectedInfo.folderId && (
                <div
                    className={filesStyles.menuItem}
                    onClick={function () {
                        changeLocation({
                            pathname: `/${routes.files.path}`,
                            search: getURLSearchParamsString({
                                folderId: p_.selectedInfo && p_.selectedInfo.folderId,
                            }),
                        })
                    }}
                >
                    {translate('location')}
                </div>
            )}
            {p_.landscapeStatus === 'error' && (
                <div
                    className={filesStyles.menuItem}
                    onClick={function () {
                        if (p_.rotateVideo && p_.id) {
                            p_.rotateVideo({ sourceId: p_.id })
                        }
                    }}
                >
                    {translate('rotateAgain')}
                </div>
            )}
            {p_.downloadLink && (
                <a
                    className={filesStyles.menuItem}
                    href={p_.downloadLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={function () {
                        if (p_.onChange) {
                            p_.onChange([])
                        }
                    }}
                >
                    {translate('download')}
                </a>
            )}
            {p_.selectedInfo ? (
                p_.selectedInfo.isDirectory ? (
                    <div className={filesStyles.menuItem} onClick={p_.openSmartFolderSettings}>
                        {translate('rules')}
                    </div>
                ) : null
            ) : null}
            {p_.selectedInfo &&
                p_.selectedInfo.data &&
                p_.selectedInfo.data.widgetInfo &&
                p_.selectedInfo.data.widgetInfo.isEditable && (
                    <div className={filesStyles.menuItem} onClick={p_.onEdit}>
                        {translate('edit')}
                    </div>
                )}
            {p_.showPreview && (
                <div
                    className={filesStyles.menuItem}
                    onClick={function () {
                        if (p_.onClickPreview) {
                            p_.onClickPreview()
                        }
                    }}
                >
                    {translate('preview')}
                </div>
            )}
            {isShowVideoEditorMenuItem && (
                <div
                    className={filesStyles.menuItem}
                    onClick={function () {
                        changeLocation({
                            pathname: `/${routes.videoEditor.path}`,
                            search: getURLSearchParamsString({
                                sourceId: p_.selectedInfo.id,
                            }),
                        })
                    }}
                >
                    {translate('videoEditor')}
                </div>
            )}
            {p_.selectedInfo && !p_.selectedInfo.isDirectory && (
                <div className={filesStyles.menuItem} onClick={() => setOpenedWhereUse(!isOpenedWhereUse)}>
                    {translate('whereUsed')}
                </div>
            )}
            {!p_.selectedInfo && (
                <div
                    className={filesStyles.menuItem}
                    onClick={function () {
                        if (p_.onChange) {
                            p_.onChange([])
                        }
                    }}
                >
                    {translate('deselect')}
                </div>
            )}

            {isOpenedWhereUse && (
                <Modal
                    open={isOpenedWhereUse}
                    showCloseIcon={false}
                    mod="clear"
                    onClose={() => setOpenedWhereUse(false)}
                >
                    <div className={cn(grid.full, grid.rowCenter, item.blackOpacity)}>
                        <div className={cn(item.whereUseCard, item.shadowModal, item.rounded_full_mini)}>
                            <div className={cn(grid.col, grid.mb_md)}>
                                <div className={cn(grid.row)}>
                                    <Typography className={cn(grid.mb_md, text.bold)}>
                                        {translate('contentUsedIn')}:
                                    </Typography>
                                </div>

                                <div className={cn(grid.row, grid.justify, grid.mt_mini)}>
                                    <Typography type="text">{translate('broadcasts')}:</Typography>
                                    <Typography type="text" className={cn(text.bold)}>
                                        {broadcastsCount}
                                    </Typography>
                                    <span
                                        className={cn(text.underline, item.pointer)}
                                        onClick={function () {
                                            changeLocation({
                                                pathname: `/${routes.broadcasts.path}`,
                                                search: getURLSearchParamsString({
                                                    contentId: p_.selectedInfo.id,
                                                }),
                                            })
                                        }}
                                    >
                                        {translate('go')}
                                    </span>
                                </div>
                            </div>

                            <Button
                                className={cn(grid.mt_md, item.rel)}
                                onClick={() => setOpenedWhereUse(false)}
                                mod="fill"
                            >
                                {translate('cancel')}
                            </Button>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    )
}

type Data = { sourceId: number; folderId: number; newFolderId: number }

const FilesToolbar = (p_: any) => {
    const [currentIndexSlider, setCurrentIndexSlider] = useState<number>(0)
    const [sliderItems, setSliderItems] = useState<{}[]>([])
    const [isOpenedSmartFolderSettings, setIsOpenedSmartFolderSettings] = useState<boolean>(false)
    const openSmartFolderSettings = () => {
        setIsOpenedSmartFolderSettings(true)
    }

    const onCloseSmartFolderSettings = () => {
        setIsOpenedSmartFolderSettings(false)
    }

    const getFilesInFolder = async () => {
        const { selectedInfo, settings, openPreview, ...props } = p_
        let sliderItems = props.files

        if (settings.view.files === 'list') {
            try {
                const result: any = await api.send('getFilesAndFolders', { folderId: selectedInfo.folderId })
                sliderItems = result.data.filter((file: { isDirectory: boolean }) => !file.isDirectory)
            } catch (e) {
                console.error(e)
            }
        }

        const currentIndexSlider = sliderItems.findIndex((item: { id: number }) => item.id === selectedInfo.id)

        setCurrentIndexSlider(currentIndexSlider)
        setSliderItems(sliderItems)
        setTimeout(() => {
            openPreview()
        }, 100)
    }

    const updateSourceTag = (data: {}) => {
        api.send('updateSourceTag', data)
    }

    const deleteSourceTag = (data: {}) => {
        api.send('deleteSourceTag', data)
    }

    const createSourceTag = (data: {}) => {
        api.send('createSourceTag', data)
    }

    const getCustomRightContent = () => {
        const s_ = { currentIndexSlider, sliderItems, isOpenedSmartFolderSettings }
        const query = getURLSearchParamsByLocation(p_.location)
        const folderId: any = query.folderId

        if (p_.selectedInfo) {
            return (
                <ToolbarFile
                    {...p_}
                    previewSliderOptions={{ ...s_ }}
                    onClickPreview={getFilesInFolder}
                    bottomContent={
                        <BottomContent
                            openTagsEditModalOpen={p_.openTagsEditModalOpen}
                            query={query}
                            folderId={folderId}
                            downloadLink={
                                getDownloadFiles(p_.selectedInfo.fileType || p_.selectedInfo.type)
                                    ? p_.downloadLink
                                    : null
                            }
                            showPreview={p_.selectedInfo && getMediaFiles(p_.selectedInfo.fileType)}
                            landscapeStatus={p_.selectedInfo.dataLandscapeStatus}
                            id={p_.selectedInfo.id}
                            selectedInfo={p_.selectedInfo}
                            selectedItems={p_.selectedItems}
                            onClickPreview={getFilesInFolder}
                            onEdit={p_.onEdit}
                            openSmartFolderSettings={openSmartFolderSettings}
                            onCloseSmartFolderSettings={onCloseSmartFolderSettings}
                        />
                    }
                />
            )
        }

        if (p_.selectedItems.length) {
            return null
        }

        return (
            <div className={grid.mt_md}>
                <TagsList
                    title={translate('tags')}
                    tags={p_.tags}
                    onUpdate={updateSourceTag}
                    onDelete={deleteSourceTag}
                    onCreate={createSourceTag}
                />
            </div>
        )
    }

    const deleteFilesFolders = () => {
        let idFiles: number[] = []
        let idFolders: number[] = []

        p_.selectedItems.forEach((item: { id: number; isDirectory: number }) => {
            item.isDirectory ? idFolders.push(item.id) : idFiles.push(item.id)
        })

        const deletePromises = []

        if (idFolders.length > 0) {
            deletePromises.push(api.send('deleteFolder', { id: idFolders }))
        }

        if (idFiles.length > 0) {
            deletePromises.push(api.send('deleteFile', { id: idFiles }))
        }

        Promise.all(deletePromises).then(() => {
            p_.onChange([])
        })
    }

    const editFilesFolders = (data: Data) => {
        const { sourceId, folderId, newFolderId } = data
        const dataToEditFiles = omitEmpty({
            parentId: newFolderId,
            sourceId,
            sourceFolderId: folderId,
        })

        api.send('moveFilesAndFolders', dataToEditFiles).then(() => {
            p_.onChange([])
        })
    }

    const s_ = { currentIndexSlider, sliderItems, isOpenedSmartFolderSettings }
    const sources = p_.selectedItems.filter((source: { isDirectory: number }) => !source.isDirectory)
    const query = getURLSearchParamsByLocation(p_.location)
    const folderId = query.folderId
    const isUploadable = helpers.isAvailable({ key: 'files', action: 'create' }) && folderId

    return (
        <React.Fragment>
            <Sidebar
                onSelectInfo={p_.onSelectInfo}
                selectSidebar={(selectedSide: string) => p_.setToolbar(selectedSide)}
                selectedSide={isUploadable ? p_.toolbar : 'right'}
                onGetFoldersOnMultiple={p_.onGetFoldersOnMultiple}
                content={{
                    right:
                        !p_.selectedInfo && p_.selectedItems.length
                            ? {
                                  title: translate('selectedFiles'),
                                  options: p_.selectedItems,
                                  folders: p_.folders,
                                  folderId: folderId,
                                  editTitle: translate('parentFolder'),
                                  isDirectory: (option: { isDirectory: number }) => option.isDirectory,
                                  downloadLink: p_.downloadLink,
                              }
                            : null,
                }}
                bottomContent={{
                    right: (
                        <BottomContent
                            downloadLink={p_.downloadLink}
                            openTagsEditModalOpen={p_.openTagsEditModalOpen}
                            showPreview={false}
                            onChange={p_.onChange}
                            onEdit={p_.onEdit}
                        />
                    ),
                }}
                customContent={{
                    left: isUploadable ? (
                        <ToolbarUploads uploads={p_.uploads} folderId={folderId} cancelUpload={p_.cancelUpload} />
                    ) : null,
                    right: getCustomRightContent(),
                }}
                onChange={(list: {}[], side?: string) => {
                    if (side === 'right') {
                        p_.onChange(list)
                    }
                }}
                onSubmit={(side: string, type: string, data: Data) => {
                    if (side === 'right' && type === 'delete') {
                        deleteFilesFolders()
                    } else if (side === 'right' && type === 'edit') {
                        editFilesFolders(data)
                    }
                }}
                icon={{
                    left: {
                        name: 'download',
                    },
                }}
                isEmpty={{
                    right: false,
                }}
                emptyText={{
                    right: {
                        title: translate('elementNotSelected'),
                        description: translate('fileNotSelectedDescription'),
                    },
                }}
                isShowActions
                isEditable={!helpers.isSomeNotHaveAction(p_.selectedItems, 'update')}
                isDeletable={!helpers.isSomeNotHaveAction(p_.selectedItems, 'delete')}
            />
            <FilesEditTagsModal sources={sources} tags={p_.tags} />
            {s_.isOpenedSmartFolderSettings && (
                <SmartFolderSettings
                    onClose={onCloseSmartFolderSettings}
                    smartFolderRules={p_.selectedInfo.smartFolderRules}
                    folderId={p_.selectedInfo.id}
                />
            )}
        </React.Fragment>
    )
}

const mapStateToProps = (state: any) => ({
    toolbar: state.files.toolbar,
    uploads: state.downloads.uploads,
    folders: state.files.folders,
    displayedTooltips: state.user.data.settings.displayedTooltips,
    settings: state.user.data.settings,
    isPreviewOpen: state.files.isPreviewOpen,
    tags: state.files.tags,
    files: state.files.files,
})

const mapDispatchToProps = (dispatch: any) => {
    return {
        openTagsEditModalOpen: () => dispatch(filesActions.openTagsEditModalOpen()),
        openPreview: () => dispatch(filesActions.openPreview()),
        rotateVideo: (video: { sourceId: number }) => dispatch(rotateVideo(video)),
        setToolbar: (toolbar: any) => dispatch(filesActions.setToolbar(toolbar)),
        cancelUpload: (id: string) => dispatch(cancelUpload(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FilesToolbar))
