import React, { DragEvent } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { SketchPicker } from 'react-color'
import enhanceWithClickOutside from 'react-click-outside'
import { cn } from 'ethcss'
import styles from './colorpicker.jcss'
import { text as textStyles } from 'theme'
import { grid, item } from 'theme'
import parse from 'parse-css-color'
const rgbaToHex = require('hex-and-rgba').rgbaToHex

const PICKER_WIDTH = 220
const PICKER_HEIGHT = 309

const rgbaToHexConverter = (value: string) => {
    const rgba: any = parse(value)

    return rgbaToHex(rgba.values[0], rgba.values[1], rgba.values[2], rgba.alpha)
}

const closest = (el: any, className: string) => {
    while (el.className && !el.className.includes(className)) {
        el = el.parentNode
        if (!el) {
            return null
        }
    }

    return el
}

const getPickerStyles = (trigger: HTMLElement, container: HTMLElement) => {
    const height = trigger.offsetHeight
    const top = trigger.offsetTop
    const left = trigger.offsetLeft
    const containerWidth = container.offsetWidth
    const containerHeight = container.offsetHeight
    const pickerWidth = left + PICKER_WIDTH
    const pickerHeight = top + PICKER_HEIGHT

    const data = {
        top: top + height,
        left,
    }

    if (pickerWidth > containerWidth) {
        data.left = left - PICKER_WIDTH + trigger.offsetWidth
    }
    if (pickerHeight > containerHeight) {
        const offsetTop = top - PICKER_HEIGHT

        if (offsetTop > 0) {
            data.top = offsetTop
        }
    }

    return data
}

class Picker extends React.PureComponent<{
    onHide: (value: boolean) => void
    onChange: (value: string) => void
    disableAlpha: boolean
    title: string
    color: string
    trigger: HTMLElement
    container: HTMLElement
}> {
    handleClickOutside = () => {
        const p_ = this.props
        p_.onHide(false)
    }
    onChangeColorComplete = ({ rgb, hex }: { rgb: { r: string; g: string; b: string; a: string }; hex: string }) => {
        const p_ = this.props

        if (p_.disableAlpha) {
            p_.onChange(hex)
            return
        }

        const rgba = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`

        p_.onChange(rgba)
    }

    render() {
        const p_ = this.props

        return (
            <div className={styles.picker} style={getPickerStyles(p_.trigger, p_.container)}>
                <div className={cn(item.white, item.rounded_full_micro)}>
                    {p_.title && <div className={cn(textStyles.center, grid.p_micro, textStyles.mini)}>{p_.title}</div>}
                    <SketchPicker
                        color={p_.color}
                        disableAlpha={p_.disableAlpha}
                        // @ts-ignore
                        onChangeComplete={this.onChangeColorComplete}
                    />
                </div>
            </div>
        )
    }
}

const PickerDrop = enhanceWithClickOutside(Picker)

class ColorPicker extends React.PureComponent<{
    onChange: (value: string) => void
    onDropdown: (value: string) => void
    color: string
    title: string
    disabled: boolean
    containerDOM: {}
    disableAlpha: boolean
    className?: string
    style?: {}
    side?: any
}> {
    static propTypes = {
        onChange: PropTypes.func,
        onDropdown: PropTypes.func,
        color: PropTypes.string,
        title: PropTypes.string,
        disabled: PropTypes.bool,
        containerDOM: PropTypes.object,
        disableAlpha: PropTypes.bool,
    }
    static defaultProps = {
        onChange: () => {},
        onDropdown: () => {},
        disableAlpha: false,
        disabled: false,
        color: '#000000',
        containerDOM: document.getElementById('root'),
    }
    state = {
        show: false,
    }
    trigger: any = null
    renderColorPicker = () => {
        const s_ = this.state
        const p_ = this.props

        if (!s_.show) {
            return null
        }
        const container = this.getContainer()

        return ReactDOM.createPortal(
            // @ts-ignore
            <PickerDrop
                {...p_}
                onChange={(value) => {
                    p_.onChange(rgbaToHexConverter(value))
                }}
                trigger={this.trigger}
                container={container}
                onHide={(show: boolean) => this.setState({ show })}
            />,
            container
        )
    }
    getContainer = () => {
        const p_ = this.props
        const parent = closest(this.trigger, 'picker-container')

        if (parent) {
            return parent
        }

        return p_.containerDOM
    }
    preventDragHandler = (e: DragEvent<HTMLImageElement>) => {
        e.preventDefault()
    }

    render() {
        const p_ = this.props
        const s_ = this.state

        return (
            <div
                onDragStart={this.preventDragHandler}
                ref={(trigger) => (this.trigger = trigger)}
                className={cn(styles.wrapper, p_.className, { [item.disabled]: p_.disabled })}
                style={p_.style}
                onClick={() => {
                    if (p_.disabled) {
                        return
                    }

                    if (!s_.show) {
                        this.setState({ show: true })
                    }
                }}
            >
                {p_.children}
                {!p_.children && (
                    <div
                        style={{ backgroundColor: p_.color }}
                        className={cn(styles.trigger, { [styles.transparentWrapper]: p_.color === 'transparent' })}
                    />
                )}
                {this.renderColorPicker()}
            </div>
        )
    }
}

export default ColorPicker
