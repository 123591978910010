import React from 'react'
import { ReportMethodsHooks } from './report-hooks'
import Content from 'blocks.app/content/content'
import ReportToolbar from './__toolbar/report__toolbar'
import { Radio } from 'atoms/Radio'
import Stick from 'blocks.simple/stick/stick'
import Catalog from 'blocks.app/catalog/catalog'
import Link from 'blocks.simple/link/link'
import ScheduleSetting from 'blocks.app/scheduleSettings/scheduleSettings'
import Button from 'blocks.simple/button/button'
import ScrollUp from 'blocks.simple/scrollUp/scrollUp'
import translate from 'core/translate'
import { cn } from 'ethcss'
import styles from './report.jcss'
import { grid, item } from '../../theme'
import moment from 'moment'
import helpers from 'core/helpers'
import { Typography } from 'atoms/Typography'
import { Steps } from 'atoms/Steps/Steps-view'
import { getURLSearchParamsByLocation, getURLSearchParamsString } from 'features/routes/utils'
import { checkAvailableModule } from '../../core/helpers/routes/routes'

type Location = {
    pathname: string
    search: string
    hash: string
    state: any
}

const Report = ({ location }: { location: Location }) => {
    const query = getURLSearchParamsByLocation(location)
    const reportType = query.reportType || 'displays'
    const {
        reports,
        report,
        period,
        setStartDate,
        setEndDate,
        setStartTime,
        setEndTime,
        setMailReport,
        setDevicesReportType,
        setReportItems,
        getTitle,
        submit,
        setPeriod,
        selectPeriod,
    } = ReportMethodsHooks()

    const onSelect = (selectedItems: any) => {
        const query = getURLSearchParamsByLocation(location)
        const reportType = query.reportType || 'displays'
        setReportItems(reportType, selectedItems)
    }

    return (
        <Content
            toolbar={
                reportType === 'currencyReport' ||
                reportType === 'licenseReport' ||
                reportType === 'photoBoothSurveyReport' ? null : (
                    <ReportToolbar location={location} />
                )
            }
            title={translate('reports')}
            wrapperClassName={styles.reportsWrapper}
        >
            <div className={styles.reportWrapper}>
                {reportType !== 'currencyReport' &&
                    reportType !== 'photoBoothSurveyReport' &&
                    reportType !== 'licenseReport' && (
                        <div>
                            <Steps className={styles.tabs} active={reportType}>
                                {reports
                                    .map((reportItem, index) => {
                                        if (!helpers.isAvailable({ key: reportItem.id, action: 'read' })) {
                                            return null
                                        }
                                        return (
                                            <Link
                                                id={reportItem.id}
                                                key={`reportTab_${index}`}
                                                to={{
                                                    pathname: location.pathname,
                                                    search: getURLSearchParamsString({ reportType: reportItem.id }),
                                                }}
                                            >
                                                {reportItem.name}
                                            </Link>
                                        )
                                    })
                                    .filter((step) => step)}
                            </Steps>
                        </div>
                    )}
                <form onSubmit={(e) => submit({ e, reportType: reportType })}>
                    <div className={styles.whiteBlock}>
                        <Typography className={grid.mb_mdPlus} type="text">
                            {translate('reportParams')}
                        </Typography>
                        <ScheduleSetting
                            startDate={{
                                label: translate('startDate'),
                                dateValue: moment(report.startDate),
                                id: 'startDate',
                                maxDate: moment(report.endDate),
                            }}
                            onChangeStartDate={(startDate: string) => {
                                setPeriod('other')
                                setStartDate(moment(startDate).toDate().getTime())
                            }}
                            endDate={{
                                label: translate('endDate'),
                                id: 'endDate',
                                minDate: moment(report.startDate),
                                dateValue: moment(report.endDate),
                            }}
                            onChangeEndDate={(endDate: string) => {
                                setPeriod('other')
                                setEndDate(moment(endDate).toDate().getTime())
                            }}
                            period={{
                                mod: 'default',
                                label: translate('period'),
                                value: period,
                                options: [
                                    { name: translate('day'), id: 'day' },
                                    { name: translate('week'), id: 'week' },
                                    { name: translate('month'), id: 'month' },
                                    { name: translate('anyPeriod'), id: 'other' },
                                ],
                            }}
                            onPeriodChange={selectPeriod}
                            startTime={{
                                label: translate('startTime'),
                                id: 'startTime',
                                type: 'tel',
                                mask: '99:99:99',
                                value: report.startTime,
                                noFocus: true,
                            }}
                            onChangeStartTime={(value: any) => {
                                if (moment(value, 'HH:mm:ss', true).isValid()) {
                                    const startMs = moment(value, 'HH:mm:ss').unix()
                                    const endMs = moment(report.endTime, 'HH:mm:ss').unix()

                                    if (startMs < endMs) {
                                        setStartTime(value)
                                        setPeriod('other')
                                    }
                                }
                            }}
                            endTime={{
                                label: translate('endTime'),
                                id: 'endTime',
                                type: 'tel',
                                mask: '99:99:99',
                                value: report.endTime,
                                noFocus: true,
                            }}
                            onChangeEndTime={(value: any) => {
                                if (moment(value, 'HH:mm:ss', true).isValid()) {
                                    const startMs = moment(report.startTime, 'HH:mm:ss').unix()
                                    const endMs = moment(value, 'HH:mm:ss').unix()

                                    if (startMs < endMs) {
                                        setEndTime(value)
                                        setPeriod('other')
                                    }
                                }
                            }}
                            checkbox={{
                                label: translate('sendToEmail'),
                                checked: report.mailReport,
                            }}
                            onChecked={(mailReport: boolean) => {
                                setMailReport(mailReport)
                            }}
                        />
                    </div>
                    {reportType === 'displays' && (
                        <div className={cn(grid.mb_md, styles.paddingMobile)}>
                            <Typography type="title" className={cn(item.title, grid.pt_md)}>
                                {translate('reportType')}
                            </Typography>
                            <div
                                className={cn(
                                    grid.row,
                                    grid.center,
                                    grid.mt_mini,
                                    grid.mb_mini,
                                    styles.radioBlockMobile
                                )}
                            >
                                <Radio
                                    label={translate('playingContent')}
                                    name="reportType"
                                    className={styles.radio}
                                    selected={report.devicesReportType === ''}
                                    onClick={() => setDevicesReportType('')}
                                />
                                <Radio
                                    label={translate('operationOfDevices')}
                                    name="reportType"
                                    className={styles.radio}
                                    selected={report.devicesReportType === 'digitalSignageStatusReport'}
                                    onClick={() => setDevicesReportType('digitalSignageStatusReport')}
                                />
                                <Radio
                                    label={translate('qualityAssessmentReport')}
                                    name="reportType"
                                    className={styles.radio}
                                    selected={report.devicesReportType === 'qualityAssessmentReport'}
                                    onClick={() => setDevicesReportType('qualityAssessmentReport')}
                                />
                                <Radio
                                    label={translate('videoAnalyticsReport')}
                                    name="reportType"
                                    className={styles.radio}
                                    selected={report.devicesReportType === 'videoAnalyticsReport'}
                                    onClick={() => setDevicesReportType('videoAnalyticsReport')}
                                />
                                <Radio
                                    label={translate('totalCostReport')}
                                    name="reportType"
                                    className={styles.radio}
                                    selected={report.devicesReportType === 'totalCostReport'}
                                    onClick={() => setDevicesReportType('totalCostReport')}
                                />
                            </div>
                        </div>
                    )}
                    {reportType && <div className={grid.mt_md} />}
                    <Stick enabled={true}>
                        <div className={cn(grid.rowCenter, grid.p_micro)}>
                            <Button type="submit" mod="fill">
                                {translate('createReport')}
                            </Button>
                        </div>
                    </Stick>
                </form>
                {reportType !== 'currencyReport' &&
                    reportType !== 'photoBoothSurveyReport' &&
                    reportType !== 'licenseReport' && (
                        <>
                            <Typography type="title" className={styles.title}>
                                {getTitle(location)}
                            </Typography>
                            <div className={styles.report__catalog}>
                                {helpers.isAvailable({ key: reportType, action: 'read' }) && (
                                    <Catalog
                                        key={`catalog_${reportType}`}
                                        type={reportType}
                                        searchbarMod={'withShadow'}
                                        selectedItems={reportType === 'displays' ? report.devices : report[reportType]}
                                        onSelect={onSelect}
                                    />
                                )}
                            </div>
                        </>
                    )}
            </div>
            <ScrollUp />
        </Content>
    )
}

const exportReport = checkAvailableModule('reports') ? Report : null

export default exportReport
