import React, { Fragment, FunctionComponent, memo, useEffect, useState } from 'react'
import EditText, { EditTextPassword } from 'blocks.simple/editText/editText'
import Link from 'blocks.simple/link/link'
import Button from 'blocks.simple/button/button'
import { cn } from 'ethcss'
import { changeLocation, routes } from 'features/routes'
import translate from 'core/translate'
import { grid, mediaCSS, text as textStyles } from 'theme'
import { IAuthorizationLoginComponent } from './AuthorizationLogin-types'
import { authorizationLoginStyles } from './AuthorizationLogin-styles'
import authorizationStyles from '../authorization.jcss'
import { isNotEmptyString, trimSegmentsFromString } from 'core/utils/coreUtil'
import { useAuthorizationLogin } from 'core/hooks'
import { getURLSearchParamsByLocation, getURLSearchParamsString } from 'features/routes/utils'
import styles from '../authorization.jcss'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import { useDispatch } from 'react-redux'
import { GoogleLogin } from 'react-google-login'
import { gapi } from 'gapi-script'
import Icon from '../../../blocks.simple/icon/icon'

const AuthorizationLoginComponent: FunctionComponent<IAuthorizationLoginComponent> = ({
    location,
    onLoading,
    showPasswordButton,
}) => {
    const {
        email,
        password,
        code,
        prefix,
        onChangeEmail,
        onChangePassword,
        onChangeCode,
        authStep,
        login,
        confirmCode,
        isLoading,
    } = useAuthorizationLogin({ onLoading })
    const [errorMessage, setErrorMessage] = useState('')
    const dispatch = useDispatch()
    const config = window.spconfig

    const getRawCodeString = (code: string) => {
        return trimSegmentsFromString(code, [`${prefix}-`, '_'])
    }

    const handleChangeCode = (code: string) => {
        let updatedCode = getRawCodeString(code)
        onChangeCode(updatedCode)
    }

    const handleSendEmailClick = (e: React.MouseEvent) => {
        if (isLoading) return

        login()
    }

    const isEnableLoginButton = () => {
        return !isLoading
    }

    const isEnableTwoFactorLoginButton = () => {
        return !isLoading && isNotEmptyString(getRawCodeString(code))
    }

    const query = getURLSearchParamsByLocation(location)

    const checkAuthorizationStatus = () => {
        const search = new URLSearchParams(window.location.search)
        const successStatus = search.get('success')

        if (successStatus === null) return

        if (successStatus === 'true') {
            const dataTokens = search.get('result')

            if (dataTokens) {
                login(JSON.parse(dataTokens))
            }
        }
        if (successStatus === 'false') {
            setErrorMessage(translate('authError'))
        }
    }

    const checkYandexAuth = () => {
        const hash = window.location.hash.substr(1)
        const result: any = hash.split('&').reduce(function (res: any, item) {
            const parts = item.split('=')
            res[parts[0]] = parts[1]
            return res
        }, {})

        if (result.access_token && result.yandex_auth) {
            login({ tokens: { accessToken: result.access_token }, isYandexAuth: true })
        }
    }
    const checkSSODisable = () => {
        const search = new URLSearchParams(window.location.search)
        const error = search.get('error')

        if (error === null) return

        if (error === 'sso_disabled') {
            dispatch(emitError('ssoIsDisabledOnServer'))
        }
    }
    const googleLogin = (id: string) => {
        login({ tokens: { accessToken: id }, isGoogleAuth: true })
    }
    const remoteSupportLogin = () => {
        const query = getURLSearchParamsByLocation(location)

        if (query.support_token) {
            const { accessToken, refreshToken } = JSON.parse(decodeURIComponent(query.support_token))

            login({ tokens: { accessToken, refreshToken } })
        }
    }

    useEffect(() => {
        if (config.auth.showSSOButton) {
            checkSSODisable()
            checkAuthorizationStatus()
        }
        if (config.auth.showYandexButton) {
            checkYandexAuth()
        }
    }, [])
    useEffect(() => {
        const start = () => {
            gapi.client.init({
                clientId: config.auth.googleClientId,
            })
        }

        gapi.load('client:auth2', start)
    }, [])
    useEffect(() => {
        remoteSupportLogin()
    }, [])

    const onGoogleLoginSuccess = (res: any) => {
        if (res.accessToken) {
            googleLogin(res.accessToken)
        }
    }
    const onGoogleLoginFailure = (res: any) => {
        console.log(res)
    }
    const getQuickButtonSize = () => {
        const width = window.innerWidth
        const authConfig = config.auth
        const values = [authConfig.showSSOButton, authConfig.showYandexButton, authConfig.showGoogleButton]
        let count = 0

        values.forEach((value) => {
            if (value) {
                count++
            }
        })

        if (count === 3) {
            if (width < 1920) {
                return '100%'
            }

            return 60
        }
        if (count === 2) {
            if (width < 1920) {
                return '100%'
            }

            return 90
        }
        if (width < 1920) {
            return '100%'
        }

        return 190
    }

    return (
        <Fragment>
            {authStep === 'ONE' && (
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        login()
                    }}
                    className={authorizationStyles.formWrapper}
                >
                    <div className={authorizationStyles.item}>
                        <EditText
                            id={'email_login'}
                            className={cn(textStyles.center)}
                            placeholder={translate('email')}
                            type="text"
                            mod="withBottomLine"
                            autoComplete="on"
                            rounded="none"
                            value={email}
                            onChange={onChangeEmail}
                        />
                    </div>
                    <div className={!showPasswordButton ? authorizationLoginStyles.disableShowPasswordButton : ''}>
                        <EditTextPassword
                            id={'password_login'}
                            className={cn(textStyles.center)}
                            placeholder={translate('password')}
                            mod="withBottomLine"
                            rounded="none"
                            value={password}
                            autoComplete="current-password"
                            onChange={onChangePassword}
                        />
                    </div>
                    <div className={authorizationStyles.restorePassword}>
                        <Link
                            textKey="forgetPassword"
                            mods={['hoverUnderline']}
                            to={{
                                pathname: `/${routes.forgetPassword.path}`,
                                search: getURLSearchParamsString({
                                    lang: query.lang,
                                }),
                            }}
                        />
                    </div>
                    <div className={cn(grid.row, grid.center, grid.mt_mdPlus)}>
                        <Button
                            disabled={!isEnableLoginButton()}
                            type="submit"
                            mod="secondary"
                            className={cn(grid.row, grid.center, grid.w70)}
                        >
                            {translate('joining')}
                        </Button>
                    </div>
                    {(config.auth.showYandexButton || config.auth.showGoogleButton || config.auth.showSSOButton) && (
                        <div className={styles.quickLoginButtons}>
                            <div>{translate('loginWith')}</div>
                            <div className={styles.quickLoginButtonsWrapper}>
                                {config.auth.showYandexButton && (
                                    <div className={styles.quickButton} style={{ width: getQuickButtonSize() }}>
                                        <div className={cn(grid.row, grid.center)}>
                                            <a
                                                href={`https://oauth.yandex.ru/authorize?response_type=token&client_id=${config.auth.yandexClientId}`}
                                                className={cn(grid.row, grid.center, grid.w70, styles.authLinkBtn)}
                                            >
                                                <img src={'images/authorization/yandex.svg'} />
                                            </a>
                                        </div>
                                    </div>
                                )}
                                {config.auth.showGoogleButton && (
                                    <div className={styles.quickButton} style={{ width: getQuickButtonSize() }}>
                                        <div className={cn(grid.row, grid.center)}>
                                            <div className={styles.googleBtnWrapper}>
                                                <GoogleLogin
                                                    clientId={config.auth.googleClientId}
                                                    render={(renderProps) => (
                                                        <button
                                                            type={'button'}
                                                            className={cn(
                                                                grid.row,
                                                                grid.center,
                                                                grid.w70,
                                                                styles.authLinkBtn
                                                            )}
                                                            onClick={renderProps.onClick}
                                                        >
                                                            <img src={'images/authorization/google.svg'} />
                                                        </button>
                                                    )}
                                                    cookiePolicy={'single_host_origin'}
                                                    onSuccess={onGoogleLoginSuccess}
                                                    onFailure={onGoogleLoginFailure}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {config.auth.showSSOButton && (
                                    <div className={styles.quickButton} style={{ width: getQuickButtonSize() }}>
                                        <div className={cn(grid.row, grid.center)}>
                                            <a
                                                href={config.ip_address + 'v1/sso/saml/login'}
                                                className={cn(grid.row, grid.center, grid.w70, styles.authLinkBtn)}
                                            >
                                                SSO
                                            </a>
                                        </div>
                                        <div className={authorizationLoginStyles.errorMessage}>{errorMessage}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {config.registration.isEnabled && (
                        <>
                            <div className={styles.orSeparator}>{translate('or')}</div>
                            <div className={cn(grid.row, grid.center)}>
                                <Button
                                    type="button"
                                    mod="secondary"
                                    className={cn(grid.row, grid.center, grid.w70)}
                                    onClick={() => changeLocation('registration')}
                                >
                                    {translate('toRegister')}
                                </Button>
                            </div>
                        </>
                    )}
                </form>
            )}
            {authStep === 'TWO' && (
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        confirmCode()
                    }}
                    className={authorizationStyles.formWrapper}
                >
                    <div className={authorizationStyles.item}>
                        <div className={authorizationLoginStyles.authorizationLogin__email}>{email}</div>
                    </div>
                    <div>
                        <EditText
                            id={'code_login'}
                            className={cn(textStyles.center)}
                            placeholder={translate('confirmEmailLoginPlaceholder')}
                            mod="withBottomLine"
                            rounded="none"
                            value={code}
                            mask={`${prefix}-****`}
                            onChange={handleChangeCode}
                        />
                    </div>
                    <div className={cn(authorizationLoginStyles.authorizationLogin__text)}>
                        {translate('confirmationEmailLoginText')}
                    </div>
                    <div
                        className={cn(authorizationLoginStyles.authorizationLogin__link)}
                        onClick={handleSendEmailClick}
                    >
                        {translate('confirmationEmailLoginLink')}
                    </div>
                    <div className={cn(grid.row, grid.center, grid.mt_mdPlus)}>
                        <Button
                            disabled={!isEnableTwoFactorLoginButton()}
                            type="submit"
                            mod="secondary"
                            className={cn(grid.row, grid.center, grid.w70)}
                        >
                            {translate('joining')}
                        </Button>
                    </div>
                </form>
            )}
        </Fragment>
    )
}

export const AuthorizationLogin = memo(AuthorizationLoginComponent)
