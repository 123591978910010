import { addStyles } from 'ethcss'
import { textSizes } from '../media'
import { colors } from '../colors'

const text = {
    right: {
        textAlign: 'right',
    },
    left: {
        textAlign: 'left',
    },
    center: {
        textAlign: 'center',
    },
    centerBlock: {
        display: 'block',
        width: '100%',
        textAlign: 'center',
    },
    justify: {
        textAlign: 'justify',
    },
    bold: {
        fontWeight: 'bold',
    },
    redError: {
        color: colors.errorLight,
    },
    white: {
        color: colors.white,
    },
    darkCloud: {
        color: colors.darkCloud,
    },
    darkBlue: {
        color: colors.darkBlue,
    },
    grey: {
        color: colors.darkCloud,
    },
    blue: {
        color: colors.blue,
    },
    underline: {
        textDecoration: 'underline',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    hoverUnderline: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    noUnderline: {
        textDecoration: 'none',
    },
    up: {
        textTransform: 'uppercase',
    },
    low: {
        textTransform: 'lowercase',
    },
    capitalize: {
        textTransform: 'capitalize',
    },
    wordWrap: {
        wordWrap: 'break-word',
    },
    noWrap: {
        'white-space': 'nowrap',
    },
    ...(() => {
        let key
        let textSizesStyles = {}

        for (key in textSizes) {
            if (textSizes.hasOwnProperty(key)) {
                textSizesStyles[key] = {
                    fontSize: textSizes[key],
                }
            }
        }

        return textSizesStyles
    })(),
    ...(() => {
        const colorsStyles = {}

        for (let key in colors) {
            if (colors.hasOwnProperty(key)) {
                colorsStyles[key] = {
                    color: colors[key],
                }
            }
        }

        return colorsStyles
    })(),
}

addStyles(text)
export default text
