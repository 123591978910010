import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { indents } from 'blocks.app/config'
import { defaultThemeStyles } from './EditorItem-theme'
import { textSizes } from 'theme/media'

const editorItemStyles = {
    editorItemWrapper() {
        return {
            position: 'relative',
            ...grid.full,
            overflow: 'hidden',
            '& img': {
                ...grid.w100,
                'user-drag': 'none',
                'user-select': 'none',
                '-moz-user-select': 'none',
                '-webkit-user-drag': 'none',
                '-webkit-user-select': 'none',
                '-ms-user-select': 'none',
            },
        }
    },
    delete: {
        position: 'absolute',
        top: indents.microPlus,
        right: indents.microPlus,
        zIndex: 4,
    },
    layers: {
        ...grid.rowCenter,
        position: 'absolute',
        ...item.blackOpacity,
        borderRadius: '50%',
        width: 37,
        height: 37,
        bottom: indents.mini,
        right: indents.mini,
        zIndex: 4,
    },
    layersCount: {
        ...grid.mr_nano,
        fontSize: textSizes.mini,
        color: `var(--var-module-editor-component-item-style-text, ${defaultThemeStyles.text})`,
    },
    link: {
        ...grid.full,
        backgroundColor: `var(--var-module-editor-component-item-style-link, ${defaultThemeStyles.link})`,
        overflow: 'hidden',
    },
    linkInner: {
        ...grid.full,
        ...grid.colCenter,
        fontSize: 14,
        color: `var(--var-module-editor-component-item-style-linkText, ${defaultThemeStyles.linkText})`,
    },
    linkDomain: {
        fontSize: 16,
        textTransform: 'capitalize',
    },
    audioWrapper: {
        cursor: 'default',
        border: 'none',
    },
    audio: {
        ...grid.w100,
        ...grid.row,
        ...grid.normalCenter,
        fontSize: 12,
        ...grid.p_micro,
        textTransform: 'capitalize',
        border: `2px solid var(--var-module-editor-component-item-style-line, ${defaultThemeStyles.line})`,
        color: `var(--var-module-editor-component-item-style-line, ${defaultThemeStyles.line})`,
        borderRadius: 10,
        cursor: 'pointer',
    },
    audioSelected: {
        border: `2px solid var(--var-module-editor-component-item-style-lineSelected, ${defaultThemeStyles.lineSelected})`,
        backgroundColor: `var(--var-module-editor-component-item-style-lineSelected, ${defaultThemeStyles.lineSelected})`,
        borderRadius: 10,
    },
    videoWrapper: {
        ...grid.full,
        position: 'relative',
    },
    videoButtons: {
        ...grid.w100,
        position: 'absolute',
        top: 0,
        zIndex: 10000,
        opacity: 0,
        transition: 'opacity 0.3s',
    },
    videoButtonsShow: {
        opacity: '1 !important',
    },
    progressWrapper: {
        height: 3,
        width: '100%',
        backgroundColor: `var(--var-module-editor-component-item-style-line, ${defaultThemeStyles.line})`,
    },
    progress: {
        height: '100%',
        backgroundColor: `var(--var-module-editor-component-item-style-lineSelected, ${defaultThemeStyles.lineSelected})`,
    },
    imgWrapper: {
        ...grid.full,
        position: 'relative',
        '& img': {
            'user-drag': 'none',
            'user-select': 'none',
            '-moz-user-select': 'none',
            '-webkit-user-drag': 'none',
            '-webkit-user-select': 'none',
            '-ms-user-select': 'none',
        },
    },
    opacityLayer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 2,
    },
    stretchLayer: {
        ...grid.full,
        ...item.cover,
    },
    inputFileWrapper: {
        ...grid.full,
        ...grid.rowCenter,
        overflow: 'hidden',
        backgroundColor: `var(--var-module-editor-component-item-style-inputFile, ${defaultThemeStyles.inputFile})`,
    },
    text: {
        ...grid.full,
        ...grid.row,
        ...grid.normalCenter,
        ...grid.center,
        overflow: 'hidden',
        textAlign: 'center',
        color: `var(--var-module-editor-component-item-style-textSecondary, ${defaultThemeStyles.textSecondary})`,
    },
    tablePreview: {
        overflow: 'hidden !important',
    },
}

addStyles(editorItemStyles)
export default editorItemStyles
