import { addStyles } from 'ethcss'
import { indents, IStyles, textSizes } from 'theme'
import grid from 'theme/grid/styles'

export const styles: IStyles = {
    DisplayOfWarningsSettings: {
        marginBottom: indents.md,
    },
    DisplayOfWarningsSettings__item: {
        ...grid.flex,
        ...grid.row,
        ...grid.flexStart,
        ...grid.normalCenter,
        paddingBottom: indents.md,
    },
    DisplayOfWarningsSettings__text: {
        margin: 0,
        padding: 0,
        width: '45%',
    },
    DisplayOfWarningsSettings__button: {
        ...grid.flex,
    },
    inactivitySettings__input: {
        textAlign: 'center',
    },
}

addStyles(styles)
