import { addStyles } from 'ethcss'
import { IStyles, mediaCSS, grid, item, colors } from '../../theme'

const NotificationСenter: IStyles = {
    notificationRow: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '9px 20px',
        borderRadius: 5,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        marginBottom: 20,
        overflow: 'hidden',
        color: colors.white,
        [mediaCSS.tab]: {
            padding: 20,

            [mediaCSS.tabMini]: {
                display: 'block',
            },
        },
    },
    about: {
        paddingTop: 16,
        textDecoration: 'underline',
    },
    pointer: {
        cursor: 'pointer'
    },
    cardText: {
        paddingLeft: 20,
    },
    unreadCircle: {
        marginLeft: -14,
        marginRight: 4,
    },
    rightDate: {
        position: 'absolute',
        right: 26,
        bottom: 10
    },
    bold: {
        fontWeight: 550,
    },
    opacity: {
        opacity: 0.8,
    },
    opacity9: {
        opacity: 0.9,
    },
    nameText: {
        overflow:'hidden',
        maxWidth: '250px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    rightDelete: {
        position: 'absolute',
        right: 26,
        top: 10
    },
    wrapperClassName: {
        marginBottom: -36,
        [mediaCSS.tabMini]: {
            paddingTop: 36
        },
    },
    modal: {
        ...grid.w50,
        [mediaCSS.tab]: {
            ...grid.w60,
        },
        [mediaCSS.tabMini]: {
            ...grid.w70,
        },
        [mediaCSS.mob]: {
            ...grid.w90,
        },
        ...grid.p_none,
    },
    wrapper: {
        borderRadius: '10px 10px 0 0',
        ...grid.w100,
        ...grid.mb_md,
    },
    textModal: {
        ...grid.pl_md,
        ...grid.w100,
    },
    icon: {
        ...grid.mr_mini,
    },
    headerModal: {
        textAlign: 'center',
        alignItems: 'center',
        ...grid.mt_md,
        ...grid.mb_md,
        ...grid.pl_md,
        ...grid.row,
    },
}

addStyles(NotificationСenter)
export default NotificationСenter
