import PropTypes from 'prop-types'
import React from 'react'
import { cn } from 'ethcss'
import ReactPlayer from 'react-player'
import DropMenu from 'blocks.simple/dropMenu/dropMenu'
import RangeVolume from 'blocks.simple/range/_volume/range_volume'
import Draggable from 'blocks.simple/draggable/draggable'
import Icon from 'blocks.simple/icon/icon'
import TableEditorPreview from 'blocks.app/tableEditor/_preview/tableEditor_preview'
import PreviewWebApp from 'blocks.app/preview/__webApp/preview__webApp'
import { PreviewMediaMessage } from 'blocks.app/preview/__media/preview__media'
import EditorItemLocalMethods from 'blocks.app/editor/__item/editor__item.local.methods'

import { TEXT_DISPLAY_AS } from 'core/models/Files'

import styles from './editor__item.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import helpers from 'core/helpers'
import filesHelpers from 'core/helpers/_files'
import translate from 'core/translate'
import './editor__item_style.css'

const getWebAppSizes = (s_, p_) => {
    const position = s_.position
    const resolutionWidth = p_.resolutionWidth
    const resolutionHeight = p_.resolutionHeight
    const area = p_.area

    if (!position || !resolutionWidth || !resolutionHeight || !area) return null

    return {
        width: position.width * resolutionWidth * area.width,
        height: position.height * resolutionHeight * area.height,
    }
}

const getScrollInfo = (item) => {
    if (!item || !item.data) return 'yes'

    return !item.data.scroll || item.data.scroll === 'on' ? 'yes' : 'no'
}

class Preview extends EditorItemLocalMethods {
    render() {
        const p_ = this.props

        if (!p_.fillMode) {
            return (
                <div className={styles.imgWrapper}>
                    <img className={grid.full} src={p_.src} alt="" />
                    <div className={styles.opacityLayer} />
                </div>
            )
        }
        if (p_.fillMode === 'stretch') {
            return (
                <div className={styles.imgWrapper}>
                    <div className={styles.stretchLayer} style={{ backgroundImage: `url(${p_.src})` }} />
                </div>
            )
        }
    }
}

class EditorItem extends EditorItemLocalMethods {
    render() {
        const s_ = this.state
        const p_ = this.props

        const iconColor =
            p_.type === 'link' || p_.type === 'input' || p_.fileType === 'office' || p_.fileType === 'web_app'
                ? 'darkBlue'
                : 'darkCloud'

        return (
            <Draggable
                {...s_.position}
                zIndex={p_.zIndex}
                resizableZIndex={p_.zIndex}
                maxWidth={p_.maxWidth}
                maxHeight={p_.maxHeight}
                minWidth={p_.minWidth}
                minHeight={p_.minHeight}
                disableResize={p_.disableResize}
                disableDrag={p_.disableDrag}
                onSelect={p_.onSelect}
                onDragEnd={p_.onDragEnd}
                onResizeEnd={p_.onResizeEnd}
                onResize={this.onResize}
                selected={p_.selected}
                noBorder={p_.noBorder}
                borderColor={'selected'}
                selectedBorderColor={'white'}
                isShowDraggableSquares={p_.isScreenItem}
                selectedSquareColorType={p_.type === 'area' ? 'default' : 'contrast'}
            >
                <div
                    className={cn(styles.editorItemWrapper, p_.className)}
                    onMouseDown={p_.onMouseDown}
                    ref={(wrapper) => (this.wrapperRef = wrapper)}
                    style={p_.wrapperStyle}
                >
                    {(() => {
                        switch (p_.fileType) {
                            case 'video':
                                return (
                                    <div
                                        className={styles.videoWrapper}
                                        onMouseOver={this.showVideoBar}
                                        onMouseLeave={this.hideVideoBar}
                                    >
                                        {(() => {
                                            const playing = p_.playing

                                            return (
                                                <div className={grid.full} style={p_.style}>
                                                    <div className={grid.full}>
                                                        <ReactPlayer
                                                            ref={(reactPlayer) => {
                                                                if (reactPlayer) {
                                                                    if (p_.seekTo) {
                                                                        const currentTime = Math.round(
                                                                            (p_.time - p_.startTime) / 1000
                                                                        )
                                                                        reactPlayer.seekTo(currentTime)
                                                                    }
                                                                }
                                                            }}
                                                            className={
                                                                p_.fillMode === 'stretch' ? 'stretched-video' : ''
                                                            }
                                                            width="100%"
                                                            height="100%"
                                                            url={p_.src}
                                                            playing={playing}
                                                            onEnded={this.onReactPlayerEnded}
                                                            onProgress={this.onReactPlayerProgress}
                                                            onReady={this.onReactPlayerReady}
                                                            onError={this.onReactPlayerError}
                                                            progressInterval={250}
                                                            volume={s_.volume / 100}
                                                        />
                                                        <PreviewMediaMessage
                                                            error={s_.error}
                                                            src={p_.src}
                                                            style={{ top: 0 }}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })()}
                                        <div className={styles.opacityLayer} />
                                        <div
                                            className={cn(
                                                styles.videoButtons,
                                                s_.isShowVideoBar ? styles.videoButtonsShow : ''
                                            )}
                                        >
                                            <div className={styles.progressWrapper}>
                                                <div
                                                    style={{ width: `${s_.played * 100}%` }}
                                                    className={styles.progress}
                                                />
                                            </div>
                                            <DropMenu
                                                active={s_.active}
                                                onChange={(active) => this.setState({ active })}
                                                onClick={this.clickDropMenu}
                                                className={item.blackOpacity}
                                                mod="centered"
                                                items={[
                                                    {
                                                        id: 'volume',
                                                        icon: 'volume_min',
                                                        tooltip: {
                                                            title: translate('volume'),
                                                        },
                                                        children: (
                                                            <RangeVolume
                                                                volume={s_.volume}
                                                                onChange={(volume) => this.setState({ volume })}
                                                            />
                                                        ),
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                )
                            case 'audio':
                                return (
                                    <div
                                        className={styles.videoWrapper}
                                        onMouseOver={this.showVideoBar}
                                        onMouseLeave={this.hideVideoBar}
                                    >
                                        {(() => {
                                            const playing = p_.playing

                                            return (
                                                <div className={grid.full}>
                                                    <div className={item.none}>
                                                        <ReactPlayer
                                                            ref={(reactPlayer) => {
                                                                if (reactPlayer) {
                                                                    if (p_.seekTo) {
                                                                        const currentTime = Math.round(
                                                                            (p_.time - p_.startTime) / 1000
                                                                        )
                                                                        reactPlayer.seekTo(currentTime)
                                                                    }
                                                                }
                                                            }}
                                                            width="0"
                                                            height="0"
                                                            url={p_.src}
                                                            playing={playing}
                                                            onEnded={this.onReactPlayerEnded}
                                                            onProgress={this.onReactPlayerProgress}
                                                            onReady={this.onReactPlayerReady}
                                                            onError={this.onReactPlayerError}
                                                            progressInterval={250}
                                                            volume={s_.volume / 100}
                                                        />
                                                    </div>
                                                    <PreviewMediaMessage
                                                        error={s_.error}
                                                        src={p_.src}
                                                        style={{ top: 0 }}
                                                    />
                                                </div>
                                            )
                                        })()}
                                        <div
                                            className={cn(styles.opacityLayer, item.contain)}
                                            style={{
                                                backgroundImage: `url(${filesHelpers.getSrc(p_)})`,
                                                backgroundSize: 'auto auto',
                                            }}
                                        />
                                        <div
                                            className={cn(
                                                styles.videoButtons,
                                                s_.isShowVideoBar ? styles.videoButtonsShow : ''
                                            )}
                                        >
                                            <div className={styles.progressWrapper}>
                                                <div
                                                    style={{ width: `${s_.played * 100}%` }}
                                                    className={styles.progress}
                                                />
                                            </div>
                                            <DropMenu
                                                active={s_.active}
                                                onChange={(active) => this.setState({ active })}
                                                onClick={this.clickDropMenu}
                                                className={item.blackOpacity}
                                                mod="centered"
                                                items={[
                                                    {
                                                        id: 'volume',
                                                        icon: 'volume_min',
                                                        tooltip: {
                                                            title: translate('volume'),
                                                        },
                                                        children: (
                                                            <RangeVolume
                                                                volume={s_.volume}
                                                                onChange={(volume) => this.setState({ volume })}
                                                            />
                                                        ),
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                )
                            case 'office':
                                return (
                                    <div className={styles.inputFileWrapper}>
                                        <div className={cn(grid.w40)} style={p_.style}>
                                            <Icon size={'100%'} type={filesHelpers.getFileIconName(p_)} />
                                        </div>
                                    </div>
                                )
                            case 'smartFolder':
                                return (
                                    <div className={styles.inputFileWrapper}>
                                        <div className={cn(grid.w40)} style={p_.style}>
                                            <Icon size={'100%'} type={filesHelpers.getFileIconName(p_)} />
                                        </div>
                                    </div>
                                )
                            case 'web_app':
                                return (
                                    <div className={cn(item.rel, grid.full)} style={p_.style}>
                                        <PreviewWebApp
                                            item={{ id: p_.id }}
                                            indents={'none'}
                                            {...getWebAppSizes(s_, p_)}
                                        />

                                        <div className={cn(item.abs, item.fullAbs)} />
                                    </div>
                                )
                            default:
                                if (
                                    this.textFileTypes.includes(p_.fileType) &&
                                    p_.data &&
                                    p_.data.displayAs === TEXT_DISPLAY_AS.QR_CODE
                                ) {
                                    return (
                                        <div className={grid.full}>
                                            <Preview fillMode={p_.fillMode} src={p_.thumbnail} />
                                        </div>
                                    )
                                }
                                if (p_.type === 'link') {
                                    return (
                                        <div className={styles.link} style={p_.style}>
                                            {!p_.additionalData.iFrameAvailable && (
                                                <div className={styles.linkInner}>
                                                    <div className={styles.linkDomain}>{s_.name}</div>
                                                    <div className={grid.w30}>
                                                        <img src={'images/editor/link.png'} alt="" />
                                                    </div>
                                                    <div>{p_.name}</div>
                                                </div>
                                            )}
                                            {p_.additionalData.iFrameAvailable && (
                                                <iframe
                                                    src={p_.name}
                                                    width="100%"
                                                    height="100%"
                                                    title={p_.name}
                                                    scrolling={getScrollInfo(p_)}
                                                />
                                            )}
                                            {p_.additionalData.iFrameAvailable && (
                                                <div className={cn(item.abs, item.fullAbs)} />
                                            )}
                                        </div>
                                    )
                                } else if (p_.type === 'text' || p_.type === 'xml') {
                                    if (this.textFileTypes.includes(p_.fileType)) {
                                        return (
                                            <div
                                                className={styles.text}
                                                style={p_.data ? filesHelpers.convertStyles(p_.data.style) : {}}
                                            >
                                                <span
                                                    ref={(textContentRef) => (this.textContentRef = textContentRef)}
                                                    style={this.getTextContentStyles()}
                                                >
                                                    {helpers.strip_html_tags(p_.name)}
                                                </span>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div className={styles.inputFileWrapper}>
                                                <div className={cn(grid.w40)}>
                                                    <Icon size={'100%'} type={filesHelpers.getFileIconName(p_)} />
                                                </div>
                                            </div>
                                        )
                                    }
                                } else if (p_.type === 'area') {
                                    return p_.children
                                } else if (p_.type === 'table') {
                                    return (
                                        <TableEditorPreview
                                            tableFormat={p_.data.tableFormat}
                                            cells={p_.data.cells}
                                            wrapperClassName={cn(styles.tablePreview)}
                                            scale={p_.scale}
                                        />
                                    )
                                } else if (p_.type === 'input') {
                                    return (
                                        <div className={styles.inputFileWrapper}>
                                            <div className={cn(grid.w40)}>
                                                <Icon size={'100%'} type={filesHelpers.getFileIconName(p_)} />
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className={grid.full} style={p_.style}>
                                            <Preview fillMode={p_.fillMode} src={p_.src} />
                                        </div>
                                    )
                                }
                        }
                    })()}
                    {p_.showDeleteIcon && (
                        <div className={styles.delete} onClick={p_.onDelete}>
                            <Icon type={'delete'} color={iconColor} size={15} />
                        </div>
                    )}
                    {p_.showLayersIcon && (
                        <div className={styles.layers}>
                            <div className={styles.layersCount}>{p_.layersCount}</div>
                            <Icon
                                tooltip={{ title: translate('layers') }}
                                type={'layers'}
                                color={'greyLight'}
                                size={15}
                            />
                        </div>
                    )}
                </div>
            </Draggable>
        )
    }
}

EditorItem.propTypes = {
    name: PropTypes.string,
    type: PropTypes.string,
    isScreenItem: PropTypes.bool,
    src: PropTypes.string,
    style: PropTypes.object,
    width: PropTypes.number,
    height: PropTypes.number,
    left: PropTypes.number,
    top: PropTypes.number,
    fileType: PropTypes.string,
    selected: PropTypes.bool,
    disableResize: PropTypes.bool,
    disableDrag: PropTypes.bool,
    zIndex: PropTypes.number,
    minWidth: PropTypes.number,
    minHeight: PropTypes.number,
    maxWidth: PropTypes.number,
    maxHeight: PropTypes.number,
    showDeleteIcon: PropTypes.bool,
    showLayersIcon: PropTypes.bool,
    noBorder: PropTypes.bool,
    layersCount: PropTypes.number,
    scale: PropTypes.number,
    onSelect: PropTypes.func,
    onDelete: PropTypes.func,
    onDragEnd: PropTypes.func,
    onResizeEnd: PropTypes.func,
    onResize: PropTypes.func,
    fillMode: PropTypes.string,
    additionalData: PropTypes.object,
}

EditorItem.defaultProps = {
    additionalData: {},
    scale: 1,
    onResize: () => {},
    isScreenItem: false,
}

export default EditorItem
