import { addStyles } from 'ethcss'
import { item, IStyles, grid } from 'theme'
import { defaultThemeStyles } from './Steps-theme'

export const stepsStyles: IStyles = {
    Steps: {
        ...grid.row,
    },
    Steps__content: {
        ...grid.space,
        ...grid.rowCenter,
        ...grid.p_md,
        ...item.pointer,
        textDecoration: 'none',
        borderRadius: '7px',
        // color: `var(--var-module-ui-component-steps-style-textMain, ${defaultThemeStyles.textMain})`,
        color: `var(--var-module-ui-component-steps-style-textMain, ${defaultThemeStyles.textDefault})`,
        //border: `1px solid var(--var-module-ui-component-steps-style-hoverBackground, ${defaultThemeStyles.hoverBackground})`,
        marginRight: '10px',
        '&:hover': {
            backgroundColor: `var(--var-module-ui-component-steps-style-hoverBackground, ${defaultThemeStyles.hoverBackground})`,
        },
        '&:last-child': {
            marginRight: 0,
        },
    },
    Steps__content_default_activated: {
        backgroundColor: `var(--var-module-ui-component-steps-style-activeBackground, ${defaultThemeStyles.activeBackground})`,
    },
    Steps__content_default_disabled: {
        backgroundColor: `var(--var-module-ui-component-steps-style-background, ${defaultThemeStyles.background})`,
    },
    Steps__content_inverse_activated: {
        backgroundColor: `var(--var-module-ui-component-steps-style-inverseTabActive, ${defaultThemeStyles.inverseTabActive})`,
    },
    Steps__content_inverse_disabled: {
        backgroundColor: `var(--var-module-ui-component-steps-style-textMain, ${defaultThemeStyles.textMain})`,
    },
}

addStyles(stepsStyles)
