import React, { useEffect, useState } from 'react'
import Modal from 'blocks.simple/modal/modal'
import Catalog from 'blocks.app/catalog/catalog'
import Button from 'blocks.simple/button/button'
import ContentItem from '../../__item/_files/catalog__item_files'
import Sidebar from 'blocks.app/sidebar/sidebar'
import Icon from 'blocks.simple/icon/icon'
import { cn } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import translate from 'core/translate'
import styles from './catalog_select_content.jcss'
import { Typography } from 'atoms/Typography'
import { getURLSearchParamsByLocation, getURLSearchParamsString } from 'features/routes/utils'
import { withRouter } from 'react-router'
import { changeLocation } from 'features/routes'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import { connect } from 'react-redux'
import { Location } from 'history'

interface ISelectedInfo {
    isDirectory: number
    fileType: string
    name: string
}
interface ICatalogSelectContentProps {
    selectedInfo: ISelectedInfo | null
    selectCatalogContentRef: (data: { openModal: () => void }) => void
    type: string
    title: string
    disabled: boolean
    emitError: (error: string) => void
    onSelect: (data: ISelectedInfo) => void
    onRemove: (data: any) => void
    location: Location
    isHideButtonAndPreview: boolean
    isHidePreview: boolean
    showFileName: boolean
    showSelectButton: boolean
    selectedItemStyles: object
    isHideSidebar: boolean
    extensions: string[]
}

const CatalogSelectContent = (p_: any) => {
    const [open, setOpen] = useState<boolean>(false)
    const [selectedInfo, setSelectedInfo] = useState<ISelectedInfo | null>(p_.selectedInfo)

    useEffect(() => {
        if (p_.selectCatalogContentRef) {
            p_.selectCatalogContentRef({
                openModal: openModal,
            })
        }
    }, [])

    const validateSelectedInfo = (selectedInfo: ISelectedInfo) => {
        const media = ['image', 'video', 'audio']
        const isMedia = media.includes(selectedInfo.fileType)

        switch (p_.type) {
            case 'archive':
                return selectedInfo.fileType === 'web_app'
            case 'media':
                return isMedia
            case 'mediaAndFolder':
                return isMedia || selectedInfo.isDirectory
            case 'video':
                return selectedInfo.fileType === 'video'
            case 'image':
                return selectedInfo.fileType === 'image'
            case 'audio':
                return selectedInfo.fileType === 'audio'
            case 'all':
                return true
            default:
                return ''
        }
    }
    const getTitle = () => {
        switch (p_.type) {
            case 'archive':
                return translate('selectArchive')
            case 'video':
                return translate('selectVideo')
            case 'image':
                return translate('selectImage')
            case 'audio':
                return translate('selectAudio')
            case 'media':
            case 'mediaAndFolder':
            case 'all':
                if (p_.title) {
                    return p_.title
                }
                return translate('selectContent')
            default:
                return ''
        }
    }
    const getFilterItems = () => {
        switch (p_.type) {
            case 'archive':
                return [
                    { name: translate('all') },
                    {
                        name: translate('web_app'),
                        fileType: 'web_app',
                        type: 'fs',
                    },
                ]
            case 'video':
                return [{ name: translate('video'), fileType: 'video', type: 'fs' }]
            case 'audio':
                return [{ name: translate('audio'), fileType: 'audio', type: 'fs' }]
            case 'image':
                return [{ name: translate('image'), fileType: 'image', type: 'fs' }]
            case 'media':
            case 'mediaAndFolder':
                return [
                    { name: translate('all') },
                    {
                        name: translate('images'),
                        fileType: 'image',
                        type: 'fs',
                    },
                    { name: translate('audio'), fileType: 'audio', type: 'fs' },
                    { name: translate('video'), fileType: 'video', type: 'fs' },
                ]
            default:
                return []
        }
    }
    const onSelectInfo = (selectedInfo: ISelectedInfo) => {
        if (p_.type !== 'mediaAndFolder') {
            save(selectedInfo)
            return
        }

        setSelectedInfo(selectedInfo)
    }
    const openModal = () => {
        if (p_.disabled) {
            return
        }
        setOpen(true)
    }
    const save = (selectedInfo: ISelectedInfo | null) => {
        if (!selectedInfo) {
            return
        }

        if (p_.type !== 'mediaAndFolder' && selectedInfo.isDirectory) {
            return
        }

        if (!validateSelectedInfo(selectedInfo)) {
            p_.emitError(translate('selectContentError'))

            return
        }
        clearLocation()
        setSelectedInfo(selectedInfo)
        setOpen(false)
        setTimeout(() => {
            p_.onSelect(selectedInfo)
        }, 100)
    }
    const remove = () => {
        setSelectedInfo(null)
        p_.onRemove(null)
    }
    const clearLocation = () => {
        const query = getURLSearchParamsByLocation(p_.location)
        const queryString = getURLSearchParamsString({
            ...query,
            fileType: undefined,
            type: undefined,
            files__fileType: undefined,
            files__type: undefined,
        })

        changeLocation({
            pathname: p_.location.pathname,
            search: queryString,
        })
    }

    return (
        <div className={grid.fullWidth}>
            {!p_.isHideButtonAndPreview && (
                <div className={cn(grid.row, grid.noWrap, grid.normalCenter)}>
                    <div
                        className={cn(grid.row, grid.noWrap, grid.normalCenter, styles.tabMiniPt)}
                        onClick={p_.onSelect ? openModal : () => {}}
                    >
                        {!p_.isHidePreview && selectedInfo && p_.type !== 'archive' && (
                            <div className={grid.w100} style={p_.selectedItemStyles}>
                                <ContentItem
                                    hideTitle={!p_.showFileName}
                                    item={selectedInfo}
                                    onDoubleClickObject={() => {}}
                                />
                            </div>
                        )}
                        {(!p_.selectedInfo || p_.type === 'archive' || p_.type === 'all' || p_.showSelectButton) && (
                            <Button mod={'fill'} indentSize={'mini'} textSize={'mini'} rounded={'full_normal'}>
                                {getTitle()}
                            </Button>
                        )}
                        {p_.type === 'archive' && (
                            <div className={cn(grid.pl_md, item.ellipsis)}>
                                {p_.selectedInfo && (
                                    <span>
                                        {translate('archiveSelected')}: {p_.selectedInfo.name}
                                    </span>
                                )}
                                {!p_.selectedInfo && <span>{translate('catalogSelectArchive')}</span>}
                            </div>
                        )}
                    </div>
                    {p_.selectedInfo && p_.onRemove && (
                        <Button
                            mod="withBorder"
                            indentSize="mini"
                            textSize="mini"
                            rounded="full_normal"
                            className={grid.ml_mini}
                            onClick={remove}
                        >
                            {translate('delete')}
                        </Button>
                    )}
                </div>
            )}
            <Modal
                open={open}
                showCloseIcon={false}
                sidebars={
                    !p_.isHideSidebar
                        ? {
                              right: (
                                  <div className={styles.toolbar}>
                                      <Sidebar
                                          selectedSide="right"
                                          customContent={{
                                              right: (
                                                  <div>
                                                      {p_.type !== 'archive' && (
                                                          <div>
                                                              <div className={styles.toolbarTitle}>
                                                                  Выберите контент,
                                                                  <br />
                                                                  соответствующий требованиям.
                                                              </div>
                                                              {p_.type === 'media' && (
                                                                  <div className={cn(grid.colCenter, grid.mb_big)}>
                                                                      <Icon
                                                                          containerClass={cn(grid.colCenter)}
                                                                          type={'image'}
                                                                          size={'20%'}
                                                                      />

                                                                      <div className={grid.mt_md}>
                                                                          Требования к изображению:
                                                                      </div>
                                                                      <div className={grid.mt_mini}>
                                                                          Формат изображения - png
                                                                      </div>
                                                                      <div className={grid.mt_mini}>Прозрачный фон</div>
                                                                      <div className={grid.mt_mini}>
                                                                          Размер изображения 500x500
                                                                      </div>
                                                                  </div>
                                                              )}
                                                              {p_.type === 'image' && (
                                                                  <div className={cn(grid.colCenter, grid.mb_big)}>
                                                                      <Icon
                                                                          containerClass={cn(grid.colCenter)}
                                                                          type={'image'}
                                                                          size={'20%'}
                                                                      />
                                                                      <div className={grid.mt_md}>
                                                                          Выберите изображение
                                                                      </div>
                                                                  </div>
                                                              )}
                                                              {(p_.type === 'video' || p_.type === 'media') && (
                                                                  <div className={cn(grid.colCenter, grid.mb_big)}>
                                                                      <Icon
                                                                          containerClass={cn(grid.colCenter)}
                                                                          type={'video'}
                                                                          size={'20%'}
                                                                      />

                                                                      <div className={grid.mt_md}>
                                                                          Требования к видео:
                                                                      </div>
                                                                      <div className={grid.mt_mini}>
                                                                          Формат видео - {p_.extensions.join('/')}
                                                                      </div>
                                                                  </div>
                                                              )}
                                                          </div>
                                                      )}

                                                      {p_.type === 'archive' && (
                                                          <div>
                                                              <div className={styles.toolbarTitle}>
                                                                  Выберите архив,
                                                                  <br />
                                                                  для проигрывания на экранах.
                                                              </div>
                                                              <div className={cn(grid.colCenter, grid.mb_big)}>
                                                                  <Icon
                                                                      containerClass={cn(grid.colCenter)}
                                                                      type={'web_app'}
                                                                      size={'20%'}
                                                                  />
                                                              </div>
                                                          </div>
                                                      )}
                                                  </div>
                                              ),
                                          }}
                                          isEmpty={{ right: false }}
                                      />
                                  </div>
                              ),
                          }
                        : undefined
                }
            >
                <div className={styles.content}>
                    <Typography type="title" className={styles.title}>
                        {translate(getTitle())}
                    </Typography>
                    <Catalog
                        filterItems={p_.type === 'all' ? null : getFilterItems()}
                        searchbarMod={'withShadow'}
                        selectedInfo={selectedInfo}
                        onSelectInfo={onSelectInfo}
                        type={'files'}
                        useWindow={false}
                        disableFolderSelection={true}
                        breadcrumbsClassName={cn(styles.breadcrumbs)}
                        showHeaderButton={true}
                        autoSelectContentType={p_.autoSelectContentType}
                        contentAndFolders={p_.contentAndFolders}
                    />
                    <div className={styles.buttons}>
                        <Button
                            className={cn(grid.ml_micro, grid.mr_mini)}
                            mod="withBorder"
                            indentSize={'mini'}
                            textSize={'mini'}
                            rounded={'full_normal'}
                            onClick={() => {
                                clearLocation()
                                setOpen(false)
                                setSelectedInfo(p_.selectedInfo)
                            }}
                        >
                            {translate('cancel')}
                        </Button>
                        {p_.type === 'mediaAndFolder' && (
                            <Button
                                mod={'fill'}
                                disabled={!selectedInfo}
                                className={cn(grid.ml_micro, grid.mr_mini)}
                                indentSize={'mini'}
                                textSize={'mini'}
                                rounded={'full_normal'}
                                onClick={() => {
                                    save(selectedInfo)
                                }}
                            >
                                {translate('save')}
                            </Button>
                        )}
                    </div>
                </div>
            </Modal>
        </div>
    )
}

CatalogSelectContent.defaultProps = {
    selectedInfo: null,
    isHideSidebar: false,
    type: 'media',
    showFileName: false,
    disabled: false,
    showSelectButton: false,
    extensions: ['ogg', 'ogv'],
    isHideButtonAndPreview: false,
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        emitError: (err: string) => dispatch(emitError(err)),
    }
}

// @ts-ignore
export default connect(null, mapDispatchToProps)(withRouter(CatalogSelectContent))
