import store from 'core/store'
import { IRoutesPermissions } from 'features/routes/routes-types'
import { routes } from 'features/routes'
import jwt_decode from 'jwt-decode'
import merge from 'merge'

import * as treeViewHelpers from './treeView'
import { getThemeIconValue } from 'theme/colors'
import { defaultFilesIconsTheme } from './FilesIcons-theme'
import translate from '../translate'
import { IRole, IRolePermission, TActionType } from 'core/models/Roles'
import { IStyleMediaProperties } from 'theme'
import { IMediaJs } from 'theme/media'
import { cloneDeep } from 'lodash'
import { IUser, IUserDecodeToken, IUserTokens } from 'core/models/Users'
import moment from 'moment'
import { api } from 'core/api/ConnectionManager'
import { TFileType } from 'core/models/Files'
import { IMenuWrapper } from 'core/models/Menu'
import { isExist } from '../utils/coreUtil'
import { ApiListenEventsType } from 'core/api/events'
import { numberToString } from 'core/utils/convertUtil'
import { IBreadcrumbsItem } from 'core/models/Breadcrumbs'

export interface IDebounceOptions {
    id: string
    limit: number
}

export interface ILasestTouchTap {
    time: number
    target: Element | null
}

export interface ICreateApiListeners {
    category: string | null
    type: string
    onCreate: ApiListenEventsType
    onUpdate: ApiListenEventsType
    onDelete: ApiListenEventsType
}

export interface ICreateWidgetApiListeners {
    onCreate: ApiListenEventsType
    onUpdate: ApiListenEventsType
    onDelete: ApiListenEventsType
}

export interface IApiListenerCb {
    catalogType: string
    categoryName: string
    model: any
}

export interface IRecordWithStrings {
    [key: string]: string
}

export interface IRecordWithFunctions {
    [key: string]: (data?: any) => void
}

export interface IRecordWithAny {
    [key: string]: any
}

export interface IViewParams {
    disposition: string
    sort: string
    dateFilter?: string
}

const debounceMap = new Map()

export const addListener = {
    updateDisplayProgress: function (fn: () => void) {
        listeners.updateDisplayProgress = fn
    },
    updateBroadcastProgress: function (fn: () => void) {
        listeners.updateBroadcastProgress = fn
    },
}

export const listeners = {
    updateDisplayProgress: function (data: any) {},
    updateBroadcastProgress: function (data: any) {},
}

const DOUBLE_TOUCH_MAX_DELAY = 300
let latestTouchTap: ILasestTouchTap = {
    time: 0,
    target: null,
}

export { treeViewHelpers }

export default {
    getFormattedLocalDate(date: moment.Moment | string | null, format: string) {
        if (date) {
            const localDate = moment.utc(date).toDate()

            return moment(localDate).format(format)
        }

        return null
    },
    disableWindowScroll(disabled: boolean, modalTarget: string) {
        if (disabled && !document.body.getAttribute('modal-target')) {
            document.body.setAttribute('modal-target', modalTarget)
            document.body.classList.add('modal_opened')
        } else if (!disabled && document.body.getAttribute('modal-target') === modalTarget) {
            document.body.classList.remove('modal_opened')
            document.body.removeAttribute('modal-target')
        }
    },
    getPathname(pathname = '') {
        return pathname.replace(/^\/+|\/+$/g, '')
    },
    isAvailable(params: IRoutesPermissions & { permissions?: IRolePermission[] }) {
        if (!params || !store) {
            return false
        }

        const state = store.getState()
        const tokens = state.user.tokens
        const user = state.user.data

        if (!user || !tokens) {
            return false
        }

        if (!params.strict) {
            if (routes[params.key] && routes[params.key].permissions) {
                params = routes[params.key].permissions as IRoutesPermissions
            }
        }

        let { key, action } = params

        let permissions: IRolePermission[] = user.permissions

        if (params.permissions) {
            permissions = params.permissions
        }

        let findedPermission = permissions.find((permission) => permission.name === key)

        if (!findedPermission) {
            return false
        }

        return findedPermission.actions.map((actions) => actions.name).includes(action as TActionType)
    },
    isAvailableAction(item: any, record: IRecordWithStrings) {
        if (!store) {
            return false
        }

        const state = store.getState()
        const tokens = state.user.tokens
        const user = state.user.data

        if (!user || !tokens) {
            return false
        }

        let key: any,
            action = '',
            subaction = ''

        if (isExist(record)) {
            key = record.key
            action = record.action ? record.action : ''
            subaction = record.subaction ? record.subaction : ''
        }

        const permissions: IRolePermission[] = user.permissions

        if (!permissions) {
            return false
        }

        const currentPermissions = permissions.find((permission) => permission.name === key)

        if (!currentPermissions) {
            return false
        }

        const currentAction = currentPermissions.actions.find((actionItem) => actionItem.name === action)

        if (currentAction && currentAction.rules.length) {
            const currentRule = currentAction.rules.find((rule) => rule.options.hasOwnProperty('subactions'))

            if (currentRule) {
                return (
                    (item ? item.actions.includes(action) : false) && currentRule.options.subactions.includes(subaction)
                )
            }
        }

        if (!item || !isExist(item.actions)) {
            return true
        }

        return item.actions.includes(action)
    },
    isAvailableMenu({ key, name }: { key: string; name?: string }) {
        let isAvailable = false

        if (!store) return isAvailable

        const state = store.getState()
        let menu = state.app.menu as IMenuWrapper[]

        if (!menu) {
            return isAvailable
        }

        menu.forEach((menuCategory) => {
            const category = menuCategory.menu.find((category) => category.action === key)

            if (category && !isAvailable && name) {
                const children = category.children
                    ? category.children.find(
                          (child) =>
                              child.action === name &&
                              this.isAvailable(routes[child.action].permissions as IRoutesPermissions)
                      )
                    : null

                if (children) {
                    isAvailable = true
                }
            } else if (!isAvailable && category) {
                isAvailable = true
            }
        })

        return isAvailable
    },
    isAvailableFileType(params: { key: string; name: string; action: TActionType }) {
        if (!params || !store) {
            return false
        }

        const state = store.getState()
        const user = state.user.data

        if (!user) return false

        const { key, name, action } = params

        let permissions: IRolePermission[] = user.permissions
        let findedPermission = permissions.find((permission: IRolePermission) => permission.name === key)

        if (!findedPermission) {
            return false
        }

        const actions = findedPermission.actions.find((act) => act.name === action)

        if (!actions) {
            return false
        }

        if (!actions.rules.length) {
            return true
        }

        return actions.rules.find((actionItem) => {
            const options = actionItem.options
            const ignoredTypes = ['text', 'link', 'input']

            if (!ignoredTypes.includes(name) && options.hasOwnProperty('data.type')) {
                return options['data.type'].includes(name)
            }
            if (options.hasOwnProperty('type')) {
                return options['type'].includes(name)
            }
            return false
        })
    },
    debounce(func: (...args: any[]) => void, options: IDebounceOptions) {
        if (debounceMap.has(options.id)) {
            return
        }

        const method = (...args: any[]) => {
            func(...args)
            setTimeout(() => debounceMap.delete(options.id), options.limit)
        }

        debounceMap.set(options.id, method)
        method()
    },
    isDblTouchTap(event: React.MouseEvent) {
        const touchTap = {
            time: new Date().getTime(),
            target: event.currentTarget,
        }
        const isFastDblTouchTap =
            touchTap.target === latestTouchTap.target && touchTap.time - latestTouchTap.time < DOUBLE_TOUCH_MAX_DELAY

        latestTouchTap = touchTap
        return isFastDblTouchTap
    },
    prefixQuery({ field, name }: { field: string; name: string }) {
        return `${name}__${field}`
    },
    getQuery(query: IRecordWithStrings, type: string) {
        const parsedQuery: IRecordWithStrings = {}
        for (let key in query) {
            if (query.hasOwnProperty(key)) {
                const parsedKey = key.replace(`${type}__`, '')

                parsedQuery[parsedKey] = query[key]
            }
        }

        return parsedQuery
    },
    deepCopy<T>(o: T): T {
        return cloneDeep(o)
    },
    stripHtmlTags(str: string) {
        if (!str) {
            return false
        }

        str = str.toString()
        return str.replace(/<[^>]*>/g, '')
    },
    getNestedFolderKey(type: string) {
        switch (type) {
            case 'displays':
                return 'groupId'
            default:
                return 'folderId'
        }
    },
    addParentKeyIdForTree<T extends { nestedKey: string }>(array: T[], key: string) {
        return array.map((item) => {
            item.nestedKey = key

            return item
        })
    },
    getViewParamsByUser(viewType: string, user: IUser): IViewParams {
        const settings = user.settings
        const disposition = settings.view[viewType] ? settings.view[viewType] : 'list'
        const sort = settings.sort[viewType]
        const dateFilter = settings.dateFilter[viewType]

        const parameters = {
            disposition,
            sort,
        }

        if (dateFilter) {
            return {
                ...parameters,
                dateFilter,
            }
        }

        return parameters
    },
    getUniqueArray<T>(array: T[], { field }: { field: keyof T }) {
        return array.reduce((acc: T[], x) => {
            return acc.concat(acc.find((y) => y[field] === x[field]) ? [] : [x])
        }, [])
    },
    getPropertiesByMediaQueries(properties: IStyleMediaProperties, mediaJS: IMediaJs) {
        let props = properties.desktop

        Object.entries(properties).forEach(([media, properties]) => {
            if (mediaJS[media] && mediaJS[media].matches) {
                props = properties
            }
        })

        return props
    },
    getLocalDate(data: { date: string; time: string }) {
        if (!data.date) {
            return null
        }

        const timeList = data.time.split(':')
        return moment
            .utc(data.date)
            .add(timeList[0], 'hours')
            .add(timeList[1], 'minutes')
            .add(timeList[2], 'seconds')
            .local()
    },
    getFormattedUtcDate(date: string, format: string) {
        if (date) {
            return moment(date).utc().format(format)
        }
    },
    strip_html_tags(str: string) {
        if (!str) {
            return false
        }

        return str.toString().replace(/<[^>]*>/g, '')
    },
    isSomeNotHaveAction(list: any, action: string) {
        return list.some((item: any) => !item.actions.includes(action))
    },
    preloadImage(url: string, callback: (value: string) => {}, options: IRecordWithStrings) {
        const thumbnails: IRecordWithStrings = {
            files: getThemeIconValue('catalog', 'catalog', 'corruptFile') || `${defaultFilesIconsTheme.corruptFile}`,
        }

        const img = new Image()
        img.src = url

        img.onload = () => {
            callback(img.src)
        }

        if (!img.complete) {
            callback(thumbnails[options.type])
        }
    },
    getTranslatedIfMain(value: string) {
        if (value === 'Главная') {
            return translate('main')
        }

        return value
    },
    getBreadcrumbsItemName(value: string, index: number) {
        if (value === 'Главная') {
            return translate('main')
        }

        if (index === 0) {
            return translate(value)
        }

        return value
    },
    translateRoles(roles: IRole[]) {
        if (!roles) {
            return roles
        }

        return roles.map((role) => this.translateRoleLabel(role))
    },

    isPersonalRole(roleName: string) {
        return roleName.includes('personalRole')
    },

    getPersonalRoleTranslate(role: IRole) {
        return translate('personalRole', [numberToString(role.userId as number)])
    },

    getTranslatedIfRole(role: IRole) {
        const roleName = role.name

        const rolesForTranslate = [
            'admin',
            'adminBrand',
            'userBrand',
            'adminAdvertiser',
            'userAdvertiser',
            'adminSchool',
            'userSchool',
            'userImageAndVideo',
            'adminAutomedia',
            'adminSamsonite',
            'adminAskona',
            'adminPitamix',
            'adminMegafon',
            'adminNeftm',
            'ogranichenie.prav.dlya.azs_12',
            'adminOKey',
            'adminSamberi',
        ]

        if (rolesForTranslate.includes(roleName)) {
            return translate(roleName)
        }

        if (this.isPersonalRole(role.name)) {
            return this.getPersonalRoleTranslate(role)
        }

        return ''
    },
    translateRoleLabel(role: IRole) {
        let nameToLabel = this.getTranslatedIfRole(role)

        if (nameToLabel === '') {
            nameToLabel = role.label
        }

        return {
            ...role,
            label: nameToLabel,
        }
    },
    isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    },
    getTimeOptions(interval = 30 * 60 * 1000) {
        const options = []
        const dayMs = 24 * 60 * 60 * 1000
        const stepsLength = dayMs / interval

        for (let i = 0; i < stepsLength; i++) {
            options.push({ id: i, name: this.msToHMS(i * interval, '') })
        }

        return options
    },
    hmsToS(str: string) {
        let p = str.split(':')
        let s = 0
        let m = 1

        while (p.length > 0) {
            s += m * parseInt(p.pop() as string, 10)
            m *= 60
        }

        return s
    },
    sToHM(secondsData: number, space: string = ' ') {
        let symbol = ''
        if (secondsData > 0) {
            symbol = '+'
        }
        if (secondsData < 0) {
            symbol = '-'
        }

        let seconds = Math.abs(secondsData)
        let hours = Math.floor(seconds / 3600)
        seconds = seconds % 3600
        let minutes = Math.floor(seconds / 60)

        let hoursString = this.twoSymbolsNumber(hours)
        let minutesString = this.twoSymbolsNumber(minutes)

        return `${symbol}${hoursString}${space}:${space}${minutesString}${space}`
    },
    msToHMS(ms: number, space = ' ') {
        let seconds = ms / 1000
        let hours = Math.floor(seconds / 3600)
        seconds = seconds % 3600
        let minutes = Math.floor(seconds / 60)
        seconds = Math.floor(seconds % 60)

        let hoursString = this.twoSymbolsNumber(hours)
        let minutesString = this.twoSymbolsNumber(minutes)
        let secondsString = this.twoSymbolsNumber(seconds)

        return `${hoursString}${space}:${space}${minutesString}${space}:${space}${secondsString}`
    },
    twoSymbolsNumber(number: number): string {
        return number >= 0 && number < 10 ? `0${number}` : `${number}`
    },
    getMaxOfArray(numArray: number[]) {
        return Math.max.apply(null, numArray)
    },
    copyObject<T>(obj: T) {
        return merge.recursive(true, {}, obj)
    },
    removeDuplicates<T>(array: T[], prop: keyof T) {
        return array.filter((obj, pos, arr) => arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos)
    },
    isProd() {
        return process.env.REACT_APP_BUILD !== 'dev'
    },
    createListeners(params: ICreateApiListeners) {
        return {
            onCreateListeners: [
                (cb: (value: any) => void, clearList: string[]) =>
                    api.addObserver(
                        params.onCreate,
                        ({ catalogType, categoryName, model }: IApiListenerCb) => {
                            if (catalogType === params.type && categoryName === params.category) {
                                cb(model)
                            }
                        },
                        clearList
                    ),
            ],
            onUpdateListeners: [
                (cb: (value: any) => void, clearList: string[]) =>
                    api.addObserver(
                        params.onUpdate,
                        ({ catalogType, categoryName, model }: IApiListenerCb) => {
                            if (catalogType === params.type && categoryName === params.category) {
                                cb(model)
                            }
                        },
                        clearList
                    ),
            ],
            onDeleteListeners: [
                (cb: (value: any) => void, clearList: string[]) =>
                    api.addObserver(
                        params.onDelete,
                        ({ catalogType, categoryName, model }: IApiListenerCb) => {
                            if (catalogType === params.type && categoryName === params.category) {
                                cb(model)
                            }
                        },
                        clearList
                    ),
            ],
        }
    },
    createListenersWidget(params: ICreateWidgetApiListeners) {
        return {
            onCreateListeners: [
                (cb: (value: any) => void, clearList: string[]) =>
                    api.addObserver(
                        params.onCreate,
                        (data: any) => {
                            cb(data)
                        },
                        clearList
                    ),
            ],
            onUpdateListeners: [
                (cb: (value: any) => void, clearList: string[]) =>
                    api.addObserver(
                        params.onUpdate,
                        (data: any) => {
                            cb(data)
                        },
                        clearList
                    ),
            ],
            onDeleteListeners: [
                (cb: (value: any) => void, clearList: string[]) =>
                    api.addObserver(
                        params.onDelete,
                        (data: any) => {
                            cb(data)
                        },
                        clearList
                    ),
            ],
        }
    },
    compareNumbers(a: number, b: number) {
        return a - b
    },
    isActive: (selectedItems: any[], item: any, type: string) => {
        let active = false

        switch (type) {
            case 'displays':
                selectedItems.forEach((selectedItem) => {
                    if (item.id === selectedItem.id && item.type === selectedItem.type) {
                        active = true
                    }
                })
                break
            case 'files':
                selectedItems.forEach((selectedItem) => {
                    if (item.id === selectedItem.id && item.isDirectory === selectedItem.isDirectory) {
                        active = true
                    }
                })
                break
            default:
                selectedItems.forEach((selectedItem) => {
                    if (item.id === selectedItem.id) {
                        active = true
                    }
                })
                break
        }

        return active
    },
    getDays() {
        return [
            translate('mo'),
            translate('tu'),
            translate('we'),
            translate('th'),
            translate('fr'),
            translate('sa'),
            translate('su'),
        ]
    },
    calcStepsByDuration(scaleLength: number, step: number) {
        if (scaleLength === 0 || step === 0) {
            return []
        }
        const length = Math.floor(scaleLength / step)
        const steps = []
        const baseWidth = (step / scaleLength) * 100
        for (let i = 1; i < length; i++) {
            steps.push({
                left: baseWidth * i,
            })
        }

        return steps
    },
    calcScaleSteps(scaleLength: number, length = 10, options: { scale?: number } = {}) {
        if (!scaleLength) {
            return []
        }

        let step = Math.floor(scaleLength / length)
        if (options.scale) {
            step = step - (step % options.scale) + options.scale
        }

        const steps = []
        const baseWidth = (step / scaleLength) * 100
        const lastWidth = baseWidth + ((scaleLength - step * length) / scaleLength) * 100

        for (let i = 0; i < length; i++) {
            if (i < length - 1) {
                steps.push({
                    number: i * step,
                    width: baseWidth,
                })
            } else {
                steps.push({
                    number: i * step,
                    width: lastWidth,
                })
            }
        }

        return steps
    },
    isToday(date: string) {
        return moment(date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
    },
    getBroadcastCover(coverSource: { fileType?: TFileType; thumbnail: string }) {
        const cover: {
            isIcon: boolean
            src?: string
        } | null = {
            isIcon: true,
        }

        if (!coverSource) {
            cover.src =
                getThemeIconValue('broadcast', 'broadcast', 'previewEmpty') || `${defaultFilesIconsTheme.previewEmpty}`
        } else if (coverSource.fileType === 'office') {
            cover.src =
                getThemeIconValue('broadcast', 'broadcast', 'previewOffice') ||
                `${defaultFilesIconsTheme.previewOffice}`
        } else if (coverSource.fileType === 'audio') {
            cover.src =
                getThemeIconValue('broadcast', 'broadcast', 'previewAudio') || `${defaultFilesIconsTheme.previewAudio}`
        } else {
            cover.src = coverSource.thumbnail
            cover.isIcon = false
        }

        return cover
    },
    isListExist(list: any[]) {
        if (list) {
            if (list.length) {
                return true
            }
        }

        return false
    },
    lineBreak(string: string, method: string) {
        if (!string || !method) return

        if (method === 'string') {
            return string.replace(/<br\s*[\/]?>/gi, '\n')
        }
        if (method === 'html') {
            return string.replace(/\n/g, '<br/>')
        }

        return string
    },
    quotesConverter(string: any, method: string) {
        if (!string || !method) return

        if (method === 'string') {
            return string.replace(/&#34;+/g, '"').replace(/&#39;+/g, "'")
        }
        if (method === 'html') {
            return string.replace(/'+/g, '&#39;').replace(/"+/g, '&#34;').replace(/\\+/g, '')
        }

        return string
    },
    leadingZeroToString(value: number) {
        const num = isNaN(value) ? 0 : value
        if (num < 10) {
            return '0' + num
        }
        return num
    },
    isDevMode() {
        const host = window.location.host
        return host === 'localhost:8080' || host === 'develop.smartplayer.org'
    },
    getLabelByNumber(number: number, titles: string[]) {
        const cases = [2, 0, 1, 1, 1, 2]
        return `${titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]}`
    },
    dateDifference(lastDate: any) {
        let firstDateFormat: any = moment().format('YYYY-MM-DD')
        let lastDateFormat: any = moment(lastDate).format('YYYY-MM-DD')
        const result = moment(lastDateFormat).diff(moment(firstDateFormat), 'days')
        return result < 0 ? 0 : result
    },
    getSelectCountryList() {
        return [
            {
                id: 'Abkhazia',
                name: 'Abkhazia',
            },
            {
                id: 'Afghanistan',
                name: 'Afghanistan',
            },
            {
                id: 'Aland Islands',
                name: 'Aland Islands',
            },
            {
                id: 'Albania',
                name: 'Albania',
            },
            {
                id: 'Algeria',
                name: 'Algeria',
            },
            {
                id: 'American Samoa',
                name: 'American Samoa',
            },
            {
                id: 'Andorra',
                name: 'Andorra',
            },
            {
                id: 'Angola',
                name: 'Angola',
            },
            {
                id: 'Anguilla',
                name: 'Anguilla',
            },
            {
                id: 'Antarctica',
                name: 'Antarctica',
            },
            {
                id: 'Antigua and Barbuda',
                name: 'Antigua and Barbuda',
            },
            {
                id: 'Argentina',
                name: 'Argentina',
            },
            {
                id: 'Armenia',
                name: 'Armenia',
            },
            {
                id: 'Aruba',
                name: 'Aruba',
            },
            {
                id: 'Australia',
                name: 'Australia',
            },
            {
                id: 'Austria',
                name: 'Austria',
            },
            {
                id: 'Avdaland',
                name: 'Avdaland',
            },
            {
                id: 'Azad Jammu and Kashmir',
                name: 'Azad Jammu and Kashmir',
            },
            {
                id: 'Azania',
                name: 'Azania',
            },
            {
                id: 'Azawad',
                name: 'Azawad',
            },
            {
                id: 'Azerbaijan',
                name: 'Azerbaijan',
            },
            {
                id: 'Azores',
                name: 'Azores',
            },
            {
                id: 'Bahamas',
                name: 'Bahamas',
            },
            {
                id: 'Bahrain',
                name: 'Bahrain',
            },
            {
                id: 'Bangladesh',
                name: 'Bangladesh',
            },
            {
                id: 'Barbados',
                name: 'Barbados',
            },
            {
                id: 'Belarus',
                name: 'Belarus',
            },
            {
                id: 'Belgium',
                name: 'Belgium',
            },
            {
                id: 'Belize',
                name: 'Belize',
            },
            {
                id: 'Benin',
                name: 'Benin',
            },
            {
                id: 'Bermuda',
                name: 'Bermuda',
            },
            {
                id: 'Bir Tawil',
                name: 'Bir Tawil',
            },
            {
                id: 'Bolivia',
                name: 'Bolivia',
            },
            {
                id: 'Bonaire',
                name: 'Bonaire',
            },
            {
                id: 'Bosnia and Herzegovina',
                name: 'Bosnia and Herzegovina',
            },
            {
                id: 'Botswana',
                name: 'Botswana',
            },
            {
                id: 'Brazil',
                name: 'Brazil',
            },
            {
                id: 'British Indian Ocean Territory',
                name: 'British Indian Ocean Territory',
            },
            {
                id: 'British Virgin Islands',
                name: 'British Virgin Islands',
            },
            {
                id: 'Brunei',
                name: 'Brunei',
            },
            {
                id: 'Bulgaria',
                name: 'Bulgaria',
            },
            {
                id: 'Burkina Faso',
                name: 'Burkina Faso',
            },
            {
                id: 'Burundi',
                name: 'Burundi',
            },
            {
                id: 'Butane',
                name: 'Butane',
            },
            {
                id: 'CAR',
                name: 'CAR',
            },
            {
                id: 'Cambodia',
                name: 'Cambodia',
            },
            {
                id: 'Cameroon',
                name: 'Cameroon',
            },
            {
                id: 'Canada',
                name: 'Canada',
            },
            {
                id: 'Canary Islands',
                name: 'Canary Islands',
            },
            {
                id: 'Cape Verde',
                name: 'Cape Verde',
            },
            {
                id: 'Cayman islands',
                name: 'Cayman islands',
            },
            {
                id: 'Ceuta',
                name: 'Ceuta',
            },
            {
                id: 'Chad',
                name: 'Chad',
            },
            {
                id: 'Chile',
                name: 'Chile',
            },
            {
                id: 'China',
                name: 'China',
            },
            {
                id: 'Christmas Island',
                name: 'Christmas Island',
            },
            {
                id: 'Coconut Islands',
                name: 'Coconut Islands',
            },
            {
                id: 'Colombia',
                name: 'Colombia',
            },
            {
                id: 'Comoros',
                name: 'Comoros',
            },
            {
                id: 'Cook Islands',
                name: 'Cook Islands',
            },
            {
                id: 'Costa Rica',
                name: 'Costa Rica',
            },
            {
                id: 'Croatia',
                name: 'Croatia',
            },
            {
                id: 'Cuba',
                name: 'Cuba',
            },
            {
                id: 'Curacao',
                name: 'Curacao',
            },
            {
                id: 'Cyprus',
                name: 'Cyprus',
            },
            {
                id: 'Czech',
                name: 'Czech',
            },
            {
                id: 'DR Congo',
                name: 'DR Congo',
            },
            {
                id: 'Denmark',
                name: 'Denmark',
            },
            {
                id: 'Djibouti',
                name: 'Djibouti',
            },
            {
                id: 'Dominica',
                name: 'Dominica',
            },
            {
                id: 'Dominican Republic',
                name: 'Dominican Republic',
            },
            {
                id: 'East Timor',
                name: 'East Timor',
            },
            {
                id: 'Ecuador',
                name: 'Ecuador',
            },
            {
                id: 'Egypt',
                name: 'Egypt',
            },
            {
                id: 'Equatorial Guinea',
                name: 'Equatorial Guinea',
            },
            {
                id: 'Eritrea',
                name: 'Eritrea',
            },
            {
                id: 'Estonia',
                name: 'Estonia',
            },
            {
                id: 'Eswatini',
                name: 'Eswatini',
            },
            {
                id: 'Ethiopia',
                name: 'Ethiopia',
            },
            {
                id: 'Falkland Islands',
                name: 'Falkland Islands',
            },
            {
                id: 'Faroe islands',
                name: 'Faroe islands',
            },
            {
                id: 'Federated States of Micronesia',
                name: 'Federated States of Micronesia',
            },
            {
                id: 'Fiji',
                name: 'Fiji',
            },
            {
                id: 'Finland',
                name: 'Finland',
            },
            {
                id: 'France',
                name: 'France',
            },
            {
                id: 'French Southern and Antarctic Territories',
                name: 'French Southern and Antarctic Territories',
            },
            {
                id: 'French polynesia',
                name: 'French polynesia',
            },
            {
                id: 'Gabon',
                name: 'Gabon',
            },
            {
                id: 'Galmudug',
                name: 'Galmudug',
            },
            {
                id: 'Gambia',
                name: 'Gambia',
            },
            {
                id: 'Georgia',
                name: 'Georgia',
            },
            {
                id: 'Germany',
                name: 'Germany',
            },
            {
                id: 'Ghana',
                name: 'Ghana',
            },
            {
                id: 'Gibraltar',
                name: 'Gibraltar',
            },
            {
                id: 'Golan heights',
                name: 'Golan heights',
            },
            {
                id: 'Great Britain',
                name: 'Great Britain',
            },
            {
                id: 'Greece',
                name: 'Greece',
            },
            {
                id: 'Greenland',
                name: 'Greenland',
            },
            {
                id: 'Grenada',
                name: 'Grenada',
            },
            {
                id: 'Guadeloupe',
                name: 'Guadeloupe',
            },
            {
                id: 'Guam',
                name: 'Guam',
            },
            {
                id: 'Guatemala',
                name: 'Guatemala',
            },
            {
                id: 'Guernsey',
                name: 'Guernsey',
            },
            {
                id: 'Guiana',
                name: 'Guiana',
            },
            {
                id: 'Guinea-Bissau',
                name: 'Guinea-Bissau',
            },
            {
                id: 'Guyana',
                name: 'Guyana',
            },
            {
                id: 'Haiti',
                name: 'Haiti',
            },
            {
                id: 'Hawaii',
                name: 'Hawaii',
            },
            {
                id: 'Himan and Heb',
                name: 'Himan and Heb',
            },
            {
                id: 'Honduras',
                name: 'Honduras',
            },
            {
                id: 'Hong Kong',
                name: 'Hong Kong',
            },
            {
                id: 'Hungary',
                name: 'Hungary',
            },
            {
                id: 'Iceland',
                name: 'Iceland',
            },
            {
                id: 'India',
                name: 'India',
            },
            {
                id: 'Indonesia',
                name: 'Indonesia',
            },
            {
                id: 'Iran',
                name: 'Iran',
            },
            {
                id: 'Iraq',
                name: 'Iraq',
            },
            {
                id: 'Ireland',
                name: 'Ireland',
            },
            {
                id: 'Isle Of Man',
                name: 'Isle Of Man',
            },
            {
                id: 'Israel',
                name: 'Israel',
            },
            {
                id: 'Italy',
                name: 'Italy',
            },
            {
                id: 'Ivory Coast',
                name: 'Ivory Coast',
            },
            {
                id: 'Jamaica',
                name: 'Jamaica',
            },
            {
                id: 'Japan',
                name: 'Japan',
            },
            {
                id: 'Jersey',
                name: 'Jersey',
            },
            {
                id: 'Jordan',
                name: 'Jordan',
            },
            {
                id: 'Jubaland',
                name: 'Jubaland',
            },
            {
                id: 'Kazakhstan',
                name: 'Kazakhstan',
            },
            {
                id: 'Kenya',
                name: 'Kenya',
            },
            {
                id: 'Kiribati',
                name: 'Kiribati',
            },
            {
                id: 'Kuwait',
                name: 'Kuwait',
            },
            {
                id: 'Kyrgyzstan',
                name: 'Kyrgyzstan',
            },
            {
                id: 'Laos',
                name: 'Laos',
            },
            {
                id: 'Latvia',
                name: 'Latvia',
            },
            {
                id: 'Lebanon',
                name: 'Lebanon',
            },
            {
                id: 'Lesotho',
                name: 'Lesotho',
            },
            {
                id: 'Liberia',
                name: 'Liberia',
            },
            {
                id: 'Libya',
                name: 'Libya',
            },
            {
                id: 'Liechtenstein',
                name: 'Liechtenstein',
            },
            {
                id: 'Lithuania',
                name: 'Lithuania',
            },
            {
                id: 'Luxembourg',
                name: 'Luxembourg',
            },
            {
                id: 'Macau',
                name: 'Macau',
            },
            {
                id: 'Macedonia',
                name: 'Macedonia',
            },
            {
                id: 'Madagascar',
                name: 'Madagascar',
            },
            {
                id: 'Madeira',
                name: 'Madeira',
            },
            {
                id: 'Malawi',
                name: 'Malawi',
            },
            {
                id: 'Malaysia',
                name: 'Malaysia',
            },
            {
                id: 'Maldives',
                name: 'Maldives',
            },
            {
                id: 'Mali',
                name: 'Mali',
            },
            {
                id: 'Malta',
                name: 'Malta',
            },
            {
                id: 'Marshall Islands',
                name: 'Marshall Islands',
            },
            {
                id: 'Martinique',
                name: 'Martinique',
            },
            {
                id: 'Mauritania',
                name: 'Mauritania',
            },
            {
                id: 'Mauritius',
                name: 'Mauritius',
            },
            {
                id: 'Mayotte',
                name: 'Mayotte',
            },
            {
                id: 'Melilla',
                name: 'Melilla',
            },
            {
                id: 'Mexico',
                name: 'Mexico',
            },
            {
                id: 'Moldavian Republic',
                name: 'Moldavian Republic',
            },
            {
                id: 'Moldova',
                name: 'Moldova',
            },
            {
                id: 'Monaco',
                name: 'Monaco',
            },
            {
                id: 'Mongolia',
                name: 'Mongolia',
            },
            {
                id: 'Montenegro',
                name: 'Montenegro',
            },
            {
                id: 'Montserrat',
                name: 'Montserrat',
            },
            {
                id: 'Morocco',
                name: 'Morocco',
            },
            {
                id: 'Mozambique',
                name: 'Mozambique',
            },
            {
                id: 'Myanmar',
                name: 'Myanmar',
            },
            {
                id: 'Nagorno-Karabakh Republic',
                name: 'Nagorno-Karabakh Republic',
            },
            {
                id: 'Namibia',
                name: 'Namibia',
            },
            {
                id: 'Nauru',
                name: 'Nauru',
            },
            {
                id: 'Nepal',
                name: 'Nepal',
            },
            {
                id: 'Netherlands',
                name: 'Netherlands',
            },
            {
                id: 'New Caledonia',
                name: 'New Caledonia',
            },
            {
                id: 'New Zealand',
                name: 'New Zealand',
            },
            {
                id: 'Nicaragua',
                name: 'Nicaragua',
            },
            {
                id: 'Niger',
                name: 'Niger',
            },
            {
                id: 'Nigeria',
                name: 'Nigeria',
            },
            {
                id: 'Niue',
                name: 'Niue',
            },
            {
                id: 'Norfolk Island',
                name: 'Norfolk Island',
            },
            {
                id: 'North Korea North',
                name: 'North Korea North',
            },
            {
                id: 'Northern Mariana Islands',
                name: 'Northern Mariana Islands',
            },
            {
                id: 'Norway',
                name: 'Norway',
            },
            {
                id: 'Oman Order of Malta',
                name: 'Oman Order of Malta',
            },
            {
                id: 'Pakistan',
                name: 'Pakistan',
            },
            {
                id: 'Palau',
                name: 'Palau',
            },
            {
                id: 'Panama',
                name: 'Panama',
            },
            {
                id: 'Papua New Guinea',
                name: 'Papua New Guinea',
            },
            {
                id: 'Paraguay',
                name: 'Paraguay',
            },
            {
                id: 'Peru',
                name: 'Peru',
            },
            {
                id: 'Philippines',
                name: 'Philippines',
            },
            {
                id: 'Pitcairn Islands',
                name: 'Pitcairn Islands',
            },
            {
                id: 'Poland',
                name: 'Poland',
            },
            {
                id: 'Portugal',
                name: 'Portugal',
            },
            {
                id: 'Pridnestrovian',
                name: 'Pridnestrovian',
            },
            {
                id: 'Puerto Rico',
                name: 'Puerto Rico',
            },
            {
                id: 'Puntland',
                name: 'Puntland',
            },
            {
                id: 'Qatar',
                name: 'Qatar',
            },
            {
                id: 'Republic of Kosovo',
                name: 'Republic of Kosovo',
            },
            {
                id: 'Republic of Northern Cyprus',
                name: 'Republic of Northern Cyprus',
            },
            {
                id: 'Republic of the Congo',
                name: 'Republic of the Congo',
            },
            {
                id: 'Reunion',
                name: 'Reunion',
            },
            {
                id: 'Romania',
                name: 'Romania',
            },
            {
                id: 'Russia',
                name: 'Russia',
            },
            {
                id: 'Rwanda',
                name: 'Rwanda',
            },
            {
                id: 'Saba',
                name: 'Saba',
            },
            {
                id: 'Saharan Arab Democratic Republic',
                name: 'Saharan Arab Democratic Republic',
            },
            {
                id: 'Saint Barthelemy',
                name: 'Saint Barthelemy',
            },
            {
                id: 'Saint Helena, Ascension and Tristan da Cunha',
                name: 'Saint Helena, Ascension and Tristan da Cunha',
            },
            {
                id: 'Saint Kitts and Nevis',
                name: 'Saint Kitts and Nevis',
            },
            {
                id: 'Saint Lucia',
                name: 'Saint Lucia',
            },
            {
                id: 'Saint Martin',
                name: 'Saint Martin',
            },
            {
                id: 'Saint Pierre and Miquelon',
                name: 'Saint Pierre and Miquelon',
            },
            {
                id: 'Saint Vincent and the Grenadines',
                name: 'Saint Vincent and the Grenadines',
            },
            {
                id: 'Salvador',
                name: 'Salvador',
            },
            {
                id: 'Samoa',
                name: 'Samoa',
            },
            {
                id: 'San Marino',
                name: 'San Marino',
            },
            {
                id: 'Sao Tome and Principe',
                name: 'Sao Tome and Principe',
            },
            {
                id: 'Saudi Arabia',
                name: 'Saudi Arabia',
            },
            {
                id: 'Sealand',
                name: 'Sealand',
            },
            {
                id: 'Senegal',
                name: 'Senegal',
            },
            {
                id: 'Serbia',
                name: 'Serbia',
            },
            {
                id: 'Seychelles',
                name: 'Seychelles',
            },
            {
                id: 'Shang State',
                name: 'Shang State',
            },
            {
                id: 'Sierra Leone',
                name: 'Sierra Leone',
            },
            {
                id: 'Singapore',
                name: 'Singapore',
            },
            {
                id: 'Sint Eustatius',
                name: 'Sint Eustatius',
            },
            {
                id: 'Sint Maarten',
                name: 'Sint Maarten',
            },
            {
                id: 'Slovakia',
                name: 'Slovakia',
            },
            {
                id: 'Slovenia',
                name: 'Slovenia',
            },
            {
                id: 'Solomon islands',
                name: 'Solomon islands',
            },
            {
                id: 'Somalia',
                name: 'Somalia',
            },
            {
                id: 'Somaliland',
                name: 'Somaliland',
            },
            {
                id: 'South Africa',
                name: 'South Africa',
            },
            {
                id: 'South Georgia and the South Sandwich Islands',
                name: 'South Georgia and the South Sandwich Islands',
            },
            {
                id: 'South Ossetia',
                name: 'South Ossetia',
            },
            {
                id: 'South Sudan',
                name: 'South Sudan',
            },
            {
                id: 'Spain',
                name: 'Spain',
            },
            {
                id: 'Sri Lanka',
                name: 'Sri Lanka',
            },
            {
                id: 'State of Palestine',
                name: 'State of Palestine',
            },
            {
                id: 'State of Wa',
                name: 'State of Wa',
            },
            {
                id: 'Sudan',
                name: 'Sudan',
            },
            {
                id: 'Suriname',
                name: 'Suriname',
            },
            {
                id: 'Svalbard',
                name: 'Svalbard',
            },
            {
                id: 'Sweden',
                name: 'Sweden',
            },
            {
                id: 'Switzerland',
                name: 'Switzerland',
            },
            {
                id: 'Syria',
                name: 'Syria',
            },
            {
                id: 'Tajikistan',
                name: 'Tajikistan',
            },
            {
                id: 'Tanzania',
                name: 'Tanzania',
            },
            {
                id: 'Thailand',
                name: 'Thailand',
            },
            {
                id: 'The Republic of Korea',
                name: 'The Republic of Korea',
            },
            {
                id: 'Togo',
                name: 'Togo',
            },
            {
                id: 'Tokelau',
                name: 'Tokelau',
            },
            {
                id: 'Tonga',
                name: 'Tonga',
            },
            {
                id: 'Trinidad and Tobago',
                name: 'Trinidad and Tobago',
            },
            {
                id: 'Tunisia',
                name: 'Tunisia',
            },
            {
                id: 'Turkish',
                name: 'Turkish',
            },
            {
                id: 'Turkiye',
                name: 'Turkiye',
            },
            {
                id: 'Turkmenistan',
                name: 'Turkmenistan',
            },
            {
                id: 'Turks and Caicos',
                name: 'Turks and Caicos',
            },
            {
                id: 'Tuvalu',
                name: 'Tuvalu',
            },
            {
                id: 'UAE',
                name: 'UAE',
            },
            {
                id: 'US Virgin Islands',
                name: 'US Virgin Islands',
            },
            {
                id: 'USA',
                name: 'USA',
            },
            {
                id: 'Uganda',
                name: 'Uganda',
            },
            {
                id: 'Ukraine',
                name: 'Ukraine',
            },
            {
                id: 'Uruguay',
                name: 'Uruguay',
            },
            {
                id: 'Uzbekistan',
                name: 'Uzbekistan',
            },
            {
                id: 'Vanuatu',
                name: 'Vanuatu',
            },
            {
                id: 'Vatican',
                name: 'Vatican',
            },
            {
                id: 'Venezuela',
                name: 'Venezuela',
            },
            {
                id: 'Vietnam',
                name: 'Vietnam',
            },
            {
                id: 'Wallis and Futuna',
                name: 'Wallis and Futuna',
            },
            {
                id: 'Waziristan',
                name: 'Waziristan',
            },
            {
                id: 'Yemen',
                name: 'Yemen',
            },
            {
                id: 'Zambia',
                name: 'Zambia',
            },
            {
                id: 'Zimbabwe',
                name: 'Zimbabwe',
            },
        ]
    },
}
