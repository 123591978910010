import React from 'react'
import TabsMethods from './tabs.local.methods'
import { grid, item } from '../../theme'
import { cn } from 'ethcss'
import styles from './tabs.jcss'

export interface ITabsProps {
    active?: string
    onChange: (tab?: string) => void
    wrapperClassName?: string | {}
    className: string | {}
    children: any
    tabsIsHidden: boolean
    tabWrapper?: string | {}
}

const Tabs = (p_: ITabsProps) => {
    const { active, onClick, getTabFromId } = TabsMethods(p_)

    return (
        <div className={cn(styles.fullContent, p_.wrapperClassName)}>
            <div className={grid.row}>
                {!p_.tabsIsHidden &&
                    p_.children &&
                    p_.children.map(
                        (
                            tab: {
                                props: {
                                    tabid: string
                                    disabled: boolean
                                    label: string
                                }
                            },
                            index: number
                        ) => (
                            <div
                                key={index}
                                className={cn(
                                    styles.tab,
                                    {
                                        [styles.tabActive]: active === tab.props.tabid,
                                        [item.disableWrapper]: tab.props.disabled,
                                    },
                                    p_.tabWrapper
                                )}
                                onClick={() => {
                                    if (tab.props.disabled) {
                                        return
                                    }

                                    onClick(tab.props.tabid)
                                }}
                            >
                                {tab.props.label}
                            </div>
                        )
                    )}
            </div>
            <div className={cn(p_.className, grid.space)}>{getTabFromId(active)}</div>
        </div>
    )
}

Tabs.defaultProps = {
    onChange: () => {},
    className: grid.p_md,
    tabsIsHidden: false,
}

export default Tabs
