import React, { useEffect, useState } from 'react'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import DndDraggable from 'blocks.app/dnd/_draggable/dnd_draggable'
import DotTooltip from 'blocks.simple/dotTooltip/dotTooltip'
import Dotdotdot from 'react-dotdotdot'
import displaysHelpers from 'core/helpers/_digitalSignages'
import { cn } from 'ethcss'
import styles from './catalog__item_broadcasts.jcss'
import { text as textStyles } from 'theme'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import broadcastsHelpers from 'core/helpers/_broadcasts'
import { indents } from 'blocks.app/config'
import helpers from 'core/helpers'
import { ProgressBar } from 'atoms/ProgressBar'
import Icon from 'blocks.simple/icon/icon'

type Item = {
    id: number
    instanceType: string
    __view: {
        selected: boolean
    }
    downloadPercentage: {
        title: string
        percentage: number
    }[]
    cover: {
        src: string
        isIcon: boolean
    }
    title: string
    orientation: number
}

interface IItemBroadcastsProps {
    active: boolean
    item: Item
    onDoubleClickObject: (item: Item) => void
    onSelectFolder: (item: Item) => void
    drag: true
    onDragStart: () => void
    onDragEnd: () => void
    multipleSelect: boolean
    onSelect: (data: { selected: boolean; item: Item }) => void
    onSelectProgress: () => void
    onSelectInfo: (e: any) => void
    getDragData: (data: IItemBroadcastsProps) => void
}

const Progress = ({ list, onClick }: { list: { title: string; percentage: number }[]; onClick: () => void }) => {
    if (list && list.length > 0) {
        return (
            <div className={cn(styles.loading)} onClick={onClick}>
                <div className={styles.progress}>
                    <ProgressBar value={list[0].percentage} strokeWidth={17} />
                </div>
                <div className={cn(grid.row, grid.noWrap, grid.w75, item.ellipsisPure)}>
                    <div className={item.ellipsisPure}>
                        {list[0].title}
                        <span>&nbsp;</span>
                    </div>
                    <div>{Math.round(list[0].percentage)}%</div>
                </div>
            </div>
        )
    } else {
        return null
    }
}

const ItemBroadcasts = (p_: IItemBroadcastsProps) => {
    const [dragData, setDragData] = useState(p_.getDragData(p_))
    const [isItemDrag, setIsItemDrag] = useState(false)

    useEffect(() => {
        setDragData(p_.getDragData(p_))
    }, [p_])

    const active = p_.active || p_.item.__view.selected
    const isDirectory = broadcastsHelpers.isDirectory(p_.item)
    const downloads = p_.item.downloadPercentage
    let broadcastCover: {
        backgroundSize?: string
        backgroundImage?: string
    } = {}

    const OrientationIcon = (p_: { orientation: number }) => {
        const orientation: { id: number; name: string; fullName: string } | undefined = displaysHelpers.getOrientation(
            p_.orientation
        )

        if (!orientation) {
            return null
        }

        function getRotate() {
            switch (orientation!.id) {
                case 0:
                case 180:
                    return 'right'
                case 90:
                case 270:
                    return 'left'
                default:
                    return ''
            }
        }

        return (
            <Icon
                containerClass={styles.orientationIcon}
                color="white"
                size={20}
                type="horizontal_pos"
                rotate={getRotate()}
                tooltip={{
                    title: orientation.fullName,
                }}
            />
        )
    }

    if (!isDirectory) {
        broadcastCover = {
            backgroundSize: p_.item.cover.isIcon ? 'contain' : 'cover',
        }

        if (p_.item.cover.src) {
            broadcastCover.backgroundImage = `url(${p_.item.cover.src})`
        }
    }

    return (
        <div
            onClick={function (e) {
                if (helpers.isDblTouchTap(e)) {
                    if (!isDirectory) {
                        p_.onDoubleClickObject(p_.item)

                        e.preventDefault()
                        return
                    }

                    p_.onSelectFolder(p_.item)
                    return
                }

                p_.onSelectInfo(e)
            }}
            onContextMenu={(e) => {
                if (helpers.isMobileDevice()) {
                    e.preventDefault()
                }
            }}
            id={`broadcast_${p_.item.instanceType}_${p_.item.id}`}
        >
            {!isDirectory && (
                <Rectangle width={16} height={9} className={styles.wrapper}>
                    <DndDraggable
                        data={dragData}
                        dragEnabled={p_.drag}
                        className={styles.broadcast}
                        style={broadcastCover}
                        onDragStart={() => {
                            p_.onDragStart()
                            setIsItemDrag(true)
                        }}
                        onDragEnd={() => {
                            p_.onDragEnd()
                            setIsItemDrag(false)
                        }}
                    >
                        <div className={styles.contentBroadcast}>
                            {p_.multipleSelect && (
                                <div
                                    className={styles.checkWrapper}
                                    onClick={(e) => e.stopPropagation()}
                                    style={p_.item.__view.selected ? { opacity: 1 } : {}}
                                >
                                    <Checkbox
                                        mod={'circleInv'}
                                        onClick={(selected: boolean) => {
                                            p_.onSelect({ selected, item: p_.item })
                                        }}
                                        checked={p_.item.__view.selected}
                                    />
                                </div>
                            )}
                            <DotTooltip
                                id={p_.item.id}
                                tooltip={{ html: p_.item.title, disabled: isItemDrag }}
                                containerClass={styles.name}
                                portalId={`broadcast_${p_.item.instanceType}_${p_.item.id}`}
                            >
                                <div className={styles.infoContainer}>
                                    <div className={styles.nameInner}>{p_.item.title}</div>
                                    <OrientationIcon orientation={p_.item.orientation} />
                                </div>
                            </DotTooltip>
                            <div className={cn(styles.preview, grid.full, { [item.blackOpacity]: active })} />
                            <div style={{ bottom: indents.nano, left: indents.nano }} className={cn(item.abs)}>
                                <Progress list={downloads} onClick={p_.onSelectProgress} />
                            </div>
                            <div className={cn(grid.col, grid.full)} />
                        </div>
                    </DndDraggable>
                </Rectangle>
            )}
            {isDirectory && (
                <Rectangle width={16} height={9} className={styles.groupWrapper}>
                    <DndDraggable
                        data={dragData}
                        dragEnabled={p_.drag}
                        className={styles.group}
                        onDragStart={() => {
                            p_.onDragStart()
                            setIsItemDrag(true)
                        }}
                        onDragEnd={() => {
                            p_.onDragEnd()
                            setIsItemDrag(false)
                        }}
                    >
                        <div className={styles.content}>
                            {p_.multipleSelect && (
                                <div
                                    className={styles.checkWrapper}
                                    onClick={(e) => e.stopPropagation()}
                                    style={p_.item.__view.selected ? { opacity: 1 } : {}}
                                >
                                    <Checkbox
                                        mod={'circleInv'}
                                        onClick={(selected: boolean) => {
                                            p_.onSelect({ selected, item: p_.item })
                                        }}
                                        checked={p_.item.__view.selected}
                                    />
                                </div>
                            )}
                            <div className={cn(grid.colCenter, grid.full, { [item.blackOpacity]: active })}>
                                <div className={cn(textStyles.center, textStyles.white, grid.w90)}>
                                    <div className={cn(grid.w100, grid.p_micro, textStyles.normalPlus)}>
                                        <Dotdotdot clamp={2}>{p_.item.title}</Dotdotdot>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DndDraggable>
                    <div className={styles.groupBackWrapper}>
                        <div className={cn(styles.groupBack, styles.groupBack_second)} />
                    </div>
                    <div className={styles.groupBackWrapper}>
                        <div className={cn(styles.groupBack, styles.groupBack_first)} />
                    </div>
                </Rectangle>
            )}
        </div>
    )
}

export default ItemBroadcasts
