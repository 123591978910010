import { useEffect, useState } from 'react'
import translate from 'core/translate'
import moment from 'moment'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import helpersBroadcasts from 'core/helpers/_broadcasts'
import { api } from 'core/api/ConnectionManager'
import { routes } from 'features/routes'
import { changeLocation } from 'features/routes'
import { reportInitializedNotification } from 'blocks.app/app/__notifications/app__notifications'
import { reportActions } from './report.state'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { emitError } from '../../features/appNotifications/AppNotifications.state'
import { IFile } from '../../core/models/Files'

type Location = {
    pathname: string
    search: string
    hash: string
    state: any
}

const getReportType = (reportType: string, devicesReportType: string) => {
    switch (reportType) {
        case 'files':
            return 'folderReport'
        case 'broadcasts':
            return 'broadcastReport'
        case 'advertisingCampaigns':
            return 'advertisingCampaignReport'
        case 'photoBoothSurveyReport':
            return 'photoBoothSurveyReport'
        case 'licenseReport':
            return 'licenseReport'
        case 'currencyReport':
            return 'currencyReport'
        default:
            if (!devicesReportType) {
                return 'digitalSignageReport'
            }
            return devicesReportType
    }
}

type IDate = {
    startDate: number
    endDate: number
    startTime: string
    endTime: string
}

export const ReportMethodsHooks = () => {
    const report: any = useSelector<RootStateOrAny>((state) => state.report)
    const dispatch = useDispatch()
    const [period, setPeriod] = useState<string>('day')
    const reports = [
        {
            id: 'displays',
            name: translate('devices'),
        },
        {
            id: 'broadcasts',
            name: translate('broadcasts'),
        },
        {
            id: 'files',
            name: translate('files'),
        },
        {
            id: 'advertisingCampaigns',
            name: translate('advertisingCampaigns'),
        },
    ]

    const clearData = () => dispatch(reportActions.clearData())
    const setDate = (data: IDate) => dispatch(reportActions.setDate(data))
    const setStartDate = (data: number) => dispatch(reportActions.setStartDate(data))
    const setEndDate = (data: number) => dispatch(reportActions.setEndDate(data))
    const setStartTime = (data: string) => dispatch(reportActions.setStartTime(data))
    const setEndTime = (data: string) => dispatch(reportActions.setEndTime(data))
    const setMailReport = (data: boolean) => dispatch(reportActions.setMailReport(data))
    const setDevicesReportType = (data: string) => dispatch(reportActions.setDevicesReportType(data))
    const setReportFiles = (data: any) => dispatch(reportActions.setFiles(data))
    const setReportBroadcasts = (data: any) => dispatch(reportActions.setBroadcasts(data))
    const setReportAdvertisingCampaigns = (data: any) => dispatch(reportActions.setAdvertisingCampaigns(data))
    const setReportDevices = (data: any) => dispatch(reportActions.setDevices(data))
    const showError = (err: string) => dispatch(emitError(err))

    const getTitle = (location: Location) => {
        const query = getURLSearchParamsByLocation(location)
        const reportType = query.reportType || 'displays'

        switch (reportType) {
            case 'files':
                return translate('advFilesEmpty')
            case 'broadcasts':
                return translate('advBroadcastsEmpty')
            case 'advertisingCampaigns':
                return translate('advReportEmpty')
            default:
                return translate('advDevicesEmpty')
        }
    }

    const selectPeriod = (selected: { id: any }) => {
        const periods = ['day', 'week', 'month']
        const period = selected.id

        if (periods.includes(period)) {
            setDate({
                startDate: moment().subtract(1, period).toDate().getTime(),
                endDate: moment().toDate().getTime(),
                startTime: moment().startOf('day').format('HH:mm:ss'),
                endTime: moment().endOf('day').format('HH:mm:ss'),
            })
        }

        setPeriod(period)
    }
    const submit = (action: { e: any; reportType: string }) => {
        const {
            startDate,
            endDate,
            startTime,
            endTime,
            advertisingCampaigns,
            devices,
            devicesReportType,
            files,
            broadcasts,
            mailReport,
        } = report

        action.e.preventDefault()

        const startDateMoment = moment(startDate).format('YYYY-MM-DD')
        const endDateMoment = moment(endDate).format('YYYY-MM-DD')

        const startDateUTC = moment(`${startDateMoment} ${startTime}`, 'YYYY-MM-DD HH:mm:ss').utc()
        const endDateUTC = moment(`${endDateMoment} ${endTime}`, 'YYYY-MM-DD HH:mm:ss').utc()

        let data: any = {
            startDate: `${startDateUTC.format('YYYY-MM-DD')} ${startDateUTC.format('HH:mm:ss')}`,
            endDate: `${endDateUTC.format('YYYY-MM-DD')} ${endDateUTC.format('HH:mm:ss')}`,
            email: mailReport,
        }

        if (validate(action.reportType)) {
            switch (action.reportType) {
                case 'files':
                    data = {
                        ...data,
                        folderId: files.filter((file: IFile) => file.isDirectory).map((file: IFile) => file.id),
                        sourceId: files.filter((file: IFile) => !file.isDirectory).map((file: IFile) => file.id),
                    }
                    break
                case 'broadcasts':
                    data = { ...data, ...getIdsFromBroadcasts(broadcasts) }
                    break
                case 'advertisingCampaigns':
                    data = { ...data, advertisingCampaignId: advertisingCampaigns.map((adv: any) => adv.id) }
                    break
                case 'photoBoothSurveyReport':
                    data = { ...data, utcOffsetMinutes: moment().utcOffset() }
                    break
                case 'licenseReport':
                    data = { ...data }
                    break
                case 'currencyReport':
                    data = { ...data, utcOffsetMinutes: moment().utcOffset() }
                    break
                default:
                    data = { ...data, ...getDsFromDevices(devices) }
                    break
            }

            if (devicesReportType === 'totalCostReport') {
                return reportInitializedNotification()
            }

            api.send('createReportTask', {
                ...data,
                reportType: getReportType(action.reportType, devicesReportType),
            }).then(() => {
                changeLocation(`/${routes.reportTasks.path}`)
                clearData()
            })
        }
    }
    const validate = (reportType: string) => {
        const { files, broadcasts, advertisingCampaigns, devices } = report

        switch (reportType) {
            case 'files':
                if (files.length === 0) {
                    showError('advFilesEmpty')
                }

                return files.length > 0
            case 'broadcasts':
                if (broadcasts.length === 0) {
                    showError('advBroadcastsEmpty')
                }

                return broadcasts.length > 0
            case 'advertisingCampaigns':
                if (!advertisingCampaigns.length) {
                    showError('advReportEmpty')
                }
                return !!advertisingCampaigns.length
            case 'photoBoothSurveyReport':
            case 'licenseReport':
            case 'currencyReport':
                return true
            default:
                if (devices.length === 0) {
                    showError('advDevicesEmpty')
                }

                return devices.length > 0
        }
    }
    const getDsFromDevices = (devices: any) => {
        const groupId: any = []
        const digitalSignageId: any = []

        devices.forEach((device: any) => {
            if (device.type === 'group') {
                groupId.push(device.id)
            } else {
                digitalSignageId.push(device.id)
            }
        })

        return { groupId, digitalSignageId }
    }
    const getIdsFromBroadcasts = (broadcasts: any) => {
        const broadcastFolderId: number[] = []
        const broadcastId: any = []

        broadcasts.forEach((broadcast: any) => {
            if (helpersBroadcasts.isFolder(broadcast)) {
                broadcastFolderId.push(broadcast.id)
            } else {
                broadcastId.push(broadcast.id)
            }
        })

        return { broadcastFolderId, broadcastId }
    }
    const setReportItems = (reportType: string, selectedItems: any) => {
        switch (reportType) {
            case 'broadcasts':
                return setReportBroadcasts(selectedItems)
            case 'files':
                return setReportFiles(selectedItems)
            case 'advertisingCampaigns':
                return setReportAdvertisingCampaigns(selectedItems)
            default:
                return setReportDevices(selectedItems)
        }
    }

    useEffect(() => {
        clearData()
        selectPeriod({ id: 'week' })
    }, [])

    return {
        reports,
        report,
        period,
        setStartDate,
        setEndDate,
        setStartTime,
        setEndTime,
        setMailReport,
        setDevicesReportType,
        setReportItems,
        submit,
        getTitle,
        setPeriod,
        selectPeriod,
    }
}
