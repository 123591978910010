import React from 'react'
import styles from './monitorWindow.jcss'
import Icon from '../icon/icon'
import { DSLogMonitor } from '../../pages/displays/__logMonitor/DSLogMonitor'
import { MonitorWindowMethods } from './MonitorWindowMethods'
import { cn } from 'ethcss'
import DotTooltip from 'blocks.simple/dotTooltip/dotTooltip'
import translate from '../../core/translate'
import { CustomScroll } from '../../atoms/CustomScroll'

type Data = {
    type: string
    title?: string
    options?: any
}
export const MonitorWindow = ({ data }: { data: Data }) => {
    const {
        onClose,
        onMinimize,
        onMaximize,
        windowStyles,
        minimize,
        onDragStart,
        onMouseDown,
        monitorRef,
        resizerLeftRef,
        resizerRightRef,
        resizerTopRef,
        resizerBottomRef,
        downloadLog,
        setDownloadLog,
        contentRef,
        actionScroll,
        toggleAutoscroll,
        scrollToBottom,
    } = MonitorWindowMethods()
    const renderContent = () => {
        switch (data.type) {
            case 'digitalSignageLogMonitor':
                return <DSLogMonitor options={data.options} download={downloadLog} actionScroll={actionScroll} />
            default:
                return null
        }
    }
    const getTitle = () => {
        return (
            <DotTooltip
                tooltip={{
                    html: data.title,
                    position: 'left',
                    disabled: false,
                    followCursor: true,
                }}
                containerClass={cn(styles.menuItem__text)}
            >
                <div className={styles.title}>
                    {!minimize && <b>{translate('monitoring')}: </b>}
                    {data.title}
                </div>
            </DotTooltip>
        )
    }

    return (
        <div
            ref={monitorRef}
            className={styles.monitorWindow}
            style={windowStyles ? windowStyles : {}}
            onMouseDown={onMouseDown}
            onDragStart={onDragStart}
        >
            <div
                ref={resizerLeftRef}
                className={cn(styles.resizer, styles.resizer_l, minimize ? styles.noDraggable : '')}
            />
            <div
                ref={resizerTopRef}
                className={cn(styles.resizer, styles.resizer_t, minimize ? styles.noDraggable : '')}
            />
            <div
                ref={resizerRightRef}
                className={cn(styles.resizer, styles.resizer_r, minimize ? styles.noDraggable : '')}
            />
            <div
                ref={resizerBottomRef}
                className={cn(styles.resizer, styles.resizer_b, minimize ? styles.noDraggable : '')}
            />
            <div className={styles.header}>
                {getTitle()}
                <div className={styles.actions}>
                    <div className={styles.action}>
                        <div className={styles.downloadLog} onClick={toggleAutoscroll}>
                            {!scrollToBottom ? (
                                <Icon
                                    type={'auto_scroll'}
                                    size={14}
                                    color={'blackLight'}
                                    tooltip={{
                                        title: translate('enableAutoScroll'),
                                    }}
                                />
                            ) : (
                                <Icon
                                    type={'auto_scroll_disable'}
                                    size={16}
                                    color={'blackLight'}
                                    tooltip={{
                                        title: translate('disableAutoScroll'),
                                    }}
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.action}>
                        <div className={styles.downloadLog} onClick={() => setDownloadLog(true)}>
                            <Icon
                                type={'download'}
                                size={14}
                                color={'blackLight'}
                                tooltip={{
                                    title: translate('downloadLogs'),
                                }}
                            />
                        </div>
                    </div>
                    <div className={cn(styles.action, minimize ? styles.disabled : '')} onClick={onMaximize}>
                        <Icon
                            type={'maximize_hollow'}
                            size={14}
                            color={'blackLight'}
                            tooltip={{
                                title: translate('fullScreen'),
                            }}
                        />
                    </div>
                    <div className={styles.action} onClick={onMinimize}>
                        <Icon
                            type={'minimize_line'}
                            size={14}
                            color={'blackLight'}
                            tooltip={{
                                title: translate('minimize'),
                            }}
                        />
                    </div>
                    <div className={styles.action} onClick={onClose}>
                        <Icon
                            type={'close_tiny'}
                            size={14}
                            color={'blackLight'}
                            tooltip={{
                                title: translate('close'),
                            }}
                        />
                    </div>
                </div>
            </div>
            <div
                className={cn(styles.content, minimize ? styles.hidden : '')}
                onMouseDown={(e: any) => e.stopPropagation()}
            >
                <CustomScroll containerRef={contentRef} scrollType={'wide'} classNames={styles.scroll}>
                    {renderContent()}
                </CustomScroll>
            </div>
        </div>
    )
}
