import PropTypes from 'prop-types'
import React, { memo, useEffect, useState } from 'react'
import Measure from 'react-measure'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import EditorArea from '../__area/editor__area'
import Item from '../__item/editor__item'
import EditorFooter from '../__footer/editor__footer'
import Icon from 'blocks.simple/icon/icon'
import Preview from '../__preview/editor__preview'
import CustomDropdown from 'atoms/Dropdown'
import DndDroppable from 'blocks.app/dnd/_droppable/dnd_droppable'
import Draggable from 'blocks.simple/draggable/draggable'
import EditorScreenMethods from './editor__screen.local.methods'
import Lines from '../__lines/editor__lines'
import Tippy from 'blocks.simple/tippy/tippy'
import { Scrollbars } from 'react-custom-scrollbars'
import EditorDiagrams from '../__diagrams/editor__diagrams'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import translate from 'core/translate'
import styles from './editor__screen.jcss'
import { editor } from '../editor.local.methods'
import { layers } from 'blocks.app/config'
import { cn } from 'ethcss'
import helpers from 'core/helpers'
import { defaultThemeStyles as defaultThemeIconStyles } from 'blocks.simple/icon/icon.theme'
import deepEqual from 'fast-deep-equal'
import { getThemeStyleValue } from 'theme/colors'
import { isExist, isNotEmptyArray } from 'core/utils/coreUtil'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import { connect } from 'react-redux'
import filesHelpers from 'core/helpers/_files'
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import ModalAccept from 'blocks.simple/modal/_accept/modal_accept'
import { SmartFolderSettings } from '../../../pages/files/__smartFolder/files__smartFolderModal'
import { editorFooter } from '../__footer/editor__footer'

const EditorAnimationSettings = memo(
    (props) => {
        const { isSimple, togglePreview, areaViewType, viewOptions, pages, viewIcons, isMobile, selectedArea } = props
        const [playing, setPlaying] = useState(false)

        const [isOpenDiagram, setIsOpenDiagram] = useState(false)

        const onChangeAreaViewType = ({ id }) => {
            editor.changeAreaViewType(id)
        }

        const handleTogglePreview = (e) => {
            e.stopPropagation()
            togglePreview(true)
        }

        const handleMouseDown = (e) => {
            e.stopPropagation()
        }

        const handleOpenDurationSettings = () => {
            editor.openDurationSettings()
            showToolbar(true)
        }

        const handleOpenDiagrams = () => {
            setIsOpenDiagram(true)
        }

        const handleCloseDiagrams = () => {
            setIsOpenDiagram(false)
        }

        const togglePages = () => {
            editor.toggleShowPages()
        }

        const showToolbar = (value) => {
            editor.showToolbar(value)
        }

        const iconColor = getThemeStyleValue('ui', 'icon', 'mainSub') || defaultThemeIconStyles.mainSub
        const iconActiveColor = getThemeStyleValue('ui', 'icon', 'active') || defaultThemeIconStyles.active

        useEffect(() => {
            let interval = setInterval(() => {
                const play = editorFooter.state && editorFooter.state.playing

                if (playing !== play) {
                    setPlaying(play)
                }
            }, 500)

            return () => clearInterval(interval)
        }, [playing])
        return (
            <div className={styles.bottomToolbar}>
                <div onMouseDown={handleMouseDown} className={grid.row}>
                    <Tippy title={translate(`list__${areaViewType}`)}>
                        <CustomDropdown
                            tippyDropdown
                            mod={'fill'}
                            options={viewOptions}
                            value={areaViewType}
                            valueKey={'icon'}
                            icon={viewIcons[areaViewType]}
                            iconColor={iconColor}
                            iconActiveColor={iconActiveColor}
                            onChange={onChangeAreaViewType}
                        />
                    </Tippy>
                </div>
                {isMobile && (
                    <>
                        <div className={grid.row} onClick={editorFooter.play}>
                            <Icon type={playing ? 'pause' : 'play'} color="white" size="25" />
                        </div>
                        <div className={grid.row}>
                            <Icon
                                onClick={(e) => {
                                    editor.showToolbar(true)
                                    editor.setAreaIndexForMultipleAdd(-1)
                                    return false
                                }}
                                type="addSoundTrack"
                                color="white"
                                size="19"
                            />
                        </div>
                        <div className={grid.row}>
                            <Icon
                                onClick={(e) => {
                                    editor.showToolbar(true)
                                    return false
                                }}
                                type="addMedia"
                                color="white"
                                size="23"
                                disabled={!isSimple && selectedArea}
                            />
                        </div>
                        {!isSimple && (
                            <div className={grid.row}>
                                <Icon onClick={togglePages} type="pagesView" color="white" size="28" />
                            </div>
                        )}
                    </>
                )}
                <div className={grid.row} onMouseDown={handleMouseDown}>
                    <Icon
                        onClick={handleOpenDurationSettings}
                        type="page_settings"
                        color="white"
                        size="20"
                        tooltip={{ title: translate('openDurationSettings') }}
                    />
                    {!isSimple &&
                        !isMobile &&
                        helpers.isAvailable({
                            key: 'broadcastEventsAndActions',
                            action: 'create',
                        }) && (
                            <React.Fragment>
                                <Icon
                                    containerClass={cn(grid.ml_mini)}
                                    onClick={handleOpenDiagrams}
                                    type="flow_diagram"
                                    color="white"
                                    size="20"
                                    tooltip={{ title: translate('pageChangeVisualRepresentation') }}
                                />
                                <EditorDiagrams open={isOpenDiagram} pages={pages} onClose={handleCloseDiagrams} />
                            </React.Fragment>
                        )}
                    {isSimple && (
                        <Icon
                            containerClass={cn(grid.ml_mini)}
                            color="white"
                            onClick={handleTogglePreview}
                            type="multimedia"
                            size="20"
                            tooltip={{ title: translate('preview') }}
                        />
                    )}
                </div>
            </div>
        )
    },
    (prevProps, nextProps) => {
        return deepEqual(nextProps, prevProps)
    }
)

const DragHandle = SortableHandle(() => (
    <div className={styles.sortableIcon}>
        <Icon size={18} type="sortable_handle" color={'white'} />
    </div>
))

const SortableList = SortableContainer(
    ({ items, isActivePage, handleSwitchPage, handleDeletePage, getSourcePreview }) => (
        <ul className={styles.pagesList}>
            {items.map((page, index) => {
                return (
                    <SortableItem
                        key={index}
                        itemsLength={items.length}
                        isActivePage={isActivePage}
                        handleSwitchPage={handleSwitchPage}
                        handleDeletePage={(e) => handleDeletePage(e, index)}
                        getSourcePreview={getSourcePreview}
                        index={index}
                        pageIndex={index}
                    />
                )
            })}
        </ul>
    )
)

const SortableItem = SortableElement(
    ({ itemsLength, isActivePage, handleSwitchPage, handleDeletePage, getSourcePreview, pageIndex }) => {
        return (
            <li
                className={cn(styles.page, isActivePage(pageIndex) ? styles.pageActive : '')}
                onClick={(e) => handleSwitchPage(pageIndex)}
            >
                {getSourcePreview(pageIndex)}
                <div className={styles.pageNumber}>{pageIndex + 1}</div>
                {itemsLength > 1 ? (
                    <div className={cn(styles.deleteBtn, 'delete')}>
                        <Icon type={'delete'} size={15} color={'white'} onClick={handleDeletePage} />
                    </div>
                ) : null}
                <DragHandle />
            </li>
        )
    }
)

class EditorScreen extends EditorScreenMethods {
    handleClose() {
        editor.closeDeleteConfirm()
    }

    handleAddPage() {
        editor.addPage()
    }

    handleDeletePage(e, pageIndex) {
        editor.setState({
            pageIndexForDelete: pageIndex,
        })
        editor.openDeleteConfirm()
        e.stopPropagation()
    }

    confirmDelete() {
        editor.deletePage(editor.state.pageIndexForDelete)
        editor.closeDeleteConfirm()
    }

    handleSwitchPage(pageIndex) {
        editor.navigateToPage(pageIndex)
    }

    isActivePage(pageIndex) {
        return editor.state.data.selectedPage === pageIndex
    }

    getSourcePreview(pageIndex) {
        const areas = editor.state.broadcast.pages[pageIndex].areas

        if (areas.length) {
            if (areas[0].content.length) {
                const source = areas[0].content[0].source

                if (source.thumbnail) {
                    return <img className={styles.sourcePreview} src={source.thumbnail} />
                }
                if (source.extension === '.gif') {
                    return <img className={styles.sourcePreview} src={source.src} />
                }
                return (
                    <div className={styles.iconWrapper}>
                        <Icon size={40} type={filesHelpers.getFileIconName(source)} color={'white'} />
                    </div>
                )
            }
        }

        return null
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        const { pages } = editor.state.broadcast
        const { selectedTemplate } = editor.state.data
        const sortedPages = arrayMove(pages, oldIndex, newIndex).slice()
        const sortedSelectedTemplate = arrayMove(selectedTemplate, oldIndex, newIndex).slice()
        const sortedPagesByOrder = sortedPages.map((page, index) => {
            return {
                ...page,
                order: index,
                nextPageOrder: index + 1 <= sortedPages.length - 1 ? index + 1 : 0,
            }
        })
        editor.onSortPages(sortedPagesByOrder, sortedSelectedTemplate)
    }

    isEmptyAreas() {
        const { areas } = this.props
        return !isNotEmptyArray(areas)
    }

    isEmptySimpleArea() {
        const { isSimple, areas } = this.props
        return isSimple && !isNotEmptyArray(areas[0].content)
    }

    isRenderInfo() {
        return this.isEmptyAreas() || this.isEmptySimpleArea()
    }

    isRenderSoundtrackArea() {
        const { areas } = this.state

        return isExist(areas[-1])
    }

    renderInfo() {
        const { resolutionWidth, resolutionHeight, isSimple } = this.props

        return (
            <div className={styles.text}>
                {!this.props.isMobile && (
                    <div className={grid.mb_mini}>
                        {translate('editorTooltip1')} {resolutionWidth}x{resolutionHeight}
                    </div>
                )}
                {!isSimple && (
                    <div className={cn(grid.rowCenter, grid.mb_mini)}>
                        <span className={grid.pr_mini}>{translate('editorTooltipArea')}</span>
                        {this.props.isMobile && (
                            <>
                                <Icon type={'editorActionToggle'} color={'greyLight'} size={34} default={true} />
                                <span style={{ padding: '0 8px' }}>{translate('then')}</span>
                            </>
                        )}
                        <Icon type={'add_area'} color={'greyLight'} size={26} default={true} />
                    </div>
                )}
                {!this.props.isMobile ? (
                    <div className={grid.mb_mini}>{translate('editorTooltip2')}</div>
                ) : (
                    <div className={styles.lastText}>
                        <span className={grid.pr_mini}>{translate('editorTooltip3_mob')}</span>
                        <Icon type={'addMedia'} color={'greyLight'} size={26} default={true} />
                    </div>
                )}
                <div> </div>
                <div className={styles.lastText}>
                    <span className={grid.pr_mini}>{translate('editorTooltip4')}</span>
                    <Icon type={'gear'} color={'greyLight'} size={26} default={true} />
                </div>
            </div>
        )
    }

    renderAreas() {
        const {
            selectedArea,
            resolutionWidth,
            resolutionHeight,
            isSelectedContent,
            selectedContent,
            isSelectedArea,
            isSimple,
            minimized,
            duration,
        } = this.props

        const { areas, scale, time, playing, maxHeight, maxWidth } = this.state
        const { fileLoading, isMobile } = this.props

        return (
            <>
                {areas.map(({ area, content }, index) => {
                    const selected = selectedArea === index

                    return (
                        <Item
                            key={`areaItem_${index}`}
                            name={'areaName'}
                            width={area.width}
                            height={area.height}
                            left={area.left}
                            top={area.top}
                            maxWidth={maxWidth[index]}
                            maxHeight={maxHeight[index]}
                            resolutionWidth={resolutionWidth}
                            resolutionHeight={resolutionHeight}
                            type={'area'}
                            isScreenItem={true}
                            //selected={selected}
                            zIndex={selected && !isSimple ? 900 : index}
                            disableResize={(selected && isSelectedContent) || isSimple}
                            disableDrag={isSimple}
                            showDeleteIcon={!isSimple && selected}
                            onSelect={function () {
                                editor.selectArea(index, !minimized)
                                editor.actionBarToggle(false)
                            }}
                            onDelete={function () {
                                editor.deleteArea(index)
                            }}
                            onDragEnd={function (pos) {
                                const isValid = editor.updateAreaPosition(index, pos)
                                return isValid
                            }}
                            onResizeEnd={(pos) => {
                                const isValid = editor.updateAreaPosition(index, pos)
                                this.setState({ maxWidth: {}, maxHeight: {} })
                                return isValid
                            }}
                            onResize={(pos, dir) => this.onResize(pos, dir, index)}
                        >
                            <EditorArea
                                isMobile={isMobile}
                                key={`area_${index}`}
                                index={index}
                                duration={duration}
                                area={area}
                                resolutionWidth={resolutionWidth}
                                resolutionHeight={resolutionHeight}
                                content={content}
                                onAddContent={function (data) {
                                    editor.dropFile(data, index)
                                }}
                                selected={selected}
                                selectedContent={selectedContent}
                                isSelectedArea={isSelectedArea}
                                onControlPanerClick={function (type, contentIndex) {
                                    switch (type) {
                                        case 'area': {
                                            editor.selectArea(index, !minimized)
                                            break
                                        }
                                        default: {
                                            editor.selectContent(index, contentIndex, !minimized)

                                            break
                                        }
                                    }
                                }}
                                onSelectContent={function (contentIndex) {
                                    editor.selectContent(index, contentIndex, !minimized)
                                    editor.setAreaIndexForMultipleAdd(index)
                                    editor.actionBarToggle(false)
                                }}
                                onUpdatePosition={function (contentIndex, position, type) {
                                    editor.updatePosition(index, contentIndex, position, type)
                                }}
                                scale={scale}
                                time={time}
                                playing={playing}
                                seekTo={this.seekTo}
                                fileLoading={fileLoading}
                                isSimple={this.props.isSimple}
                            />
                        </Item>
                    )
                })}
            </>
        )
    }

    renderSountrackArea() {
        const { areas, scale, time, playing } = this.state

        return (
            <Item
                name={'areaName'}
                width={areas[-1].area.width}
                height={areas[-1].area.height}
                left={areas[-1].area.left}
                top={areas[-1].area.top}
                type={'area'}
            >
                <EditorArea
                    area={areas[-1].area}
                    index={-1}
                    content={areas[-1].content}
                    scale={scale}
                    time={time}
                    playing={playing}
                    seekTo={this.seekTo}
                />
            </Item>
        )
    }

    onClose() {
        editor.onCloseRulesList()
    }

    onSelectRule(id) {
        editor.smartFolderRuleSelected(id)
        editor.onCloseRulesList()
    }

    renderContent() {
        return (
            <>
                {this.isRenderInfo() && this.renderInfo()}
                {this.renderAreas()}
                {this.isRenderSoundtrackArea() && this.renderSountrackArea()}
            </>
        )
    }

    render() {
        const s_ = this.state
        const p_ = this.props
        const selectedArea = p_.areas[p_.selectedArea]
        const screenWidth = this.screenWidth
        const screenHeight = this.screenHeight

        return (
            <div
                onMouseDown={this.handleClearSelectArea}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
            >
                <div className={`${p_.isSimple ? styles.pagesLinesWrapperIsSimple : styles.pagesLinesWrapper}`}>
                    <ModalAccept
                        open={editor.state.modalDeleteIsVisible}
                        onReject={this.handleClose}
                        onAccept={this.confirmDelete}
                        onClose={this.handleClose}
                        title={translate('deleteLabel')}
                        description={translate('deleteApprove')}
                        rejectLabel={translate('cancel')}
                        acceptLabel={translate('delete')}
                    />
                    {!p_.isSimple && !p_.isMobile && (
                        <div className={styles.pagesWrapper}>
                            <SortableList
                                items={p_.pages}
                                isActivePage={this.isActivePage}
                                handleSwitchPage={this.handleSwitchPage}
                                handleDeletePage={this.handleDeletePage}
                                getSourcePreview={this.getSourcePreview}
                                onSortEnd={this.onSortEnd}
                                useDragHandle={true}
                            />
                            <div className={styles.pageNew} onClick={this.handleAddPage}>
                                <Icon type={'plus'} size={20} color={'white'} />
                            </div>
                        </div>
                    )}
                    <div className={styles.lines}>
                        {!p_.isMobile && (
                            <Lines
                                mouseIn={s_.mouseIn}
                                scrollLeft={s_.scrollLeft}
                                scrollTop={s_.scrollTop}
                                zoom={p_.zoom}
                                resolutionWidth={p_.resolutionWidth}
                                resolutionHeight={p_.resolutionHeight}
                                screenWidth={screenWidth}
                                screenHeight={screenHeight}
                                onAddHLine={() => this.setState({ addHLine: true })}
                                onAddVLine={() => this.setState({ addVLine: true })}
                            />
                        )}
                        <Rectangle width={p_.screenSize.width} height={p_.screenSize.height}>
                            <Scrollbars
                                ref={(scrollbar) => (this.scrollbar = scrollbar)}
                                onScroll={this.onScroll}
                                style={{ width: '100%', height: '100%' }}
                            >
                                <Measure
                                    bounds
                                    onResize={(contentRect) => {
                                        this.onChangeScreenSize(contentRect.bounds)
                                    }}
                                >
                                    {({ measureRef }) => (
                                        <div
                                            ref={measureRef}
                                            style={{
                                                width: `${100 * p_.zoom}%`,
                                                height: `${100 * p_.zoom}%`,
                                            }}
                                            onMouseDown={this.onMouseDown}
                                            onMouseMove={this.onMouseMove}
                                            onMouseUp={this.onMouseUp}
                                            onMouseLeave={this.onMouseLeave}
                                            className={cn(grid.colCenter, item.overNone, {
                                                [item.cursorGrab]: p_.zoom > 1,
                                            })}
                                        >
                                            <div
                                                style={{
                                                    width: `${screenWidth}%`,
                                                    height: `${screenHeight}%`,
                                                }}
                                            >
                                                <DndDroppable
                                                    id={'editorScreenContent'}
                                                    className={styles.content}
                                                    onDrop={() => {
                                                        p_.emitError('noArea')
                                                    }}
                                                    onMouseDown={function (e) {
                                                        if (e.target.id !== `editorScreenContent`) {
                                                            e.stopPropagation()
                                                        }
                                                    }}
                                                >
                                                    {this.renderContent()}
                                                </DndDroppable>
                                            </div>
                                        </div>
                                    )}
                                </Measure>
                            </Scrollbars>
                        </Rectangle>

                        {s_.hLines.map((hLine, index) => (
                            <Draggable
                                key={`hLine_${index}`}
                                left={0}
                                top={hLine.top}
                                width={1}
                                height={s_.lineHeight}
                                disableResize={true}
                                onDragEnd={(pos) => this.updateLinePos(pos, 'h', index)}
                                noBorder={true}
                                zIndex={layers.sticky}
                                moveCursor={'ns-resize'}
                            >
                                <div className={styles.lineHWrapper}>
                                    <div className={styles.lineH} />
                                </div>
                            </Draggable>
                        ))}
                        {s_.vLines.map((vLine, index) => (
                            <Draggable
                                key={`vLine_${index}`}
                                left={vLine.left}
                                top={0}
                                width={s_.lineWidth}
                                height={1}
                                disableResize={true}
                                noBorder={true}
                                onDragEnd={(pos) => this.updateLinePos(pos, 'v', index)}
                                zIndex={layers.sticky}
                                moveCursor={'ew-resize'}
                            >
                                <div className={styles.lineVWrapper}>
                                    <div className={styles.lineV} />
                                </div>
                            </Draggable>
                        ))}
                        {s_.addHLine && (
                            <Draggable
                                key={`hLine_add`}
                                left={0}
                                top={0}
                                width={1}
                                height={s_.lineHeight}
                                disableResize={true}
                                noBorder={true}
                                isDraggable={s_.addHLine}
                                onDragEnd={this.addHLine}
                                zIndex={layers.sticky}
                                moveCursor={'ns-resize'}
                            >
                                <div className={styles.lineHWrapper}>
                                    <div className={styles.lineH} />
                                </div>
                            </Draggable>
                        )}
                        {s_.addVLine && (
                            <Draggable
                                key={`vLine_add`}
                                left={0}
                                top={0}
                                width={s_.lineWidth}
                                height={1}
                                disableResize={true}
                                noBorder={true}
                                isDraggable={s_.addVLine}
                                onDragEnd={this.addVLine}
                                zIndex={layers.sticky}
                                moveCursor={'ew-resize'}
                            >
                                <div className={styles.lineVWrapper}>
                                    <div className={styles.lineV} />
                                </div>
                            </Draggable>
                        )}
                    </div>
                </div>
                {!p_.showAnimationSettings && (
                    <div className={styles.animationSettingsWrapper}>
                        {p_.isMobile && editor.state.mobilePagesOpened && (
                            <div className={styles.pageListBlockMobile}>
                                <div className={styles.pagesBlock}>
                                    <div
                                        onClick={() => {
                                            if (p_.selectedPage - 1 !== -1) {
                                                this.handleSwitchPage(p_.selectedPage - 1)
                                            }
                                        }}
                                    >
                                        <Icon type="switchLeft" size="28" />
                                    </div>
                                    <div className={styles.pagesListMobile}>
                                        {p_.pages.map((page, index) => (
                                            <span
                                                className={cn(
                                                    styles.mobilePage,
                                                    p_.selectedPage === index ? styles.mobilePageActive : ''
                                                )}
                                                onClick={() => {
                                                    this.handleSwitchPage(index)
                                                }}
                                            >
                                                {index + 1}
                                            </span>
                                        ))}
                                    </div>
                                    <div
                                        onClick={() => {
                                            if (p_.selectedPage + 1 < p_.pages.length) {
                                                this.handleSwitchPage(p_.selectedPage + 1)
                                            }
                                        }}
                                    >
                                        <Icon type="switchRight" size="28" />
                                    </div>
                                </div>
                                <div onClick={this.handleAddPage} className={styles.addNewPage}>
                                    <Icon type="addNewPage" size="28" />
                                </div>
                                <div
                                    onClick={(e) => this.handleDeletePage(e, p_.selectedPage)}
                                    className={styles.deletePage}
                                >
                                    <Icon type="deletePage" size="28" />
                                </div>
                            </div>
                        )}
                        <EditorAnimationSettings
                            isMobile={p_.isMobile}
                            isSimple={p_.isSimple}
                            togglePreview={this.togglePreview}
                            areaViewType={p_.areaViewType}
                            viewOptions={this.viewOptions}
                            pages={p_.pages}
                            selectedPage={p_.selectedPage}
                            viewIcons={this.viewIcons}
                            selectedArea={editor.state.multipleAddIndex === null}
                        />
                    </div>
                )}

                <EditorFooter
                    isMobile={p_.isMobile}
                    showAllAreas={p_.showAllAreas}
                    areaViewType={p_.showAllAreas ? 'x' : p_.areaViewType}
                    selectedArea={p_.selectedArea}
                    duration={p_.duration}
                    sources={p_.sources}
                    selected={p_.selected}
                    selectedContent={p_.selectedContent}
                    minimized={p_.minimized}
                    onDelete={this.handleDelete}
                    onEdit={this.handleEdit}
                    emitError={p_.emitError}
                    areas={p_.areas}
                    show={
                        p_.selectedArea >= 0 &&
                        !p_.showAnimationSettings &&
                        !p_.showAllAreas &&
                        selectedArea &&
                        selectedArea.content &&
                        selectedArea.content.length > 0
                    }
                    loop={p_.loop}
                    isSimple={p_.isSimple}
                    soundOrderMode={p_.soundOrderMode}
                    isSoundtrackLoading={p_.isSoundtrackLoading}
                />
                {p_.isSimple && (
                    <Preview
                        duration={p_.duration}
                        open={s_.openPreview}
                        onClose={this.togglePreview}
                        areas={s_.areas}
                        screenWidth={screenWidth}
                        screenHeight={screenHeight}
                        screenSize={p_.screenSize}
                        sources={p_.sources}
                    />
                )}
                {editor.state.rulesList.length ? (
                    <SmartFolderSettings
                        onClose={this.onClose}
                        smartFolderRules={editor.state.rulesList}
                        folderId={editor.state.folderId}
                        onSelectRule={this.onSelectRule}
                    />
                ) : null}
            </div>
        )
    }
}

EditorScreen.propTypes = {
    duration: PropTypes.number,
    areas: PropTypes.array,
    hLines: PropTypes.array,
    isSimple: PropTypes.bool,
    isSelectedContent: PropTypes.bool,
    isSelectedArea: PropTypes.bool,
    showAllAreas: PropTypes.bool,
    showAnimationSettings: PropTypes.bool,
    resolutionWidth: PropTypes.number,
    resolutionHeight: PropTypes.number,
    screenSize: PropTypes.object,
    pagesLength: PropTypes.number,
    pages: PropTypes.array,
    selectedPage: PropTypes.number,
    selectedArea: PropTypes.number,
    sources: PropTypes.object,
    selectedContent: PropTypes.number,
    zoom: PropTypes.number,
    areaViewType: PropTypes.string,
    isSoundtrackLoading: PropTypes.bool,
    fileLoading: PropTypes.object,
}

const mapDispatchToProps = (dispatch) => {
    return {
        emitError: (err) => dispatch(emitError(err)),
    }
}

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(EditorScreen)
