import React, { useEffect, useMemo, useRef, useState } from 'react'
import styles from './upload.jcss'
import translate from '../../../core/translate'
import EditText from '../../../blocks.simple/editText/editText'
import { cn } from 'ethcss'
import Button from '../../../blocks.simple/button/button'
import Dropdown from '../../../blocks.simple/dropdown/dropdown'
import { Typography } from '../../../atoms/Typography'
import Dropzone from 'react-dropzone'
import { IFirmwareUpload } from '../FirmwareListHooks'
import LoaderItem from 'blocks.app/loader/__item/loader__item'
import Icon from '../../../blocks.simple/icon/icon'
import { colors } from '../../../theme'

const accept = (platform: string | null) => {
    let accept: string = ''

    switch (platform) {
        case 'SSSP 2':
        case 'SSSP 3':
            accept = '.msd'
            break
        case 'SSSP 4':
        case 'SSSP 5':
        case 'SSSP 6':
        case 'SSSP 7':
        case 'SSSP 10':
            accept = '.bem'
            break
        case 'WEBOS 2':
        case 'WEBOS 3':
        case 'WEBOS 3.2':
        case 'WEBOS 4':
        case 'WEBOS 4.1':
        case 'WEBOS 6':
            accept = '.epk'
            break
        case 'ANDROID':
            accept = '.zip'
            break
        case 'BRIGHTSIGN':
            accept = '.bsfw'
            break
    }

    return accept
}

type FirmwareFile = {
    path: string
    lastModified: string
    lastModifiedDate: string
    name: string
    size: number
    type: string
    webkitRelativePath: string
}

interface IUpload {
    onSave: (data: IFirmwareUpload) => void
    onClose: () => void
    isUploading: boolean
}

export const Upload = ({ onSave, onClose, isUploading }: IUpload) => {
    const [version, setVersion] = useState<string>('')
    const [modelList, setModelList] = useState<{ name: string; label: string }[]>([])
    const [platform, setPlatform] = useState<string>('')
    const [firmwareFile, setFirmwareFile] = useState<FirmwareFile | null>(null)
    const inputFileRef = useRef<any>(null)
    const availablePlatforms = useMemo(
        () => [
            {
                id: 1,
                platform: 'SSSP 2',
            },
            {
                id: 2,
                platform: 'SSSP 3',
            },
            {
                id: 3,
                platform: 'SSSP 4',
            },
            {
                id: 4,
                platform: 'SSSP 5',
            },
            {
                id: 5,
                platform: 'SSSP 6',
            },
            {
                id: 6,
                platform: 'SSSP 7',
            },
            {
                id: 7,
                platform: 'SSSP 10',
            },
            {
                id: 8,
                platform: 'WEBOS 2',
            },
            {
                id: 9,
                platform: 'WEBOS 3',
            },
            {
                id: 10,
                platform: 'WEBOS 3.2',
            },
            {
                id: 11,
                platform: 'WEBOS 4',
            },
            {
                id: 12,
                platform: 'WEBOS 4.1',
            },
            {
                id: 13,
                platform: 'WEBOS 6',
            },
            {
                id: 14,
                platform: 'ANDROID',
            },
            {
                id: 15,
                platform: 'BRIGHTSIGN',
            },
        ],
        []
    )

    const onOpenFilesClick = () => {
        if (inputFileRef.current) {
            inputFileRef.current.click()
        }
    }
    const onDrop = (filesList: FirmwareFile[]) => {
        if (filesList.length) {
            const file = filesList[0]
            const extension = file.name.split('.').pop()

            if ('.' + extension === accept(platform)) {
                setFirmwareFile(filesList[0])
            }
        }
    }
    const onPlatformChange = (e: any) => {
        const value = e.target.value
        setPlatform(value)
        setFirmwareFile(null)
        if (inputFileRef.current) {
            inputFileRef.current.value = null
        }
    }
    const isValid = () => {
        return version && modelList.length && platform && firmwareFile
    }
    const save = () => {
        onSave({
            platform,
            version,
            modelNameForUpdate: modelList.map((option: { name: string; label: string }) => option.name),
            file: firmwareFile,
        })
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.uploadModal}>
                {isUploading ? (
                    <div className={styles.loaderWrapper}>
                        <div className={styles.loader}>
                            <LoaderItem inv={true} />
                        </div>
                        <div>{translate('firmwareIsUploading')}</div>
                    </div>
                ) : (
                    <>
                        <div className={styles.caption}>
                            <span>{translate('uploadFirmwareOnServer')}</span>
                            <a
                                href={
                                    'https://wiki.smartplayer.org/index.php/DevicesFirmware#%D0%92%D0%B5%D1%80%D1%81%D0%B8%D0%BE%D0%BD%D0%BD%D0%BE%D1%81%D1%82%D1%8C_%D0%BF%D1%80%D0%BE%D1%88%D0%B8%D0%B2%D0%BA%D0%B8'
                                }
                                target={'_blank'}
                            >
                                <Icon
                                    type={'question'}
                                    size={16}
                                    color={colors.grey}
                                    tooltip={{ title: translate('simpleDescription') }}
                                />
                            </a>
                        </div>
                        <div className={styles.row}>
                            <EditText
                                label={translate('firmwareVersion')}
                                onChange={(value: string) => setVersion(value)}
                                type="text"
                                placeholder={translate('enterFirmwareVersion')}
                                value={version}
                                labelColor={'grey'}
                            />
                        </div>
                        <div className={styles.row}>
                            <label className={styles.label}>{translate('platform')}</label>
                            <select className={styles.select} onChange={onPlatformChange}>
                                <option disabled selected>
                                    {translate('selectPlatform')}
                                </option>
                                {availablePlatforms.map((option: { id: number; platform: string }) => (
                                    <option key={option.id}>{option.platform}</option>
                                ))}
                            </select>
                        </div>
                        <div className={cn(styles.row, styles.uploadRow)}>
                            <EditText
                                label={translate('firmwareFile')}
                                type="text"
                                placeholder={translate('selectFirmwareFile')}
                                value={firmwareFile ? firmwareFile.name : ''}
                                className={styles.firmwareInput}
                                disabled={true}
                                labelColor={'grey'}
                            />
                            <Button
                                mod={'fill'}
                                className={styles.firmwareButton}
                                onClick={onOpenFilesClick}
                                disabled={!platform}
                            >
                                {translate('selectAction')}
                            </Button>
                        </div>
                        <div>
                            <label className={styles.label}>{translate('supportModelsList')}</label>
                            <Dropdown
                                className={styles.modelsInput}
                                list={modelList}
                                options={[]}
                                searchable={true}
                                multiselectItems={true}
                                onChange={(elements) => {
                                    setModelList(elements)
                                }}
                            />
                            <Typography className={styles.modelTip} type="text">
                                {translate('addFirmwareModels')}
                            </Typography>
                        </div>
                        <div className={styles.btnGroup}>
                            <Button mod={'withBorder'} className={styles.modalBtn} onClick={onClose}>
                                {translate('cancel')}
                            </Button>
                            <Button mod={'fill'} className={styles.modalBtn} onClick={save} disabled={!isValid()}>
                                {translate('upload')}
                            </Button>
                        </div>
                    </>
                )}
            </div>
            <Dropzone onDrop={(acceptedFiles: any) => onDrop(acceptedFiles)}>
                {({ getRootProps, getInputProps }) => (
                    <div
                        {...getRootProps({
                            onClick: (event) => event.stopPropagation(),
                            className: cn(styles.dropzone),
                        })}
                    >
                        <input
                            {...getInputProps()}
                            type={'file'}
                            ref={inputFileRef}
                            multiple={false}
                            accept={accept(platform)}
                        />
                    </div>
                )}
            </Dropzone>
        </div>
    )
}
