import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import BroadcastsToolbarContentMethods from './broadcasts__toolbarContent.local.methods'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import DropMenu from 'blocks.simple/dropMenu/dropMenu'
import Button from 'blocks.simple/button/button'
import { ScheduleItem } from 'organisms/ScheduleItem/ScheduleItem-view'
import ShowMore from 'blocks.simple/showMore/showMore'
import Icon from 'blocks.simple/icon/icon'
import { cn } from 'ethcss'
import styles from './broadcasts__toolbarContent.jcss'
import { grid, item } from 'theme'
import { text as textStyles } from 'theme'
import translate from 'core/translate'
import { api } from 'core/api/ConnectionManager'
import { setEditBroadcastLocation } from '../broadcasts.local.methods'
import helpers from 'core/helpers'
import { routes } from 'features/routes'
import helpersBroadcasts from 'core/helpers/_broadcasts'
import { Typography } from 'atoms/Typography'
import { getThemeIconValue } from 'theme/colors'
import { defaultFilesIconsTheme } from 'core/helpers/FilesIcons-theme'
import { getURLSearchParamsString } from 'features/routes/utils'
import { changeLocation } from 'features/routes'

const getUnsupportedPlatformsDescription = (platform: any) => {
    let description = ''

    if (platform.sinceDsVersion) {
        description += `<div>${translate('notSupportSinceVersion')} ${platform.sinceDsVersion} </div>`
    }

    if (platform.beforeDsVersion) {
        description += `<div>${translate('notSupportBeforeVersion')} ${platform.beforeDsVersion}</div>`
    }

    if (!description.length) {
        description = translate('notSupport')
    }

    return description
}

const BroadcastsToolbarContent = (p_: any) => {
    const { state, setState, getItems, downloadBroadcast } = BroadcastsToolbarContentMethods(p_)

    const s_ = state
    const isDirectory = helpersBroadcasts.isDirectory(p_.selectedInfo)
    const queryParameters: any = helpers.getQuery(p_.query, 'broadcasts')

    if (!queryParameters.folderId) {
        queryParameters.folderId = null
    } else {
        queryParameters.folderId = parseInt(queryParameters.folderId, 10)
    }

    const folderIcon = getThemeIconValue('catalog', 'catalog', 'folder') || `${defaultFilesIconsTheme.folder}`

    return (
        <div className={styles.toolbarContentWrapper}>
            {p_.selectedInfo && (
                <div className={styles.wrapper}>
                    <div className={styles.itemWrapper}>
                        <Typography type="title" className={styles.name}>
                            {p_.selectedInfo.title}
                        </Typography>
                        {p_.selectedInfo.description && (
                            <div className={cn(styles.itemDescription, textStyles.wordWrap)}>
                                <Dotdotdot clamp={2}>{p_.selectedInfo.description}</Dotdotdot>
                            </div>
                        )}
                    </div>
                    <Rectangle
                        src={isDirectory ? folderIcon : p_.selectedInfo.cover.src}
                        contain={p_.selectedInfo.cover.isIcon || isDirectory}
                        className={cn(grid.col, styles.viewInfoNoHiddenOverflow)}
                        width={16}
                        height={9}
                    >
                        <div className={grid.space} />
                        <DropMenu
                            active={s_.active}
                            className={cn(item.blackOpacity)}
                            mod="centered"
                            onChange={(active: object[]) =>
                                setState((prevState) => {
                                    return { ...prevState, active }
                                })
                            }
                            onClick={(clicked: string) => {
                                switch (clicked) {
                                    case 'download':
                                        downloadBroadcast(p_.selectedInfo)
                                        break
                                    case 'clone':
                                        api.send('cloneBroadcast', { broadcastId: p_.selectedInfo.id })
                                        break
                                    case 'edit':
                                        if (!isDirectory) {
                                            setEditBroadcastLocation(p_.selectedInfo, p_.folderId)
                                        }
                                        break
                                    case 'date':
                                        const data = {
                                            broadcasts: [
                                                {
                                                    id: p_.selectedInfo.id,
                                                    name: p_.selectedInfo.title,
                                                },
                                            ],
                                        }

                                        changeLocation({
                                            pathname: `/${routes.schedule.path}`,
                                            search: getURLSearchParamsString({
                                                scheduleData: JSON.stringify(data),
                                            }),
                                        })
                                        break
                                    default:
                                        break
                                }
                            }}
                            items={getItems(p_)}
                        />
                    </Rectangle>
                    {queryParameters.query && queryParameters.folderId !== p_.selectedInfo.folderId && (
                        <div>
                            <div
                                className={styles.menuItem}
                                onClick={function () {
                                    changeLocation({
                                        pathname: `/${routes.broadcasts.path}`,
                                        search: getURLSearchParamsString({
                                            folderId: p_.selectedInfo.folderId,
                                        }),
                                    })
                                }}
                            >
                                {translate('location')}
                            </div>
                            <div className={styles.separator} />
                        </div>
                    )}
                    {!isDirectory && s_.unsupportedPlatforms && (
                        <ShowMore
                            className={styles.separator}
                            trigger={
                                <div className={styles.showMoreTrigger}>
                                    <div>{translate('restrictDeviceSupport')}</div>
                                    <Icon type={s_.showMoreRestrictSupport ? 'arrow_up' : 'arrow_down'} size={16} />
                                </div>
                            }
                            isShow={s_.showMoreRestrictSupport}
                            onToggle={(showMoreRestrictSupport: boolean) =>
                                setState((prevState) => {
                                    return { ...prevState, showMoreRestrictSupport }
                                })
                            }
                        >
                            <div className={styles.showMoreContentWrapper}>
                                {s_.unsupportedPlatforms.map((platform: { platformName: string }) => (
                                    <div key={platform.platformName} className={styles.showMoreContent}>
                                        <div className={styles.showMoreTitle}>{platform.platformName}</div>
                                        <div
                                            className={styles.showMoreDescription}
                                            dangerouslySetInnerHTML={{
                                                __html: getUnsupportedPlatformsDescription(platform),
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </ShowMore>
                    )}
                    {!isDirectory && helpers.isAvailable({ key: 'schedule', action: 'read' }) && s_.schedule && (
                        <div className={s_.schedule.length > 0 ? styles.scheduleListTitle : styles.scheduleEmptyTitle}>
                            {s_.schedule.length > 0
                                ? translate('toolbarListTitle')
                                : translate('toolbarEmptyListTitle')}
                        </div>
                    )}
                    {!isDirectory &&
                        helpers.isAvailable({ key: 'schedule', action: 'read' }) &&
                        s_.schedule.map((item, index: number) => {
                            const sorted = s_.schedule
                                .filter((schedule: any) => schedule.hasOwnProperty('view_'))
                                .sort((a: any, b: any) => a.view_ - b.view_)
                            let showButtons: any = sorted[sorted.length - 1]

                            if (!showButtons) {
                                showButtons = true
                            } else {
                                showButtons = showButtons.view_ === item.view_
                            }

                            return (
                                <ScheduleItem
                                    key={`eventItem_${index}`}
                                    timeTitle={item.timeTitle}
                                    selected={item.id === s_.selected}
                                    broadcastTitle={item.broadcastTitle}
                                    isShowButtons={showButtons}
                                    onDeleteClick={() => {
                                        api.send('deleteSchedule', { id: item.id })
                                    }}
                                    onEditClick={() =>
                                        changeLocation({
                                            pathname: `/${routes.editSchedule.path}`,
                                            search: getURLSearchParamsString({
                                                scheduleId: item.id,
                                            }),
                                        })
                                    }
                                    scheduleToDigitalSignageList={[]}
                                    broadcastId={p_.selectedInfo.id}
                                    onSelectClick={() =>
                                        setState((prevState) => {
                                            return { ...prevState, selected: item.id }
                                        })
                                    }
                                />
                            )
                        })}
                    <div className={grid.space} />
                    <div className={cn(grid.p_mini, grid.rowCenter, styles.closeBtn)}>
                        <Button mod={'withBorder'} onClick={p_.onCloseInfo} indentSize={'mini'} rounded={'full_normal'}>
                            {translate('close')}
                        </Button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default BroadcastsToolbarContent
