import { Typography } from 'atoms/Typography'
import Icon from 'blocks.simple/icon/icon'
import translate from 'core/translate'
import React, { FC } from 'react'
import { getThemeStyleValue } from 'theme/colors'
import { DistributivesHeaderProps } from './DistributivesHeader-types'
import { styles } from './DistributivesHeader-styles'

export const DistributivesHeader: FC<DistributivesHeaderProps> = ({ onUpdate }) => {
    return (
        <div className={styles.distributivesHeader}>
            <div
                className={styles.distributivesHeader__button}
                onClick={onUpdate}
                data-testid="distributivesHeader_button"
            >
                <Icon type="refresh" color={getThemeStyleValue('ui', 'icon', 'active')} />
                <Typography type="promo" className={styles.distributivesHeader__text}>
                    {translate('distributives')}
                </Typography>
            </div>
        </div>
    )
}
