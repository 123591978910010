import React from 'react'
import helpers from 'core/helpers'
import filesHelpers from 'core/helpers/_files'
import { api } from 'core/api/ConnectionManager'
import translate from 'core/translate'
import { arrayMove } from 'react-sortable-hoc'
import { routes } from 'features/routes'
import { transitions } from 'blocks.app/config'
import deepEqual from 'fast-deep-equal'
import moment from 'moment'
import overlap from 'rectangle-overlap'
import { defaultOrderMode } from 'core/models/Editor'
import { generateIdByUUID, isArray } from 'core/utils/coreUtil'
import { roundFloatToInteger } from 'core/utils/convertUtil'
import { getURLSearchParamsByLocation, getURLSearchParamsString } from 'features/routes/utils'
import { getDefaultSourceDuration } from 'core/utils/broadcastUtil'
import { changeLocation } from 'features/routes'
import store from 'core/store'
import helpersBroadcasts from '../../core/helpers/_broadcasts'

let minimizedTimeout = null

//Возвращает объект структуры зоны
function createArea(position, content = []) {
    return {
        ...position,
        content,
        autoPos: 'withoutSpaces',
        xViewAvailable: true,
    }
}

//Возвращает объект структуры страницы
//Добавляет зону с индексом -1, необходимую для саундтрека всей трансляции
function createPage(order = 0, duration = 0, soundtrack = [], nextPageOrder = 0) {
    const page = {
        order,
        duration,
        areas: [],
        nextPageOrder,
    }

    page.areas[-1] = createArea(
        {
            width: 0,
            height: 0,
            top: -0.5,
            left: -0.5,
        },
        soundtrack
    )

    return page
}

export let editor = {}
const screenSize = { width: 1920, height: 1080 }
const getOverlapRectangle = ({ left: x, top: y, width, height }) => ({
    x,
    y,
    width,
    height,
})

const getFormattedAreaSize = ({ width, height, top, left }) => {
    const getSize = (size) => parseFloat(size.toFixed(6))
    const area = {
        width,
        height,
        top,
        left,
    }

    return Object.entries(area).reduce((acc, [key, value]) => {
        acc[key] = getSize(value)

        return acc
    }, {})
}

class EditorMethods extends React.Component {
    constructor(p_, context) {
        super(p_, context)
        this.state = this.getInitState()
        this.sorting = false
        this.stepChanged = false
        this.steps = []
        this.keyMap = {
            goPrev: 'ctrl+z',
            goNext: 'ctrl+shift+z',
        }
        this.screenKeyMap = {
            copy: 'ctrl+c',
            paste: 'ctrl+v',
        }
        this.screenHandlers = {
            copy: () => {
                const s_data = this.getStateData()
                const isSelectedArea = s_data.selectedArea === null

                if (!isSelectedArea) {
                    this.copyAreaContent()
                }
            },
            paste: () => {
                const s_data = this.getStateData()
                const s_ = this.state
                const isDisabledPaste = s_.data.clonedArea === null || s_data.selectedArea === null

                if (!isDisabledPaste) {
                    this.pasteAreaContent()
                }
            },
        }
        this.handlers = {
            goPrev: () => {
                const s_ = this.state
                if (s_.currentTimeStep === 0) {
                    return
                }

                this.changeStep(s_.currentTimeStep - 1)
            },
            goNext: () => {
                const s_ = this.state
                if (s_.currentTimeStep >= this.steps.length - 1) {
                    return
                }

                this.changeStep(s_.currentTimeStep + 1)
            },
        }
        editor = this
    }

    setEditorState(state, cb) {
        if (typeof cb === 'function') {
            this.setState(state, cb)
            return
        }

        this.setState(state)
    }

    //Возвращает state редактора. Объект broadcast - данные, отправляемые на сервер. Объект data используется для UI
    getInitState = () => {
        const state = store.getState()
        const userState = state.user

        return {
            broadcast: {
                title: '',
                orientation: 0,
                broadcastType: 'advanced',
                resolutionWidth: screenSize.width,
                resolutionHeight: screenSize.height,
                pages: [createPage()],
                soundOrderMode: defaultOrderMode,
            },
            data: {
                zoom: 1,
                saving: false,
                selectedPage: 0,
                selectedArea: null,
                validSelectedArea: null,
                selectedContent: null,
                showSettings: null,
                showAnimationSettings: false,
                titleError: '',
                duration: 0,
                durationError: '',
                sources: {},
                simple: false,
                minimized: false,
                selectedTemplate: [],
                screenProportions: screenSize.width / screenSize.height,
                areaViewType: userState.data.settings.broadcastAreaViewType,
                showAllAreas: false,
                screenSize,
                clonedArea: null,
                selected: [],
            },
            currentTimeStep: 0,
            fileLoading: null,
            isSoundtrackLoading: false,
            modalDeleteIsVisible: false,
            pageIndexForDelete: 0,
            rulesList: [],
            folderId: null,
            mobilePagesOpened: false,
            toolbarIsOpened: window.innerWidth > 960,
            multipleAddIndex: null,
            actionsBarOpened: false,
        }
    }

    componentDidMount() {
        const s_ = this.state
        const p_ = this.props
        const query = getURLSearchParamsByLocation(p_.location)

        this.initSimple()
        if (query.broadcastId) {
            p_.setTitle([translate('editBroadcast')])

            // Устанавливается модель трансляции полученной с сервера
            this.initBroadcast(query.broadcastId)
        } else if (p_.broadcast) {
            // Устанавливается модель трансляции из props, нужно для сохранения данных при переходе между шагами во влкадке "Контент на устройтсво"
            this.setBroadcast(p_.broadcast)
        } else {
            p_.setTitle([translate('createBroadcast')])

            if (p_.transitionData) {
                // Создаются зоны для видеостены, если переход в редактор был из "broadcasts/addVideowall"
                this.createVideoWallAreas(p_.transitionData)
                p_.setTransition(null)
            }

            s_.broadcast.title = `${translate('newBroadcast')} ${moment().format('HH:mm:ss DD/MM/YYYY')}`
            if (query.broadcastFolderId) {
                s_.broadcast.folderId = parseInt(query.broadcastFolderId, 10)
            }
            s_.data.duration = 0
            this.setPageDuration()
            this.startBroadcast = this.copyBroadcast(this.state.broadcast)

            if (p_.isSimple) {
                api.send('getAvailableToCreateParentBroadcastFolders', { nestedQ: false }).then((foldersData) => {
                    const folders = foldersData.map((folder) => helpersBroadcasts.getFolder(folder))
                    const mainFolder = folders.find((folder) => !folder.parentId)

                    if (mainFolder) {
                        s_.broadcast.folderId = mainFolder.id
                    }
                })
            }
        }
    }

    componentDidUpdate(prevProps) {
        const nextProps = this.props
        const prevQuery = getURLSearchParamsByLocation(prevProps.location)
        const nextQuery = getURLSearchParamsByLocation(nextProps.location)

        if (prevQuery.broadcastId && !nextQuery.broadcastId) {
            this.setEditorState(this.getInitState())
        }
    }

    openDeleteConfirm() {
        this.setState({
            modalDeleteIsVisible: true,
        })
    }

    closeDeleteConfirm() {
        this.setState({
            modalDeleteIsVisible: false,
        })
    }

    // Добавляет шаг в историю изменений, по которой можно двигаться при помощи ctrl+z и ctrl+shift+z
    addStep = (broadcastObj, dataObj) => {
        const s_ = this.state
        const broadcast = this.copyBroadcast(broadcastObj)
        const data = helpers.copyObject(dataObj)

        if (s_.currentTimeStep < this.steps.length) {
            this.steps = this.steps.slice(0, s_.currentTimeStep)
        }
        this.steps.push({ broadcast, data })
        s_.currentTimeStep++
        this.setEditorState(s_)
    }
    // Перемещает по истории изменений
    changeStep = (step) => {
        const s_ = this.state
        const lastIndex = this.steps.length - 1
        if (s_.currentTimeStep > lastIndex) {
            this.addStep(s_.broadcast, s_.data)
        }

        s_.broadcast = this.steps[step].broadcast
        s_.data = this.steps[step].data
        s_.currentTimeStep = step
        this.stepChanged = true

        this.setEditorState(s_)
    }

    // Приближает полотно редактора
    zoomIn = () => {
        const s_ = this.state
        s_.data.zoom += 0.5
        this.setEditorState(s_)
    }

    // Уменьшает полотно редактора
    zoomOut = () => {
        const s_ = this.state
        s_.data.zoom -= 0.5
        this.setEditorState(s_)
    }

    // Устанавливаает простую модель для вкладки "Контент на устройство"
    initSimple = () => {
        const p_ = this.props
        const s_ = this.state
        const query = getURLSearchParamsByLocation(p_.location)

        if (p_.isSimple) {
            s_.data.simple = true
            s_.broadcast.broadcastType = 'simple'

            if (!query.broadcastId && !p_.broadcast) {
                api.send('getTemplate', { id: 1 }).then((template) => {
                    editor.setArea(template)
                    this.startBroadcast = this.copyBroadcast(this.state.broadcast)
                })
            }
        }
    }

    // Изменение заголовка трансляции
    changeTitle(title) {
        const s_ = this.state
        s_.broadcast.title = title
        s_.data.titleError = ''
        this.setEditorState(s_)
    }

    // Валидация заголовка трансляции
    validateTitle() {
        const s_ = this.state
        if (!s_.broadcast.title) {
            s_.data.titleError = translate('emptyNameError')
        } else {
            api.send(
                'validateBroadcastTitle',
                { title: s_.broadcast.title, id: s_.broadcast.id },
                { hideLoader: true }
            ).then((data) => {
                if (data.isBusy) {
                    s_.data.titleError = translate('nameExist')
                }
            })
        }

        if (s_.data.titleError) {
            s_.data.showSettings = 'broadcast'
            s_.toolbarIsOpened = true
        }
        this.setEditorState(s_)
    }

    // Открывает вкладку "продолжительность" в правом тулбаре
    openDurationSettings() {
        const s_ = this.state
        s_.data.showSettings = 'duration'
        this.setEditorState(s_)
    }

    // Создаёт зоны для видеостены при переходе из "broadcasts/addVideowall"
    createVideoWallAreas(data) {
        const s_ = this.state
        s_.data.simple = true
        s_.broadcast.broadcastType = 'simple'

        const areas = this.getAreas()
        const width = data.resolutionWidth
        const height = data.resolutionHeight
        this.changeResolutionSize(data.cols * width, 'width')
        this.changeResolutionSize(data.rows * height, 'height')

        if (data.isOneContent) {
            areas.push(
                createArea({
                    width: 1,
                    height: 1,
                    top: 0,
                    left: 0,
                })
            )
        } else {
            const areaWidth = 1 / data.cols
            const areaHeight = 1 / data.rows

            for (let i = 0; i < data.cols; i++) {
                for (let j = 0; j < data.rows; j++) {
                    const area = createArea({
                        width: areaWidth,
                        height: areaHeight,
                        top: j * areaHeight,
                        left: i * areaWidth,
                    })
                    areas.push(area)
                }
            }
        }
        this.setEditorState(s_)
    }

    // Добавляет зону на странице
    addArea() {
        const s_ = this.state
        const { emitError } = this.props
        const maxWidth = 0.5
        const maxHeight = 0.5
        const areas = this.getAreas()
        const borders = {
            left: [1],
            top: [1],
            right: [0],
            bottom: [0],
        }

        const space = { width: 0, height: 0, top: 0, left: 0 }

        areas.forEach((area) => {
            borders.right.push(area.left + area.width)
            borders.bottom.push(area.top + area.height)
            borders.left.push(area.left)
            borders.top.push(area.top)
        })
        borders.right.sort(helpers.compareNumbers)
        borders.bottom.sort(helpers.compareNumbers)
        borders.left.sort(helpers.compareNumbers)
        borders.top.sort(helpers.compareNumbers)

        borders.bottom.every((startY) => {
            if (space.width > 0 && space.height > 0) {
                return false
            }

            borders.right.every((startX) => {
                if (space.width > 0 && space.height > 0) {
                    return false
                }

                let endX = 1
                let endY = 1

                areas.forEach((area) => {
                    const spaceArea = { left: startX, top: startY, width: endX - startX, height: 0 }
                    if (this.intersectAreas(spaceArea, area)) {
                        if (endX > area.left) {
                            endX = area.left
                        }
                    }
                })
                if (endX - startX <= 0) {
                    return true
                }

                areas.forEach((area) => {
                    const spaceArea = { left: startX, top: startY, width: endX - startX, height: endY - startY }
                    if (this.intersectAreas(spaceArea, area)) {
                        if (endY > area.top) {
                            endY = area.top
                        }
                    }
                })
                if (endY - startY <= 0) {
                    return true
                }

                space.top = startY
                space.left = startX
                space.width = endX - startX
                space.height = endY - startY

                return false
            })

            return true
        })

        if (space.width === 0 || space.height === 0) {
            emitError('noAreaSpace')
            return false
        }

        if (space.width > maxWidth) {
            space.width = maxWidth
        }
        if (space.height > maxHeight) {
            space.height = maxHeight
        }

        areas.push(createArea(space))
        const areaIndex = areas.length - 1

        s_.data.selectedArea = areaIndex
        s_.data.validSelectedArea = areas[areaIndex] ? { ...areas[areaIndex] } : null
        s_.data.showSettings = 'area'
        s_.data.minimized = false
        s_.data.selectedTemplate[s_.data.selectedPage] = null

        this.setEditorState(s_)
    }

    // Подсчитывает размер контента, чтобы растянуть контент, сохранив пропорции
    calcFullscreenWidthAndHeight(sourceId, areaIndex) {
        const s_ = this.state
        const source = s_.data.sources[sourceId]
        const area = this.getAreas()[areaIndex]
        const width = this.getContentWidth(source, area)

        return {
            width,
            height: this.getContentHeight(source, area, width),
        }
    }

    // Устанавливает вид отображения контента в таймлинии
    changeAreaViewType = (areaViewType) => {
        const s_ = this.state
        const { updateUserSettings } = this.props

        if (areaViewType === 'x' || areaViewType === 'list') {
            this.isXViewAvailable()
        }
        updateUserSettings({ data: { broadcastAreaViewType: areaViewType }, isSaveSettings: true })
        s_.data.areaViewType = areaViewType
        s_.data.showAllAreas = false

        this.setEditorState(s_)
    }

    // Делает то же, что и changeAreaViewType, но без проверки на isXViewAvailable, устаревший метод, нужен рефакторинг
    setAreaViewType = (areaViewType) => {
        const s_ = this.state
        const { updateUserSettings } = this.props

        if (s_.data.areaViewType === areaViewType) {
            return
        }

        updateUserSettings({ data: { broadcastAreaViewType: areaViewType }, isSaveSettings: true })
        s_.data.areaViewType = areaViewType

        this.setEditorState(s_)
    }

    // Обновляет список (объект по ключам id) с данными об используемых файлах
    updateSources(updatedList) {
        const s_ = this.state
        const sources = s_.data.sources

        updatedList.forEach((source) => {
            if (sources[source.id]) {
                sources[source.id] = source
            }
        })

        this.setEditorState(s_)
    }

    // Добавляет контент в зону
    addContent(contentSource) {
        const { data } = this.state
        const { settings } = this.props
        const sources = isArray(contentSource) ? contentSource : [].concat(contentSource)

        sources.forEach((source) => {
            const sourceData = source.data || {}
            const page = this.getPage()
            const area = this.getArea()
            const contentLength = area.content.length
            data.sources[source.id] = source

            let duration
            let subItemDurationS

            if (sourceData.duration) {
                duration = roundFloatToInteger(sourceData.duration * 1000)
            } else if (sourceData.extension === '.pdf' && sourceData.metadata) {
                duration =
                    getDefaultSourceDuration(settings.defaultSourceDuration, source) * sourceData.metadata.PageCount
            } else {
                if (area.autoPos || data.areaViewType === 'x') {
                    const defaultSourceDuration = getDefaultSourceDuration(settings.defaultSourceDuration, source)
                    duration = defaultSourceDuration || duration
                } else {
                    duration = Math.round(page.duration)
                }
            }

            if (!duration) {
                duration = 20000
            }

            if (!subItemDurationS) {
                subItemDurationS = 15
            }

            if (page.duration < duration) {
                page.duration = duration
                data.duration = this.msDurationToS(duration)
            }

            const newContent = {
                inPageContentId: generateIdByUUID(),
                source: source,
                sourceId: source.id,
                duration,
                startTime: 0,
                position: this.getContentPosition(source, area, contentLength),
                animation: {
                    in: null,
                    state: null,
                    out: null,
                },
                leftIndent: 0,
                rightIndent: 0,
                style: source.style,
                volume: filesHelpers.isVolumeExist(source.fileType) ? (data.selectedArea === -1 ? 100 : 0) : null,
            }

            if (source.extension === '.pdf') {
                newContent.subItemDurationS = subItemDurationS
                newContent.subItems = null
            }

            newContent.position = this.getFullscreenCropPositionByContent(newContent)
            area.content.push(newContent)

            this.calcContentPosition(area, area.autoPos, { setPageDuration: true, disableSetState: true })
            this.setLayersCount()
        })

        this.setEditorState((prevState) => ({
            ...prevState,
            data,
            fileLoading: null,
        }))
    }

    // Позиция контента исходя из размеров таблицы
    getContentPosition = (source, area, zIndex) => {
        const s_ = this.state
        const data = {
            top: 0,
            left: 0,
            width: 1,
            height: 1,
            fillMode: null,
            zIndex,
        }

        if (source.type !== 'table') {
            return data
        }

        const sum = (array) => array.reduce((a, b) => a + b, 0)

        const tableOptions = source.data.tableFormat
        const broadcast = s_.broadcast
        const absArea = {
            height: Math.round(area.height * broadcast.resolutionHeight),
            width: Math.round(area.width * broadcast.resolutionWidth),
        }

        if (tableOptions.colWidth === 'fixed' && tableOptions.widthByCol) {
            const width = sum(tableOptions.widthByCol)
            if (absArea.width > width) {
                data.width = width / absArea.width
            }
        }

        if (tableOptions.rowHeight === 'fixed' && tableOptions.heightByRow) {
            const height = sum(tableOptions.heightByRow)
            if (absArea.height > height) {
                data.height = height / absArea.height
            }
        }

        return data
    }

    // Подсчёт количества слоёв из контента. (То есть контентов, которые накладываются друг на друга)
    calcLayersCount(content, contentIndex) {
        let layersCount = 1
        const checkContent = content[contentIndex]

        content.forEach((contentItem, index) => {
            if (index !== contentIndex) {
                const intersect = overlap(
                    getOverlapRectangle(checkContent.position),
                    getOverlapRectangle(contentItem.position)
                )

                if (intersect && intersect.area > 0) {
                    layersCount++
                }
            }
        })

        return layersCount
    }

    // Устанавливает количество наложений для каждого контента, это число отображается в правом верхнем углу контента. Необходимо, чтобы понять, сколько файлов под самым верхним
    setLayersCount() {
        const content = this.getContent()

        content.forEach((contentItem, index) => {
            contentItem.layersCount = this.calcLayersCount(content, index)
        })
    }

    // Обновление позиции контента
    updatePosition(areaIndex, contentIndex, position, type) {
        const s_ = this.state
        const contentItem = this.getContentItem(areaIndex, contentIndex)

        contentItem.position = {
            ...contentItem.position,
            ...position,
        }
        if (type === 'resize') {
            contentItem.position.fillMode = undefined
        }
        this.setLayersCount()
        this.setEditorState(s_)
    }

    // Обновление громкости у контента
    updateVolume(volume, areaIndex, contentIndex) {
        const s_ = this.state
        const contentItem = this.getContentItem(areaIndex, contentIndex)

        contentItem.volume = volume
        this.setEditorState(s_)
    }

    // Обновление громкости аудиодорожки
    updateSoundtrackVolume(volume) {
        const s_ = this.state
        const content = this.getContent(-1)

        content.forEach((soundtrackItem) => {
            soundtrackItem.volume = volume
        })

        this.setEditorState(s_)
    }

    updateContentStyle(style) {
        const s_ = this.state
        const contentItem = this.getContentItem()

        contentItem.style = style

        this.setEditorState(s_)
    }

    // Возвращает значение громкости страницы, и все ли значения у контентов одинаковы
    getPageVolume() {
        let volume
        let isEqual = true
        const page = this.getPage()

        page.areas.forEach((area) => {
            area.content.forEach((content) => {
                if (content.volume !== null) {
                    if (typeof volume === 'undefined') {
                        volume = content.volume
                    } else {
                        if (volume !== content.volume) {
                            isEqual = false
                        }
                    }
                }
            })
        })

        if (!isEqual || typeof volume === 'undefined') {
            volume = 0
        }

        return { volume, isEqual }
    }

    // Устанавливает громкость звука на страницу, по сути одинаковую громкость на все контенты
    setPageVolume(volume) {
        const s_ = this.state
        const page = this.getPage()

        page.areas.forEach((area) => {
            area.content.forEach((content) => {
                if (content.volume !== null) {
                    content.volume = volume
                }
            })
        })

        this.setEditorState(s_)
    }

    // Устанавливает условия проигрывания контента
    setPlayingCondition(areaIndex, contentIndex, playingCondition) {
        const s_ = this.state
        const contentItem = this.getContentItem(areaIndex, contentIndex)

        contentItem.playingCondition = this._mustResetCondition(playingCondition) ? {} : playingCondition
        this.setEditorState(s_)
    }

    _mustResetCondition = (playingCondition) => {
        if (playingCondition.type === 'xml' || playingCondition.type === 'csv') {
            return playingCondition.localPath === ''
        }
        return false
    }

    // Устанавливает действие на контент
    setActionsOnContent(actionsOnContent) {
        const s_ = this.state
        const contentItem = this.getContentItem(s_.selectedArea, s_.selectedContent)

        contentItem.actionsOnContent = actionsOnContent
        this.setEditorState(s_)
    }

    deleteActionOnContent(eventType, actionType) {
        const s_ = this.state
        const contentItem = this.getContentItem(s_.selectedArea, s_.selectedContent)

        contentItem.actionsOnContent.forEach((event) => {
            if (event.eventType === eventType) {
                event.actions = event.actions.filter((action) => action.actionType !== actionType)
            }
        })

        contentItem.actionsOnContent = contentItem.actionsOnContent.filter((event) => event.actions.length)

        if (!contentItem.actionsOnContent.length) {
            delete contentItem.actionsOnContent
        }

        this.setEditorState(s_)
    }

    // Растягивает контент на всю ширину, сохраняя пропорции
    setFullscreenPosition(areaIndex, contentIndex) {
        const s_ = this.state
        const contentItem = this.getContentItem(areaIndex, contentIndex)
        const contentSize = this.calcFullscreenWidthAndHeight(contentItem.sourceId, areaIndex)

        contentItem.position.width = contentSize.width
        contentItem.position.height = contentSize.height
        contentItem.position.left = 0
        contentItem.position.top = 0
        contentItem.position.fillMode = null

        this.setEditorState(s_)
    }

    // Растягивает контент на всю зону без сохранения пропорций
    setFullscreenMaximizePosition(areaIndex, contentIndex) {
        const s_ = this.state
        const contentItem = this.getContentItem(areaIndex, contentIndex)

        contentItem.position.width = 1
        contentItem.position.height = 1
        contentItem.position.left = 0
        contentItem.position.top = 0
        contentItem.position.fillMode = null

        this.setEditorState(s_)
    }

    // Растягивает контент на всю зону, с сохранением пропорции. При этом возможно обрезание видимой части контента по ширине или высоте.
    setFullscreenCropPosition(areaIndex, contentIndex) {
        const s_ = this.state
        const contentItem = this.getContentItem(areaIndex, contentIndex)

        contentItem.position = this.getFullscreenCropPositionByContent(contentItem)
        this.setEditorState(s_)
    }

    getFullscreenCropPositionByContent(content) {
        const updatedContent = { ...content }
        return {
            ...updatedContent.position,
            ...this.getFullscreenCropPosition(),
        }
    }

    // Возвращает объект с позицией контента в режиме растягивания на всю зону с обрезанием видимой части
    getFullscreenCropPosition() {
        return {
            top: 0,
            left: 0,
            width: 1,
            height: 1,
            fillMode: 'stretch',
        }
    }

    // Переключает настройки анимации
    toggleAnimationSettings() {
        const s_ = this.state

        s_.data.showAnimationSettings = !s_.data.showAnimationSettings
        this.setEditorState(s_)
    }

    // Закрывает настройки анимации
    closeAnimationSettings = () => {
        const s_ = this.state

        s_.data.showAnimationSettings = false
        this.setEditorState(s_)
    }

    // Автопозиционирование контента
    autoPosContent(posType, areaIndex) {
        const s_ = this.state
        const area = this.getArea(areaIndex)
        area.autoPos = area.autoPos === posType ? null : posType

        this.calcContentPosition(area, area.autoPos, { setPageDuration: true })
        this.isXViewAvailable()

        this.setEditorState(s_)
    }

    // Переключение зацикливания проигрывания контента
    toggleLoop(areaIndex) {
        const s_ = this.state
        const area = this.getArea(areaIndex)
        area.loop = !area.loop
        this.setEditorState(s_)
    }

    // Изменение Порядка воспроизведения для зоны
    setContentOrderMode = (areaIndex, mode) => {
        const s_ = this.state
        const area = this.getArea(areaIndex)
        area.contentOrderMode = mode
        this.setEditorState(s_)
    }

    // Изменение Порядка воспроизведения для аудио дорожки
    setSoundOrderMode = (mode) => {
        const s_ = this.state
        s_.broadcast.soundOrderMode = mode
        this.setEditorState(s_)
    }

    // Расчёт позиции контента при автопозиционировании
    calcContentPosition(area, autoPos, options = {}) {
        if (!(autoPos || this.state.data.areaViewType === 'x' || this.state.data.showAllAreas)) {
            return
        }

        const s_ = this.state
        const page = this.getPage()
        const content = area.content
        let fullContentsDuration = 0

        content.forEach((contentItem, index) => {
            if (autoPos) {
                contentItem.leftIndent = 0
                contentItem.rightIndent = 0
            }
            if (index < content.length - 1) {
                fullContentsDuration += contentItem.leftIndent + contentItem.duration
            } else {
                fullContentsDuration += contentItem.leftIndent + contentItem.duration + contentItem.rightIndent
            }
        })
        if (options.setPageDuration && page.duration <= fullContentsDuration) {
            fullContentsDuration = Math.round(fullContentsDuration)
            page.duration = fullContentsDuration
            s_.data.duration = this.msDurationToS(fullContentsDuration)
        }

        let offset = 0
        content.forEach((contentItem, index) => {
            contentItem.startTime = offset + contentItem.leftIndent
            offset += contentItem.leftIndent + contentItem.duration
        })

        this.setIndentsInArea(area)

        if (!options.disableSetState) {
            this.setEditorState(s_)
        }
    }

    // Обновление позиции зоны
    updateAreaPosition(areaIndex, position) {
        const s_ = this.state
        const { emitError } = this.props
        const areas = this.getAreas()
        const areaItem = this.getArea(areaIndex)
        const page = {
            width: 1,
            height: 1,
            top: 1,
            left: 1,
        }
        let isIntersect = false

        areas.forEach((area, index) => {
            if (index !== areaIndex && this.intersectAreas(position, area)) {
                isIntersect = true
            }
        })

        const validSelectedArea = s_.data.validSelectedArea

        if (isIntersect) {
            emitError('areaIntersect')

            areaItem.width = validSelectedArea.width
            areaItem.height = validSelectedArea.height
            areaItem.left = validSelectedArea.left
            areaItem.top = validSelectedArea.top
        } else {
            if (!validSelectedArea) {
                s_.data.selectedTemplate[s_.data.selectedPage] = null
                this.setEditorState(s_)

                return true
            }

            const areaPosition = getFormattedAreaSize(position)
            const validAreaPosition = getFormattedAreaSize(validSelectedArea)

            areaItem.width = areaPosition.width
            areaItem.height = areaPosition.height
            areaItem.left = areaPosition.left
            areaItem.top = areaPosition.top

            for (let [key, value] of Object.entries(page)) {
                if (areaItem[key] > value) {
                    areaItem[key] = value
                    areaPosition[key] = value
                }
            }

            if (!deepEqual(areaPosition, validAreaPosition)) {
                s_.data.selectedTemplate[s_.data.selectedPage] = null
                s_.data.validSelectedArea = { ...areaItem }
            }
        }
        this.setEditorState(s_)

        return !isIntersect
    }

    // Отступ контента по времени относительно левого соседа
    getLeftIndent = (contentItem, prevContentItem) => {
        let leftIndent = contentItem.startTime

        if (prevContentItem) {
            leftIndent = contentItem.startTime - (prevContentItem.startTime + prevContentItem.duration)
        }

        return leftIndent
    }

    // Отступ контента по времени относительно правого соседа
    getRightIndent = (contentItem, nextContentItem) => {
        const page = this.getPage()
        const rightTime = contentItem.startTime + contentItem.duration
        let rightIndent = page.duration - rightTime

        if (nextContentItem) {
            rightIndent = nextContentItem.startTime - rightTime
        }

        return rightIndent
    }

    //Обновление интервала смены страниц документа
    updateSubItemDurationS(areaIndex, contentIndex, subItemDurationS) {
        const s_ = this.state
        const contentItem = this.getContentItem(areaIndex, contentIndex)

        contentItem.subItemDurationS = subItemDurationS

        this.setLayersCount()
        this.setEditorState(s_)
    }

    //Обновление страниц документа, которые необходимо отобразить
    updateSubItems(areaIndex, contentIndex, subItems) {
        const s_ = this.state
        const contentItem = this.getContentItem(areaIndex, contentIndex)

        contentItem.subItems = subItems

        this.setLayersCount()
        this.setEditorState(s_)
    }

    // Обновление времени начала и продолжительности проигрывания контента
    updateTime(start, updatedDuration, areaIndex, contentIndex) {
        if (this.sorting) {
            return this.sorting
        }
        const s_ = this.state
        const { emitError } = this.props
        const contentItem = this.getContentItem(areaIndex, contentIndex)
        const area = this.getArea(areaIndex)
        let startTime = Math.round(start)
        let duration = contentItem.duration
        let moveType = 'drag'
        let calcContentPosition = false

        if (typeof updatedDuration !== 'undefined') {
            duration = Math.round(updatedDuration)
            if (duration !== contentItem.duration) {
                moveType = 'resize'
            }
        }

        if (moveType === 'resize' && area.autoPos) {
            calcContentPosition = true
        }

        if (s_.data.areaViewType === 'x' || s_.data.showAllAreas) {
            const prevContent = area.content[contentIndex - 1]
            const nextContent = area.content[contentIndex + 1]
            const leftIndent = this.getLeftIndent({ startTime, duration }, area.content[contentIndex - 1])
            const rightIndent = this.getRightIndent({ startTime, duration }, area.content[contentIndex + 1])
            if (leftIndent < 0 || rightIndent < 0) {
                if (moveType === 'drag') {
                    if (leftIndent < 0 && prevContent) {
                        startTime = prevContent.startTime + prevContent.duration
                    } else if (rightIndent < 0 && nextContent) {
                        startTime = nextContent.startTime - contentItem.duration
                    } else {
                        startTime = contentItem.startTime
                        duration = contentItem.duration
                    }
                } else if (!area.autoPos) {
                    startTime = contentItem.startTime
                    duration = contentItem.duration
                    emitError('contentIntersect')
                }
                this.sorting = true
                setTimeout(() => {
                    this.sorting = false
                }, 0)
            }
        }
        if (!calcContentPosition && (contentItem.startTime !== startTime || contentItem.duration !== duration)) {
            area.autoPos = null
        }

        contentItem.startTime = startTime
        contentItem.duration = duration
        this.setIndentsInArea(area)

        if (calcContentPosition) {
            this.calcContentPosition(area, area.autoPos, { setPageDuration: true })
        } else {
            this.setEditorState(s_)
        }
    }

    // Подсчёт отступов по времени для контентов в зоне
    setIndentsInArea(area) {
        area.content.forEach((areaContentItem, index) => {
            areaContentItem.leftIndent = this.getLeftIndent(areaContentItem, area.content[index - 1])
            areaContentItem.rightIndent = this.getRightIndent(areaContentItem, area.content[index + 1])

            if (areaContentItem.leftIndent < 0) {
                areaContentItem.leftIndent = 0
            }
            if (areaContentItem.rightIndent < 0) {
                areaContentItem.rightIndent = 0
            }
        })
    }

    // Можно ли показывать таймлинии в горизонтальном представлении, то есть нет ли пересечений контента по времени
    isXViewAvailable() {
        const page = this.getPage()

        page.areas.forEach((area) => {
            area.xViewAvailable = true
            area.content.forEach((areaContentItem, index) => {
                const leftIndent = this.getLeftIndent(areaContentItem, area.content[index - 1])
                const rightIndent = this.getRightIndent(areaContentItem, area.content[index + 1])

                if (leftIndent < 0 || rightIndent < 0) {
                    area.xViewAvailable = false
                }
            })
        })
    }

    // Обновить свойства анимации
    updateAnimation = (animation, field, areaIndex, contentIndex) => {
        const s_ = this.state
        const contentItem = this.getContentItem(areaIndex, contentIndex)

        contentItem.animation = { ...contentItem.animation, ...{ [field]: animation } }
        this.setEditorState(s_)
    }

    // Сортировать контент по времени и zIndex
    sortContent(oldIndex, newIndex, areaIndex) {
        this.sorting = newIndex !== oldIndex
        if (!this.sorting) {
            return
        }
        const s_ = this.state
        const area = this.getArea(areaIndex)
        area.content = arrayMove(area.content, oldIndex, newIndex)
        s_.data.selectedContent = newIndex
        this.updateZIndex(area)
        this.setEditorState(s_)
        setTimeout(() => {
            this.sorting = false
        }, 0)
    }

    broadcastsPathCheck = (pathName) => {
        switch (pathName) {
            case '/broadcasts/edit':
                return true
            default:
                return false
        }
    }

    // Клонировать контент
    cloneContent(contentItem) {
        const s_ = this.state
        const area = this.getArea()
        this.broadcastsPathCheck(this.props.location.pathname) && delete contentItem.id
        const isSoundtrack = s_.data.selectedArea === -1
        const clone = {
            ...contentItem,
            position: { ...contentItem.position },
            inPageContentId: generateIdByUUID(),
        }

        clone.position.top = 0
        clone.position.left = 0

        area.content.push(clone)
        s_.data.selectedContent = area.content.length - 1
        s_.data.showAnimationSettings = false

        if (isSoundtrack) {
            this.calcContentPosition(area, area.autoPos)
        } else {
            this.updateZIndex(area, true)
            this.setEditorState(s_)
        }
    }

    broadcastsPathCheck = (pathName) => {
        switch (pathName) {
            case '/broadcasts/edit':
                return true
            default:
                return false
        }
    }

    // Обновить zIndex у контента
    updateZIndex(area, setPageDuration = false) {
        const s_ = this.state
        const content = area.content
        let zIndex = 0
        content.forEach((contentItem) => {
            contentItem.position.zIndex = zIndex
            zIndex++
        })

        this.calcContentPosition(area, area.autoPos, { setPageDuration })

        this.setEditorState(s_)
    }

    // Получить абсолютную ширину контента в пикселях
    getContentWidth(source, area, contentWidth = 1) {
        const s_ = this.state
        const sourceData = source.data || {}
        const { width, height } = sourceData
        const wh = s_.data.screenProportions * (area.width / area.height)

        if (width && height && width / height < wh) {
            contentWidth = width / (height * wh)
        }
        contentWidth = parseFloat(contentWidth.toFixed(6))

        return contentWidth
    }

    // Получить абсолютную высоту контента в пикселях
    getContentHeight(source, area, relWidth) {
        const s_ = this.state
        const sourceData = source.data || {}
        const { width, height } = sourceData
        const wh = s_.data.screenProportions * (area.width / area.height)
        let contentHeight = relWidth

        if (width && height) {
            contentHeight = (height / width) * relWidth * wh
        }
        contentHeight = parseFloat(contentHeight.toFixed(6))

        return contentHeight
    }

    // Изменить расширение холста/экрана
    changeResolutionSize(value, field) {
        const s_ = this.state
        let fieldName

        if (field === 'width') {
            fieldName = 'resolutionWidth'
        }
        if (field === 'height') {
            fieldName = 'resolutionHeight'
        }

        s_.broadcast[fieldName] = value * 1
        const innerScreenWH = s_.broadcast.resolutionWidth / s_.broadcast.resolutionHeight

        if (innerScreenWH >= s_.data.screenProportions) {
            s_.data.screenSize = {
                width: s_.broadcast.resolutionWidth,
                height: s_.broadcast.resolutionWidth / s_.data.screenProportions,
            }
        } else {
            s_.data.screenSize = {
                width: s_.broadcast.resolutionHeight * s_.data.screenProportions,
                height: s_.broadcast.resolutionHeight,
            }
        }
        this.setEditorState(s_)
    }

    // Переключатель расширения экрана между вертикальным и горизонтальным
    switchResolutionSize = () => {
        const s_ = this.state
        const width = s_.broadcast.resolutionHeight
        const height = s_.broadcast.resolutionWidth

        this.changeResolutionSize(width, 'width')
        this.changeResolutionSize(height, 'height')
    }

    // Выбор контента
    selectContent(areaIndex, contentIndex, showContentSettings = false, options = {}, blockOpenToolbar) {
        const s_ = this.state

        if (s_.data.selectedArea !== areaIndex) {
            s_.data.selected = []
        }

        const areas = this.getAreas()
        s_.data.selectedArea = areaIndex
        s_.data.validSelectedArea = { ...areas[areaIndex] }
        s_.data.selectedContent = contentIndex
        s_.data.showSettings = showContentSettings ? 'content' : null
        s_.data.showAnimationSettings = false
        if (!options.notClearAllAreas) {
            s_.data.showAllAreas = false
        }
        s_.mobilePagesOpened = false
        s_.actionsBarOpened = false

        this.setEditorState(s_)
    }

    // Выбор зоны
    selectArea(areaIndex, showAreaSettings = false) {
        if (this.state.rulesList.length) return

        const s_ = this.state
        const areas = this.getAreas()

        if (areaIndex === 'allAreas') {
            this.isXViewAvailable()
            s_.data.showAllAreas = true
        } else {
            s_.data.showAllAreas = false
            s_.data.selectedArea = areaIndex
        }
        if (areaIndex !== null) {
            s_.multipleAddIndex = areaIndex
        }
        s_.data.selectedContent = null
        s_.data.showAnimationSettings = false
        s_.data.showSettings = showAreaSettings ? 'area' : null
        s_.data.validSelectedArea = areas[areaIndex] ? { ...areas[areaIndex] } : null
        s_.data.selected = []

        this.setEditorState(s_)
    }

    // Добавить страницу в трансляцию
    addPage() {
        const s_ = this.state
        const pages = s_.broadcast.pages
        const currentPage = this.getPage()

        this.selectArea(null)
        pages.push(createPage(pages.length))
        pages[pages.length - 1].areas[-1] = currentPage.areas[-1]
        s_.data.selectedPage = pages.length - 1
        s_.data.selectedTemplate.push(null)
        s_.data.duration = 0
        s_.data.selected = []

        const prevPage = pages[pages.length - 2]
        if (pages.length > 1 && !prevPage.nextPageOrder) {
            this.setNextPageOrder(pages.length - 2, pages)
        }
        this.setPageDuration()

        this.setEditorState(s_)
    }

    // Установить значение nextPageOrder
    setNextPageOrder = (pageIndex, pages) => {
        let nextPageOrder = 0

        if (pageIndex > -1 && pageIndex < pages.length - 1) {
            nextPageOrder = pages[pageIndex + 1].order
        }
        pages[pageIndex].nextPageOrder = nextPageOrder
    }
    // Устанавливает nextPageOrder из настроек связок страниц
    setNextPage = (nextPageOrder) => {
        const s_ = this.state

        s_.broadcast.pages[s_.data.selectedPage].nextPageOrder = nextPageOrder

        this.setEditorState(s_)
    }

    // Сортирует порядок страниц после завершения drag&drop
    onSortPages(pages, selectedTemplate) {
        const s_ = this.state
        this.selectArea(null)

        this.setState({
            broadcast: {
                ...s_.broadcast,
                pages,
            },
            data: {
                ...s_.data,
                selectedTemplate,
            },
        })
    }

    // Удалить страницу из трансляции
    deletePage(pageIndexForDelete) {
        const s_ = this.state
        this.selectArea(null)
        const pages = s_.broadcast.pages
        if (pages.length === 1) {
            return
        }
        const selectedPage = s_.data.selectedPage
        if (selectedPage > 0) {
            this.navigateToPage(selectedPage - 1)
        }

        const pageToDeleteOrder = pages[pageIndexForDelete].order
        const selectedTemplate = s_.data.selectedTemplate[pageIndexForDelete]

        if (selectedTemplate) {
            s_.data.selectedTemplate.splice(pageIndexForDelete, 1)
        }

        pages.splice(pageIndexForDelete, 1)

        pages
            .filter((page) => page.nextPageOrder === pageToDeleteOrder)
            .forEach((page, index) => {
                this.setNextPageOrder(index, pages)
            })

        const changedOrdersMap = new Map()

        pages.forEach((page, index) => {
            changedOrdersMap.set(page.order, index)
            page.order = index
        })
        s_.data.selected = []

        pages.forEach((page) => {
            page.nextPageOrder = changedOrdersMap.get(page.nextPageOrder)
        })

        this.setEditorState(s_, () => {
            this.removeActionsAndEvents(pageIndexForDelete, pages)
        })
    }

    setEventsToPage = (playOnEvent) => {
        const s_ = this.state

        s_.broadcast.pages[s_.data.selectedPage].playOnEvent = playOnEvent
        this.setEditorState(s_)
    }
    deleteEventFromPage = (index) => {
        const s_ = this.state

        const pages = s_.broadcast.pages.map((page) => {
            if (page.playOnEvent && page.order === s_.data.selectedPage) {
                page.playOnEvent.events.splice(index, 1)

                if (!page.playOnEvent.events.length) {
                    delete page.playOnEvent
                }
            }

            return page
        })

        this.setEditorState({
            broadcast: {
                ...s_.broadcast,
                pages,
            },
        })
    }

    removeActionsAndEvents(selectedPage, pages) {
        const s_ = this.state
        const allContent = this.getAllContent(pages)

        allContent.forEach((content) => {
            if (content.actionsOnContent) {
                content.actionsOnContent = content.actionsOnContent.filter((event) => {
                    const filteredActions = event.actions.filter(
                        (action) => action.actionOptions.key === 'order' && action.actionOptions.value !== selectedPage
                    )

                    return filteredActions.length > 0
                })

                if (!content.actionsOnContent.length) {
                    delete content.actionsOnContent
                }
            }
        })

        this.setEditorState(s_)
    }

    getAllContent = (pages) => {
        const content = []

        pages.forEach((page) => {
            page.areas.forEach((area) => {
                content.push(...area.content)
            })
        })

        return content
    }
    onSelectAllContentByTagNameInArea = (options = {}) => {
        const s_ = this.state
        if (!options.hasOwnProperty('selectedArea')) {
            options.selectedArea = s_.data.selectedArea
        }
        const area = this.getArea(options.selectedArea)
        const taggedAreaContent = area.content.filter((content) =>
            content.source.tags.some((tag) => tag.name.includes(options.tagValue))
        )

        let selected = []

        if (taggedAreaContent.length > s_.data.selected.length) {
            selected = taggedAreaContent.map((content) => content.inPageContentId)
        }

        this.setEditorState({
            data: {
                ...s_.data,
                selected,
            },
        })
    }
    // Снять выбор со всех саундтреков при поиске по тегу
    onDeselectAllContentInArea = (options = {}) => {
        const s_ = this.state
        if (!options.hasOwnProperty('selectedArea')) {
            options.selectedArea = s_.data.selectedArea
        }

        this.setEditorState({
            data: {
                ...s_.data,
                selected: [],
            },
        })
    }
    // Выбор всего контента в зоне при мультивыборе
    onSelectAllContentInArea = (options = {}) => {
        const s_ = this.state
        if (!options.hasOwnProperty('selectedArea')) {
            options.selectedArea = s_.data.selectedArea
        }
        const area = this.getArea(options.selectedArea)
        let selected = []

        if (area.content.length > s_.data.selected.length) {
            selected = area.content.map((content) => content.inPageContentId)
        }

        this.setEditorState({
            data: {
                ...s_.data,
                selected,
            },
        })
    }
    // удаляет весь контент из зоны при мультивыборе
    onRemoveSelectedSources = (options = {}) => {
        const s_ = this.state
        if (!options.hasOwnProperty('selectedArea')) {
            options.selectedArea = s_.data.selectedArea
        }
        const area = this.getArea(options.selectedArea)

        area.content = area.content.filter((source) => !s_.data.selected.includes(source.inPageContentId))
        s_.data.selected = []
        this.setEditorState(s_)
    }
    resetSelectedSources = () => {
        const s_ = this.state

        s_.data.selected = []
        s_.data.selectContent = null

        this.setEditorState(s_)
    }

    // Переключиться между страницами
    navigateToPage(navigatePage) {
        const s_ = this.state
        const pages = s_.broadcast.pages
        const currentPage = this.getPage()

        s_.data.duration = pages[navigatePage].duration / 1000
        s_.data.selectedArea = null
        s_.data.selectContent = null
        s_.data.showAllAreas = false
        s_.data.showSettings = null
        s_.data.selectedPage = navigatePage
        pages[navigatePage].areas[-1] = currentPage.areas[-1]

        this.setEditorState(s_)
    }

    // Показать настройки
    showSettings(settingName) {
        const s_ = this.state
        s_.data.showSettings = settingName
        s_.toolbarIsOpened = true

        this.setEditorState(s_)
    }

    // Установить продолжительность страницы
    setPageDuration(initDuration) {
        const { emitError } = this.props
        const s_ = this.state
        const duration = initDuration || s_.data.duration

        const page = this.getPage()
        let isValidDuration = true
        const durationMs = roundFloatToInteger(duration * 1000)

        page.areas.forEach((area) => {
            area.content.forEach((contentItem) => {
                if (durationMs < contentItem.duration) {
                    isValidDuration = false
                }
            })
        })

        if (isValidDuration) {
            this.getPage().duration = durationMs
            s_.data.duration = this.msDurationToS(durationMs)
        } else {
            s_.data.duration = this.msDurationToS(this.getPage().duration)
            emitError('advPageDurationError')
        }
        this.setEditorState(s_)
    }

    // Установить продолжительность страницы по  суммарной продолжительности контента
    setContentPageDuration() {
        const s_ = this.state
        let maxContentDuration = 0
        const areas = this.getAreas()
        areas.forEach((area, index) => {
            const contentDuration = this.getContentDuration(area.content)

            if (contentDuration > maxContentDuration) {
                maxContentDuration = contentDuration
            }
        })

        this.getPage().duration = maxContentDuration
        s_.data.duration = this.msDurationToS(maxContentDuration)
        this.setEditorState(s_)
    }

    // Получить суммарную продолжительность контента
    getContentDuration(content = []) {
        let contentDuration = 0
        content.forEach((contentItem, index) => {
            const rightBorder = contentItem.startTime + contentItem.duration

            if (rightBorder > contentDuration) {
                contentDuration = rightBorder
            }
        })

        return contentDuration
    }

    // Скрыть настройки
    hideSettings() {
        const s_ = this.state
        s_.data.showSettings = null
        if (this.props.isMobile) {
            s_.toolbarIsOpened = false
        }
        this.setEditorState(s_)
    }

    // Миллисекунды в секунды
    msDurationToS(duration) {
        return (duration / 1000).toFixed(3) * 1
    }

    // Получить страницу
    getPage() {
        const s_ = this.state
        const selectedPage = s_.data.selectedPage
        return s_.broadcast.pages[selectedPage]
    }

    // Получить зоны
    getAreas() {
        return this.getPage().areas
    }

    // Получить одну зону
    getArea(areaIndex) {
        const s_ = this.state
        if (areaIndex === null || typeof areaIndex === 'undefined') {
            areaIndex = s_.data.selectedArea
        }

        return this.getAreas()[areaIndex]
    }

    // Получить весь контент в зоне
    getContent(areaIndex) {
        const area = this.getArea(areaIndex)

        if (area) {
            return area.content
        }
    }

    // Получает отсортированный список аудиконтента и обнуляет время начала
    // дальше устанавливает непрерывную позицию контента,
    // (Расположить контент непрерывно по времени (автоподсчет))
    setAutoPositionSoundContent(content) {
        const area = this.getArea()

        let startTime = 0

        content = content.map((item, contentIdx) => {
            const model = {
                ...item,
                startTime,
                position: {
                    ...item.position,
                    zIndex: contentIdx,
                },
            }
            startTime += item.duration
            return model
        })

        area.content = content
        this.autoPosContent('withoutSpaces')
    }

    // Получить один элемент контента из зоны
    getContentItem(areaIndex, contentIndex) {
        const s_ = this.state
        if (contentIndex === null || typeof contentIndex === 'undefined') {
            contentIndex = s_.data.selectedContent
        }
        const content = this.getContent(areaIndex)

        if (content) {
            return content[contentIndex]
        }
    }

    // Добавить файл в зону
    dropFile(sourceData, areaIndex) {
        this.setEditorState((prevState) => {
            return {
                ...prevState,
                data: { ...prevState.data, selectedArea: areaIndex },
                fileLoading: {
                    areaId: areaIndex,
                },
            }
        })

        if (!sourceData.isDirectory) {
            api.send('getFile', { id: sourceData.id }, { hideLoader: true })
                .then((source) => this.addContent(source))
                .catch(() => {
                    this.setEditorState((prevState) => ({ ...prevState, fileLoading: null }))
                })
        } else {
            api.send('getFolder', { folderId: sourceData.id }, { hideLoader: false })
                .then((folder) => {
                    if (folder.smartFolderRules.length) {
                        this.setEditorState((prevState) => ({
                            ...prevState,
                            folderId: sourceData.id,
                            fileLoading: null,
                        }))
                        this.smartFolderRuleSelect(folder.smartFolderRules)

                        return
                    }

                    api.send('getSources', { folderId: [sourceData.id] }, { hideLoader: false })
                        .then((sources) => {
                            this.addContent(sources)
                        })
                        .catch(() => {
                            this.setEditorState((prevState) => ({ ...prevState, fileLoading: null }))
                        })
                })
                .catch(() => {
                    this.setEditorState((prevState) => ({ ...prevState, fileLoading: null }))
                })
        }
    }

    // Добавить файл в зону через множественный выбор
    setSourcesOnMultipleSelect(sourceList) {
        let index = this.state.multipleAddIndex === -1 ? -1 : !this.props.isSimple ? this.state.multipleAddIndex : 0
        if (!this.props.isMobile && index === null) {
            index = 0
        }
        const s_data = this.getStateData()
        const areas = this.getAreas()

        if (s_data.selectedArea === null && areas.length > 1 && !this.props.isMobile) {
            return this.props.emitError('noArea')
        }
        if (index === null) return

        const sources = sourceList.filter((source) => !source.isDirectory)
        const folders = sourceList.filter((source) => source.isDirectory)
        const sourceId = sources.map((source) => source.id)
        const folderId = folders.map((folder) => folder.id)

        this.setEditorState((prevState) => {
            return {
                ...prevState,
                data: { ...prevState.data, selectedArea: index },
                fileLoading: {
                    areaId: index,
                },
            }
        })

        api.send('getSources', { sourceId, folderId }, { hideLoader: false })
            .then((sources) => {
                if (index === -1) {
                    const s_ = this.state
                    this.setEditorState((prevState) => ({ ...prevState, isSoundtrackLoading: true }))
                    const audios = sources.filter((source) => source.data.type === 'audio')
                    const area = this.getArea(-1)

                    audios.forEach((source) => {
                        s_.data.sources[source.id] = source

                        area.content.push({
                            inPageContentId: generateIdByUUID(),
                            source: source,
                            sourceId: source.id,
                            duration: roundFloatToInteger(source.data.duration * 1000),
                            startTime: 0,
                            position: this.getContentPosition(source, area, area.content.length),
                            animation: {
                                in: null,
                                state: null,
                                out: null,
                            },
                            leftIndent: 0,
                            rightIndent: 0,
                            volume: 100,
                        })
                    })

                    this.calcContentPosition(area, area.autoPos)
                    this.setEditorState((prevState) => ({
                        ...prevState,
                        isSoundtrackLoading: false,
                    }))
                } else {
                    this.addContent(sources)
                }

                if (this.props.isMobile) {
                    this.setState({ multipleAddIndex: null })
                }
            })
            .catch(() => {
                this.setEditorState((prevState) => ({ ...prevState, fileLoading: null }))
                if (this.props.isMobile) {
                    this.setState({ multipleAddIndex: null })
                }
            })
    }

    smartFolderRuleSelect(rules) {
        const s_ = this.state
        this.setEditorState({
            ...s_,
            rulesList: rules,
        })
    }

    onCloseRulesList() {
        this.setState({
            ...editor.state,
            rulesList: [],
            folderId: null,
        })
    }

    smartFolderRuleSelected(id) {
        if (!id) {
            api.send('getSources', { folderId: this.state.folderId }, { hideLoader: false })
                .then((sources) => {
                    this.addContent(sources)
                    this.onCloseRulesList()
                })
                .catch(() => {
                    this.setEditorState((prevState) => ({ ...prevState, fileLoading: null }))
                })

            return
        }

        api.send('getFile', { id }, { hideLoader: true })
            .then((source) => {
                this.addContent(source)
                this.onCloseRulesList()
            })
            .catch(() => {
                this.setEditorState((prevState) => ({ ...prevState, fileLoading: null }))
            })
    }

    dropSoundtrackAudio(item) {
        const s_ = this.state
        const area = this.getArea(-1)

        this.setEditorState((prevState) => ({ ...prevState, isSoundtrackLoading: true }))

        api.send('getFile', { id: item.id }, { hideLoader: true }).then((source) => {
            s_.data.sources[source.id] = source

            area.content.push({
                inPageContentId: generateIdByUUID(),
                source: source,
                sourceId: source.id,
                duration: roundFloatToInteger(source.data.duration * 1000),
                startTime: 0,
                position: this.getContentPosition(source, area, area.content.length),
                animation: {
                    in: null,
                    state: null,
                    out: null,
                },
                leftIndent: 0,
                rightIndent: 0,
                volume: 100,
            })

            this.calcContentPosition(area, area.autoPos)
            this.setEditorState((prevState) => ({
                ...prevState,
                isSoundtrackLoading: false,
            }))
        })
    }

    onDropFolderToSoundtrack = (folder) => {
        const { emitError } = this.props
        this.setEditorState((prevState) => ({
            ...prevState,
            isSoundtrackLoading: true,
        }))

        api.send('getSources', { folderId: folder.id })
            .then((res) => {
                const audios = res.filter((source) => source.fileType === 'audio')

                if (!audios.length) {
                    emitError('folderNotContainAudios')

                    this.setEditorState((prevState) => ({
                        ...prevState,
                        isSoundtrackLoading: false,
                    }))

                    return
                }

                this.addAudios(audios)
            })
            .catch(() => {
                this.setEditorState((prevState) => ({
                    ...prevState,
                    isSoundtrackLoading: false,
                }))
            })
    }

    addAudios = (audios) => {
        const s_ = this.state
        const area = this.getArea(-1)

        audios.forEach((audio) => {
            s_.data.sources[audio.id] = audio

            area.content.push({
                inPageContentId: generateIdByUUID(),
                source: audio,
                sourceId: audio.id,
                duration: roundFloatToInteger(audio.data.duration * 1000),
                startTime: 0,
                position: this.getContentPosition(audio, area, area.content.length),
                animation: {
                    in: null,
                    state: null,
                    out: null,
                },
                leftIndent: 0,
                rightIndent: 0,
                volume: 100,
            })
        })

        this.calcContentPosition(area, area.autoPos)
        this.setEditorState((prevState) => ({
            ...prevState,
            isSoundtrackLoading: false,
        }))
    }

    // Удалить контент
    deleteContent(selectedArea, selectedContent, inPageContentId) {
        const s_ = this.state
        let area = this.getArea()
        const _selectedContent = s_.data.selectedContent
        let selectedContentIndex = null

        if (area && area.content && _selectedContent !== null) {
            s_.data.showSettings = null
            s_.data.selectedContent = null
            s_.data.showAnimationSettings = false

            if (inPageContentId && typeof inPageContentId === 'string') {
                const content = area.content.find((el) => el.inPageContentId === inPageContentId)

                if (content) {
                    selectedContentIndex = content.position.zIndex
                }
            }

            area.content.splice(selectedContentIndex !== null ? selectedContentIndex : _selectedContent, 1)
            this.setIndentsInArea(area)
            this.updateZIndex(area, true)
            // Автоподсчет продолжительности страницы при удалении контента
            // с условием "Расположить контент непрерывно по времени
            if (area.autoPos === 'withoutSpaces') {
                this.setContentPageDuration()
            }
        }

        this.setLayersCount()
        this.setEditorState(s_)
    }

    // Редакторовать контент (Таблицы или текст)
    editContent() {
        const s_ = this.state
        const { activateEditText, activateEditLink, activateEditTable } = this.props
        const contentItem = this.getContentItem()

        s_.data.showSettings = null
        s_.data.selectedContent = null
        s_.data.showAnimationSettings = false

        const methods = {
            table: activateEditTable,
            link: activateEditLink,
            text: activateEditText,
        }

        const method = methods[contentItem.source.type]

        if (method) {
            method(contentItem.sourceId)
        }

        this.setEditorState(s_)
    }

    // Удалить зону
    deleteArea() {
        const s_ = this.state
        const areas = this.getAreas()
        const selectedArea = s_.data.selectedArea

        s_.data.selectedArea = null
        s_.data.showSettings = null
        s_.data.selectedTemplate[s_.data.selectedPage] = null
        areas.splice(selectedArea, 1)

        this.setEditorState(s_)
    }

    // Есть ли пересечения у зон
    intersectAreas(checkArea1, checkArea2) {
        const area1 = this.toAbsAreaSize(checkArea1)
        const area2 = this.toAbsAreaSize(checkArea2)

        const intersect = overlap(getOverlapRectangle(area1), getOverlapRectangle(area2))

        return intersect && intersect.area > 0
    }

    // Множественный выбор контента в зоне
    onMultiSelect = (checked, inPageContentId, sourceId) => {
        const s_ = this.state
        let selected = []

        if (checked) {
            if (sourceId) {
                const selectedPage = s_.data.selectedPage
                const selectedArea = s_.data.selectedArea
                const inPageContentIds = s_.broadcast.pages[selectedPage].areas[selectedArea].content
                    .filter((source) => source.sourceId === sourceId)
                    .map((source) => source.inPageContentId)
                selected = [...s_.data.selected, ...inPageContentIds]
            } else {
                selected = [...s_.data.selected, inPageContentId]
            }
        } else {
            selected = s_.data.selected.filter((item) => item !== inPageContentId)
        }

        this.setEditorState({
            data: {
                ...s_.data,
                selected,
            },
        })
    }

    // Получить абсолютные размеры зоны в пикселях
    toAbsAreaSize(area) {
        const s_ = this.state

        return {
            top: Math.round(area.top * s_.broadcast.resolutionHeight),
            height: Math.round(area.height * s_.broadcast.resolutionHeight),
            left: Math.round(area.left * s_.broadcast.resolutionWidth),
            width: Math.round(area.width * s_.broadcast.resolutionWidth),
        }
    }

    // Сохранить трансляцию
    save(options = {}) {
        const s_ = this.state
        const { isValid, coverId } = this.validate()
        let templateCreatedCounter = 0

        if (isValid) {
            const broadcast = this.copyBroadcast(s_.broadcast)
            const currentPage = this.getPage()
            broadcast.coverId = coverId

            if (broadcast.resolutionWidth < broadcast.resolutionHeight) {
                broadcast.orientation = 90
            } else {
                broadcast.orientation = 0
            }

            if (options.forceUpdate) {
                broadcast.forceUpdate = true
            }

            broadcast.soundtrack = this.props.onSave
                ? currentPage.areas[-1].content
                : currentPage.areas[-1].content.map((contentItem) => ({
                      ...contentItem,
                      animation: undefined,
                      source: undefined,
                      leftIndent: undefined,
                      rightIndent: undefined,
                      layersCount: undefined,
                  }))

            const setPageTemplate = (template, pageIndex) => {
                broadcast.pages[pageIndex].templateId = template.id
                broadcast.pages[pageIndex].areas = broadcast.pages[pageIndex].areas.map((area, index) => {
                    const p_ = this.props
                    const content = p_.onSave
                        ? area.content
                        : area.content.map((contentItem) => ({
                              ...contentItem,
                              source: undefined,
                              leftIndent: undefined,
                              rightIndent: undefined,
                              layersCount: undefined,
                          }))

                    const areaPosition = getFormattedAreaSize(area)

                    const templateArea = template.areas.find((templateArea) => {
                        const templateAreaPosition = {
                            width: templateArea.width,
                            height: templateArea.height,
                            top: templateArea.top,
                            left: templateArea.left,
                        }

                        return deepEqual(templateAreaPosition, areaPosition)
                    })

                    return {
                        templateAreaId: templateArea.id,
                        content,
                        id: area.id,
                        loop: area.loop,
                        contentOrderMode: area.contentOrderMode,
                        contentWithoutSpaces: area.autoPos === 'withoutSpaces',
                    }
                })
            }

            broadcast.pages.forEach((page, pageIndex) => {
                if (s_.data.selectedTemplate[pageIndex]) {
                    setPageTemplate(s_.data.selectedTemplate[pageIndex], pageIndex)
                } else {
                    templateCreatedCounter++
                    const areas = page.areas.map(getFormattedAreaSize)

                    api.send('createTemplate', { areas }).then((template) => {
                        templateCreatedCounter--
                        setPageTemplate(template, pageIndex)
                        const clearedBroadcast = this._clearBroadcast(broadcast)

                        if (!templateCreatedCounter) {
                            this.saveMethod(clearedBroadcast)
                        }
                    })
                }
            })

            const clearedBroadcast = this._clearBroadcast(broadcast)

            if (!templateCreatedCounter) {
                this.saveMethod(clearedBroadcast)
            }
        }

        return isValid
    }

    _clearBroadcast = (broadcast) => {
        let clearedBroadcast = this._clearContents(broadcast)
        return clearedBroadcast
    }

    _clearContents = (clearedBroadcast) => {
        const broadcast = { ...clearedBroadcast }
        broadcast.pages.forEach((page) => {
            page.areas.forEach((area) => {
                area.content.forEach((content) => {
                    delete content.inPageContentId
                })
            })
        })

        broadcast.soundtrack.forEach((sountrack) => {
            delete sountrack.inPageContentId
        })

        return broadcast
    }

    // Логика сохранения трансляции
    saveMethod(broadcast) {
        const s_ = this.state
        const p_ = this.props

        if (p_.onSave) {
            p_.onSave(broadcast)

            return
        }

        s_.data.saving = true
        const method = typeof broadcast.id !== 'undefined' ? 'updateBroadcast' : 'createBroadcast'

        api.send(method, broadcast).then(() => {
            this.changeLocation()
        })
    }

    changeLocation = () => {
        const query = getURLSearchParamsByLocation(this.props.location)
        const folderId = query.broadcastFolderId

        const location = {
            pathname: `/${routes.broadcasts.path}`,
        }

        if (folderId) {
            location.search = getURLSearchParamsString({
                ...query,
                folderId,
            })
        }

        changeLocation(location)
    }

    // Валидация перед сохранением
    validate() {
        const { emitError } = this.props
        const s_ = this.state
        let isValid = true
        this.validateTitle()

        if (s_.data.titleError) {
            isValid = false
        }

        const contentValidate = this.contentLengthValidate()
        if (!contentValidate.isValid) {
            isValid = false
            emitError('advContentEmpty')
        }

        if (!s_.broadcast.folderId) {
            isValid = false
            emitError('chooseFolder')

            this.setEditorState({
                data: {
                    ...s_.data,
                    showSettings: 'broadcast',
                },
            })
        }

        return { isValid, coverId: contentValidate.coverId }
    }

    // Валидация, есть ли контент в трансляции
    contentLengthValidate() {
        const s_ = this.state
        let isValid = true
        let contentLength = 0
        let coverId
        s_.broadcast.pages.forEach((page) => {
            page.areas.forEach((area) => {
                area.content.forEach((contentItem) => {
                    contentLength++
                    if (!coverId) {
                        coverId = contentItem.sourceId
                    }
                })
            })
        })

        if (contentLength === 0) {
            isValid = false
        }

        return { isValid, coverId }
    }

    // Предупреждение при смене шаблона, если на текущей странице есть контент
    pageContentLength = () => {
        const s_ = this.state
        const selectedPage = s_.data.selectedPage
        let contentLength = 0

        s_.broadcast.pages[selectedPage].areas.forEach((area) => {
            area.content.forEach(() => {
                contentLength++
            })
        })

        return contentLength
    }

    // Закрыть трансляцию
    close() {
        changeLocation(`/${routes.broadcasts.path}`)
    }

    // Переключатель режима отображения тулбара. Минимальный или полный
    toggleMinimized() {
        const s_ = this.state
        if (!s_.data.minimized) {
            clearTimeout(minimizedTimeout)
            minimizedTimeout = setTimeout(() => {
                this.hideSettings()
            }, transitions.normal)
        }

        s_.data.minimized = !s_.data.minimized

        this.setEditorState(s_)
    }

    // Открыть в полный режим правый тулбар редактора
    openMinimized() {
        const s_ = this.state

        if (s_.data.minimized) {
            this.toggleMinimized()
        }
    }

    // Создать модель зон из шаблона
    setArea(template) {
        const s_ = this.state
        const page = this.getPage()
        const soundtrack = page.areas[-1]

        page.areas = []
        template.areas.forEach((area, index) => {
            const position = {
                width: area.width,
                height: area.height,
                top: area.top,
                left: area.left,
            }

            page.areas[index] = createArea(position)
        })
        page.areas[-1] = soundtrack

        s_.data.selectedTemplate[s_.data.selectedPage] = template
        this.setEditorState(s_)
    }

    // Получить модель редактируемой трансляции
    initBroadcast(broadcastId) {
        api.send('getBroadcast', { id: broadcastId, includeAll: true }).then((broadcast) =>
            this.setBroadcast(broadcast)
        )
    }

    // Установить данные для редактирования из модели трансляции с сервера
    setBroadcast(broadcast) {
        api.send('getTemplates', {}).then((res) => {
            const s_ = this.state
            const templates = {}
            res.forEach((template) => {
                templates[template.id] = template
            })

            s_.broadcast.id = broadcast.id
            s_.broadcast.title = broadcast.title
            s_.broadcast.soundOrderMode = broadcast.soundOrderMode
            s_.broadcast.broadcastType = broadcast.broadcastType
            s_.broadcast.orientation = broadcast.orientation
            s_.broadcast.folderId = broadcast.folderId

            if (broadcast.broadcastType === 'simple') {
                s_.data.simple = true
            }

            /* Add audio */
            const soundtrack = []
            const soundtrackContent = broadcast.soundtrack.sort(
                (contentItem1, contentItem2) => contentItem1.position.zIndex - contentItem2.position.zIndex
            )
            soundtrackContent.forEach((content, contentIndex) => {
                s_.data.sources[content.sourceId] = content.source
                const leftIndent = this.getLeftIndent(content, broadcast.soundtrack[contentIndex - 1])
                const rightIndent = this.getRightIndent(content, broadcast.soundtrack[contentIndex + 1])
                soundtrack[contentIndex] = {
                    inPageContentId: generateIdByUUID(),
                    id: content.id,
                    source: content.source,
                    sourceId: content.sourceId,
                    duration: content.duration,
                    startTime: content.startTime,
                    position: content.position,
                    animation: content.animation,
                    playingCondition: content.playingCondition,
                    volume: content.volume,
                    leftIndent,
                    rightIndent,
                }
            })
            /* /Add audio */

            this.changeResolutionSize(broadcast.resolutionWidth, 'width')
            this.changeResolutionSize(broadcast.resolutionHeight, 'height')
            broadcast.pages.forEach((page, pageIndex) => {
                const pageTemplate = templates[page.templateId]

                s_.data.selectedTemplate[pageIndex] = pageTemplate
                s_.broadcast.pages[pageIndex] = createPage(pageIndex, page.duration, soundtrack)
                s_.broadcast.pages[pageIndex].id = page.id
                s_.broadcast.pages[pageIndex].playOnEvent = page.playOnEvent
                s_.broadcast.pages[pageIndex].nextPageOrder = page.nextPageOrder

                pageTemplate.areas.forEach((area, index) => {
                    const positionArea = pageTemplate.areas.find((area) => area.id === page.areas[index].templateAreaId)

                    const position = {
                        width: positionArea.width,
                        height: positionArea.height,
                        top: positionArea.top,
                        left: positionArea.left,
                    }

                    s_.broadcast.pages[pageIndex].areas[index] = createArea(position)
                    s_.broadcast.pages[pageIndex].areas[index].id = page.areas[index].id
                    s_.broadcast.pages[pageIndex].areas[index].loop = page.areas[index].loop
                })

                page.areas.forEach((area, areaIndex) => {
                    const areaContent = area.content.sort(
                        (contentItem1, contentItem2) => contentItem1.position.zIndex - contentItem2.position.zIndex
                    )

                    areaContent.forEach((content, contentIndex) => {
                        s_.data.sources[content.sourceId] = content.source
                        const leftIndent = this.getLeftIndent(content, area.content[contentIndex - 1])
                        const rightIndent = this.getRightIndent(content, area.content[contentIndex + 1])

                        s_.broadcast.pages[pageIndex].areas[areaIndex].content[contentIndex] = {
                            id: content.id,
                            inPageContentId: generateIdByUUID(),
                            source: content.source,
                            sourceId: content.sourceId,
                            duration: content.duration,
                            startTime: content.startTime,
                            position: content.position,
                            animation: content.animation,
                            playingCondition: content.playingCondition,
                            actionsOnContent: content.actionsOnContent,
                            volume: content.volume,
                            leftIndent,
                            rightIndent,
                            style: content.style,
                        }

                        if (content.source.extension === '.pdf') {
                            const props = s_.broadcast.pages[pageIndex].areas[areaIndex].content[contentIndex]
                            props.subItemDurationS = content.subItemDurationS
                            props.subItems = content.subItems
                        }

                        s_.broadcast.pages[pageIndex].areas[areaIndex].contentOrderMode = area.contentOrderMode
                        s_.broadcast.pages[pageIndex].areas[areaIndex].autoPos = area.contentWithoutSpaces
                            ? 'withoutSpaces'
                            : null
                    })
                })
            })

            this.navigateToPage(0)
            if (!this.props.broadcast) {
                this.startBroadcast = this.copyBroadcast(this.state.broadcast)
            }
            this.isXViewAvailable()
            this.setEditorState(s_)
        })
    }

    // Скопировать трансляцию
    copyBroadcast(broadcastObj) {
        const broadcast = helpers.copyObject(broadcastObj)

        broadcast.pages.forEach((page, pageIndex) => {
            page.areas[-1] = helpers.copyObject(broadcastObj.pages[pageIndex].areas[-1])
        })

        return broadcast
    }

    // Максимальная продолжительность из страниц в трансляции
    getMaxDuration = () => {
        const broadcast = this.steps[0] ? this.steps[0].broadcast : this.state.broadcast

        const durations = broadcast.pages.map((page) => page.duration)
        const maxDuration = helpers.getMaxOfArray(durations)

        return maxDuration
    }

    // Добавить новую таблицу
    addTable = () => {
        const { saveTable, activateEditTable } = this.props
        const tableOptions = filesHelpers.getDefaultTableOptions()
        const query = getURLSearchParamsByLocation(this.props.location)

        saveTable({
            data: {
                data: {
                    ...tableOptions,
                },
                type: 'table',
                folderId: query.folderId,
            },
            options: query,
            callback: (source) => {
                this.addContent(source)
                this.hideSettings()
                activateEditTable(source.data.id)
            },
        })
    }

    // Скопировать контент из зоны
    copyAreaContent = () => {
        const area = this.getArea()
        const s_ = this.state

        s_.data.clonedArea = helpers.deepCopy(area)
        this.setEditorState(s_)
    }

    // Вставить скопированный из зоны контент
    pasteAreaContent = () => {
        const s_ = this.state
        const area = this.getArea()

        if (area) {
            s_.data.clonedArea.content.forEach((content) => {
                this.cloneContent(content)
            })
        }
    }

    onDelete = () => {
        const s_data = this.getStateData()
        const isSimple = s_data.simple
        const isSelectedContent = s_data.selectedContent !== null

        if (isSelectedContent) {
            editor.deleteContent()
        } else if (!isSimple) {
            editor.deleteArea()
        }
    }

    onClose = () => {
        editor.close()
    }

    onSave = (options) => {
        editor.save(options)
    }

    onAddArea = () => {
        editor.addArea()
    }

    onOpenBroadcastSettings = () => {
        editor.showSettings('broadcast')
        editor.openMinimized()
    }

    onFullscreenContent = () => {
        const s_data = this.getStateData()
        editor.setFullscreenPosition(s_data.selectedArea, s_data.selectedContent)
    }

    onFullscreenMaximizeContent = () => {
        const s_data = this.getStateData()
        editor.setFullscreenMaximizePosition(s_data.selectedArea, s_data.selectedContent)
    }

    onFullscreenCropContent = () => {
        const s_data = this.getStateData()
        editor.setFullscreenCropPosition(s_data.selectedArea, s_data.selectedContent)
    }

    onCopyAreaContent = () => {
        editor.copyAreaContent()
    }

    onPasteAreaContent = () => {
        editor.pasteAreaContent()
    }

    onAnimationSettings = () => {
        editor.toggleAnimationSettings()
    }

    onGoPrev = () => {
        const s_ = this.state
        this.changeStep(s_.currentTimeStep - 1)
    }

    onGoNext = () => {
        const s_ = this.state
        this.changeStep(s_.currentTimeStep + 1)
    }

    onSelectContent = (selectedContent) => {
        const s_data = this.getStateData()
        editor.selectContent(s_data.selectedArea, selectedContent, true)
    }

    onSelectArea = (selectedArea, showAreaSettings) => {
        editor.selectArea(selectedArea, showAreaSettings)
    }

    onAccept = (cb) => {
        cb(this.save())
    }

    isPreventLeaveRoute() {
        const p_ = this.props
        const s_ = this.state
        return p_.showExitWarning && !s_.data.saving && !p_.isDeleted && !deepEqual(s_.broadcast, this.startBroadcast)
    }

    toggleShowPages = () => {
        this.setState({ mobilePagesOpened: !this.state.mobilePagesOpened })
    }

    showToolbar = (value) => {
        this.setState({ toolbarIsOpened: value })
    }
    setAreaIndexForMultipleAdd = (index) => {
        this.setState({ multipleAddIndex: index })
    }

    actionBarToggle = (value) => {
        this.setState({ actionsBarOpened: value })
    }

    setFullScreenArea = () => {
        const s_ = this.state

        s_.data.validSelectedArea.width = 1
        s_.data.validSelectedArea.height = 1
        s_.broadcast.pages[s_.data.selectedPage].areas[s_.data.selectedArea].width = 1
        s_.broadcast.pages[s_.data.selectedPage].areas[s_.data.selectedArea].height = 1

        this.setState(s_)
    }

    isSingleArea = () => {
        const s_ = this.state

        return s_.broadcast.pages[s_.data.selectedPage].areas.length === 1
    }
}

export default EditorMethods
